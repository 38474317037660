// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
export const windowSize = {
  util: {
    valueMatches: (documentWidth, operation, breakpoint) => {
      let match;
      switch (operation) {
        case '<':
          match = documentWidth < breakpoint;
          break;
        case '>':
          match = documentWidth > breakpoint;
          break;
        case '=':
          match = documentWidth === breakpoint;
          break;
      }
      return match;
    },
  },
};
