// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import dividerStyles from './sh-divider.lit.scss.js';
let SHDivider = class SHDivider extends LitElement {
  constructor() {
    super(...arguments);
    /** Defines the margin around the divider line. Possible values are `s` (0.5rem), `m` (1rem), `l` (1.5rem), `xl` (3rem) and 'none' (0)
     * @type {"s"|"m"|"l"|"xl"|"none"}
     */
    this.spacing = 's';
    /** If set, the divider will have a vertical orientation  */
    this.vertical = false;
    /**This attribute will control the visual emphasis of the divider */
    this.variant = 'subtle';
  }
  static get styles() {
    return [dividerStyles];
  }
  render() {
    return html` <div class="divider-wrapper"></div> `;
  }
  updated(changedProperties) {
    const listOfProperties = ['vertical', 'spacing', 'variant'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
};
__decorate([property({ type: String, reflect: true })], SHDivider.prototype, 'spacing', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDivider.prototype, 'vertical', void 0);
__decorate([property({ type: String, reflect: true })], SHDivider.prototype, 'variant', void 0);
SHDivider = __decorate([customElement('sh-divider')], SHDivider);
export { SHDivider };
