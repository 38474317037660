// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/**
 * @deprecated use \@event() decorator instead
 * ### dispatchCustomEvent()
 * ___
 * Function that dispatches custom events for SHUI components
 * ___
 * #### How to use this function ?
 *
 * Import like this in the component:
 *
 * ```js
 * import {dispatchCustomEvent} from '@shui-web-components/utils/custom-event-dispatcher.js'
 * ```
 * Then use the function like the following:
 *
 * Example : Suppose the custom event name is `drawer-closed`,
 *
 * Without detail object, the syntax would be:
 * ```js
 * dispatchCustomEvent(this, 'drawer-closed')
 * ```
 * With detail object, the syntax might look something like this:
 * ```js
 * dispatchCustomEvent(this, 'drawer-closed', {message: 'Something important happened'})
 * ```
 * ___
 * @param {HTMLElement} component - the component that dispatches the event
 * @param {string} eventName - the name of the event
 * @param {object} [detail] - optional - default value: null - The detail object
 * @param {boolean} [bubbles] - optional - default value: true - Whether the event should bubble or not
 * @param {boolean} composed - optional - default value: true - Whether the event should be composed or not
 */
export const dispatchCustomEvent = function (
  component: Element,
  eventName: string,
  detail: unknown = null,
  bubbles = true,
  composed = true
) {
  component.dispatchEvent(
    new CustomEvent(eventName, {
      bubbles: bubbles,
      composed: composed,
      detail: detail,
    })
  );
};
