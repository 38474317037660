import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import emptyStateStyles from './sh-empty-state.lit.scss.js';
/**
 * @slot footer - Takes Buttons or Text Links as children.
 * @slot image - Takes images as children.
 */
let SHEmptyState = class SHEmptyState extends LitElement {
  constructor() {
    super(...arguments);
    this.emptyFooter = true;
    this.emptyImage = true;
    /** Defines the icon shown above the text. If left undefined, no icon will be shown. */
    this.dragAndDrop = false;
  }
  static get styles() {
    return [emptyStateStyles];
  }
  render() {
    return html`
      <div class="image-wrapper" ?empty-image="${this.emptyImage}">
        <slot name="image" @slotchange="${(e) => this.ImageSlotChange(e)}" id="imageSlot"></slot>
      </div>
      ${this.icon && this.emptyImage
        ? html`<sh-icon icon="${this.icon}" .color="${this.iconColor}"></sh-icon>`
        : nothing}
      ${this.header ? html` <sh-text size="header-2" class="header">${this.header}</sh-text> ` : ''}
      <sh-text size="body-1">${this.label}</sh-text>
      <div class="footer-wrapper" ?empty-footer="${this.emptyFooter}">
        <slot name="footer" @slotchange="${(e) => this.footerSlotChange(e)}" id="footerSlot"></slot>
      </div>
    `;
  }
  updated(changedProperties) {
    const listOfProperties = ['label', 'icon', 'dragAndDrop', 'iconColor', 'header'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  footerSlotChange(e) {
    const footerslot = e.target;
    const footerslotNode = footerslot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerslotNode === 0;
  }
  ImageSlotChange(e) {
    const imageslot = e.target;
    const imagesSlotNodes = imageslot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyImage = imagesSlotNodes === 0;
  }
};
__decorate([state()], SHEmptyState.prototype, 'emptyFooter', void 0);
__decorate([state()], SHEmptyState.prototype, 'emptyImage', void 0);
__decorate([property({ type: String, reflect: true })], SHEmptyState.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHEmptyState.prototype, 'icon', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'drag-and-drop' })],
  SHEmptyState.prototype,
  'dragAndDrop',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHEmptyState.prototype,
  'iconColor',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHEmptyState.prototype, 'header', void 0);
SHEmptyState = __decorate([customElement('sh-empty-state')], SHEmptyState);
export { SHEmptyState };
