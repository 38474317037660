// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-value-indicator.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-value-indicator-l-value-line-height=4rem] - line height of value for l size
   */
  --sh-value-indicator-l-value-line-height: 4rem;
  /* 
   * @cssprop [--sh-value-indicator-l-label-line-height=1.5rem] - line height of label for l size
   */
  --sh-value-indicator-l-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-label-line-height=1rem] - line height of label for s size
   */
  --sh-value-indicator-s-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-value-indicator-l-icon-size-m=4rem] - icon size for l size
   */
  --sh-value-indicator-l-icon-size-m: 4rem;
  /* 
   * @cssprop [--sh-value-indicator-s-icon-size-m=2rem] - icon size for s size
   */
  --sh-value-indicator-s-icon-size-m: 2rem;
  /* 
   * @cssprop [--sh-value-indicator-s-value-line-height=2rem] - line height of value for s size
   */
  --sh-value-indicator-s-value-line-height: 2rem;
  /* 
   * @cssprop [--sh-value-indicator-l-unit-line-height=2.5rem] - line height of unit for l size
   */
  --sh-value-indicator-l-unit-line-height: 2.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-unit-line-height=1.25rem] - line height of unit for s size
   */
  --sh-value-indicator-s-unit-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-value-indicator-l-unit-font-size=1.75rem] - line height of unit for s size
   */
  --sh-value-indicator-l-unit-font-size: 1.75rem;
  /* 
   * @cssprop [--sh-value-indicator-s-unit-font-size=0.875rem] - line height of unit for s size
   */
  --sh-value-indicator-s-unit-font-size: 0.875rem;
  /* 
   * @cssprop [--sh-value-indicator-l-icon-font-size-m=4rem] - font size of icon for l size
   */
  --sh-value-indicator-l-icon-font-size-m: 4rem;
  /* 
   * @cssprop [--sh-value-indicator-s-icon-font-size-m=2rem] - font size of icon for s size
   */
  --sh-value-indicator-s-icon-font-size-m: 2rem;
  /* 
   * @cssprop [--sh-value-indicator-l-value-font-size=3.5rem] - font size of value for l size
   */
  --sh-value-indicator-l-value-font-size: 3.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-value-font-size=1.75rem] - font size of value for s size
   */
  --sh-value-indicator-s-value-font-size: 1.75rem;
  /* 
   * @cssprop [--sh-value-indicator-l-label-font-size=1rem] - font size of label for l size
   */
  --sh-value-indicator-l-label-font-size: 1rem;
  /* 
   * @cssprop [--sh-value-indicator-s-label-font-size=0.875rem] - font size of label for s size
   */
  --sh-value-indicator-s-label-font-size: 0.875rem;
  /* 
   * @cssprop [--sh-value-indicator-s-status-size-m=1.5rem] - size of status for s size
   */
  --sh-value-indicator-s-status-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-status-font-size-m=1.5rem] - font size of status for s size
   */
  --sh-value-indicator-s-status-font-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-icon-margin-right=0.25rem] - margin right of icon for s size
   */
  --sh-value-indicator-s-icon-margin-right: 0.25rem;
  /* 
   * @cssprop [--sh-value-indicator-l-icon-margin-right=0.5rem] - margin right of icon for l size
   */
  --sh-value-indicator-l-icon-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-value-indicator-s-unit-padding-top=0.625rem] - padding top of unit for s size
   */
  --sh-value-indicator-s-unit-padding-top: 0.625rem;
  /* 
   * @cssprop [--sh-value-indicator-l-unit-padding-top=1.23rem] - padding top of unit for l size
   */
  --sh-value-indicator-l-unit-padding-top: 1.23rem;
}

:host {
  display: flex;
  flex-direction: column;
}

:host > * + * {
  margin-top: 0.25rem;
}

.value-wrapper {
  display: flex;
  align-items: center;
}

.status,
.unit {
  margin-left: 0.25rem;
}

:host([size=s]) .unit {
  padding-top: var(--sh-value-indicator-s-unit-padding-top);
}

:host([size=l]) .unit {
  padding-top: var(--sh-value-indicator-l-unit-padding-top);
}

:host([size=s]) .icon {
  margin-right: var(--sh-value-indicator-s-icon-margin-right);
}

:host([size=l]) .icon {
  margin-right: var(--sh-value-indicator-l-icon-margin-right);
}

sh-text {
  width: auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* size */
:host([size=l]) {
  align-items: center;
  padding: 0.5rem;
}

:host([size=s]) {
  align-items: left;
}

.value,
.label {
  font: var(--body-1);
}

:host([size=l]) .value {
  line-height: var(--sh-value-indicator-l-value-line-height);
}

:host([size=l]) .label {
  font-size: var(--sh-value-indicator-l-label-font-size);
  line-height: var(--sh-value-indicator-l-label-line-height);
}

:host([size=s]) .label {
  line-height: var(--sh-value-indicator-s-label-line-height);
  font-size: var(--sh-value-indicator-s-label-font-size);
}

:host([size=l]) .value {
  font-size: var(--sh-value-indicator-l-value-font-size);
}

:host([size=l]) .unit {
  line-height: var(--sh-value-indicator-l-unit-line-height);
  font-size: var(--sh-value-indicator-l-unit-font-size);
}

:host([size=s]) .unit {
  line-height: var(--sh-value-indicator-s-unit-line-height);
  font-size: var(--sh-value-indicator-s-unit-font-size);
}

:host([size=l]) .icon {
  --sh-icon-size-m: var(--sh-value-indicator-l-icon-size-m);
  --sh-icon-font-size-m: var(--sh-value-indicator-l-icon-font-size-m);
}

:host([size=s]) .icon {
  --sh-icon-size-m: var(--sh-value-indicator-s-icon-size-m);
  --sh-icon-font-size-m: var(--sh-value-indicator-s-icon-font-size-m);
}

:host([size=s]) .value {
  line-height: var(--sh-value-indicator-s-value-line-height);
  font-size: var(--sh-value-indicator-s-value-font-size);
}

slot {
  display: flex;
}

:host([size=s]) .status {
  --sh-icon-size-m: var(--sh-value-indicator-s-status-size-m);
  --sh-icon-font-size-m: var(--sh-value-indicator-s-status-font-size-m);
}`;