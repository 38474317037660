// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2024. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent<
  Prop extends string,
  TDetail = void,
> extends CustomEvent<TDetail> {
  override readonly type!: `${Prop}-changed`;

  constructor(prop: Prop, init?: CustomEventInit<TDetail>) {
    super(`${prop}-changed`, init);
  }
}

export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent<'disabled', boolean> {
  constructor(init?: CustomEventInit<boolean>) {
    super('disabled', init);
  }
}

export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent<'icon'> {
  constructor() {
    super('icon');
  }
}

export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent<'label'> {
  constructor() {
    super('label');
  }
}

export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent<'size'> {
  constructor() {
    super('size');
  }
}

export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent<'color'> {
  constructor() {
    super('color');
  }
}

export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent<'tooltip'> {
  constructor() {
    super('tooltip');
  }
}

export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent<'href'> {
  constructor() {
    super('href');
  }
}
