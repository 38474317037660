// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import gridStyles from './sh-grid.lit.scss.js';
/**
 * @attr {Boolean} fit-content - If true, the grid will wrap to the height of it's content. If false, it will have fit to the height of parent.
 * @slot -unnamed slot takes any component as a child.
 */
let SHGrid = class SHGrid extends LitElement {
  static get styles() {
    return [gridStyles];
  }
  render() {
    return html` <slot spacing="${this.spacing}" style="min-width: 0rem"></slot> `;
  }
  constructor() {
    super();
    /**Defines the gutter between columns and rows. Possible values are `s` (0.5rem), `m` (1rem) and `l` (1.5rem).
     * @type {"s"|"m"|"l"}
     */
    this.spacing = 'm';
    /**If true, the grid will wrap to the height of it's content. If false, it will have fit to the height of parent. */
    this.fitContent = false;
    this.spacing = 'm';
  }
  updated(changedProperties) {
    if (changedProperties.has('rows')) {
      this._rowsChanged();
    }
    if (changedProperties.has('columns')) {
      this._columnsChanged();
    }
  }
  // dynamically generate columns and rows on load
  _rowsChanged() {
    if (this.rows !== undefined) {
      this.style.gridTemplateRows = 'repeat(' + this.rows + ', 1fr)';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.style.msGridRows = '(1fr)[' + this.rows + ']';
    }
  }
  _columnsChanged() {
    if (this.rows !== undefined) {
      this.style.gridTemplateColumns = 'repeat(' + this.columns + ', 1fr)';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.style.msGridColumns = '(1fr)[' + this.columns + ']';
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHGrid.prototype, 'spacing', void 0);
__decorate([property({ type: Number, reflect: true })], SHGrid.prototype, 'columns', void 0);
__decorate([property({ type: Number, reflect: true })], SHGrid.prototype, 'rows', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'fit-content' })],
  SHGrid.prototype,
  'fitContent',
  void 0
);
SHGrid = __decorate([customElement('sh-grid')], SHGrid);
export { SHGrid };
