// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
let SHBreadcrumbs = class SHBreadcrumbs extends LitElement {
  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          float: left;
          display: block;
          overflow: hidden;
        }
      `,
    ];
  }
  render() {
    return html` <slot @slotchange="${this._handleSlotChange}"></slot> `;
  }
  _handleSlotChange() {
    const breadcrumbItems = this.querySelectorAll('sh-breadcrumb-item');
    for (let i = 0; i < breadcrumbItems.length; i++) {
      breadcrumbItems[i].active = true;
      if (breadcrumbItems[i - 1]) {
        breadcrumbItems[i - 1].active = false;
      }
    }
  }
};
SHBreadcrumbs = __decorate([customElement('sh-breadcrumbs')], SHBreadcrumbs);
export { SHBreadcrumbs };
