import { __awaiter, __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
let SHStepper = class SHStepper extends RefrainSpaceScrollMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**Default neutral. The property allows to switch between the 'primary' and 'neutral' versions of the stepper.*/
    this.color = 'neutral';
  }
  static get styles() {
    return [
      css`
        :host {
          width: 100%;
          height: auto;
          display: flex;
        }
        :host([vertical]) {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
        }
        :host([readonly]) {
          pointer-events: none;
        }
        :host(:focus-visible) {
          outline: var(--focus-outline);
        }
      `,
    ];
  }
  render() {
    return html`<slot @slotchange=${this.slotChange} @click=${this.clickFromSlot}></slot>`;
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', (e) => this.handleKeydown(e));
  }
  handleKeydown(e) {
    var _a, _b, _c;
    if (this.condensed) {
      return;
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT) && e.code !== 'Tab') {
      if (
        e.target.nextElementSibling &&
        e.target.nextElementSibling.tagName === 'SH-STEPPER-ITEM' &&
        !e.target.nextElementSibling.disabled
      ) {
        ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null ||
        _b === void 0
          ? void 0
          : _b.querySelector('#stepperCircle')
        ).classList.remove('item-focused');
        this.setFocus(e.target.nextElementSibling);
      } else if (e.target.tagName === 'SH-STEPPER') {
        this.setFocus(e.target.children[0]);
      } else {
        this.setFocus(e.target);
      }
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_LEFT) && e.code !== 'Tab') {
      if (
        e.target.previousElementSibling &&
        !e.target.previousElementSibling.disabled &&
        e.target.previousElementSibling.tagName === 'SH-STEPPER-ITEM'
      ) {
        this.setFocus(e.target.previousElementSibling);
        ((_c = e.target.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('#stepperCircle')
        ).classList.remove('item-focused');
      } else if (e.target.tagName === 'SH-STEPPER') {
        const stepperItems = e.target.querySelectorAll('sh-stepper-item:not([disabled])');
        this.setFocus(stepperItems[stepperItems.length - 1]);
      } else {
        this.setFocus(e.target);
      }
    }
  }
  setFocus(stepperItem) {
    var _a, _b;
    stepperItem.focus();
    (_b =
      (_a = stepperItem === null || stepperItem === void 0 ? void 0 : stepperItem.shadowRoot) ===
        null || _a === void 0
        ? void 0
        : _a.querySelector('#stepperCircle')) === null || _b === void 0
      ? void 0
      : _b.classList.add('item-focused');
  }
  clickFromSlot(e) {
    // only consider clics on stepper items
    if (e.target && e.target.tagName === 'SH-STEPPER-ITEM') {
      this.value = e.target.number;
      this.updateItems();
    }
  }
  // potentially there are several problem shere
  // 1. a user cannot control the numer property of an sh-tab item
  //    the system will will always trash it when the slot config changed
  //    this should be made clear to a user as part of the docs at least
  //    (i.e. "The number property will be controlled by the parent sh-stepper")
  slotChange() {
    // set number of each step based on its' index
    const items = this.querySelectorAll('sh-stepper-item');
    for (let i = 0; i < items.length; i++) {
      items[i].number = i + 1;
      if (items[i].hasAttribute('active')) {
        this.value = items[i].number;
      }
    }
    // update items on load
    this.updateItems();
    this.handleCondensedChange();
    this.handleColorChange();
    this.handleOrientationChange();
  }
  update(changedProperties) {
    if (changedProperties.has('condensed')) {
      this.handleCondensedChange();
    }
    if (changedProperties.has('value')) {
      this.updateItems();
    }
    if (changedProperties.has('color')) {
      this.handleColorChange();
    }
    if (changedProperties.has('vertical')) {
      this.handleOrientationChange();
    }
    if (this.readonly) {
      this.setAttribute('tabindex', '-1');
    } else {
      this.setAttribute('tabindex', '0');
    }
    const listOfProperties = ['value', 'condensed', 'readonly', 'color'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    super.update(changedProperties);
  }
  // update value and disabled state
  updateItems() {
    return __awaiter(this, void 0, void 0, function* () {
      const items = this.querySelectorAll('sh-stepper-item');
      for (let i = 0; i < items.length; i++) {
        if (this.value !== undefined ? this.value : false) {
          if (Number(items[i].number) > (this.value !== undefined ? this.value : 0)) {
            items[i].active = false;
            yield this.updateComplete;
            items[i].setAttribute('disabled', '');
          } else if (
            items[i].number === parseInt((this.value !== undefined ? this.value : 0).toString(), 10)
          ) {
            items[i].active = true;
          } else {
            items[i].active = false;
            items[i].disabled = false;
          }
        }
        // half-filled circles
        if (this.value > Math.floor(this.value) && i === Math.ceil(this.value - 1)) {
          items[i].setAttribute('half', '');
        } else {
          items[i].removeAttribute('half');
        }
      }
    });
  }
  // condense items if wrapper is condensed
  handleCondensedChange() {
    const items = this.querySelectorAll('sh-stepper-item');
    for (let i = 0; i < items.length; i++) {
      items[i].condensed = this.condensed;
    }
  }
  handleOrientationChange() {
    const items = this.querySelectorAll('sh-stepper-item');
    for (let i = 0; i < items.length; i++) {
      items[i].vertical = this.vertical;
    }
  }
  // update active items in terms of color if wrapper color property is changed to 'primary'
  handleColorChange() {
    const items = this.querySelectorAll('sh-stepper-item');
    for (let i = 0; i < items.length; i++) {
      items[i].color = this.color;
    }
  }
  disconnectedCallback() {
    this.removeEventListener('keydown', (e) => this.handleKeydown(e));
    super.disconnectedCallback();
  }
};
__decorate([property({ type: Number, reflect: true })], SHStepper.prototype, 'value', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHStepper.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHStepper.prototype, 'condensed', void 0);
__decorate([property({ type: String, reflect: true })], SHStepper.prototype, 'color', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHStepper.prototype, 'vertical', void 0);
SHStepper = __decorate([customElement('sh-stepper')], SHStepper);
export { SHStepper };
