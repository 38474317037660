// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T;

/** ### TooltipEllipsisCheckerMixin
 * ___
 * A class aimed to control the display of the tooltip only when
 * the content has ellipsis shown.
 * ___
 * #### How to use this ?
 *
 * Import like this in the component:
 * ```js
 * import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
 * ```
 * ___
 *
 
 * #### How does this work ?
 * It checks for the scrollwidth/scrollHeight (total width/height including hidden element) of the target
 * to be greater that the offsetwidth/offsetHeight (outer width/height),
 * based on the direction parameter: if direction is 'horizontal' , it will check for the horizontal condition (scrollWidth > offsetWidth)
 * if direction is 'both', it will check for both vertical and horizontal condition.
 * Default value of direction is 'both'.
 * and if not a touch device then it will return true to display the tooltip.
 * 
 * Else false to not display.
 * ___
 *
 */

export const TooltipEllipsisCheckerMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class TooltipEllipsisCheckerMixinElementextends extends superClass {
    ellipsisChecker(
      scrollWidth: number,
      offsetWidth: number,
      scrollHeight: number,
      offsetHeight: number,
      direction: 'horizontal' | 'both' = 'both'
    ) {
      switch (direction) {
        case 'horizontal':
          return scrollWidth > offsetWidth;
        default:
          return scrollWidth > offsetWidth || scrollHeight > offsetHeight;
      }
    }
  }
  return TooltipEllipsisCheckerMixinElementextends as unknown as Constructor<TooltipEllipsisCheckerMixinInterface> &
    T;
};

/**
 * due to typescript limitations we must explicitly define a clas
 * with the externally used api here and cast the return value of our
 * mixin to this (intermediate unknown cast is so we don't need to have to list irrelevant fields)
 * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
 */
export interface TooltipEllipsisCheckerMixinInterface {
  ellipsisChecker: (
    scrollWidth: number,
    offsetWidth: number,
    scrollHeight: number,
    offsetHeight: number,
    direction: 'horizontal' | 'both'
  ) => boolean;
}
