// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT arrow-controls.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-slider-input-height=1.5rem] - height of show-value slider input
   */
  --sh-slider-input-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-input-width=2.5rem] - width of show-value slider input
   */
  --sh-slider-input-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-slider-area-height=2rem] - height of actual slider-area
   */
  --sh-slider-slider-area-height: 2rem;
  /* 
   * @cssprop [--sh-slider-slider-area-margin-top=0rem] - margin top of actual slider-area
   */
  --sh-slider-slider-area-margin-top: 0rem;
  /* 
   * @cssprop [--sh-slider-thumb-diameter=0.875rem] - diameter of the thumb
   */
  --sh-slider-thumb-diameter: 0.875rem;
  /* 
   * @cssprop [--sh-slider-track-height=0.125rem] - height of track
   */
  --sh-slider-track-height: 0.125rem;
  /* 
   * @cssprop [--sh-slider-input-padding=0.125rem 0.25rem] - padding of the input
   */
  --sh-slider-input-padding: 0.125rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-horizontal-tooltip-thumb-distance=0.5625rem] - distance between thumb and tooltip in horizontal slider
   */
  --sh-slider-horizontal-tooltip-thumb-distance: 0.5625rem;
  /* 
   * @cssprop [--sh-slider-tooltip-width=2.5rem] - width of tooltip
   */
  --sh-slider-tooltip-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-height=1.5rem] - height of tooltip
   */
  --sh-slider-tooltip-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-padding=0rem 0.25rem] - padding of tooltip- for range slider
   */
  --sh-slider-tooltip-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter=0.375rem] - diameter of round ticks without hover
   */
  --sh-slider-tick-diameter: 0.375rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter-on-hover=0.5rem] - diameter of round ticks with hover
   */
  --sh-slider-tick-diameter-on-hover: 0.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-tooltip-thumb-distance=var(--sh-slider-horizontal-tooltip-thumb-distance)] - the distance between the thumb and the tooltip for the vertical slider
   */
  --sh-slider-vertical-slider-tooltip-thumb-distance: var(--sh-slider-horizontal-tooltip-thumb-distance);
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-slider-distance=1rem] - the distance between the input and the slider for the vertical slider
   */
  --sh-slider-vertical-slider-input-slider-distance: 1rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-container-width=4.5rem] - the width of the container of input of vertical slider
   */
  --sh-slider-vertical-slider-input-container-width: 4.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-label-input-width=1.9375rem] - the width of the input of vertical slider
   */
  --sh-slider-vertical-slider-input-label-input-width: 1.9375rem;
  /* 
   * @cssprop [--sh-slider-step-tick-width=0.0625rem] - width of the step tick
   */
  --sh-slider-step-tick-width: 0.0625rem;
  /* 
   * @cssprop [--sh-slider-step-tick-height=0.5rem] - height of the step tick
   */
  --sh-slider-step-tick-height: 0.5rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-width=0.25rem] - width of range input separator
   */
  --sh-slider-range-input-separator-width: 0.25rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-side-margin=0.25rem] - margin left and margin right of range input separator
   */
  --sh-slider-range-input-separator-side-margin: 0.25rem;
  /* 
   * @cssprop [--sh-slider-step-tick-min-width=2rem] - min-width between step-ticks
   */
  --sh-slider-step-tick-min-width: 2rem;
  /* 
   * @cssprop [--sh-slider-arrow-controls-inter-icon-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-arrow-controls-inter-icon-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-outer-wrapper-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-outer-wrapper-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-span-padding-right=0.25rem] - padding right of span max value of vertocal slider
   */
  --sh-slider-vertical-slider-span-padding-right: 0.25rem;
  /* 
   * @cssprop [--sh-slider-input-padding-left-right=0.5rem] - combined left right padding of input
   */
  --sh-slider-input-padding-left-right: 0.5rem;
  /* 
   * @cssprop [--sh-slider-label-wrapper-input-padding=0rem 0.25rem] - padding of input label wrapper
   */
  --sh-slider-label-wrapper-input-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tooltip-display=flex] - display of the slider tooltip
   */
  --sh-slider-tooltip-display: flex;
}

:host {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: row;
  gap: var(--sh-slider-arrow-controls-inter-icon-gap);
}

:host([disabled]) #plus,
:host([disabled]) #minus {
  color: rgb(var(--ui-1));
}`;