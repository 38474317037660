// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-divider.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-divider-thickness=0.0625rem] - width / height of the  vertical & horizontal divider
   */
  --sh-divider-thickness: 0.0625rem;
  /* 
   * @cssprop [--sh-divider-horizontal-s=0.5rem 0] - spacing s for the horizontal divider
   */
  --sh-divider-horizontal-s: 0.5rem 0;
  /* 
   * @cssprop [--sh-divider-horizontal-m=1rem 0] - spacing m for the horizontal divider
   */
  --sh-divider-horizontal-m: 1rem 0;
  /* 
   * @cssprop [--sh-divider-horizontal-l=1.5rem 0] - spacing l for the horizontal divider
   */
  --sh-divider-horizontal-l: 1.5rem 0;
  /* 
   * @cssprop [--sh-divider-horizontal-xl=3rem 0] - spacing xl for the horizontal divider
   */
  --sh-divider-horizontal-xl: 3rem 0;
  /* 
   * @cssprop [--sh-divider-vertical-s=0 0.5rem] - spacing s for the vertical divider
   */
  --sh-divider-vertical-s: 0 0.5rem;
  /* 
   * @cssprop [--sh-divider-vertical-m=0 1rem] - spacing m for the vertical divider
   */
  --sh-divider-vertical-m: 0 1rem;
  /* 
   * @cssprop [--sh-divider-vertical-l=0 1.5rem] - spacing l for the vertical divider
   */
  --sh-divider-vertical-l: 0 1.5rem;
  /* 
   * @cssprop [--sh-divider-vertical-xl=0 3rem] - spacing xl for the vertical divider
   */
  --sh-divider-vertical-xl: 0 3rem;
}

:host {
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  display: block;
}

.divider-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-100));
}

:host(:not([vertical])) :host(:not([vertical])) .divider-wrapper {
  width: 100%;
}

:host([vertical]),
:host([vertical]) .divider-wrapper {
  height: 100%;
}

:host(:not([vertical])) .divider-wrapper {
  height: var(--sh-divider-thickness);
}

:host([vertical]) .divider-wrapper {
  width: var(--sh-divider-thickness);
}

/* spacing */
:host([spacing=s]) .divider-wrapper {
  margin: var(--sh-divider-horizontal-s);
}

:host([spacing=m]) .divider-wrapper {
  margin: var(--sh-divider-horizontal-m);
}

:host([spacing=l]) .divider-wrapper {
  margin: var(--sh-divider-horizontal-l);
}

:host([spacing=xl]) .divider-wrapper {
  margin: var(--sh-divider-horizontal-xl);
}

:host([spacing=none]) .divider-wrapper,
:host([vertical][spacing=none]) .divider-wrapper {
  margin: 0;
}

/* vertical divider spacing */
:host([vertical][spacing=s]) .divider-wrapper {
  margin: var(--sh-divider-vertical-s);
}

:host([vertical][spacing=m]) .divider-wrapper {
  margin: var(--sh-divider-vertical-m);
}

:host([vertical][spacing=l]) .divider-wrapper {
  margin: var(--sh-divider-vertical-l);
}

:host([vertical][spacing=xl]) .divider-wrapper {
  margin: var(--sh-divider-vertical-xl);
}

/* Emphasis styles */
:host([variant=subtle]) .divider-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-100));
}

:host([variant=moderate]) .divider-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-400));
}`;