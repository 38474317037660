// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-risk-score.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-risk-score-sizeS=4rem] - s size
   */
  --sh-risk-score-sizeS: 4rem;
  /* 
   * @cssprop [--sh-risk-score-sizeL=8rem] - l size
   */
  --sh-risk-score-sizeL: 8rem;
  /* 
   * @cssprop [--sh-risk-score-radial-s-width=2.5rem] - s size radical width
   */
  --sh-risk-score-radial-s-width: 2.5rem;
  /* 
   * @cssprop [--sh-risk-score-radial-l-width=5.625rem] - l size radical width
   */
  --sh-risk-score-radial-l-width: 5.625rem;
  /* 
   * @cssprop [--sh-risk-score-radial-s-lh=1.25rem] - s size radical line-height
   */
  --sh-risk-score-radial-s-lh: 1.25rem;
  /* 
   * @cssprop [--sh-risk-score-radial-l-lh=2.25rem] - l size radical line-height
   */
  --sh-risk-score-radial-l-lh: 2.25rem;
  /* 
   * @cssprop [--sh-risk-score-lw-height=0.25rem] - height of linear-wrapper
   */
  --sh-risk-score-lw-height: 0.25rem;
  /* 
   * @cssprop [--sh-risk-score-lw-mrg-btm=0.5rem] - margin-bottom of linear-wrapper
   */
  --sh-risk-score-lw-mrg-btm: 0.5rem;
  /* 
   * @cssprop [--sh-risk-score-riskbar-mrg-left=0.25rem] - margin-left of riskbar
   */
  --sh-risk-score-riskbar-mrg-left: 0.25rem;
  /* 
   * @cssprop [--sh-risk-score-hw-mrg-btm=0.75rem] - margin-right of header-wrapper
   */
  --sh-risk-score-hw-mrg-btm: 0.75rem;
  /* 
   * @cssprop [--sh-risk-score-hw-line-height=1.25rem] - line-height of header-wrapper
   */
  --sh-risk-score-hw-line-height: 1.25rem;
}

:host {
  width: 100%;
  display: block;
}

.linear-wrapper {
  display: flex;
  height: var(--sh-risk-score-lw-height);
  margin-bottom: var(--sh-risk-score-lw-mrg-btm);
  width: 100%;
  min-width: 7rem;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  margin: 0rem 0rem 0.5rem 0rem;
}

.riskbar {
  margin-left: var(--sh-risk-score-riskbar-mrg-left);
  width: 100%;
  background-color: rgba(var(--ui-1), var(--opacity-5));
  overflow: hidden;
  position: relative;
}

.value {
  margin-left: 0.25rem;
}

.riskbar:first-child {
  margin-left: 0rem;
}

.header-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--sh-risk-score-hw-mrg-btm);
}

:host(:not([radial])) .label {
  flex: 1;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label,
.value {
  line-height: var(--sh-risk-score-hw-line-height);
}

.value {
  width: auto;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:host([disabled]) .value {
  color: var(--text-disabled);
}

:host([disabled]) .riskbar,
:host([disabled]) .bar-value {
  background-color: rgba(var(--ui-1), var(--opacity-6));
}

:host([disabled]) .radial-segment,
:host([disabled]) .radial-background {
  stroke: rgba(var(--ui-1), var(--opacity-6));
}

.label + .value {
  margin-left: 0.5rem;
}

:host(:not([label])) .value {
  flex: 1;
  text-align: center;
}

.bar-value {
  height: var(--sh-risk-score-lw-height);
  position: absolute;
  max-width: 100%;
}

.footer {
  height: fit-content;
}

:host([empty-footer]) .footer-wrapper {
  display: none;
}

/*radial*/
:host([radial]) {
  text-align: center;
  width: 12rem;
}

:host([radial]) .label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:host([disabled][radial]) .radial-label {
  color: var(--text-disabled);
}

.radial-wrapper {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.radial {
  transform: rotate(-90deg);
  overflow: visible;
}

:host([size=s]) .radial {
  width: var(--sh-risk-score-sizeS);
  height: var(--sh-risk-score-sizeS);
}

:host([size=l]) .radial {
  width: var(--sh-risk-score-sizeL);
  height: var(--sh-risk-score-sizeL);
}

.radial-label {
  position: absolute;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
  max-height: 4rem;
  width: 2.5rem;
}

:host([size=s]) .radial-background,
:host([size=s]) .radial-segment {
  stroke-width: 0.25rem;
}

:host([size=l]) .radial-background,
:host([size=l]) .radial-segment {
  stroke-width: 0.5rem;
}

:host([radial][size=s]) .radial-label {
  width: var(--sh-risk-score-radial-s-width);
  line-height: var(--sh-risk-score-radial-s-lh);
}

:host([radial][size=l]) .radial-label {
  font-size: 1.75rem;
  width: var(--sh-risk-score-radial-l-width);
  line-height: var(--sh-risk-score-radial-l-lh);
}

:host([radial]) .header-wrapper {
  margin-bottom: 0.5rem;
}

:host([radial]) .footer-wrapper {
  display: block;
  margin: 0rem auto 0.5rem auto;
}`;