// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-carousel.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-carousel-counter-line-height=1rem] - line height of counter
   */
  --sh-carousel-counter-line-height: 1rem;
  /* 
   * @cssprop [--sh-carousel-counter-bottom=0.25rem] - placement of counter from bottom
   */
  --sh-carousel-counter-bottom: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-counter-left-right=0.25rem] - placement of counter left and right
   */
  --sh-carousel-counter-left-right: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-counter-border-radius=0.125rem] - placement of counter bottom
   */
  --sh-carousel-counter-border-radius: 0.125rem;
  /* 
   * @cssprop [--sh-carousel-counter-padding=0.25rem] - the padding for counter
   */
  --sh-carousel-counter-padding: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-arrow-left=0.5rem] - the position of arrow from left
   */
  --sh-carousel-arrow-left: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-arrow-right=0.5rem] - the position of arrow from right
   */
  --sh-carousel-arrow-right: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-arrow-padding=0.25rem] - the padding for arrow
   */
  --sh-carousel-arrow-padding: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-icon-width=2rem] - the width of arrow icon
   */
  --sh-carousel-icon-width: 2rem;
  /* 
   * @cssprop [--sh-carousel-icon-height=2rem] - the height of arrow icon
   */
  --sh-carousel-icon-height: 2rem;
  /* 
   * @cssprop [--sh-carousel-idle-opacity=0.9] - idle opacity of icon
   */
  --sh-carousel-idle-opacity: 0.9;
}

:host {
  display: flex;
  position: relative;
  font: var(--body-1);
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  --icon-idle-opacity: var(--sh-carousel-idle-opacity);
}

slot::-webkit-scrollbar {
  display: none;
}

slot {
  display: flex;
  align-items: center;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
  height: fit-content;
  scrollbar-width: none;
}

::slotted(*) {
  flex: none;
  width: var(--slot-component-width);
}

/* snap */
:host([snap=start]) ::slotted(*) {
  scroll-snap-align: start;
}

:host([snap=end]) ::slotted(*) {
  scroll-snap-align: end;
}

:host([snap=center]) ::slotted(*) {
  scroll-snap-align: center;
}

/* counter */
.counter {
  position: absolute;
  bottom: var(--sh-carousel-counter-bottom);
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--sh-carousel-counter-border-radius);
  line-height: var(--sh-carousel-counter-line-height);
  color: var(--text-primary);
  padding: var(--sh-carousel-counter-padding);
  background: rgba(var(--ui-6), var(--opacity-2));
}

/* arrow */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  padding: var(--sh-carousel-arrow-padding);
  background: rgba(var(--ui-6), var(--opacity-2));
  transition: 0.2s all ease-in-out;
}

.arrow.left {
  left: var(--sh-carousel-arrow-left);
}

.arrow.right {
  right: var(--sh-carousel-arrow-right);
}

sh-icon {
  width: var(--sh-carousel-icon-width);
  height: var(--sh-carousel-icon-height);
  color: rgba(var(--ui-1), var(--opacity-2));
  background: rgba(var(--ui-6), var(--opacity-2));
}

sh-icon.arrow:before {
  content: "";
  background: rgba(var(--ui-1), var(--opacity-6));
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  transition: 0.2s all ease-in-out;
}

sh-icon:not(.touch-device).arrow:hover:before {
  opacity: 1;
}`;