// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
import userIdentifierStyles from './sh-user-identifier.lit.scss.js';
let SHUserIdentifier = class SHUserIdentifier extends TooltipEllipsisCheckerMixin(LitElement) {
  static get styles() {
    return [userIdentifierStyles];
  }
  render() {
    return html`
      <div class="circle">
        ${this.unauthenticated
          ? html`
              ${this.name
                ? html` <sh-icon icon="user-avatar"></sh-icon> `
                : html` <sh-icon icon="user"></sh-icon> `}
            `
          : html`
              ${this.image
                ? html` <sh-icon icon="${this.image}"></sh-icon> `
                : html`
                    ${this.initials
                      ? this.formatInitials(this.initials)
                      : this.name
                        ? this.getInitials(this.name)
                        : nothing}
                  `}
            `}
      </div>
      ${this.name
        ? html`
            <div class="info-wrapper">
              <div class="name" @mouseenter="${(e) => this.showTooltip(e, this.name)}">
                ${this.name}
              </div>
              ${this.info && !this.unauthenticated
                ? html`
                    <div class="info" @mouseenter="${(e) => this.showTooltip(e, this.info)}">
                      ${this.info}
                    </div>
                  `
                : nothing}
            </div>
            <sh-tooltip placement="bottom-left" position-fixed-adjustment></sh-tooltip>
          `
        : nothing}
    `;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    this.dispatchEvent(new Event(`${name}-changed`));
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
  }
  firstUpdated() {
    this.addEventListener('focus', () => {
      this.onkeyup = (e) => {
        if (keyboardInteraction(e, KEYCODE.TAB)) {
          this.classList.add('focus');
        }
      };
    });
    this.addEventListener('blur', () => {
      this.classList.remove('focus');
    });
  }
  showTooltip(e, content) {
    const tooltip = this.renderRoot.querySelector('sh-tooltip');
    if (content && tooltip) {
      const target = e.target;
      const isEllipsis = this.ellipsisChecker(
        target.scrollWidth,
        target.offsetWidth,
        target.scrollHeight,
        target.offsetHeight,
        'horizontal'
      );
      if (isEllipsis) {
        tooltip.target = target;
        tooltip.visible = true;
        tooltip.label = content;
      } else {
        tooltip.target = '';
        tooltip.visible = false;
        tooltip.label = '';
      }
    }
  }
  getInitials(name) {
    const initials = name
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '');
    return this.formatInitials(initials);
  }
  formatInitials(initials) {
    const firstChar = initials.length > 0 ? initials.charAt(0) : '';
    const lastChar = initials.length > 1 ? initials.charAt(initials.length - 1) : '';
    return (firstChar + lastChar).toUpperCase();
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHUserIdentifier.prototype,
  'image',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHUserIdentifier.prototype,
  'initials',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHUserIdentifier.prototype, 'name', void 0);
__decorate([property({ type: String, reflect: true })], SHUserIdentifier.prototype, 'info', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHUserIdentifier.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHUserIdentifier.prototype,
  'unauthenticated',
  void 0
);
SHUserIdentifier = __decorate([customElement('sh-user-identifier')], SHUserIdentifier);
export { SHUserIdentifier };
