// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier';
import '../../utils/device-identifier.js'; // just to pull in modrnzr
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import radioButtonStyles from './sh-radio-button.lit.scss.js';
/**
 * @fires active-changed - Emitted when active state changes to true.
 * @fires clicked - DEPRECATED, use active-changed instead. Emitted when active state changes.
 */
let SHRadioButton = class SHRadioButton extends LitElement {
  constructor() {
    super(...arguments);
    /**Default label. Defines the label of the radio button. */
    this.label = 'label';
    /** Default false. Defines if a radio button is checked or not. Changes when clicked. */
    this.active = false;
    /** Default false. Defines if a radio button is disabled. If true, it won't allow clicks.*/
    this.disabled = false;
    /** Default false. If true, the active state will be gray instead of orange. */
    this.neutral = false;
    /** If true, the radio button is non-interactive, but the label keeps its appearance and remains fully readable.*/
    this.readonly = false;
  }
  static get styles() {
    return [extendInteractiveArea(), radioButtonStyles];
  }
  render() {
    return html`
      <div class="radio-container">
        <input
          class="radioBtn"
          type="radio"
          name="${this.label ? this.label : ''}"
          ?checked="${this.active}"
          ?aria-readonly="${this.readonly}"
          ?active="${this.active}"
          tabindex="-1"
        />
        <span class="pseudo-radio"></span>
        ${this.label ? html` <label>${this.label}</label>` : ''}
      </div>
    `;
  }
  attributeChangedCallback(name, oldval, newVal) {
    super.attributeChangedCallback(name, oldval, newVal);
    const val = newVal !== null;
    if (name === 'active') {
      this.setAttribute('aria-checked', val.toString());
    }
    if (name === 'readonly') {
      this.setAttribute('aria-disabled', val.toString());
    }
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('no-hovermq');
    }
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'radio');
    }
  }
  updated(changedProperties) {
    if (changedProperties.has('active') && this.active) {
      this.dispatchEvent(new Event(`active-changed`));
    }
    if (!this.readonly) {
      if (this.active) {
        this.setAttribute('tabindex', '0');
      } else {
        this.setAttribute('tabindex', '-1');
      }
    }
  }
  firstUpdated() {
    this.addEventListener('click', () => {
      this.handleActive();
    });
    this.addEventListener('focus', () => {
      this.handleFocus();
    });
    this.addEventListener('blur', () => {
      this.handleBlur();
    });
    if (this.readonly && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
  }
  handleFocus() {
    var _a;
    const pseudoRadio =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.pseudo-radio');
    if (!this.disabled) {
      this.onkeyup = function (e) {
        if (keyboardInteraction(e, KEYCODE.TAB)) {
          pseudoRadio === null || pseudoRadio === void 0
            ? void 0
            : pseudoRadio.classList.add('focus');
        }
      };
      this.onkeydown = (e) => {
        this.handleKeydown(e);
      };
    }
  }
  handleKeydown(e) {
    var _a;
    console.log('Target on keydown', e.target);
    const siblings =
      (_a = this.parentElement) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll(
            "SH-RADIO-BUTTON[tabindex='0'][readonly], SH-RADIO-BUTTON:not([disabled]):not([readonly])"
          );
    if (siblings) {
      if (
        keyboardInteraction(e, KEYCODE.ARROW_RIGHT) ||
        keyboardInteraction(e, KEYCODE.ARROW_DOWN)
      ) {
        this.handleTarget(e, ['ArrowRight', 'ArrowDown'], siblings, 'next');
      } else if (
        keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
        keyboardInteraction(e, KEYCODE.ARROW_UP)
      ) {
        this.handleTarget(e, ['ArrowLeft', 'ArrowUp'], siblings, 'prev');
      }
    }
  }
  handleTarget(e, targetKeys, siblings, traversal) {
    let target;
    const posThis = Array.from(siblings).indexOf(this);
    if (traversal === 'next') {
      //next not disabled sibling
      target = posThis === siblings.length - 1 ? siblings[0] : siblings[posThis + 1];
    } else {
      //previous not disabled sibling
      target = posThis === 0 ? siblings[siblings.length - 1] : siblings[posThis - 1];
    }
    this.handleArrowInput(e, targetKeys, target);
    this.handleTabs(e, ['Tab'], target);
  }
  handleTabs(e, triggerKeys, target) {
    if (triggerKeys.includes(e.code) && target) {
      e.preventDefault();
    }
  }
  handleArrowInput(e, triggerKeys, target) {
    var _a;
    if (triggerKeys.includes(e.code) && target) {
      e.preventDefault();
      if (this.readonly && !target.active && !target.readonly) {
        const parentElement = this.parentElement;
        const activeEls = parentElement.querySelectorAll('[active]');
        for (const activeEl of activeEls) {
          if (activeEl !== target) {
            activeEl.active = false;
          }
        }
        target.active = true;
      } else if (!target.readonly) {
        this.active = false;
        target.active = true;
      } else if (target.readonly && target.tabIndex !== -1) {
        const pseudoRadio =
          (_a = target.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.pseudo-radio');
        pseudoRadio === null || pseudoRadio === void 0
          ? void 0
          : pseudoRadio.classList.add('focus');
      }
      target.focus();
    }
  }
  handleBlur() {
    var _a;
    const pseudoRadio =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.pseudo-radio');
    pseudoRadio === null || pseudoRadio === void 0 ? void 0 : pseudoRadio.classList.remove('focus');
  }
  handleActive() {
    if (!this.readonly) {
      this.active = true;
      //TODO This has to be removed in future as we are already dispatching active-changed event
      this.dispatchEvent(new CustomEvent('clicked', { bubbles: true, detail: this }));
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHRadioButton.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHRadioButton.prototype, 'active', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'neutral',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'readonly',
  void 0
);
SHRadioButton = __decorate([customElement('sh-radio-button')], SHRadioButton);
export { SHRadioButton };
