// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { prependZero } from '../../utils/number-formatter.js';
export const TimeModifierMixin = (superClass) => {
    class TimeModifierMixin extends superClass {
        constructor() {
            super(...arguments);
            this.previousHourValue = 0;
        }
        incrementTime(keydownedInputBox) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepicker = this;
            //user might prefer to select am / pm by up/down arrows. So the condition of am/pm was excluded here
            this.previousHourValue = Number(timepicker.h);
            if (timepicker.h === '' || timepicker.m === '' || (timepicker.s === '' && !timepicker.mini)) {
                this.updateWithSystemTime(timepicker);
                return;
            }
            this.updateTimeOnIncrementOrDecrement(timepicker, keydownedInputBox.dataset.id, true);
            dispatchCustomEvent(this, 'time-value-changed');
        }
        decrementTime(keydownedInputBox) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepicker = this;
            this.previousHourValue = Number(timepicker.h);
            if (timepicker.h === '' || timepicker.m === '' || (timepicker.s === '' && !timepicker.mini)) {
                this.updateWithSystemTime(timepicker);
                return;
            }
            this.updateTimeOnIncrementOrDecrement(timepicker, keydownedInputBox.dataset.id, false);
            dispatchCustomEvent(this, 'time-value-changed');
        }
        updateWithSystemTime(timepicker) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const presentBrowserTime = new Date();
            let hour = presentBrowserTime.getHours();
            /*** Initial display logic*/
            if (timepicker.clockFormat === '12-hour') {
                timepicker.amPm = (hour > 11 && hour !== 12) || hour === 12 ? 'PM' : 'AM';
                if (hour > 11 && hour !== 12) {
                    hour = hour - 12;
                }
                hour = hour === 12 || hour === 0 ? 12 : hour;
            }
            else if (timepicker.clockFormat === '24-hour') {
                timepicker.amPm = '';
            }
            /**
             * If number is less than 10, then make it like '0x' , eg: '02' for 2
             */
            timepicker.h = this.getTimeValueAfterValidation(hour);
            timepicker.m = this.getTimeValueAfterValidation(presentBrowserTime.getMinutes());
            timepicker.s = this.getTimeValueAfterValidation(presentBrowserTime.getSeconds());
            dispatchCustomEvent(timepicker, 'time-value-changed');
        }
        incrementOrDecrementOnKeyDown(e) {
            let eventSrc;
            if (e.composedPath()) {
                eventSrc = e.composedPath()[0];
            }
            else {
                eventSrc = e.target;
            }
            if (keyboardInteraction(e, KEYCODE.ARROW_UP) || e.key === 'Up') {
                e.preventDefault();
                this.incrementTime(eventSrc);
            }
            if (keyboardInteraction(e, KEYCODE.ARROW_DOWN) || e.key === 'Down') {
                e.preventDefault();
                this.decrementTime(eventSrc);
            }
            dispatchCustomEvent(this, 'time-value-changed');
        }
        updateTimeOnIncrementOrDecrement(timepicker, inputBoxID, isIncrement) {
            switch (inputBoxID) {
                case 's':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                case 's-ip':
                    this.updateSecondValue(timepicker, isIncrement);
                case 'm':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                case 'm-ip':
                    this.updateMinuteValue(timepicker, inputBoxID, isIncrement);
                case 'h':
                case 'h-ip':
                    this.updateHourValue(timepicker, inputBoxID, isIncrement);
                    break;
                default:
                    if (inputBoxID === 'ap' || inputBoxID === 'ap-ip') {
                        timepicker.amPm = timepicker.amPm === 'PM' ? 'AM' : 'PM';
                    }
                    break;
            }
        }
        updateSecondValue(timepicker, isIncrement) {
            const updatedTimepicker = this.updateSecondOrMinuteValue(timepicker.s, timepicker.m, isIncrement, timepicker.step, true);
            timepicker.s = updatedTimepicker.current;
            timepicker.m = updatedTimepicker.previous;
        }
        getValueBasedOnIncrement(isIncrement, timepickerField, timepickerStep) {
            return isIncrement
                ? (parseInt(timepickerField.toString(), 10) + parseInt(timepickerStep.toString(), 10)).toString()
                : (parseInt(timepickerField.toString(), 10) - parseInt(timepickerStep.toString(), 10)).toString();
        }
        updateMinuteValue(timepicker, inputBoxID, isIncrement) {
            const isMinute = inputBoxID === 'm' || inputBoxID === 'm-ip';
            const updatedTimepicker = this.updateSecondOrMinuteValue(timepicker.m, timepicker.h, isIncrement, timepicker.step, isMinute);
            timepicker.m = updatedTimepicker.current;
            timepicker.h = updatedTimepicker.previous;
        }
        updateSecondOrMinuteValue(inputFieldCurrent, inputFieldPrevious, isIncrement, step, isMinute) {
            if (isMinute) {
                inputFieldCurrent = this.getValueBasedOnIncrement(isIncrement, inputFieldCurrent, step);
            }
            if (isIncrement) {
                if (Number(inputFieldCurrent) > 59) {
                    inputFieldPrevious = this.updatePreviousFieldBasedOnCurrent(inputFieldPrevious);
                    inputFieldCurrent = (Number(inputFieldCurrent) - 60).toString();
                }
            }
            else {
                if (Number(inputFieldCurrent) < 0) {
                    inputFieldPrevious = (parseInt(inputFieldPrevious.toString(), 10) - 1).toString();
                    inputFieldCurrent = this.calculateSubValue(inputFieldCurrent, step);
                }
            }
            if (Number(inputFieldCurrent) < 10) {
                inputFieldCurrent = prependZero(inputFieldCurrent);
            }
            const inputField = {
                current: inputFieldCurrent,
                previous: inputFieldPrevious,
            };
            return inputField;
        }
        calculateSubValue(timepickerField, timepickerStep) {
            const minSubVal = Number(timepickerField) % Number(timepickerStep) === 0
                ? timepickerStep
                : Number(timepickerField) % Number(timepickerStep);
            return (60 - Math.abs(parseInt(minSubVal.toString(), 10))).toString();
        }
        updatePreviousFieldBasedOnCurrent(timepickerFieldValue) {
            return (parseInt(timepickerFieldValue.toString(), 10) + 1).toString();
        }
        updateHourValue(timepicker, inputBoxID, isIncrement) {
            if (inputBoxID === 'h' || inputBoxID === 'h-ip') {
                timepicker.h = isIncrement
                    ? (parseInt(timepicker.h.toString(), 10) + 1).toString()
                    : (parseInt(timepicker.h.toString(), 10) - 1).toString();
            }
            if (timepicker.clockFormat === '12-hour') {
                timepicker.amPm = this.getAmPmBasedOnCurrentHour(timepicker);
                timepicker.h = this.getHourBasedOnPreviousValue(timepicker);
            }
            else if (timepicker.clockFormat === '24-hour') {
                this.getValueFor24HrBasedOnIncrement(timepicker);
            }
            if (Number(timepicker.h) < 10) {
                timepicker.h = prependZero(timepicker.h);
            }
        }
        getValueFor24HrBasedOnIncrement(timepicker) {
            if (Number(timepicker.h) === -1 && this.previousHourValue === 0) {
                timepicker.h = '23';
            }
            if (Number(timepicker.h) === 24 && this.previousHourValue === 23) {
                timepicker.h = '0';
            }
        }
        getAmPmBasedOnCurrentHour(timepicker) {
            const currentHour = Number(timepicker.h);
            if ((currentHour === 11 && this.previousHourValue === 12) ||
                (currentHour === 12 && this.previousHourValue === 11)) {
                return timepicker.amPm === 'PM' ? 'AM' : 'PM';
            }
            return timepicker.amPm;
        }
        getHourBasedOnPreviousValue(timepicker) {
            const currentHour = Number(timepicker.h);
            if ((currentHour === 0 && this.previousHourValue === 1) ||
                (currentHour === -1 && this.previousHourValue === 0)) {
                return '12';
            }
            if (Number(timepicker.h) === 13 && this.previousHourValue === 12) {
                return '1';
            }
            return timepicker.h;
        }
        getTimeValueAfterValidation(value) {
            let validatedValue;
            if (value < 10) {
                validatedValue = prependZero(value);
            }
            else {
                validatedValue = value.toString();
            }
            return validatedValue;
        }
    }
    return TimeModifierMixin;
};
