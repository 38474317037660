// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-slider.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-slider-input-height=1.5rem] - height of show-value slider input
   */
  --sh-slider-input-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-input-width=2.5rem] - width of show-value slider input
   */
  --sh-slider-input-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-slider-area-height=2rem] - height of actual slider-area
   */
  --sh-slider-slider-area-height: 2rem;
  /* 
   * @cssprop [--sh-slider-slider-area-margin-top=0rem] - margin top of actual slider-area
   */
  --sh-slider-slider-area-margin-top: 0rem;
  /* 
   * @cssprop [--sh-slider-thumb-diameter=0.875rem] - diameter of the thumb
   */
  --sh-slider-thumb-diameter: 0.875rem;
  /* 
   * @cssprop [--sh-slider-track-height=0.125rem] - height of track
   */
  --sh-slider-track-height: 0.125rem;
  /* 
   * @cssprop [--sh-slider-input-padding=0.125rem 0.25rem] - padding of the input
   */
  --sh-slider-input-padding: 0.125rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-horizontal-tooltip-thumb-distance=0.5625rem] - distance between thumb and tooltip in horizontal slider
   */
  --sh-slider-horizontal-tooltip-thumb-distance: 0.5625rem;
  /* 
   * @cssprop [--sh-slider-tooltip-width=2.5rem] - width of tooltip
   */
  --sh-slider-tooltip-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-height=1.5rem] - height of tooltip
   */
  --sh-slider-tooltip-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-padding=0rem 0.25rem] - padding of tooltip- for range slider
   */
  --sh-slider-tooltip-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter=0.375rem] - diameter of round ticks without hover
   */
  --sh-slider-tick-diameter: 0.375rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter-on-hover=0.5rem] - diameter of round ticks with hover
   */
  --sh-slider-tick-diameter-on-hover: 0.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-tooltip-thumb-distance=var(--sh-slider-horizontal-tooltip-thumb-distance)] - the distance between the thumb and the tooltip for the vertical slider
   */
  --sh-slider-vertical-slider-tooltip-thumb-distance: var(--sh-slider-horizontal-tooltip-thumb-distance);
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-slider-distance=1rem] - the distance between the input and the slider for the vertical slider
   */
  --sh-slider-vertical-slider-input-slider-distance: 1rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-container-width=4.5rem] - the width of the container of input of vertical slider
   */
  --sh-slider-vertical-slider-input-container-width: 4.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-label-input-width=1.9375rem] - the width of the input of vertical slider
   */
  --sh-slider-vertical-slider-input-label-input-width: 1.9375rem;
  /* 
   * @cssprop [--sh-slider-step-tick-width=0.0625rem] - width of the step tick
   */
  --sh-slider-step-tick-width: 0.0625rem;
  /* 
   * @cssprop [--sh-slider-step-tick-height=0.5rem] - height of the step tick
   */
  --sh-slider-step-tick-height: 0.5rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-width=0.25rem] - width of range input separator
   */
  --sh-slider-range-input-separator-width: 0.25rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-side-margin=0.25rem] - margin left and margin right of range input separator
   */
  --sh-slider-range-input-separator-side-margin: 0.25rem;
  /* 
   * @cssprop [--sh-slider-step-tick-min-width=2rem] - min-width between step-ticks
   */
  --sh-slider-step-tick-min-width: 2rem;
  /* 
   * @cssprop [--sh-slider-arrow-controls-inter-icon-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-arrow-controls-inter-icon-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-outer-wrapper-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-outer-wrapper-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-span-padding-right=0.25rem] - padding right of span max value of vertocal slider
   */
  --sh-slider-vertical-slider-span-padding-right: 0.25rem;
  /* 
   * @cssprop [--sh-slider-input-padding-left-right=0.5rem] - combined left right padding of input
   */
  --sh-slider-input-padding-left-right: 0.5rem;
  /* 
   * @cssprop [--sh-slider-label-wrapper-input-padding=0rem 0.25rem] - padding of input label wrapper
   */
  --sh-slider-label-wrapper-input-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tooltip-display=flex] - display of the slider tooltip
   */
  --sh-slider-tooltip-display: flex;
}

:host {
  width: 100%;
  outline: 0;
  display: block;
}

:host([vertical]) {
  width: fit-content;
  width: -moz-fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:host([vertical][right]) {
  float: right;
}

:host([show-range]) #inputText {
  display: none;
}

:host([vertical][direction-toggle]) .vertical-slider-wrapper,
:host([vertical][direction-toggle][show-controls]) arrow-controls {
  transform: rotate(180deg);
}

:host([vertical][direction-toggle][show-controls]) arrow-controls {
  transform-style: preserve-3d;
}

:host .outer-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: var(--sh-slider-slider-area-margin-top);
}

:host(.touch-device) .outer-wrapper {
  gap: var(--sh-slider-outer-wrapper-gap);
}

:host .sliderWrapper {
  position: relative;
  width: 100%;
  height: var(--sh-slider-slider-area-height);
  outline: none;
  display: flex;
  align-items: center;
}

:host([show-controls]) .sliderWrapper {
  width: calc(100% - 2 * var(--sh-icon-size-m));
}

:host([vertical]) .verticalTrack {
  height: var(--sh-slider-track-height);
  width: 100%;
  position: relative;
  display: flex;
  outline: none;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(var(--ui-1), var(--opacity-6));
  opacity: unset;
  left: 0;
  right: 0;
  border: 0;
}

.sliderWrapper,
.vertical-wrapper {
  --halo-ring-radius: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2);
}

:host(.touch-device[vertical]) .verticalTrack {
  transform: rotate(0deg);
}

:host([vertical]) #inputLabel input {
  width: var(--inp-w);
  background: transparent;
  border: none;
  text-align: right;
  font: var(--body-1);
  padding: 0;
  outline: none;
  color: var(--text-secondary);
  -moz-appearance: textfield;
  height: fit-content;
  box-sizing: border-box;
}

:host([vertical]:not([hide-max])) #inputLabel input {
  margin-left: var(--sh-slider-vertical-slider-span-padding-right);
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
}

.sliderTrack::-webkit-slider-thumb,
.verticalTrack::-webkit-slider-thumb {
  appearance: none;
  outline: 0;
  position: relative;
  width: var(--sh-slider-thumb-diameter);
  height: var(--sh-slider-thumb-diameter);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  z-index: 1;
  background: rgba(var(--ui-5), var(--opacity-1));
  -webkit-appearance: none;
  user-select: none;
  -webkit-user-select: none;
  transition: var(--hover-transition-time) background;
}

:host(.touch-device) .sliderTrack::-webkit-slider-thumb,
:host(.touch-device) .verticalTrack::-webkit-slider-thumb {
  background-clip: padding-box;
  border: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2) solid transparent;
  box-sizing: unset;
}

:host(.touch-device) .sliderTrack::-webkit-slider-runnable-track,
:host(.touch-device) .verticalTrack::-webkit-slider-runnable-track {
  margin-left: calc(0rem - var(--halo-ring-radius));
  margin-right: calc(0rem - var(--halo-ring-radius));
}

.sliderTrack::-webkit-slider-runnable-track,
.verticalTrack::-webkit-slider-runnable-track {
  cursor: pointer;
  background: transparent;
}

.sliderTrack::-moz-range-track,
.verticalTrack::-moz-range-track {
  cursor: pointer;
  background: transparent;
}

.sliderTrack::-moz-range-progress,
.verticalTrack::-moz-range-progress {
  cursor: pointer;
}

.sliderTrack::-moz-range-thumb,
.verticalTrack::-moz-range-thumb {
  position: relative;
  width: var(--sh-slider-thumb-diameter);
  height: var(--sh-slider-thumb-diameter);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  z-index: 1;
  background: rgba(var(--ui-5), var(--opacity-1));
  transition: var(--hover-transition-time) background;
  transform: translateZ(0);
}

:host(:not(.touch-device)) .sliderTrack::-webkit-slider-thumb:hover,
:host(:not(.touch-device)) .verticalTrack::-webkit-slider-thumb:hover,
:host([active-track]:not(.touch-device):not(:active)) .sliderTrack:hover::-webkit-slider-thumb,
:host([active-track-invert]:not(.touch-device):not(:active)) .sliderTrack:hover::-webkit-slider-thumb,
:host([active-track]:not(.touch-device):not(:active)) .verticalTrack:hover::-webkit-slider-thumb,
:host([active-track-invert]:not(.touch-device):not(:active)) .verticalTrack:hover::-webkit-slider-thumb {
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host(:not(.touch-device)) .active.sliderTrack::-webkit-slider-thumb,
:host(:not(.touch-device)) .active.verticalTrack::-webkit-slider-thumb,
:host .active.sliderTrack::-webkit-slider-thumb,
:host .active.verticalTrack::-webkit-slider-thumb {
  background-color: rgba(var(--ui-3), var(--opacity-1));
}

:host(.touch-device) .active.sliderTrack::-webkit-slider-thumb,
:host(.touch-device) .active.verticalTrack::-webkit-slider-thumb {
  border: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2) solid rgba(var(--ui-3), var(--opacity-5));
}

:host(:not(.touch-device)) .sliderTrack::-moz-range-thumb:hover,
:host(:not(.touch-device)) .verticalTrack::-moz-range-thumb:hover,
:host([active-track]:not(.touch-device):not(:active)) .sliderTrack:hover::-moz-range-thumb,
:host([active-track-invert]:not(.touch-device):not(:active)) .sliderTrack:hover::-moz-range-thumb,
:host([active-track]:not(.touch-device)) .verticalTrack:hover::-moz-range-thumb,
:host([active-track-invert]:not(.touch-device):not(:active)) .verticalTrack:hover::-moz-range-thumb {
  background: rgba(var(--ui-2), var(--opacity-1));
}

.sliderTrack::-moz-range-thumb:active,
.verticalTrack::-moz-range-thumb:active {
  background: rgba(var(--ui-3), var(--opacity-1));
}

.sliderTrack::-webkit-slider-thumb:active,
.verticalTrack::-webkit-slider-thumb:active {
  background: rgba(var(--ui-3), var(--opacity-1));
}

:host(:not(.touch-device)) .active.sliderTrack::-moz-range-thumb,
:host(:not(.touch-device)) .active.verticalTrack::-moz-range-thumb,
:host .active.sliderTrack::-moz-range-thumb,
:host .active.verticalTrack::-moz-range-thumb {
  background: rgba(var(--ui-3), var(--opacity-1));
}

:host([vertical]) #inputLabel span {
  width: auto;
  min-width: var(--sh-slider-vertical-slider-input-label-input-width);
  padding-right: var(--sh-slider-vertical-slider-span-padding-right);
  font: var(--body-1);
  color: rgba(var(--ui-1), var(--opacity-5));
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
}

:host .sliderTrack {
  width: 100%;
  height: var(--sh-slider-track-height);
  outline: 0;
  background-color: rgba(var(--ui-1), var(--opacity-6));
  position: absolute;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
  opacity: unset;
  border: 0;
}

/* active-track styles */
:host([active-track]) .sliderTrack,
:host([vertical][active-track]) .verticalTrack {
  background: linear-gradient(to right, rgba(var(--ui-5), var(--opacity-1)) var(--gradient-value), rgba(var(--ui-1), var(--opacity-6)) 0%);
}

:host([active-track]) .active.sliderTrack,
:host([vertical][active-track]) .active.verticalTrack {
  background: linear-gradient(to right, rgba(var(--ui-3), var(--opacity-1)) var(--gradient-value), rgba(var(--ui-1), var(--opacity-6)) 0%) !important;
}

:host([active-track]:not(.touch-device)) .sliderTrack:hover,
:host([vertical][active-track]:not(.touch-device)) .verticalTrack:hover {
  background: linear-gradient(to right, rgba(var(--ui-2), var(--opacity-1)) var(--gradient-value), rgba(var(--ui-1), var(--opacity-6)) 0%);
}

/* active-track-invert styles */
:host([active-track-invert]) .sliderTrack,
:host([vertical][active-track-invert]) .verticalTrack {
  background: linear-gradient(to right, rgba(var(--ui-1), var(--opacity-6)) var(--gradient-value), rgba(var(--ui-5), var(--opacity-1)) 0%);
}

:host([active-track-invert]) .active.sliderTrack,
:host([vertical][active-track-invert]) .active.verticalTrack {
  background: linear-gradient(to right, rgba(var(--ui-1), var(--opacity-6)) var(--gradient-value), rgba(var(--ui-3), var(--opacity-1)) 0%) !important;
}

:host([active-track-invert]:not(.touch-device)) .sliderTrack:hover,
:host([vertical][active-track-invert]:not(.touch-device)) .verticalTrack:hover {
  background: linear-gradient(to right, rgba(var(--ui-1), var(--opacity-6)) var(--gradient-value), rgba(var(--ui-2), var(--opacity-1)) 0%);
}

:host([disabled][active-track]) #horizontalSlider,
:host([disabled][vertical][active-track]) #verticalSlider,
:host([disabled][active-track-invert]) #horizontalSlider,
:host([disabled][vertical][active-track-invert]) #verticalSlider {
  background: linear-gradient(to right, rgba(var(--ui-6), var(--opacity-1)) var(--gradient-value), rgba(var(--ui-1), var(--opacity-7)) 0%);
}

/* common styles */
.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
}

.slider-label {
  flex: 1 1 auto;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s all ease-in-out;
}

:host(:not([vertical])) .slider-label {
  padding-right: 0.5rem;
}

:host([vertical]) .slider-label {
  text-align: center;
}

.label-wrapper input {
  display: none;
  outline: 0;
  border: none;
  padding: var(--sh-slider-label-wrapper-input-padding);
  height: var(--sh-slider-input-height);
  font: var(--body-1);
  line-height: var(--sh-slider-input-height);
  color: var(--text-secondary);
  background: rgba(var(--ui-1), var(--opacity-7));
  border-radius: 0.125rem;
  box-sizing: border-box;
  transition: 0s all linear;
  text-align: center;
  -moz-appearance: textfield;
  width: max(var(--inp-w) + var(--sh-slider-input-padding-left-right), var(--sh-slider-input-width));
  flex-shrink: 0;
}

input::-webkit-inner-spin-button {
  display: none;
}

:host(:not([label])) .label-wrapper {
  display: none;
}

:host([show-value]) .label-wrapper input {
  display: inline-flex;
}

:host([show-value][vertical]) .label-wrapper input {
  display: none;
}

:host(:not([show-value])[vertical]) .label-wrapper {
  margin-bottom: var(--sh-slider-vertical-slider-input-slider-distance);
}

/* Disabled Styles */
:host([disabled]) {
  pointer-events: none;
}

:host([disabled]) ::-webkit-slider-thumb {
  background: rgba(var(--ui-6), var(--opacity-1));
}

:host([disabled]) ::-moz-range-thumb {
  background: rgba(var(--ui-6), var(--opacity-1));
}

:host([disabled]) #verticalSlider,
:host([disabled]) #horizontalSlider {
  background: rgb(var(--ui-1), var(--opacity-7));
}

input[type=range]:disabled#horizontalSlider::-moz-range-thumb,
input[type=range]:disabled#verticalSlider::-moz-range-thumb {
  background: rgba(var(--ui-6), var(--opacity-1));
}

:host([disabled]) .slider-label,
:host([disabled]) .label-wrapper input {
  color: var(--text-disabled);
  opacity: unset;
}

:host([disabled]) .tick-item,
:host(.touch-device[vertical][disabled]) .tick-item:after {
  background: rgba(var(--ui-1), var(--opacity-4));
}

:host([disabled]) #verticalInput {
  color: var(--text-disabled) !important;
  opacity: unset;
}

:host([show-controls][vertical]) arrow-controls {
  flex-direction: column;
  flex-shrink: 0;
}

:host .tick-item {
  cursor: pointer;
  border-radius: 50%;
  outline: 0;
  background: rgba(var(--ui-1), var(--opacity-1));
  -moz-appearance: none;
  -webkit-appearance: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.ticks-list {
  position: absolute;
  height: fit-content;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: var(--hover-transition-time) all;
}

:host([vertical]) .ticks-list {
  top: unset;
}

.ticks-list li {
  height: var(--sh-slider-tick-diameter);
  width: var(--sh-slider-tick-diameter);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  list-style: none;
  position: absolute;
  margin: 0 !important;
}

:host(.touch-device[vertical]) .ticks-list li:after {
  content: "";
  position: absolute;
  height: var(--sh-slider-tick-diameter);
  width: var(--sh-slider-tick-diameter);
  z-index: -1;
  outline: 0;
  border-radius: 50%;
  margin: 0 !important;
  background: rgba(var(--ui-1), var(--opacity-1));
}

:host(:not(.touch-device)) .ticks-list li:hover {
  height: var(--sh-slider-tick-diameter-on-hover);
  width: var(--sh-slider-tick-diameter-on-hover);
  background: rgba(var(--ui-0), var(--opacity-1));
  -moz-user-select: none;
  -webkit-user-select: none;
  list-style: none;
  position: absolute;
}

.ticks-list li:active {
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host(:not([show-value])) #inputLabel {
  display: none;
}

#inputLabel {
  background-color: rgba(var(--ui-1), var(--opacity-7));
  border-radius: 0.125rem;
  margin: auto;
  margin-bottom: var(--sh-slider-vertical-slider-input-slider-distance);
  min-width: var(--sh-slider-vertical-slider-input-container-width);
  width: auto;
  height: var(--sh-slider-input-height);
  display: flex;
  box-sizing: border-box;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  flex: none;
  gap: 0.125rem;
}

:host([hide-max]) #inputLabel {
  min-width: var(--sh-slider-input-width);
}

:host(:not([vertical])) #inputLabel {
  display: none;
}

#sliderTooltip,
#verticalTooltip {
  width: auto;
  min-width: var(--sh-slider-tooltip-width);
  padding: var(--sh-slider-input-padding);
  height: var(--sh-slider-tooltip-height);
  display: none;
  position: absolute;
  pointer-events: none;
  outline: 0;
  text-align: center;
  background: var(--base-3);
  box-shadow: var(--shadow-raised);
  border-radius: 0.125rem;
  box-sizing: border-box;
  z-index: 5;
  justify-content: center;
  align-items: center;
  --thumb-radius: calc(var(--sh-slider-thumb-diameter) / 2);
  --value-percent: calc((var(--value) - var(--min)) / (var(--max) - var(--min)));
  --available-slider-length: calc((100% - var(--sh-slider-thumb-diameter)));
  --thumb-left-position: calc((var(--value-percent)) * (var(--available-slider-length)));
  --thumb-mid-position: calc(var(--thumb-left-position) + var(--thumb-radius));
}

#sliderTooltip {
  left: var(--thumb-mid-position);
  transform: translateX(-50%) translateY(calc(-50% - var(--thumb-radius) - var(--sh-slider-horizontal-tooltip-thumb-distance)));
}

#verticalTooltip {
  left: var(--thumb-mid-position);
  transform-origin: left center;
  transform: rotate(-90deg) translateX(calc(var(--thumb-radius) + var(--sh-slider-horizontal-tooltip-thumb-distance)));
}

:host([vertical][right]) #verticalTooltip {
  transform: rotate(-90deg) translateX(calc(-100% - var(--thumb-radius) - var(--sh-slider-horizontal-tooltip-thumb-distance)));
}

:host([vertical][direction-toggle]) #verticalTooltip {
  transform: rotate(90deg) translateX(calc(var(--thumb-radius) + var(--sh-slider-horizontal-tooltip-thumb-distance)));
}

:host([vertical][right][direction-toggle]) #verticalTooltip {
  transform: rotate(90deg) translateX(calc(-100% - var(--thumb-radius) - var(--sh-slider-horizontal-tooltip-thumb-distance)));
}

:host(:not([show-value]):hover:not(.touch-device)) #sliderTooltip,
:host(:not([show-value]):hover:not(.touch-device)) #verticalTooltip,
#label-wrapper.hover #sliderTooltip {
  display: var(--sh-slider-tooltip-display);
}

:host .ticks_collision::-webkit-slider-thumb {
  background-color: rgba(var(--ui-0), var(--opacity-1)) !important;
}

:host(:not(.touch-device)) .ticks_collision::-webkit-slider-thumb:hover {
  background-color: rgba(var(--ui-2), var(--opacity-1)) !important;
}

:host(:not(.touch-device)) .active.ticks_collision::-webkit-slider-thumb {
  background-color: rgba(var(--ui-0), var(--opacity-1)) !important;
}

:host .ticks_collision::-moz-range-thumb {
  background-color: rgba(var(--ui-0), var(--opacity-1)) !important;
}

:host(:not(.touch-device)) .ticks_collision::-moz-range-thumb:hover {
  background-color: rgba(var(--ui-2), var(--opacity-1)) !important;
}

:host .sh-step-thumb-item {
  cursor: pointer;
  outline: 0;
  background: rgba(var(--ui-1), var(--opacity-6));
  -moz-appearance: none;
  -webkit-appearance: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host(.touch-device[vertical]) .tick_collision_z,
:host(.touch-device[vertical]) .sh-step-thumb-item {
  z-index: -1;
}

.step_ticks {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: unset;
}

.step_ticks li {
  height: var(--sh-slider-step-tick-height);
  width: var(--sh-slider-step-tick-width);
  -moz-user-select: none;
  -webkit-user-select: none;
  list-style: none;
  position: absolute;
}

:host([disabled]) .sh-step-thumb-item {
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host .markers.step_ticks {
  display: flex;
}

:host(:not([show-range])) .markers.step_ticks {
  height: fit-content;
}

/* vertical slider */
:host([vertical]) .vertical-slider-wrapper {
  width: 2rem;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

:host([vertical]) .vertical-wrapper {
  transform: rotate(90deg);
  display: flex;
  width: fit-content;
  align-items: center;
}

/* tab-outline */
:host .tab_outline::-webkit-slider-thumb {
  outline: var(--focus-outline);
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host .tab_outline::-moz-range-thumb {
  outline: var(--focus-outline);
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host(:not([show-value])) .tab_tooltip#sliderTooltip,
:host(:not([show-value])) .tab_tooltip#verticalTooltip {
  display: var(--sh-slider-tooltip-display);
}

:host([vertical]) .textbox_click#verticalMax {
  visibility: hidden;
  width: 0%;
  margin-left: 0px;
  min-width: unset;
}

:host([vertical]) .text_align#verticalInput {
  text-align: center;
}

:host([vertical][hide-max]) #verticalMax {
  display: none;
}

:host([vertical][hide-max]) #verticalInput {
  text-align: center;
}

:host(.touch-device) input[type=range],
:host(.touch-device) input[type=range]:focus {
  user-select: none;
  -webkit-user-select: none;
}

:host([show-value][vertical]) .label-wrapper {
  margin-bottom: 0.25rem;
  flex-shrink: 0;
}

.tick-item:focus-visible {
  outline: var(--focus-outline);
}

.sh-step-thumb-item:focus-visible {
  outline: var(--focus-outline);
}`;