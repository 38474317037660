// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-list.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-list-integrated-margin=0 -1rem] - negative margins of the integrated list
   */
  --sh-list-integrated-margin: 0 -1rem;
  /* 
   * @cssprop [--sh-list-integrated-arrow-margin=0rem] - arrow negative margin when inside integrated list
   */
  --sh-list-integrated-arrow-margin: 0rem;
}

:host {
  height: fit-content;
  overflow: auto;
  display: block;
}

:host(:not([flat])) {
  background-color: var(--base-3);
  box-shadow: var(--shadow-raised);
  border-radius: 0.125rem;
}

:host([integrated]) {
  margin: var(--sh-list-integrated-margin);
  --sh-list-item-arrow-right-margin: var(--sh-list-integrated-arrow-margin);
}

:host(:not([flat])) ::slotted(sh-list-item:first-child:not([disabled]):hover)::before {
  background-color: transparent;
}

:host([flat]) ::slotted(sh-list-item:first-child:not([disabled])[active])::before,
:host([flat]) ::slotted(sh-list-item:first-child:not([disabled]):hover)::before {
  top: 0;
}`;