// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-notification.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-notification-width=20rem] - width of notification
   */
  --sh-notification-width: 20rem;
}

:host {
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  width: var(--sh-notification-width);
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
}

/* positions */
:host,
:host([position=top-right]) {
  top: 1rem;
  bottom: auto;
  left: auto;
  right: 1rem;
}

:host([position=top-left]) {
  top: 1rem;
  bottom: auto;
  left: 1rem;
  right: auto;
}

:host([position=top-center]) {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  left: 0;
  margin: auto;
}

:host([position=bottom-right]) {
  top: auto;
  bottom: 1rem;
  left: auto;
  right: 1rem;
}

:host([position=bottom-left]) {
  top: auto;
  bottom: 1rem;
  left: 1rem;
  right: auto;
}

:host([position=bottom-center]) {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  left: 0;
  top: auto;
  right: 1rem;
  margin: auto;
}

:host([position=top-right]) ::slotted(*),
:host([position=top-left]) ::slotted(*),
:host([position=top-center]) ::slotted(*) {
  margin-bottom: 0.5rem;
}

:host([position=bottom-right]) ::slotted(*),
:host([position=bottom-left]) ::slotted(*),
:host([position=bottom-center]) ::slotted(*) {
  margin-top: 0.5rem;
}

/* mobile */
:host([mobile][position=top-right]),
:host([mobile][position=top-left]),
:host([mobile][position=top-center]) {
  top: 4.5rem;
}

:host([mobile]) {
  width: calc(100% - 2rem);
}

:host([position=top-left]) ::slotted(sh-notification-item:not([visible])),
:host([position=bottom-left]) ::slotted(sh-notification-item:not([visible])) {
  transform: translateX(-5rem);
}

:host([position=bottom-right]) ::slotted(sh-notification-item:not([visible])),
:host([position=top-right]) ::slotted(sh-notification-item:not([visible])) {
  transform: translateX(5rem);
}

:host([position=bottom-center]) > ::slotted(sh-notification-item:not([visible])) {
  transform: translateY(5rem);
}

:host([position=top-center]) ::slotted(sh-notification-item:not([visible])) {
  transform: translateY(-5rem);
}`;