// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import wrapperStyles from './sh-wrapper.lit.scss.js';
let SHWrapper = class SHWrapper extends LitElement {
  constructor() {
    super(...arguments);
    /** Defines the direction the components are arranged. Possible values are `column` or `row`.
     * @type {column|row}
     */
    this.direction = 'row';
    /** Defines the spacing between the components. Possible values are `s` (4px), `m` (8px) or `l` (16px).
     * @type {s|m|l}
     */
    this.spacing = 's';
  }
  static get styles() {
    return [wrapperStyles];
  }
  render() {
    return html` <slot></slot> `;
  }
};
__decorate([property({ type: String, reflect: true })], SHWrapper.prototype, 'direction', void 0);
__decorate([property({ type: String, reflect: true })], SHWrapper.prototype, 'spacing', void 0);
SHWrapper = __decorate([customElement('sh-wrapper')], SHWrapper);
export { SHWrapper };
