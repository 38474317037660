// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
export class EventFiredWithinElementChecker {
  /**
   *
   * Checks if the click happened within
   * the target's bound box
   *
   * @param {MouseEvent} event
   * @param {number} targetLeft
   * @param {number} targetTop
   * @param {number} targetRight
   * @param {number} targetBottom
   */
  checkClickEvent(event, targetLeft, targetTop, targetRight, targetBottom) {
    const clickedInsideElement =
      event.clientX >= targetLeft &&
      event.clientX <= targetRight &&
      event.clientY >= targetTop &&
      event.clientY <= targetBottom;
    return event.type === 'click' && clickedInsideElement;
  }
}
