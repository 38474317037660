// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../styles';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode.js';
import splitButtonStyles from './sh-split-button.lit.scss.js';
let SHSplitButton = class SHSplitButton extends LitElement {
  static get styles() {
    return [extendInteractiveArea(), splitButtonStyles];
  }
  render() {
    return html`
      <sh-button
        class="main-btn"
        label="${this.label}"
        size="${this.size}"
        color="${this.color}"
        .href="${this.href}"
        ?disabled="${this.disabled}"
        role="button"
        @click="${() => {
          dispatchCustomEvent(this, 'button-part-click');
        }}"
      >
      </sh-button>
      <div class="icon-wrapper" ?active="${this.active}" @click="${this.toggleOverlay}">
        <sh-icon
          class="split-button-icon"
          icon="down-s"
          tabindex="0"
          ?disabled="${this.disabled || this.arrowDisabled}"
        >
        </sh-icon>
      </div>
      <sh-popover
        class="popup-menu"
        tabindex="0"
        .visible="${this.active}"
        .target="${this}"
        manual-controlled
        position="bottom-left"
        .overlayTargetDistance=${this.popoverButtonDistance}
        @visible-changed="${(e) => {
          if (this.active) {
            e.target.focus();
          }
        }}"
        @clicked="${() => {
          /**
           * using a "clicked" event listener
           * to listen to click on menu-items
           * and force popover to close. This is because
           * automatic closing of popover on clicking anywhere
           * is disabled since we have used
           * manual-controlled property
           * to manually control the visible property of popover.
           */
          this.active = false;
          /*explicitly adding it as menu item click propagates to the split button click,
            hence, not considered as outside click and causes the popover to not open the next time arrow is clicked*/
          document.body.click();
        }}"
        suppress-placement-warning
        @keyup="${(e) => {
          if (keyboardInteraction(e, KEYCODE.ESCAPE)) {
            this.active = false;
          }
        }}"
      >
        <slot
          @slotchange="${(e) => {
            this.hasSubElements = e.target.assignedElements({ flatten: true }).length;
          }}"
        ></slot>
      </sh-popover>
    `;
  }
  constructor() {
    super();
    this.active = false;
    /**Defines the button's label in all shapes.*/
    this.label = 'Label';
    /**If icon is not set, defines the size of the button. Possible values are `s`, `m`, `l`.
     * @type {s|m|l}
     */
    this.size = 's';
    /**Defines the color of the button. Possible values are `primary`, `secondary`, `tertiary`.
     * @type {primary|secondary|tertiary}
     */
    this.color = 'primary';
    /**If set, won't allow mouse events and disabled styles will be applied to the button.*/
    this.disabled = false;
    /**Disables split button arrow. */
    this.arrowDisabled = false;
    this.popoverButtonDistance = 0.5;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    // do not access attributes or any other dom api of your element in the constructor
    // do it in connectedCallback instead
    // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance
    this.setAttribute('role', 'button');
    this.documentClick = this.handleDocumentClick.bind(this);
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.documentClick);
    super.disconnectedCallback();
  }
  /**
   * Gets the current scale of the button and returns the
   * the distance between the popover and the button at that particular scale.
   *
   * @returns the distance between the popover and the button at a particular scale
   */
  getTargetDistance() {
    const currentScale = getComputedStyle(this).getPropertyValue('--current-scale').trim();
    return currentScale === 'xl' || currentScale === 'l' ? 0.75 : 0.5;
  }
  /**
   * Invoked on clicking the split-button arrow.
   * Toggles the visible property of popover.
   *
   * Also prevents the click from reaching the host if
   * arrow-disabled property is present.
   *
   * @param {Event} e - Click event
   */
  toggleOverlay(e) {
    dispatchCustomEvent(this, 'arrow-part-click');
    if (this.arrowDisabled) {
      /**
       * Using a stopPropagation here so that
       * the event does not bubble up to the host
       * so that arrowDisabled does not trigger
       * any click events on any part of the host
       */
      e.stopPropagation();
    } else if (this.hasSubElements) {
      this.active = !this.active;
      //event doesn't bubble up to the parent element
      e.stopPropagation();
      //explicitly clicking on the document body so that the popover close logic works when more than one split button is present.
      document.body.click();
      if (this.active) {
        this.popoverButtonDistance = this.getTargetDistance();
        /**
         * Removing first the click event listener so as to prevent accidental
         * duplication of adding click listener to document.
         */
        document.body.removeEventListener('click', this.documentClick);
        /**
         * Adding click event listener here and not at the connectedCallback
         * level since we don't need to listen to document click always.
         *
         * Its only needed when the popover is opened (active property is true).
         */
        document.body.addEventListener('click', this.documentClick);
      }
    } else {
      // do nothing. just adding for Sonar
    }
  }
  handleDocumentClick(e) {
    if (e.composedPath().indexOf(this) === -1) {
      this.active = false;
      dispatchCustomEvent(this, 'overlay-closed');
      document.body.removeEventListener('click', this.documentClick);
    }
  }
};
__decorate([state()], SHSplitButton.prototype, 'active', void 0);
__decorate([state()], SHSplitButton.prototype, 'popoverButtonDistance', void 0);
__decorate([state()], SHSplitButton.prototype, 'hasSubElements', void 0);
__decorate([property({ type: String, reflect: true })], SHSplitButton.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHSplitButton.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHSplitButton.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHSplitButton.prototype, 'href', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHSplitButton.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'arrow-disabled' })],
  SHSplitButton.prototype,
  'arrowDisabled',
  void 0
);
SHSplitButton = __decorate([customElement('sh-split-button')], SHSplitButton);
export { SHSplitButton };
