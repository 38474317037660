// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-radio-button.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-radio-button-container-margin=0] - margin of the container(XL)
   */
  --sh-radio-button-container-margin: 0;
  /* 
   * @cssprop [--sh-radio-button-label-line-height=1.25rem] - line height of the label
   */
  --sh-radio-button-label-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-radio-button-label-padding=0.25rem] - left padding of the label
   */
  --sh-radio-button-label-padding: 0.25rem;
  /* 
   * @cssprop [--sh-radio-button-label-margin=0.125rem 0] - top and bottom margins of the label
   */
  --sh-radio-button-label-margin: 0.125rem 0;
  /* 
   * @cssprop [--sh-radio-button-width=1.5rem] - width of the radio-button
   */
  --sh-radio-button-width: 1.5rem;
  /* 
   * @cssprop [--sh-radio-button-height=1.5rem] - height of radio-button
   */
  --sh-radio-button-height: 1.5rem;
  /* 
   * @cssprop [--sh-radio-button-outline-offset=0.125rem] - outline ofset
   */
  --sh-radio-button-outline-offset: 0.125rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-width=1.125rem] - width of the pseudo radio-button
   */
  --sh-radio-button-pseudo-width: 1.125rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-height=1.125rem] - height of the pseudo radio-button
   */
  --sh-radio-button-pseudo-height: 1.125rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-margin=0.1875rem] - margin of the pseudo radio-button
   */
  --sh-radio-button-pseudo-margin: 0.1875rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-border-width=0.0625rem] - border-width of the pseudo radio-button
   */
  --sh-radio-button-pseudo-border-width: 0.0625rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-border-width-active=0.375rem] - border-width of the pseudo radio-button when active
   */
  --sh-radio-button-pseudo-border-width-active: 0.375rem;
  /* 
   * @cssprop [--sh-radio-button-pseudo-border-width-hover=0.125rem] - border-width of the pseudo radio-button when hover and active i.e(on-press)
   */
  --sh-radio-button-pseudo-border-width-hover: 0.125rem;
}

:host {
  outline: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  font: var(--body-1);
  color: var(--text-primary);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host([disabled]) {
  pointer-events: none;
  color: var(--text-disabled) !important;
}

:host(:not([disabled]):not([readonly])) * {
  cursor: pointer;
}

:host .radio-container {
  display: flex;
  align-items: baseline;
  height: fit-content;
  width: 100%;
  position: relative;
  margin: var(--sh-radio-button-container-margin);
}

:host .radioBtn {
  opacity: 0;
  position: absolute;
  margin: 0;
  width: var(--sh-radio-button-width);
  height: var(--sh-radio-button-height);
  align-self: flex-start;
}

:host .pseudo-radio {
  border-radius: 50%;
  border-width: var(--sh-radio-button-pseudo-border-width);
  border-style: solid;
  border-color: rgba(var(--ui-1), var(--opacity-600));
  transition: 0.05s ease-in-out all;
  margin: var(--sh-radio-button-pseudo-margin);
  box-sizing: border-box;
  height: var(--sh-radio-button-pseudo-height);
  width: var(--sh-radio-button-pseudo-width);
  align-self: flex-start;
}

:host label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: var(--sh-radio-button-label-padding);
  margin: var(--sh-radio-button-label-margin);
  flex: 1;
  font: var(--body-1);
  line-height: var(--sh-radio-button-label-line-height);
  text-overflow: ellipsis;
  overflow: hidden;
}

:host([label=""]),
:host(:not([label])) {
  width: fit-content;
  width: -moz-fit-content;
}

/* Component State Styles */
:host([active]) .pseudo-radio {
  height: var(--sh-radio-button-pseudo-height);
  width: var(--sh-radio-button-pseudo-width);
  border-width: var(--sh-radio-button-pseudo-border-width-active) !important;
  box-sizing: border-box;
  border-style: solid;
  transition: 0.05s ease-in-out all;
  border-color: rgba(var(--ui-2), var(--opacity-1000));
  background: rgba(var(--ui-7), var(--opacity-1000));
}

/* Neutral */
:host([neutral][active]:not([disabled])) .pseudo-radio {
  border-color: var(--selection-ctrl-neutral-active-fill);
  background: rgba(var(--ui-7), var(--opacity-1000));
}

:host([disabled]) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-600));
  opacity: 0.3;
}

:host([readonly]) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-600));
  background: rgba(var(--ui-1), var(--opacity-50));
  opacity: 0.3;
}

:host([neutral][disabled][active]) .pseudo-radio,
:host([neutral][readonly][active]) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-700));
  opacity: 0.3;
}

:host([disabled][active]:not([neutral])) .pseudo-radio,
:host([readonly][active]:not([neutral])) .pseudo-radio {
  border-color: rgba(var(--ui-3), var(--opacity-1000));
  opacity: 0.3;
}

/* hover */
:host(:hover:not([readonly]):not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-600));
  border-width: var(--sh-radio-button-pseudo-border-width-hover);
}

:host(:active:not([readonly]):not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-900));
  border-width: var(--sh-radio-button-pseudo-border-width-hover);
}

:host([active]:not([readonly]):hover:not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-3), var(--opacity-1000));
  background: rgba(var(--ui-7), var(--opacity-1000));
}

:host([active]:active:not([readonly]):not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-2), var(--opacity-1000));
  background: rgba(var(--ui-7), var(--opacity-1000));
  opacity: 1;
}

:host([neutral][active]:not([readonly]):hover:not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-900));
  background: rgba(var(--ui-7), var(--opacity-1000));
}

:host([neutral][active]:not([readonly]):active:not(.no-hovermq)) .pseudo-radio {
  border-color: rgba(var(--ui-1), var(--opacity-700));
  background: rgba(var(--ui-7), var(--opacity-1000));
  opacity: 1;
}

.focus {
  outline: var(--focus-outline);
  outline-offset: var(--sh-radio-button-outline-offset);
}`;