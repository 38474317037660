// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { ArcOverlayMixin } from '@advanced-rest-client/arc-overlay-mixin';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
/**
 * marked as internal, typings not exported!
 * TODO: ArcOverlayMixin should be replaced by html dialog component!
 */
let SHOverlay = class SHOverlay extends ArcOverlayMixin(LitElement) {
  static get styles() {
    return [
      css`
        :host {
          background: transparent;
        }
      `,
    ];
  }
  render() {
    return html` <slot></slot> `;
  }
  firstUpdated() {
    this.addEventListener('closed', (e) => {
      e.stopImmediatePropagation();
    });
    this.addEventListener('opened', (e) => {
      e.stopImmediatePropagation();
    });
  }
};
SHOverlay = __decorate([customElement('sh-overlay')], SHOverlay);
export { SHOverlay };
