// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-table-head.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-table-head-line-height=2rem] - line-height of head wrapper
   */
  --sh-table-head-line-height: 2rem;
  /* 
   * @cssprop [--sh-table-head-wrapper-margin=0.5rem 0.75rem] - margin of head wrapper
   */
  --sh-table-head-wrapper-margin: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-condensed-wrapper-margin=0.25rem 0.75rem] - margin of head wrapper
   */
  --sh-table-head-condensed-wrapper-margin: 0.25rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-mobile-wrapper-margin=0.5rem] - margin of mobile head wrapper
   */
  --sh-table-head-mobile-wrapper-margin: 0.5rem;
  /* 
   * @cssprop [--sh-table-head-sort-icon-margin=0] - margin of sort icon
   */
  --sh-table-head-sort-icon-margin: 0;
  /* 
   * @cssprop [--sh-table-head-condensed-wrapper-height=2rem] - height of the condensed header wrapper
   */
  --sh-table-head-condensed-wrapper-height: 2rem;
  /* 
   * @cssprop [--sh-table-head-icon-min-width=3.5rem] - min-width of the icon header wrapper
   */
  --sh-table-head-icon-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-table-head-icon-cell-min-width=3.5rem] - min-width of the icon cell wrapper
   */
  --sh-table-head-icon-cell-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-table-head-header-wrapper-height=2rem] - height of the  header wrapper
   */
  --sh-table-head-header-wrapper-height: 2rem;
  /* 
   * @cssprop [--sh-table-head-padding-left=2.5rem] - padding-left of the table header
   */
  --sh-table-head-padding-left: 2.5rem;
  /* 
   * @cssprop [--sh-table-head-checkbox-margin-top=0.2rem] - checkbox margin top
   */
  --sh-table-head-checkbox-margin-top: 0.2rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-checkbox-margin-top=-1px] - checkbox margin top when label rows property is set
   */
  --sh-table-head-label-rows-checkbox-margin-top: -1px;
  /* 
   * @cssprop [--sh-table-head-label-rows-line-height=1.125rem] - line-height when label rows property is set
   */
  --sh-table-head-label-rows-line-height: 1.125rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-wrapper-margin=0.25rem 0.75rem] - margin of head wrapper when label rows property is set
   */
  --sh-table-head-label-rows-wrapper-margin: 0.25rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-condensed-wrapper-margin=0.125rem 0.75rem] - margin of head wrapper when label rows property is set
   */
  --sh-table-head-label-rows-condensed-wrapper-margin: 0.125rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-mobile-wrapper-margin=0.25rem 0.75rem] - margin of mobile head wrapper when label rows property is set
   */
  --sh-table-head-label-rows-mobile-wrapper-margin: 0.25rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-header-wrapper-height=auto] - height of the  header wrapper when label rows property is set
   */
  --sh-table-head-label-rows-header-wrapper-height: auto;
  /* 
   * @cssprop [--sh-table-head-label-rows-checkbox-wrapper-margin=0.5rem 0.75rem] - margin of the  header wrapper when both checkbox label rows property is set
   */
  --sh-table-head-label-rows-checkbox-wrapper-margin: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-condensed-wrapper-height=auto] - height of the condensed header wrapper when label rows property is set
   */
  --sh-table-head-label-rows-condensed-wrapper-height: auto;
  /* 
   * @cssprop [--sh-table-head-label-rows-header-wrapper-min-height=2.5rem] - minimum height of the  header wrapper when label rows property is set
   */
  --sh-table-head-label-rows-header-wrapper-min-height: 2.5rem;
  /* 
   * @cssprop [--sh-table-head-label-rows-condensed-wrapper-min-height=2rem] - minimum height of the condensed header wrapper when label rows property is set
   */
  --sh-table-head-label-rows-condensed-wrapper-min-height: 2rem;
}

:host {
  display: inline-flex;
  box-sizing: border-box;
  width: -ms-fit-content;
  width: -moz-fit-content;
  outline: 0;
  align-items: center;
}

:host([unsortable][number]) {
  justify-content: flex-end;
}

:host * {
  box-sizing: border-box;
}

.head-wrapper {
  display: flex;
  color: var(--text-primary);
  font: var(--title-1);
  line-height: var(--sh-table-head-line-height);
  margin: var(--sh-table-head-wrapper-margin);
  height: var(--sh-table-head-header-wrapper-height);
  width: calc(100% - 1.5rem);
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
}

:host(:not([icon]):not([checkbox])) .head-wrapper-text {
  width: fit-content;
  max-width: calc(100% - 2rem);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

:host([label-rows]) .head-wrapper {
  height: var(--sh-table-head-label-rows-header-wrapper-height);
  line-height: var(--sh-table-head-label-rows-line-height);
  margin: var(--sh-table-head-label-rows-wrapper-margin);
  align-items: center;
}

:host([label-rows]:not([icon]):not([checkbox])) .head-wrapper-text {
  display: -webkit-box;
  -webkit-line-clamp: var(--label-rows);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
}

:host([label-rows][checkbox]) {
  margin-top: var(--sh-table-head-label-rows-checkbox-margin-top);
  height: 100%;
}

:host([label-rows][checkbox]) .head-wrapper {
  margin-top: var(--sh-table-head-label-rows-checkbox-wrapper-margin);
}

:host(:not([checkbox])[unsortable]) .head-wrapper-text {
  max-width: 100%;
}

:host([active]) {
  background: transparent;
}

.sort-icon {
  display: inline-block;
  visibility: hidden;
  position: relative;
  color: rgba(var(--ui-1), var(--opacity-4));
  margin: var(--sh-table-head-sort-icon-margin);
}

.alwaysStay {
  visibility: visible;
}

/* number heads */
:host([number]) .head-wrapper {
  display: flex;
  justify-content: flex-end;
}

/* icon heads */
:host([icon]),
:host([checkbox]) {
  flex: 0;
  min-width: var(--sh-table-head-icon-min-width) !important;
}

:host([checkbox]) {
  margin-top: var(--sh-table-head-checkbox-margin-top);
}

:host([icon]) .sort-icon,
:host([checkbox]) .sort-icon {
  display: none;
}

/* medium screens */
@media only screen and (max-width: 64rem) {
  :host([icon]),
  :host([checkbox]) {
    min-width: var(--sh-table-head-icon-min-width) !important;
  }
  .head-wrapper {
    margin: var(--sh-table-head-mobile-wrapper-margin);
    width: calc(100% - 1rem);
  }
  :host([label-rows]) .head-wrapper {
    margin: var(--sh-table-head-label-rows-mobile-wrapper-margin);
  }
}
/* hover */
:host([unsortable]:hover:not(.no-hovermq)),
:host([unsortable]:hover) *,
:host([unsortable].no-hovermq):hover,
:host([unsortable]):hover * {
  cursor: default;
}

:host([unsortable]:hover:not(.no-hovermq)) .head-wrapper,
:host([unsortable].no-hovermq):hover .head-wrapper {
  cursor: default;
}

:host(:not(.no-hovermq):hover) .sort-icon,
:host(.no-hovermq:hover) .sort-icon,
:host(:not(.no-hovermq)) .head-wrapper.hover .sort-icon,
:host(.no-hovermq) .head-wrapper.hover .sort-icon {
  visibility: visible;
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host(:not(.no-hovermq)) .head-wrapper:hover,
:host(.no-hovermq) .head-wrapper:hover,
:host(:not(.no-hovermq)) .head-wrapper.hover,
:host(.no-hovermq) .head-wrapper.hover,
:host(:not(.no-hovermq):not([unsortable]):hover) {
  cursor: pointer;
}

/* Condensed Table */
:host([condensed]) .head-wrapper {
  margin: var(--sh-table-head-condensed-wrapper-margin);
  height: var(--sh-table-head-condensed-wrapper-height);
}

:host([condensed][label-rows]) .head-wrapper {
  margin: var(--sh-table-head-label-rows-condensed-wrapper-margin);
  height: var(--sh-table-head-label-rows-condensed-wrapper-height);
  min-height: var(--sh-table-head-label-rows-condensed-wrapper-min-height);
}

/* focus */
.focus {
  outline: var(--focus-outline);
}

/* first nested cell */
:host(.first-header-cell-checkbox) {
  padding-left: var(--sh-table-head-padding-left);
}

:host(.first-header-cell) {
  padding-left: 0;
}`;