// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { css } from 'lit';
const $_documentContainer = document.createElement('template');
export const sharedStyles = css `
  ::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
    visibility: hidden;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(var(--ui-1), var(--opacity-6));
    border-radius: var(--scrollbar-corner-radius);
  }

  ::-webkit-scrollbar-thumb:active,
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--ui-1), var(--opacity-4))
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(var(--ui-1), var(--opacity-7));
    border-radius: var(--scrollbar-corner-radius);
  }

  *:active::-webkit-scrollbar-track:active {
    width: 1rem;
  }

  sh-nav-bar::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    background-color: transparent !important;
    box-shadow: 0rem !important;
  }

  *[hidden] {
    display: none !important;
  }

  /* Firefox scrollbar */
@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color:  rgba(var(--ui-1), var(--opacity-6)) rgba(var(--ui-1), var(--opacity-7));
    scrollbar-width: thin;
  }
  *:hover, *:active {
    scrollbar-color:  rgba(var(--ui-1), var(--opacity-4)) rgba(var(--ui-1), var(--opacity-7));
    scrollbar-width: thin
  }
}
  /* hr spacer */

  hr {
    border: none;
    -webkit-margin-before: 0.5rem;
    -webkit-margin-after: 0.5rem;
  }
`;
$_documentContainer.innerHTML = `<dom-module id="shared-styles">
  <template>
    <style>
      ${sharedStyles}
    </style>
  </template>
</dom-module>`;
document.head.appendChild($_documentContainer.content);
