// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-dropdown.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-dropdown-overlay-height=2.5rem] - height of the dropdown to position the overlay
   */
  --sh-dropdown-overlay-height: 2.5rem;
  /* 
   * @cssprop [--sh-dropdown-overlay-condensed-height=2rem] - height of the dropdown when condensed to position the overlay
   */
  --sh-dropdown-overlay-condensed-height: 2rem;
  /* 
   * @cssprop [--sh-dropdown-overlay-padding=0 0.5rem] - overlay padding
   */
  --sh-dropdown-overlay-padding: 0 0.5rem;
  /* 
   * @cssprop [--sh-dropdown-overlay-max-height=13.75rem] - max height of the overlay
   */
  --sh-dropdown-overlay-max-height: 13.75rem;
  /* 
   * @cssprop [--sh-dropdown-icon-input-padding=1.25rem 0 0.1875rem 3rem] - padding of the dropdown input with icon set
   */
  --sh-dropdown-icon-input-padding: 1.25rem 0 0.1875rem 3rem;
  /* 
   * @cssprop [--sh-dropdown-icon-label-padding-left=3rem] - padding of the label with icon set
   */
  --sh-dropdown-icon-label-padding-left: 3rem;
  /* 
   * @cssprop [--sh-dropdown-icon-top=0.25rem] - position top of the icon
   */
  --sh-dropdown-icon-top: 0.25rem;
  /* 
   * @cssprop [--sh-dropdown-icon-left=0.5rem] - position left of the icon
   */
  --sh-dropdown-icon-left: 0.5rem;
  /* 
   * @cssprop [--sh-dropdown-label-pointer-event=all] - Pointer event of the label
   */
  --sh-dropdown-label-pointer-event: all;
  /* 
   * @cssprop [--sh-dropdown-label-disabled-pointer-event=none] - pointer events of the input text label when disabled
   */
  --sh-dropdown-label-disabled-pointer-event: none;
  /* 
   * @cssprop [--sh-dropdown-icon-wrapper-left-margin=0.25rem] - left margin of the arrow icon inside the icon wrapper
   */
  --sh-dropdown-icon-wrapper-left-margin: 0.25rem;
  /* 
   * @cssprop [--sh-dropdown-error-success-icon-left-margin=0.5rem] - left margin of the error/success icons inside the icon wrapper
   */
  --sh-dropdown-error-success-icon-left-margin: 0.5rem;
  /* 
   * @cssprop [--sh-dropdown-condensed-input-padding=0.25rem 0 0.1875rem 2.5rem] - Padding for the input when dropdown is condensed
   */
  --sh-dropdown-condensed-input-padding: 0.25rem 0 0.1875rem 2.5rem;
  /* 
   * @cssprop [--sh-dropdown-arrow-icon-size=1.9375rem] - Dropdown arrow icon size when dropdown is condensed
   */
  --sh-dropdown-arrow-icon-size: 1.9375rem;
  /* 
   * @cssprop [--sh-dropdown-input-cursor=pointer] - cursor for the dropdown input
   */
  --sh-dropdown-input-cursor: pointer;
  /* 
   * @cssprop [--sh-dropdown-transition-time=0s] - transition for label and input
   */
  --sh-dropdown-transition-time: 0s;
}

:host {
  width: 100%;
  height: fit-content;
  position: relative;
  display: block;
  --sh-input-text-pointer-events: var(--sh-dropdown-label-pointer-event);
  --sh-input-text-icon-left-margin: var(--sh-dropdown-error-success-icon-left-margin);
  --sh-input-text-icon-wrapper-right-padding: var(--sh-dropdown-icon-wrapper-left-margin);
  --sh-input-text-slot-icon-left-margin: var(--sh-dropdown-icon-wrapper-left-margin);
  --sh-input-text-input-cursor: var(--sh-dropdown-input-cursor);
  --transition-time: var(--sh-dropdown-transition-time);
}

sh-input-text {
  cursor: pointer;
}

.dropdown-wrapper {
  position: relative;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

:host(:not([disabled]:not([readonly]))) .dropdown-wrapper:focus-visible {
  outline: var(--focus-outline);
}

:host([active]) #dropdown-icon {
  transform: rotate(180deg);
}

.overlay {
  min-width: 0;
  overflow: auto;
  padding: var(--sh-dropdown-overlay-padding);
  background: var(--base-4);
  box-shadow: var(--shadow-overlay);
  box-sizing: border-box;
  border-radius: 0.125rem;
  display: none;
}

:host([items]) .overlay {
  max-height: var(--max-height) !important;
}

:host(:not([items])) .overlay {
  max-height: var(--sh-dropdown-overlay-max-height) !important;
}

:host([active]) .overlay {
  display: block;
  max-width: unset !important;
}

/* Validation Styles */
:host([readonly]) #dropdown-icon {
  display: none;
}

:host([readonly]),
:host([disabled]) {
  pointer-events: none;
  --sh-input-text-pointer-events: var(--sh-dropdown-label-disabled-pointer-event);
}

/* icon */
:host([condensed]:not([editable])) {
  --sh-input-text-label-condensed-display: block;
}

.dropdown-icon {
  position: absolute;
  top: var(--sh-dropdown-icon-top);
  left: var(--sh-dropdown-icon-left);
}

::slotted([class="touch-device hidden"]),
::slotted([class=hidden]) {
  display: none;
}

:host([icon]) {
  --sh-input-text-label-left: var(--sh-dropdown-icon-label-padding-left) !important;
  --sh-input-text-padding: var(--sh-dropdown-icon-input-padding) !important;
  --sh-input-text-condensed-padding: var(--sh-dropdown-condensed-input-padding) !important;
}

.overlay-container {
  position: absolute;
  height: var(--sh-dropdown-overlay-height);
  top: 0rem;
  left: 0rem;
}

:host([condensed]) .overlay-container {
  height: var(--sh-dropdown-overlay-condensed-height);
}

:host([condensed]) #dropdown-icon {
  --sh-icon-size-m: var(--sh-dropdown-arrow-icon-size);
}`;