// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-scrollpicker.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-scrollpicker-main-container-height=12.5rem] - height of the main-container
   */
  --sh-scrollpicker-main-container-height: 12.5rem;
  /* 
   * @cssprop [--sh-scrollpicker-parent-container-padding=0.5rem 1rem] - padding of the parent-container
   */
  --sh-scrollpicker-parent-container-padding: 0.5rem 1rem;
  /* 
   * @cssprop [--sh-scrollpicker-parent-container-width=18rem] - width of the parent-container
   */
  --sh-scrollpicker-parent-container-width: 18rem;
  /* 
   * @cssprop [--sh-scrollpicker-data-cell-height=2.5rem] - height of the data cell
   */
  --sh-scrollpicker-data-cell-height: 2.5rem;
  /* 
   * @cssprop [--sh-scrollpicker-data-cell-width=3.25rem] - width of the data cell
   */
  --sh-scrollpicker-data-cell-width: 3.25rem;
  /* 
   * @cssprop [--sh-scrollpicker-selector-top=5rem] - top of the selector
   */
  --sh-scrollpicker-selector-top: 5rem;
  /* 
   * @cssprop [--sh-scrollpicker-selector-height=var(--sh-scrollpicker-data-cell-height)] - height placement of the second selector
   */
  --sh-scrollpicker-selector-height: var(--sh-scrollpicker-data-cell-height);
  /* 
   * @cssprop [--sh-scrollpicker-current-scale=s] - the current scale of scrollpicker used to highlight the time/date selected
   */
  --sh-scrollpicker-current-scale: s;
}

:host * {
  box-sizing: border-box;
}

:host {
  --current-scale: var(--sh-scrollpicker-current-scale);
}

.parent-container {
  width: var(--sh-scrollpicker-parent-container-width);
  padding: var(--sh-scrollpicker-parent-container-padding);
  border-radius: 0.125rem;
  background-color: var(--base-4);
  overflow: hidden;
  box-shadow: var(--shadow-overlay);
}

.main-container {
  height: var(--sh-scrollpicker-main-container-height);
  position: relative;
  transition: 0s all linear !important;
}

.scrollers-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: 0s all linear !important;
}

.scroll-container {
  height: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  transition: 0s all linear !important;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: fit-content;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.row {
  height: var(--sh-scrollpicker-data-cell-height);
  width: var(--sh-scrollpicker-data-cell-width);
  font: var(--body-1);
  color: var(--text-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  box-sizing: border-box;
  word-break: keep-all;
  transition: 0s all linear !important;
}

.selector {
  position: absolute;
  top: var(--sh-scrollpicker-selector-top);
  border-top-color: rgba(var(--ui-1), var(--opacity-6));
  border-bottom-color: rgba(var(--ui-1), var(--opacity-6));
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 0.0625rem;
  height: var(--sh-scrollpicker-selector-height);
  width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  transition: 0s all linear !important;
}

.row.selected {
  color: var(--text-primary);
}

.row.no-click {
  width: 100%;
  padding: 0;
}`;