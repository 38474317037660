// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-home-screen.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-home-screen-card-height=20rem] - height of the card in cards-wrapper
   */
  --sh-home-screen-card-height: 20rem;
  /* 
   * @cssprop [--sh-home-screen-card-width=20rem] - width of the card in cards-wrapper
   */
  --sh-home-screen-card-width: 20rem;
  /* 
   * @cssprop [--sh-home-screen-card-margin-right=1rem] - margin right of the card
   */
  --sh-home-screen-card-margin-right: 1rem;
  /* 
   * @cssprop [--sh-home-screen-functions-button-bottom=1rem] - vertical position of the function button
   */
  --sh-home-screen-functions-button-bottom: 1rem;
  /* 
   * @cssprop [--sh-home-screen-functions-button-right=1rem] - horizintal position of the function button
   */
  --sh-home-screen-functions-button-right: 1rem;
  /* 
   * @cssprop [--sh-home-screen-functions-card-bottom=3.5rem] - vertical position of the function card
   */
  --sh-home-screen-functions-card-bottom: 3.5rem;
  /* 
   * @cssprop [--sh-home-screen-functions-card-right=1rem] - horizontal position of the function card
   */
  --sh-home-screen-functions-card-right: 1rem;
  /* 
   * @cssprop [--sh-home-screen-functions-card-width=15rem] - width of the function card
   */
  --sh-home-screen-functions-card-width: 15rem;
}

:host {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: fit-content;
  width: fit-content;
  max-height: calc(100% + 1rem);
  max-width: calc(100% + 1rem);
  margin: -0.5rem;
  justify-content: center;
  align-items: center;
}

.cards-wrapper > ::slotted(sh-card) {
  height: var(--sh-home-screen-card-height);
  width: var(--sh-home-screen-card-width);
}

.cards-wrapper > ::slotted(sh-card:not(:first-of-type)) {
  margin-left: var(--sh-home-screen-card-margin-right);
}

#functions-button {
  position: fixed;
  bottom: var(--sh-home-screen-functions-button-bottom);
  right: var(--sh-home-screen-functions-button-right);
}

.functions-card:not([active]) {
  opacity: 0;
  margin-bottom: -1rem;
  pointer-events: none;
}

.functions-card {
  transition: var(--transition-time) all ease-in-out;
  position: fixed;
  right: var(--sh-home-screen-functions-card-right);
  bottom: var(--sh-home-screen-functions-card-bottom);
  width: var(--sh-home-screen-functions-card-width);
}

.functions-div {
  margin: -0.5rem 0;
}

/* small screens */
:host([mobile]) {
  align-items: flex-start;
}

:host([mobile]) .cards-wrapper {
  width: calc(100% + 1rem);
  flex-direction: column;
}

:host([mobile]) .cards-wrapper > ::slotted(sh-home-card) {
  width: calc(100% - 1rem);
}`;