// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2024. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent extends CustomEvent {
  constructor(prop, init) {
    super(`${prop}-changed`, init);
  }
}
export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('disabled', init);
  }
}
export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('icon');
  }
}
export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('label');
  }
}
export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('size');
  }
}
export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('color');
  }
}
export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('tooltip');
  }
}
export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('href');
  }
}
