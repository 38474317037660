import { __awaiter, __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier';
import { convertToHourMinSec, getUpdatedTime } from '../../utils/media-time-converter.js';
import mediaControlsStyles from './sh-media-controls.lit.scss.js';
let SHMediaControls = class SHMediaControls extends LitElement {
  static get styles() {
    return [mediaControlsStyles];
  }
  constructor() {
    super();
    /** Defines the width of the control bar and accepts a number or CSS attribute (e.g., fit-content). */
    this.width = '100%';
    /** When set, Allows users to step back or forward in the video by a specific duration*/
    this.stepDuration = 10;
    /** The progress-position attribute accepts two values: `center` (default), which positions the progress bar vertically centred within the control bar, and `top`, which positions the progress bar at the top of the control bar.*/
    this.progressPosition = 'center';
    /** The time-display-primary attribute on sh-media-controls determine the types of time display; possible values are `current`, `remaining`, `total`, and `false`.*/
    this.timeDisplayPrimary = 'current';
    /** The time-display-secondary attribute on sh-media-controls determine the types of time display; possible values are `current`, `remaining`, `total`, and `false`.*/
    this.timeDisplaySecondary = 'total';
    this.emptyFeatureRight = true;
    /** @ignore */
    this.emptySettingsLeft = true;
    /** @ignore */
    this.emptyExtended = true;
    this.playBackSpeedValues = ['4×', '2×', '1×', '0.5×', '0.25×'];
  }
  render() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return html`
      <div class="extended-slot-wrapper" style="width: ${this.width}; ">
        <slot
          name="extended-controls"
          @slotchange="${(e) => {
            this.emptyExtended = this.isSlotEmpty(e.target);
          }}"
        ></slot>
      </div>
      ${this.progressPosition === 'center' && !this.emptyExtended
        ? html`<sh-divider class="separator" style="width: ${this.width};"></sh-divider>`
        : ''}
      <div style="width: ${this.width}; " class="control-progress-wrapper">
        ${this.progressPosition === 'top'
          ? html`<sh-media-progress class="progress-top"></sh-media-progress>`
          : ''}
        <div class="control-wrapper">
          ${this.progressPosition === 'center'
            ? html` ${this.getPlayerControlTemplate()}
                ${!this.emptySettingsLeft ||
                (this.loopControl && this.loopControl !== 'hidden') ||
                (this.playbackSpeedControl && this.playbackSpeedControl !== 'hidden')
                  ? html` <sh-divider vertical class="divider"></sh-divider>`
                  : ''}
                ${this.getSettingControlTemplate()}
                <sh-media-progress></sh-media-progress>`
            : html` ${this.getSettingControlTemplate()} ${this.getPlayerControlTemplate()} `}
          <div class="right-wrapper">
            <div class="time-wrapper">
              ${this.timeDisplayPrimary && this.timeDisplayPrimary !== 'false'
                ? html` <sh-text
                    class="primary-text"
                    size="body-2"
                    color="primary"
                    @click="${() => {
                      if (this.timeDisplaySecondary === 'false') {
                        dispatchCustomEvent(this, 'time-clicked');
                      }
                    }}"
                  >
                  </sh-text>`
                : ''}
              ${this.timeDisplaySecondary && this.timeDisplaySecondary !== 'false'
                ? html` <sh-text color="secondary" size="body-2" class="secondary-text"></sh-text>`
                : ''}
            </div>
            ${(this.volumeControl && this.volumeControl !== 'hidden') ||
            (this.fullscreenControl && this.fullscreenControl !== 'hidden') ||
            !this.emptyFeatureRight
              ? html` <sh-divider vertical class="divider"></sh-divider>`
              : ''}
            <div class="feature-controls-wrapper">
              ${this.volumeControl &&
              (this.volumeControl === 'visible' || this.volumeControl === 'disabled')
                ? html`
                    <div
                      id="volume"
                      @mouseenter=${() => this.openPopoverOnMouseEnter()}
                      @mouseleave=${() => this.closePopoverOnMouseLeave()}
                    >
                      <sh-icon
                        class="volume-icon"
                        icon="${((_a = this.video) === null || _a === void 0 ? void 0 : _a.muted) ||
                        ((_c =
                          (_b = this.parentElement) === null || _b === void 0
                            ? void 0
                            : _b.querySelector('video')) === null || _c === void 0
                          ? void 0
                          : _c.muted)
                          ? 'speakers-off'
                          : 'speakers-on'}"
                        size="s"
                        button
                        ?toggle=${deviceIdentifier.isTouchDevice()}
                        @click=${(e) => this.handleVolumePopoverVisibilityOnIconClick(e)}
                        ?disabled=${this.volumeControl === 'disabled'}
                      ></sh-icon>

                      <sh-popover
                        id="volume-popover"
                        sticky
                        position="top"
                        @mobile-changed=${(e) => {
                          e.target.mobile = false;
                        }}
                        @visible-changed="${(e) => this.handleVolumePopoverVisibleChanged(e)}"
                      >
                        <sh-slider
                          id="volume-slider"
                          active-track
                          direction-toggle
                          vertical
                          min="0"
                          max="1"
                          step="0.1"
                          label=""
                          value="${((_d = this.video) === null || _d === void 0
                            ? void 0
                            : _d.muted) ||
                          ((_f =
                            (_e = this.parentElement) === null || _e === void 0
                              ? void 0
                              : _e.querySelector('video')) === null || _f === void 0
                            ? void 0
                            : _f.muted)
                            ? 0
                            : ((_g = this.video) === null || _g === void 0 ? void 0 : _g.volume)
                              ? (_h = this.video) === null || _h === void 0
                                ? void 0
                                : _h.volume
                              : (_k =
                                    (_j = this.parentElement) === null || _j === void 0
                                      ? void 0
                                      : _j.querySelector('video')) === null || _k === void 0
                                ? void 0
                                : _k.volume}"
                          @value-changed="${(e) => this.handleVolumeSliderValueChanged(e)}"
                          @value-change-end="${() => this.handleVolumeSliderValueChangeEnd()}"
                        ></sh-slider>
                      </sh-popover>
                    </div>
                  `
                : ''}
              ${this.fullscreenControl === 'visible' || this.fullscreenControl === 'disabled'
                ? html`<sh-icon
                    class="fullscreen"
                    icon="fullscreen"
                    size="s"
                    button
                    @click="${(e) => this.toggleFullScreen(e)}"
                    ?disabled=${this.fullscreenControl === 'disabled'}
                  ></sh-icon>`
                : ''}
              <slot
                name="features-right"
                @slotchange="${(e) => {
                  this.emptyFeatureRight = this.isSlotEmpty(e.target);
                }}"
              ></slot>
            </div>
          </div>
        </div>
      </div>
    `;
  }
  firstUpdated() {
    var _a, _b, _c, _d, _e, _f, _g;
    const player = this.parentElement;
    this.video = !this.video
      ? player === null || player === void 0
        ? void 0
        : player.getElementsByTagName('video')[0]
      : this.video;
    if (this.timeDisplaySecondary === 'false') {
      const text =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.primary-text');
      if (text) {
        text.innerText = this.getCurrentTime(this.timeDisplayPrimary);
      }
    }
    if (this.video) {
      (_b = this.video) === null || _b === void 0
        ? void 0
        : _b.addEventListener('loadstart', (e) => {
            var _a;
            const player = (_a = e.target) === null || _a === void 0 ? void 0 : _a.parentElement;
            player.isSpinnerVisible = true;
          });
      (_c = this.video) === null || _c === void 0
        ? void 0
        : _c.addEventListener('loadedmetadata', (e) => this.medtadataLoaded(e));
      (_d = this.video) === null || _d === void 0
        ? void 0
        : _d.addEventListener('click', () => this.togglePlayPauseOnVideoClick());
      (_e = this.video) === null || _e === void 0
        ? void 0
        : _e.addEventListener('timeupdate', (e) => this.handleVideoTimeUpdate(e));
      (_f = this.video) === null || _f === void 0
        ? void 0
        : _f.addEventListener('play', (e) => this.syncWithVideoPlayPauseIcon(e));
      (_g = this.video) === null || _g === void 0
        ? void 0
        : _g.addEventListener('pause', (e) => this.syncWithVideoPlayPauseIcon(e));
    }
    document.addEventListener('fullscreenchange', () => this.fullScreenChangeHandler());
  }
  connectedCallback() {
    super.connectedCallback();
    // do not access attributes or any other dom api of your element in the constructor
    // do it in connectedCallback instead
    // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance
    const player = this.parentElement;
    this.video =
      player === null || player === void 0 ? void 0 : player.getElementsByTagName('video')[0];
  }
  disconnectedCallback() {
    var _a, _b, _c, _d, _e, _f;
    (_a = this.video) === null || _a === void 0
      ? void 0
      : _a.removeEventListener('loadstart', (e) => {
          var _a;
          const player = (_a = e.target) === null || _a === void 0 ? void 0 : _a.parentElement;
          player.isSpinnerVisible = true;
        });
    (_b = this.video) === null || _b === void 0
      ? void 0
      : _b.removeEventListener('loadedmetadata', (e) => this.medtadataLoaded(e));
    (_c = this.video) === null || _c === void 0
      ? void 0
      : _c.removeEventListener('click', () => this.togglePlayPauseOnVideoClick());
    (_d = this.video) === null || _d === void 0
      ? void 0
      : _d.removeEventListener('timeupdate', (e) => this.handleVideoTimeUpdate(e));
    (_e = this.video) === null || _e === void 0
      ? void 0
      : _e.removeEventListener('play', (e) => this.syncWithVideoPlayPauseIcon(e));
    (_f = this.video) === null || _f === void 0
      ? void 0
      : _f.removeEventListener('pause', (e) => this.syncWithVideoPlayPauseIcon(e));
    document.addEventListener('fullscreenchange', () => this.fullScreenChangeHandler());
    super.disconnectedCallback();
  }
  updated(changedProperties) {
    var _a, _b;
    super.updated(changedProperties);
    if (changedProperties.has('timeDisplayPrimary') && this.timeDisplayPrimary !== 'false') {
      const text =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.primary-text');
      if (text) {
        text.innerText = this.getCurrentTime(this.timeDisplayPrimary);
      }
    }
    if (changedProperties.has('timeDisplaySecondary') && this.timeDisplaySecondary !== 'false') {
      const text =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.secondary-text');
      if (text) {
        text.innerText = this.getCurrentTime(this.timeDisplaySecondary);
      }
    }
  }
  getPlayerControlTemplate() {
    var _a, _b, _c;
    return html`<div class="player-controls-wrapper">
      ${this.skipBackControl === 'visible' || this.skipBackControl === 'disabled'
        ? html`<sh-icon
            icon="skip-backward"
            size="s"
            button
            .disabled=${this.skipBackControl === 'disabled'}
            @click=${() => {
              dispatchCustomEvent(this, 'skip-back-clicked');
            }}
          ></sh-icon>`
        : ''}
      ${this.stepControl === 'visible'
        ? html`<sh-icon
            icon="phase-backward"
            size="s"
            button
            @click="${() => this.handleStepClick(true)}"
            ?disabled="${((_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime) ===
              0 ||
            ((_c =
              (_b = this.parentElement) === null || _b === void 0
                ? void 0
                : _b.querySelector('video')) === null || _c === void 0
              ? void 0
              : _c.currentTime) === 0}"
          ></sh-icon>`
        : ''}
      <sh-icon
        icon="play"
        class="play-pause"
        size="s"
        button
        @click="${(e) => this.togglePlayPauseOnIconClick(e.target)}"
      ></sh-icon>
      ${this.stepControl === 'visible'
        ? html`<sh-icon
            icon="phase-forward"
            size="s"
            button
            @click="${() => this.handleStepClick(false)}"
          ></sh-icon>`
        : ''}
      ${this.skipForwardControl === 'visible' || this.skipForwardControl === 'disabled'
        ? html` <sh-icon
            icon="skip-forward"
            size="s"
            @click=${() => {
              dispatchCustomEvent(this, 'skip-forward-clicked');
            }}
            .disabled=${this.skipForwardControl === 'disabled'}
            button
          ></sh-icon>`
        : ''}
    </div>`;
  }
  getSettingControlTemplate() {
    var _a, _b;
    return html`<div class="setting-controls-wrapper">
      <slot
        name="settings-left"
        @slotchange="${(e) => {
          this.emptySettingsLeft = this.isSlotEmpty(e.target);
        }}"
      ></slot>
      ${this.loopControl === 'visible' || this.loopControl === 'disabled'
        ? html`<sh-icon
            icon="repeat"
            size="s"
            button
            @click=${() => this.handleLoopClick()}
            toggle
            ?active="${this.video
              ? this.video.loop
              : (
                    (_a = this.parentElement) === null || _a === void 0
                      ? void 0
                      : _a.querySelector('video')
                  )
                ? ((_b = this.parentElement) === null || _b === void 0
                    ? void 0
                    : _b.querySelector('video')
                  ).loop
                : false}"
            ?disabled=${this.loopControl === 'disabled'}
          ></sh-icon>`
        : ''}
      ${this.playbackSpeedControl === 'visible' || this.playbackSpeedControl === 'disabled'
        ? html`<sh-text
              tabindex="0"
              size="header-2"
              color="secondary"
              ?disabled=${this.playbackSpeedControl === 'disabled'}
              id="playback-speed"
              @keyup="${(e) => {
                if (e.code === 'Enter' || e.code === 'Space') {
                  e.target.click();
                }
              }}"
              >1×</sh-text
            >
            <sh-popover
              class="playback-popover"
              @visible-changed="${(e) => this.handleVisibleChanged(e)}"
              target="playback-speed"
            >
              ${this.playBackSpeedValues.map((speed) => {
                return html`<sh-menu-item
                  label=${speed}
                  ?active="${speed === '1×'}"
                  @click="${(e) => this.handlePlaybackChanged(e)}"
                  @keyup="${(e) => {
                    if (e.code === 'Enter' || e.code === 'Space') {
                      this.handlePlaybackChanged(e);
                    }
                  }}"
                ></sh-menu-item>`;
              })}
            </sh-popover>`
        : ''}
    </div>`;
  }
  fullScreenChangeHandler() {
    var _a;
    if (!document.fullscreen) {
      const currentIcon =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.fullscreen');
      currentIcon.icon = 'fullscreen';
    }
  }
  isSlotEmpty(target) {
    const slotNodes =
      target === null || target === void 0
        ? void 0
        : target.assignedNodes({
            flatten: true,
          }).length;
    return slotNodes === 0;
  }
  medtadataLoaded(e) {
    var _a;
    const player = (_a = e.target) === null || _a === void 0 ? void 0 : _a.parentElement;
    player.isSpinnerVisible = false;
  }
  syncWithVideoPlayPauseIcon(e) {
    var _a, _b;
    const player = (_a = e.target) === null || _a === void 0 ? void 0 : _a.parentElement;
    const currentIcon =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.play-pause');
    if (e.type === 'play') {
      if (currentIcon.icon === 'play') {
        currentIcon.icon = 'pause';
      }
    }
    if (e.type === 'pause') {
      player.isSpinnerVisible = false;
      if (currentIcon.icon === 'pause') {
        currentIcon.icon = 'play';
      }
    }
  }
  togglePlayPauseOnVideoClick() {
    var _a;
    const currentIcon =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.play-pause');
    this.togglePlayPauseOnIconClick(currentIcon);
  }
  togglePlayPauseOnIconClick(currentIcon) {
    var _a, _b, _c;
    if ((_a = this.video) === null || _a === void 0 ? void 0 : _a.paused) {
      (_b = this.video) === null || _b === void 0 ? void 0 : _b.play().catch(() => '');
      currentIcon.icon = 'pause';
    } else {
      (_c = this.video) === null || _c === void 0 ? void 0 : _c.pause();
      currentIcon.icon = 'play';
    }
  }
  toggleFullScreen(e) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const currentIcon = e.target;
      if (currentIcon.icon == 'fullscreen') {
        currentIcon.icon = 'exit-fullscreen';
        const player = (_a = this.video) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (player === null || player === void 0 ? void 0 : player.requestFullscreen) {
          yield player === null || player === void 0
            ? void 0
            : player.requestFullscreen().catch(() => {
                console.warn(`requestFullscreen API is not supported`);
              });
        } else {
          console.warn(`requestFullscreen API is not supported`);
        }
      } else {
        currentIcon.icon = 'fullscreen';
        if (document.activeElement !== null && document.exitFullscreen) {
          document === null || document === void 0
            ? void 0
            : document.exitFullscreen().catch(() => {
                console.warn(`exitFullscreen API is not supported`);
              });
        } else {
          console.warn(`exitFullscreen API is not supported`);
        }
      }
      dispatchCustomEvent(this, 'fullscreen-toggle');
    });
  }
  handleStepClick(isStepDown) {
    if (this.stepDuration && this.video) {
      this.video.currentTime = isStepDown
        ? this.video.currentTime - this.stepDuration
        : this.video.currentTime + this.stepDuration;
    }
  }
  handleLoopClick() {
    var _a;
    if (this.video) {
      this.video.loop = !((_a = this.video) === null || _a === void 0 ? void 0 : _a.loop);
    }
  }
  handlePlaybackChanged(e) {
    var _a, _b;
    const playBackText =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#playback-speed');
    const playbackPopover =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('sh-popover');
    const menuItems =
      playbackPopover === null || playbackPopover === void 0
        ? void 0
        : playbackPopover.querySelectorAll('sh-menu-item');
    menuItems === null || menuItems === void 0
      ? void 0
      : menuItems.forEach((menuItem) => {
          if (menuItem !== e.target) {
            menuItem.active = false;
          }
        });
    playBackText.innerHTML = e.target.label;
    if (this.video) {
      this.video.playbackRate = Number(e.target.label.split('×')[0]);
    }
  }
  handleVisibleChanged(e) {
    var _a, _b;
    const div = (_a = e.target) === null || _a === void 0 ? void 0 : _a.previousElementSibling;
    if ((_b = e.target) === null || _b === void 0 ? void 0 : _b.visible) {
      div.classList.add('active');
    } else {
      div.classList.remove('active');
    }
  }
  handleVolumeSliderValueChanged(e) {
    var _a;
    const slider = e.target;
    if (this.video) {
      const volumeIcon =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.volume-icon');
      this.video.volume = slider.value == 0 ? this.video.volume : slider.value;
      this.video.muted = slider.value == 0 ? true : false;
      volumeIcon.icon = this.video.muted ? 'speakers-off' : 'speakers-on';
    }
  }
  handleVolumePopoverVisibleChanged(e) {
    var _a, _b, _c;
    const volumeIcon =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.volume-icon');
    volumeIcon.active = !((_b = e.target) === null || _b === void 0 ? void 0 : _b.visible)
      ? (_c = e.target) === null || _c === void 0
        ? void 0
        : _c.visible
      : volumeIcon.active;
  }
  getCurrentTime(isPrimarySecondary) {
    var _a, _b, _c, _d;
    let time;
    if (isPrimarySecondary === 'current') {
      time = (_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime;
    } else if (isPrimarySecondary === 'total') {
      time = (_b = this.video) === null || _b === void 0 ? void 0 : _b.duration;
    } else {
      time =
        ((_c = this.video) === null || _c === void 0 ? void 0 : _c.duration) -
        ((_d = this.video) === null || _d === void 0 ? void 0 : _d.currentTime);
    }
    if (!time && (isPrimarySecondary === 'total' || isPrimarySecondary === 'remaining')) {
      return '';
    }
    if (!time && isPrimarySecondary === 'current') {
      return '00:00';
    }
    const convertedTime = convertToHourMinSec(time);
    const resultTime = getUpdatedTime(convertedTime.h, convertedTime.m, convertedTime.s);
    return resultTime;
  }
  handleVideoTimeUpdate(e) {
    var _a, _b, _c, _d;
    const video = e.target;
    const stepBackIcon =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('sh-icon[icon="phase-backward"]');
    const stepForwardIcon =
      (_b = this.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('sh-icon[icon="phase-forward"]');
    if (stepBackIcon) {
      stepBackIcon.disabled = video.currentTime == 0 ? true : false;
    }
    if (stepForwardIcon) {
      stepForwardIcon.disabled = video.currentTime >= video.duration ? true : false;
    }
    if (this.timeDisplayPrimary !== 'false') {
      const primaryText =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.primary-text');
      primaryText.innerText = this.getCurrentTime(this.timeDisplayPrimary).toString();
    }
    if (this.timeDisplaySecondary !== 'false') {
      const secondaryText =
        (_d = this.shadowRoot) === null || _d === void 0
          ? void 0
          : _d.querySelector('.secondary-text');
      secondaryText.innerText = this.getCurrentTime(this.timeDisplaySecondary).toString();
    }
  }
  handleVolumePopoverVisibilityOnIconClick(e) {
    var _a, _b, _c;
    const volumeIcon = e.target;
    const previousVolume = (_a = this.video) === null || _a === void 0 ? void 0 : _a.volume;
    const slider =
      (_b = this.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('#volume-slider');
    if (volumeIcon.icon === 'speakers-on') {
      if (this.video) {
        this.video.muted = true;
      }
      slider.value = 0;
    } else {
      if (this.video) {
        this.video.muted = false;
      }
      slider.value = previousVolume;
    }
    if (deviceIdentifier.isTouchDevice()) {
      e.stopPropagation();
      const popover =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('#volume-popover');
      if (popover) {
        popover.sticky = false;
        volumeIcon.active = false;
        if (!popover.visible) {
          popover.visible = true;
        }
      }
    }
  }
  openPopoverOnMouseEnter() {
    var _a;
    if (this.volumeControl === 'visible') {
      if (!deviceIdentifier.isTouchDevice()) {
        const popover =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('#volume-popover');
        if (popover) {
          popover.visible = true;
        }
      }
    }
  }
  closePopoverOnMouseLeave() {
    var _a;
    if (!deviceIdentifier.isTouchDevice()) {
      const popover =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#volume-popover');
      if (popover) {
        popover.visible = false;
      }
    }
  }
  handleVolumeSliderValueChangeEnd() {
    setTimeout(() => {
      var _a;
      if (deviceIdentifier.isTouchDevice()) {
        const popover =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('#volume-popover');
        if (popover) {
          popover.visible = false;
        }
      }
    }, 200);
  }
};
__decorate([property({ type: String, reflect: true })], SHMediaControls.prototype, 'width', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'background-style' })],
  SHMediaControls.prototype,
  'backgroundStyle',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'step-control' })],
  SHMediaControls.prototype,
  'stepControl',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'step-duration' })],
  SHMediaControls.prototype,
  'stepDuration',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'skip-back-control' })],
  SHMediaControls.prototype,
  'skipBackControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'skip-forward-control' })],
  SHMediaControls.prototype,
  'skipForwardControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'loop-control' })],
  SHMediaControls.prototype,
  'loopControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'volume-control' })],
  SHMediaControls.prototype,
  'volumeControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'fullscreen-control' })],
  SHMediaControls.prototype,
  'fullscreenControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'playback-speed-control' })],
  SHMediaControls.prototype,
  'playbackSpeedControl',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'progress-position' })],
  SHMediaControls.prototype,
  'progressPosition',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'time-display-primary' })],
  SHMediaControls.prototype,
  'timeDisplayPrimary',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'time-display-secondary' })],
  SHMediaControls.prototype,
  'timeDisplaySecondary',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'empty-feature-right' })],
  SHMediaControls.prototype,
  'emptyFeatureRight',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'empty-settings-left' })],
  SHMediaControls.prototype,
  'emptySettingsLeft',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'empty-extended' })],
  SHMediaControls.prototype,
  'emptyExtended',
  void 0
);
SHMediaControls = __decorate([customElement('sh-media-controls')], SHMediaControls);
export { SHMediaControls };
