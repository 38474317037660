// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { css, html, unsafeCSS } from 'lit';
import { query } from 'lit/decorators.js';
/** ### PullToRefresh mixin
 * ___
 * Superclass aimed to add the pull to refresh behavior into different componenents.
 * ___
 * #### How to use this mixin ?
 *
 * Import like this in the component:
 * ```js
 * import { PullToRefreshMixin } from '../../utils/pull-to-refresh-mixin';
 * ```
 * Then inherit this mixin like this:
 * ```js
 * export class SHPage extends PullToRefreshMixin(LitElement) {
 * ```
 * ___
 *
 * #### How does this mixin work ?
 *
 * - The render spinner function can be called in the render function of the component
 * where the pull to refresh should be placed. The function takes the content which should be pulled
 * down as an parameter.
 *
 * The behaviour can be enabled by adding the property `pulltorefresh`
 * on the component tag. By pulling down the content the spinner will be shown and the
 * component will be set into the "refreshing" state. This reflects on the refreshing
 * property on the component tag. By removing the refreshing attribute the component
 * is brought back into the starting postion.
 * ___
 * Note:
 * The properties `pulltorefresh` and `refreshing` are defaults and can be overwritten:
 * ```
 * export class SHPage extends PullToRefreshMixin(LitElement, {
 *  activationProp: 'test',
 *  refreshingProp: 'test2',
 * }) {
 * ```
 * ___
 */
export const pullToRefreshMixin = (
  superClass,
  { activationProp = 'pulltorefresh', refreshingProp = 'refreshing' }
) => {
  class pullToRefreshMixinElement extends superClass {
    static get properties() {
      return {
        [activationProp]: { type: Boolean, reflect: true },
        [refreshingProp]: { type: Boolean, reflect: true },
      };
    }
    static get styles() {
      return [
        css`
          :host([${unsafeCSS(activationProp)}]) .spinner-parent-element {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            scroll-snap-align: end;
            scroll-snap-type: y mandatory;
          }
          :host([${unsafeCSS(activationProp)}]) .spinner-parent-element::-webkit-scrollbar {
            display: none;
          }
          :host([${unsafeCSS(activationProp)}]) .ptr-area + * {
            scroll-snap-align: start;
            min-height: 100%;
          }
          :host([${unsafeCSS(activationProp)}]) .ptr-area {
            width: 100%;
            padding-top: 1rem;
          }
          :host([${unsafeCSS(activationProp)}][${unsafeCSS(
            refreshingProp
          )}]) .spinner-parent-element {
            scroll-snap-type: none;
          }
        `,
      ];
    }
    renderSpinner(content) {
      return html`
        <sh-spinner class="ptr-area" size="s"></sh-spinner>
        <div class="body-element">${content}</div>
      `;
    }
    connectedCallback() {
      super.connectedCallback();
    }
    update(changedProperties) {
      super.update(changedProperties);
      if (this[activationProp]) {
        const spinnerParentElement = this.spinner.parentElement;
        spinnerParentElement.addEventListener('scroll', this.pullToRefresh.bind(this));
        !spinnerParentElement.classList.contains('spinner-parent-element') &&
          (spinnerParentElement.className += ' spinner-parent-element');
      } else {
        const spinnerParentElement = this.renderRoot.querySelector('.spinner-parent-element');
        spinnerParentElement && spinnerParentElement.classList.remove('spinner-parent-element');
        this[refreshingProp] = false;
      }
    }
    pullToRefresh() {
      var _a;
      if (this.spinner) {
        if (
          ((_a = this.spinner.parentElement) === null || _a === void 0 ? void 0 : _a.scrollTop) <= 0
        ) {
          this[refreshingProp] = true;
        } else {
          this[refreshingProp] = false;
        }
      }
    }
  }
  __decorate([query('sh-spinner')], pullToRefreshMixinElement.prototype, 'spinner', void 0);
  return pullToRefreshMixinElement;
};
