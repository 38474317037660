// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tabs.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tab-item-text-line-height=1.5rem] - text line height
   */
  --sh-tab-item-text-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-height=3.5rem] - icon height
   */
  --sh-tab-item-icon-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-line-height=2rem] - icon line-height
   */
  --sh-tab-item-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-icon-padding=0.75rem 1rem 0.625rem] - icon padding
   */
  --sh-tab-item-icon-padding: 0.75rem 1rem 0.625rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-padding=0.25rem 1rem 0.125rem] - icon label padding
   */
  --sh-tab-item-icon-label-padding: 0.25rem 1rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-padding-access-bar=0.75rem 1rem] - icon label padding in access bar
   */
  --sh-tab-item-icon-label-padding-access-bar: 0.75rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-line-height=2rem] - icon label line-height
   */
  --sh-tab-item-icon-label-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-label-line-height=1rem] - label line-height
   */
  --sh-tab-item-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-height=3.5rem] - icon label height
   */
  --sh-tab-item-icon-label-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-height=4.5rem] - access-bar icon height
   */
  --sh-tab-item-access-bar-icon-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-padding=1.25rem 1rem 1.125rem] - access-bar icon padding
   */
  --sh-tab-item-access-bar-icon-padding: 1.25rem 1rem 1.125rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-line-height=2rem] - access-bar icon line height
   */
  --sh-tab-item-access-bar-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-padding=0.25rem 1rem 0.125rem] - condensed icon padding
   */
  --sh-tab-item-condensed-icon-padding: 0.25rem 1rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-line-height=2rem] - condensed icon line height
   */
  --sh-tab-item-condensed-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-height=2.5rem] - condensed icon height
   */
  --sh-tab-item-condensed-icon-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-height=4.5rem] - height of patient tab
   */
  --sh-tab-item-patient-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-height=2.5rem] - height of condensed tab
   */
  --sh-tab-item-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-normal-height=3.5rem] - height of tab
   */
  --sh-tab-item-normal-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-arrow-icons-padding=0 0.5rem] - padding in-between arrow icons
   */
  --sh-tab-item-arrow-icons-padding: 0 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-arrow-icons-line-height=2rem] - line-height of arrow icons
   */
  --sh-tab-item-arrow-icons-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-padding=1rem 1rem 0.875rem] - padding of tab-item
   */
  --sh-tab-item-tab-item-padding: 1rem 1rem 0.875rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-line-height=1.5rem] - line-height of tab-item
   */
  --sh-tab-item-tab-item-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-padding=1.5rem 1rem 1.375rem] - padding of tab-item access bar
   */
  --sh-tab-item-access-bar-padding: 1.5rem 1rem 1.375rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-height=4.5rem] - height of tab-item access bar
   */
  --sh-tab-item-access-bar-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-line-height=1.5rem] - line-height of tab-item access bar
   */
  --sh-tab-item-access-bar-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-padding=0.5rem 1rem 0.375rem] - padding of condensed tab-item
   */
  --sh-tab-item-condensed-padding: 0.5rem 1rem 0.375rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-line-height=1.5rem] - line-height of condensed tab-item
   */
  --sh-tab-item-condensed-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-badge-margin=-1.8125rem -0.625rem 0rem 0.375rem] - badge margin of tab-item
   */
  --sh-tab-item-badge-margin: -1.8125rem -0.625rem 0rem 0.375rem;
  /* 
   * @cssprop [--sh-tab-item-empty-badge-margin=-2rem -0.5rem 0rem 0.5rem] - empty badge margin of tab-item
   */
  --sh-tab-item-empty-badge-margin: -2rem -0.5rem 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-slottedfn-mg-right=2rem] - margin right of patient slotted function in tab-item
   */
  --sh-tab-item-slottedfn-mg-right: 2rem;
  /* 
   * @cssprop [--sh-tab-item-slottedfn-mg-left=-0.5rem] - margin left of patient slotted function in tab-item
   */
  --sh-tab-item-slottedfn-mg-left: -0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-right=0.5rem] - position of close icon right in tab-item
   */
  --sh-tab-item-close-icon-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-top=0.5rem] - position of close icon top in tab-item
   */
  --sh-tab-item-close-icon-top: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-mg-right=0.5rem] - margin right of patient icon in tab-item
   */
  --sh-tab-item-patient-mg-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-mg-left=0rem] - margin left of patient icon in tab-item
   */
  --sh-tab-item-patient-mg-left: 0rem;
  /* 
   * @cssprop [--sh-tab-item-pt-label-lh=1rem] - line-height of patient label in tab-item
   */
  --sh-tab-item-pt-label-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-pt-info-lh=1rem] - line-height of patient info in tab-item
   */
  --sh-tab-item-pt-info-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-accessbar-top=0.5rem] - close icon position top in accessbar
   */
  --sh-tab-item-close-icon-accessbar-top: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-accessbar-right=0.5rem] - close icon position right in accessbar
   */
  --sh-tab-item-close-icon-accessbar-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-empty-badge-margin=-0.65rem -0.65rem 0.5rem 0.5rem] - empty badge margin for condensed tab
   */
  --sh-tab-item-condensed-empty-badge-margin: -0.65rem -0.65rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-badge-margin=-0.4rem -0.7rem 0.5rem 0.5rem] - badge margin for condensed tab
   */
  --sh-tab-item-condensed-badge-margin: -0.4rem -0.7rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-close-margin=0.8rem] - badge margin along with close icon
   */
  --sh-tab-item-tab-close-margin: 0.8rem;
  /* 
   * @cssprop [--sh-tab-item-tab-max-width=11.5rem] - max-width for a tab
   */
  --sh-tab-item-tab-max-width: 11.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-min-width=3.5rem] - min-width for a tab
   */
  --sh-tab-item-tab-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-l-max-width=23.5rem] - max-width of l size for a tab
   */
  --sh-tab-item-tab-l-max-width: 23.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-active-max-width=17.5rem] - max-width for patient tab
   */
  --sh-tab-item-patient-active-max-width: 17.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-min-width=11.5rem] - min-width for patient tab
   */
  --sh-tab-item-patient-min-width: 11.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-text-margin-right=1.5rem] - margin-right of text for patient tab
   */
  --sh-tab-item-patient-text-margin-right: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-patient-collapsed-input-height=2.5rem] - collapsed patient input-height of condensed view
   */
  --sh-tab-item-condensed-patient-collapsed-input-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-patient-collapsed-input-padding=0rem 0.5rem] - collapsed patient padding of condensed view
   */
  --sh-tab-item-condensed-patient-collapsed-input-padding: 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-popover-max-height=12.25rem] - collapsed patient popover max-height of condensed view
   */
  --sh-tab-item-patient-popover-max-height: 12.25rem;
  /* 
   * @cssprop [--sh-tab-item-patient-collapsed-height=3.5rem] - collapsed patient popover item height
   */
  --sh-tab-item-patient-collapsed-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-popover-max-height=11.25rem] - collapsed popover max-height
   */
  --sh-tab-item-popover-max-height: 11.25rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-input-height=4.5rem] - collapsed input height
   */
  --sh-tab-item-collapsed-input-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-input-height=2.5rem] - condensed collapsed input height
   */
  --sh-tab-item-condensed-collapsed-input-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-text-lh=1rem] - text line-height
   */
  --sh-tab-item-text-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-patient-close-icon-right=0.5rem] - position of close icon right in tab-item
   */
  --sh-tab-item-patient-close-icon-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-context-closable-padding-right=2rem] - padding-right of close icon of closable tab inside access-context
   */
  --sh-tab-item-access-context-closable-padding-right: 2rem;
  /* 
   * @cssprop [--sh-tab-item-expand-icon-font-size=2rem] - font size of expand-icon
   */
  --sh-tab-item-expand-icon-font-size: 2rem;
  /* 
   * @cssprop [--sh-tab-item-patient-padding=1.25rem 0.5rem 1.125rem 0.5rem] - padding of pattient tab
   */
  --sh-tab-item-patient-padding: 1.25rem 0.5rem 1.125rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-label-line-height=2.5rem] - line-height of collapsed tab
   */
  --sh-tab-item-collapsed-label-line-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-href-wrapper-margin=1.25rem 0.5rem] - margin of patient href wrapper
   */
  --sh-tab-item-patient-href-wrapper-margin: 1.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-patient-href-wrapper-margin=0.75rem 0.5rem] - margin of patient in collapsed href wrapper
   */
  --sh-tab-item-collapsed-patient-href-wrapper-margin: 0.75rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-href-wrapper-margin=1.25rem 1rem] - margin of patient href wrapper in access bar
   */
  --sh-tab-item-access-bar-icon-href-wrapper-margin: 1.25rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-access-bar-icon-href-wrapper-margin=0.75rem 0rem] - margin of patient href wrapper in collapsed tab in access bar
   */
  --sh-tab-item-collapsed-access-bar-icon-href-wrapper-margin: 0.75rem 0rem;
  /* 
   * @cssprop [--sh-tab-item-href-wrapper-margin=1.5rem 1rem] - margin of href wrapper
   */
  --sh-tab-item-href-wrapper-margin: 1.5rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-href-badge-margin-top=-0.6875rem] - margin top of href badge
   */
  --sh-tab-item-href-badge-margin-top: -0.6875rem;
  /* 
   * @cssprop [--sh-tab-item-href-empty-badge-margin-top=-0.5rem] - margin top of href empty badge
   */
  --sh-tab-item-href-empty-badge-margin-top: -0.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-href-padding=0rem 1rem] - padding of icon href
   */
  --sh-tab-item-icon-href-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-href-badge-margin-top=-0.3125rem] - margin top of href badge in access bar
   */
  --sh-tab-item-access-bar-href-badge-margin-top: -0.3125rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-href-default-badge-margin-top=0rem] - margin top of href default badge in access bar
   */
  --sh-tab-item-access-bar-href-default-badge-margin-top: 0rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-access-bar-href-default-badge-margin-top=-0.0625rem] - margin top of href default badge in condensed access bar
   */
  --sh-tab-item-condensed-access-bar-href-default-badge-margin-top: -0.0625rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-pt-href-wrapper-margin=0.25rem 0.5rem] - margin of condensed patient href wrapper 
   */
  --sh-tab-item-condensed-pt-href-wrapper-margin: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-condensed-pt-href-wrapper-margin=0.25rem 0.5rem] - margin of collapsed condensed patient href wrapper 
   */
  --sh-tab-item-collapsed-condensed-pt-href-wrapper-margin: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-href-wrapper-margin=0.25rem 0rem 0.125rem] - margin of condensed icon href wrapper 
   */
  --sh-tab-item-condensed-icon-href-wrapper-margin: 0.25rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-href-condensed-height=2.5rem] - height of condensed href anchor tag
   */
  --sh-tab-item-href-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-href-wrapper-margin=0.75rem 0.5rem] - margin of patient href wrapper
   */
  --sh-tab-item-icon-href-wrapper-margin: 0.75rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-href-badge-margin-top=-0.1875rem] - margin top of condensed badge
   */
  --sh-tab-item-condensed-href-badge-margin-top: -0.1875rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-href-empty-badge-margin-top=-0.0625rem] - margin top of condensed empty badge
   */
  --sh-tab-item-condensed-href-empty-badge-margin-top: -0.0625rem;
  /* 
   * @cssprop [--sh-tab-item-closable-condensed-href-badge-margin-top=-0.3125rem] - margin top of condensed closable badge
   */
  --sh-tab-item-closable-condensed-href-badge-margin-top: -0.3125rem;
  /* 
   * @cssprop [--sh-tab-item-closable-condensed-empty-href-badge-margin-top=-0.625rem] - margin top of closable condensed empty badge
   */
  --sh-tab-item-closable-condensed-empty-href-badge-margin-top: -0.625rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-icon-href-wrapper-margin=0.25rem 0rem 0.125rem] - margin of condensed collapsed icon href wrapper 
   */
  --sh-tab-item-condensed-collapsed-icon-href-wrapper-margin: 0.25rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-href-accessbar-height=3.5rem] - collapsed href access bar height
   */
  --sh-tab-item-collapsed-href-accessbar-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-icon-margin=0 0.5rem 0 0] - margin of tab item icon in collapsed view
   */
  --sh-tab-item-tab-item-icon-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-pt-icon-href-wrapper-margin-left=0.5rem] - margin of condensed collapsed patient icon href wrapper
   */
  --sh-tab-item-condensed-collapsed-pt-icon-href-wrapper-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-icon-height=3.5rem] - collapsed icon height
   */
  --sh-tab-item-collapsed-icon-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-pt-href-wrapper-margin=0.25rem 0.5rem 0.25rem 0.5rem] - margin of condensed icon href wrapper in patient tab
   */
  --sh-tab-item-condensed-icon-pt-href-wrapper-margin: 0.25rem 0.5rem 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-only-href-wrapper-margin=0.25rem 1rem 0.125rem] - margin of condensed only icon href wrapper 
   */
  --sh-tab-item-condensed-icon-only-href-wrapper-margin: 0.25rem 1rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-min-width-tab=4rem] - min width of the tab item
   */
  --sh-tab-item-min-width-tab: 4rem;
}

:host {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

:host::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0px;
  border-bottom: 0.0625rem solid rgba(var(--ui-1), var(--opacity-7));
}

:host(.item-focused) {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}

:host([access-context])::after,
:host([nav-context])::after,
:host([footer-context])::after {
  border-bottom: none;
}

/* collapsed */
.collapsed-popover {
  position: absolute;
  top: 100%;
  left: 0rem;
  background: var(--base-3);
  box-shadow: var(--shadow-overlay);
  border-radius: 0.125rem;
  max-height: var(--sh-tab-item-popover-max-height);
  opacity: 0;
  transform: translateY(-1.5rem);
  overflow: hidden;
  display: none;
  transition: var(--transition-time) all ease-in-out;
}

:host([collapsed][active]) .collapsed-popover {
  overflow: auto;
  max-height: var(--sh-tab-item-patient-popover-max-height);
  opacity: 1;
  transform: none;
  display: block;
  width: fit-content;
}

:host([collapsed][active]) #expand-icon {
  transform: rotate(180deg);
}

:host([collapsed]) {
  overflow: visible;
}

:host([collapsed]) .collapsed-input {
  cursor: pointer;
  height: var(--sh-tab-item-collapsed-input-height);
  min-width: 6.25rem;
  max-width: 25.5rem;
  padding: 1.25rem 0.5rem;
  box-sizing: border-box;
  font: var(--body-1);
  color: var(--text-primary);
  line-height: 2rem;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

:host([collapsed][condensed]) .collapsed-input {
  height: var(--sh-tab-item-condensed-patient-collapsed-input-height);
  padding: var(--sh-tab-item-condensed-patient-collapsed-input-padding);
}

:host([collapsed][condensed]) .text {
  line-height: var(--sh-tab-item-collapsed-label-line-height);
}

:host([collapsed][condensed]) .info {
  display: none;
}

#expand-icon {
  transition: 0s transform, var(--transition-time) color ease-in-out;
  margin-left: 0.5rem;
  color: rgba(var(--ui-1), var(--opacity-4));
  --sh-icon-font-size-m: var(--sh-tab-item-expand-icon-font-size);
}

#tab-icon {
  color: rgba(var(--ui-1), var(--opacity-2));
  margin-right: 0.5rem;
}

:host([info]) .text {
  font: var(--title-1);
  line-height: var(--sh-tab-item-text-lh);
}

.text {
  color: var(--text-primary);
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: var(--sh-tab-item-collapsed-label-line-height);
}

.info {
  font: var(--body-2);
  color: var(--text-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: var(--sh-tab-item-text-lh);
}

.items-scroll-wrapper {
  display: flex;
  width: fit-content;
  width: -moz-fit-content;
}

.items-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
}

.arrow-icons {
  align-items: center;
  padding: var(--sh-tab-item-arrow-icons-padding);
  line-height: var(--sh-tab-item-arrow-icons-line-height);
}

:host([condensed]) .arrow-icons {
  padding: var(--sh-tab-item-arrow-icons-padding);
  line-height: var(--sh-tab-item-arrow-icons-line-height);
}

:host([access-context]:not([condensed])) .arrow-icons {
  padding: 1.25rem 0;
}

::-webkit-scrollbar {
  height: 0rem;
}

.hidden,
:host([no-arrows]) .arrow-icons {
  display: none;
}

/* hover */
:host([collapsed]:not(.no-hovermq)) .collapsed-input:hover #expand-icon {
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([no-scroll]) .no-scroll {
  display: flex;
}`;