// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-grid.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

::slotted(*) {
  width: auto;
  min-width: 0;
  min-height: 0;
}

/* small spacing */
:host([spacing=s]) {
  margin: -0.25rem !important;
  height: calc(100% + 0.5rem) !important;
  width: calc(100% + 0.5rem) !important;
}

:host([spacing=s]) ::slotted(*) {
  margin: 0.25rem;
}

/* medium spacing (default) */
:host([spacing=m]) {
  height: calc(100% + 1rem) !important;
  width: calc(100% + 1rem) !important;
  margin: -0.5rem !important;
}

:host([spacing=m]) ::slotted(*) {
  margin: 0.5rem;
}

/* large spacing */
:host([spacing=l]) {
  margin: -0.75rem !important;
  height: calc(100% + 1.5rem) !important;
  width: calc(100% + 1.5rem) !important;
}

:host([spacing=l]) ::slotted(*) {
  margin: 0.75rem;
}

/* fit-content */
:host([fit-content]) {
  height: auto !important;
}

/* columns rules: large screen */
::slotted([columns="0"]) {
  display: none;
}

::slotted(*:not([columns])),
::slotted([columns="1"]) {
  grid-column: span 1;
  -ms-grid-column-span: 1;
}

::slotted([columns="2"]) {
  grid-column: span 2;
  -ms-grid-column-span: 2;
}

::slotted([columns="3"]) {
  grid-column: span 3;
  -ms-grid-column-span: 3;
}

::slotted([columns="4"]) {
  grid-column: span 4;
  -ms-grid-column-span: 4;
}

::slotted([columns="5"]) {
  grid-column: span 5;
  -ms-grid-column-span: 5;
}

::slotted([columns="6"]) {
  grid-column: span 6;
  -ms-grid-column-span: 6;
}

::slotted([columns="7"]) {
  grid-column: span 7;
  -ms-grid-column-span: 7;
}

::slotted([columns="8"]) {
  grid-column: span 8;
  -ms-grid-column-span: 8;
}

::slotted([columns="9"]) {
  grid-column: span 9;
  -ms-grid-column-span: 9;
}

::slotted([columns="10"]) {
  grid-column: span 10;
  -ms-grid-column-span: 10;
}

::slotted([columns="11"]) {
  grid-column: span 11;
  -ms-grid-column-span: 11;
}

::slotted([columns="12"]) {
  grid-column: span 12;
  -ms-grid-column-span: 12;
}

/* columns rules: medium screen */
@media only screen and (max-width: 64.0625rem) {
  ::slotted([col-m="0"]) {
    display: none;
  }
  ::slotted([col-m="1"]) {
    grid-column: span 1;
    -ms-grid-column-span: 1;
  }
  ::slotted([col-m="2"]) {
    grid-column: span 2;
    -ms-grid-column-span: 2;
  }
  ::slotted([col-m="3"]) {
    grid-column: span 3;
    -ms-grid-column-span: 3;
  }
  ::slotted([col-m="4"]) {
    grid-column: span 4;
    -ms-grid-column-span: 4;
  }
  ::slotted([col-m="5"]) {
    grid-column: span 5;
    -ms-grid-column-span: 5;
  }
  ::slotted([col-m="6"]) {
    grid-column: span 6;
    -ms-grid-column-span: 6;
  }
  ::slotted([col-m="7"]) {
    grid-column: span 7;
    -ms-grid-column-span: 7;
  }
  ::slotted([col-m="8"]) {
    grid-column: span 8;
    -ms-grid-column-span: 8;
  }
  ::slotted([col-m="9"]) {
    grid-column: span 9;
    -ms-grid-column-span: 9;
  }
  ::slotted([col-m="10"]) {
    grid-column: span 10;
    -ms-grid-column-span: 10;
  }
  ::slotted([col-m="11"]) {
    grid-column: span 11;
    -ms-grid-column-span: 11;
  }
  ::slotted([col-m="12"]) {
    grid-column: span 12;
    -ms-grid-column-span: 12;
  }
}
/* columns rules: small screen */
@media only screen and (max-width: 47.9375rem) {
  ::slotted([col-s="0"]) {
    display: none;
  }
  ::slotted([col-s="1"]) {
    grid-column: span 1;
    -ms-grid-column-span: 1;
  }
  ::slotted([col-s="2"]) {
    grid-column: span 2;
    -ms-grid-column-span: 2;
  }
  ::slotted([col-s="3"]) {
    grid-column: span 3;
    -ms-grid-column-span: 3;
  }
  ::slotted([col-s="4"]) {
    grid-column: span 4;
    -ms-grid-column-span: 4;
  }
  ::slotted([col-s="5"]) {
    grid-column: span 5;
    -ms-grid-column-span: 5;
  }
  ::slotted([col-s="6"]) {
    grid-column: span 6;
    -ms-grid-column-span: 6;
  }
  ::slotted([col-s="7"]) {
    grid-column: span 7;
    -ms-grid-column-span: 7;
  }
  ::slotted([col-s="8"]) {
    grid-column: span 8;
    -ms-grid-column-span: 8;
  }
  ::slotted([col-s="9"]) {
    grid-column: span 9;
    -ms-grid-column-span: 9;
  }
  ::slotted([col-s="10"]) {
    grid-column: span 10;
    -ms-grid-column-span: 10;
  }
  ::slotted([col-s="11"]) {
    grid-column: span 11;
    -ms-grid-column-span: 11;
  }
  ::slotted([col-s="12"]) {
    grid-column: span 12;
    -ms-grid-column-span: 12;
  }
}
/* rows rules */
::slotted(*:not([rows])),
::slotted([rows="1"]) {
  grid-row: span 1;
  -ms-grid-row-span: 1;
}

::slotted([rows="2"]) {
  grid-row: span 2;
  -ms-grid-row-span: 2;
}

::slotted([rows="3"]) {
  grid-row: span 3;
  -ms-grid-row-span: 3;
}

::slotted([rows="4"]) {
  grid-row: span 4;
  -ms-grid-row-span: 4;
}

::slotted([rows="5"]) {
  grid-row: span 5;
  -ms-grid-row-span: 5;
}

::slotted([rows="6"]) {
  grid-row: span 6;
  -ms-grid-row-span: 6;
}

::slotted([rows="7"]) {
  grid-row: span 7;
  -ms-grid-row-span: 7;
}

::slotted([rows="8"]) {
  grid-row: span 8;
  -ms-grid-row-span: 8;
}

::slotted([rows="9"]) {
  grid-row: span 9;
  -ms-grid-row-span: 9;
}

::slotted([rows="10"]) {
  grid-row: span 10;
  -ms-grid-row-span: 10;
}

::slotted([rows="11"]) {
  grid-row: span 11;
  -ms-grid-row-span: 11;
}

::slotted([rows="12"]) {
  grid-row: span 12;
  -ms-grid-row-span: 12;
}`;