// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-media-player.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-media-player-control-margin=0 0.75rem 0.75rem] - Margin for media control
   */
  --sh-media-player-control-margin: 0 0.75rem 0.75rem;
  /* 
   * @cssprop [--sh-media-player-bottom-margin=0.75rem] - Bottom margin for media control
   */
  --sh-media-player-bottom-margin: 0.75rem;
}

:host {
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
}

slot[name=footer] {
  height: fit-content;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

slot:not([name])::slotted(video) {
  height: 100%;
  width: 100%;
  background-color: black;
}

slot:not([name])::slotted(video)::-webkit-media-controls {
  display: none !important;
}

:host([image-contain=fill]) slot:not([name])::slotted(video) {
  width: 100% !important;
  height: 100% !important;
  display: block;
  object-fit: cover;
}

.controls {
  background-color: rgba(235, 235, 235, 0.6);
}

slot:not([name])::slotted(sh-media-controls) {
  position: absolute;
  bottom: 0rem;
  box-sizing: border-box;
  width: 100%;
  padding: var(--sh-media-player-control-margin);
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}`;