// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/*
ShuiEnvironment is the singleton responsible for SHUI environment functionalities.
isTouchDevice is a static property to enforce touch styles.
*/

// WARNING: if you change this you need to update environtment.d.ts manually!!
const ShuiEnvironment = {
  isTouchDevice: null,
  setIsTouchDevice(value) {
    this.isTouchDevice = value;
  },
};
export { ShuiEnvironment };
