// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import paginationStyles from './sh-pagination.lit.scss.js';
/**
 * @attr {Number} current-page - Default 1. Defines which page is currently active.
 * @fires clicked-page-move	- Dispatched when clicking on a page number.
 * @fires previous-page-move - Dispatched when clicking on the "previous" arrow.
 * @fires next-page-move - Dispatched when clicking on the "next" arrow.
 */
let SHPagination = class SHPagination extends LitElement {
  constructor() {
    super(...arguments);
    /**Default 1. Defines which page is currently active. */
    this.currentPage = 1;
  }
  static get styles() {
    return [extendInteractiveArea(), paginationStyles];
  }
  render() {
    return html`
      <ul id="ul">
        <li
          title="Previous Page"
          @click="${this.prevPage}"
          class="prevPageIcon ${this.currentPage <= 1 ? 'disabled' : ''}"
          id="leftArrowWrapper"
        >
          <sh-icon
            icon="arrow-line-left-s"
            size="m"
            button
            role="button"
            ?disabled=${this.currentPage <= 1}
          ></sh-icon>
        </li>
        ${this.getPaginationItems()}
        <li
          title="Next Page"
          @click="${this.nextPage}"
          class="nextPageIcon ${this.currentPage >= this.pages ? 'disabled' : ''}"
          id="rightArrowWrapper"
        >
          <sh-icon
            icon="arrow-line-right-s"
            size="m"
            button
            role="button"
            ?disabled=${this.currentPage >= this.pages}
          ></sh-icon>
        </li>
      </ul>
    `;
  }
  getPaginationItems() {
    const paginationValues = this.getPaginationValues();
    const paginationItems = [];
    for (let item = 0; item < paginationValues.length; item++) {
      paginationItems.push(
        html`<li
          class="sh-pagination-item"
          id="item"
          @click="${this.handleClick}"
          ?active=${paginationValues[item] === this.currentPage}
          ?click-disabled=${paginationValues[item].toString() === '...'}
          tabindex=${paginationValues[item] === this.currentPage ? '0' : '-1'}
          role="button"
          @keyup="${(e) => {
            if (
              keyboardInteraction(e, KEYCODE.SPACE) ||
              keyboardInteraction(e, KEYCODE.ENTER) ||
              e.code === 'NumpadEnter'
            ) {
              const target = e.target;
              target.click();
              this.focusCurrent();
            }
            if (keyboardInteraction(e, KEYCODE.ARROW_LEFT) && item !== 0) {
              this.focusPreviousNumber(paginationValues, item, e);
            }
            if (
              keyboardInteraction(e, KEYCODE.ARROW_RIGHT) &&
              item !== paginationValues.length - 1
            ) {
              this.focusNextNumber(paginationValues, item, e);
            }
          }}"
        >
          ${paginationValues[item]}
        </li>`
      );
    }
    return paginationItems;
  }
  focusCurrent() {
    this.updateComplete.then(() => {
      var _a;
      const paginationItems =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelectorAll('.sh-pagination-item');
      const currentItem = Array.from(paginationItems).find(
        (x) => x.innerText === this.currentPage.toString()
      );
      currentItem === null || currentItem === void 0 ? void 0 : currentItem.focus();
    });
  }
  focusPreviousNumber(paginationValues, item, e) {
    var _a;
    const previousItemIndex = this.getPreviousFocusableItemIndex(paginationValues, item);
    if (previousItemIndex !== -1) {
      const target = e.target;
      const paginationItem =
        (_a = target.parentElement) === null || _a === void 0
          ? void 0
          : _a.querySelectorAll('.sh-pagination-item')[previousItemIndex];
      paginationItem.focus();
    }
  }
  focusNextNumber(paginationValues, item, e) {
    var _a;
    const nextItemIndex = this.getNextFocusableItemIndex(paginationValues, item);
    if (nextItemIndex !== -1) {
      const target = e.target;
      const paginationItem =
        (_a = target.parentElement) === null || _a === void 0
          ? void 0
          : _a.querySelectorAll('.sh-pagination-item')[nextItemIndex];
      paginationItem.focus();
    }
  }
  firstUpdated() {
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('currentPage') || changedProperties.has('pages')) {
      this.currentPage = !this.currentPage ? 1 : this.currentPage;
    }
  }
  updated(changedProperties) {
    if (changedProperties.has('pages')) {
      this.style.display = this.pages <= 1 ? 'none' : 'flex';
    }
    const listOfProperties = ['pages', 'currentPage'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  getPaginationValues() {
    this.pages = !this.pages ? 1 : this.pages;
    let paginationValues = [];
    if (this.pages <= 7) {
      paginationValues = this.getPaginationValuesWhenPagesLessThanSeven();
    } else {
      paginationValues = this.getPaginationValuesWhenPagesGreaterThanSeven();
    }
    return paginationValues;
  }
  getPaginationValuesWhenPagesLessThanSeven() {
    const paginationValues = [];
    for (let page = 1; page <= this.pages; page++) {
      paginationValues.push(page);
    }
    return paginationValues;
  }
  getPaginationValuesWhenPagesGreaterThanSeven() {
    const fourthLastPage = this.pages - 3;
    let paginationValues = [];
    /*If current page = 1 or 2 or 3 or 4 , totalArray = [1 2 3 4 5 ... 20]*/
    if (this.currentPage <= 4) {
      paginationValues = this.getPaginationValuesWhenCurrentPageLessthanFour();
    } else if (this.currentPage > 4 && this.currentPage < fourthLastPage) {
      /*If current page = x , totalArray = [1 ... (x-1) (x) (x+1) ... N], where N = total-pages */
      paginationValues = this.getPaginationValuesWhenCurrentPageGreaterthanFour();
    } else {
      /*If current page = 17 or 18 or 19 or 20, totalArray = [1 ... 16 17 18 19 20] */
      paginationValues = this.getPaginationValuesWhenCurrentPageGreaterthanFourthlast();
    }
    return paginationValues;
  }
  getPaginationValuesWhenCurrentPageLessthanFour() {
    const paginationValues = [];
    for (let page = 1; page <= 7; page++) {
      if (page <= 5) {
        paginationValues.push(page);
      } else if (page === 6) {
        paginationValues.push('...');
      } else {
        paginationValues.push(this.pages);
      }
    }
    return paginationValues;
  }
  getPaginationValuesWhenCurrentPageGreaterthanFour() {
    const paginationValues = [];
    for (let page = 1; page <= 5; page++) {
      if (page === 1) {
        paginationValues.push(page);
      } else if (page === 2 || page === 4) {
        paginationValues.push('...');
      } else if (page === 3) {
        for (let j = 1; j <= 3; j++) {
          switch (j) {
            case 1:
              paginationValues.push(this.currentPage - 1);
              break;
            case 2:
              paginationValues.push(this.currentPage);
              break;
            case 3:
              paginationValues.push(this.currentPage + 1);
              break;
          }
        }
      } else {
        paginationValues.push(this.pages);
      }
    }
    return paginationValues;
  }
  getPaginationValuesWhenCurrentPageGreaterthanFourthlast() {
    const paginationValues = [];
    const fourthLastPage = this.pages - 3;
    for (let page = 1; page <= 3; page++) {
      switch (page) {
        case 1:
          paginationValues.push(page);
          break;
        case 2:
          paginationValues.push('...');
          break;
        case 3:
          {
            let subValue = 1;
            for (let j = 1; j <= 5; j++) {
              paginationValues.push(fourthLastPage - subValue);
              --subValue;
            }
          }
          break;
      }
    }
    return paginationValues;
  }
  handleClick(e) {
    const target = e.target;
    const clickedItem = Number(target.innerText);
    const isNotANumber = isNaN(clickedItem);
    if (!isNotANumber) {
      this.currentPage = clickedItem;
      dispatchCustomEvent(this, 'clicked-page-move');
    }
  }
  prevPage() {
    const prevCurrentPage = this.currentPage - 1;
    if (prevCurrentPage >= 1) {
      this.currentPage = prevCurrentPage;
      dispatchCustomEvent(this, 'previous-page-move');
    }
  }
  nextPage() {
    const nextCurrentPage = this.currentPage + 1;
    if (nextCurrentPage <= this.pages) {
      this.currentPage = nextCurrentPage;
      dispatchCustomEvent(this, 'next-page-move');
    }
  }
  getNextFocusableItemIndex(currentValues, itemIndex) {
    let nextItemIndex = -1;
    for (let i = itemIndex + 1; i <= currentValues.length - 1; ++i) {
      if (currentValues[i] !== '...') {
        nextItemIndex = i;
        break;
      }
    }
    return nextItemIndex;
  }
  getPreviousFocusableItemIndex(currentValues, itemIndex) {
    let previousItemIndex = -1;
    for (let i = itemIndex - 1; i >= 0; --i) {
      if (currentValues[i] !== '...') {
        previousItemIndex = i;
        break;
      }
    }
    return previousItemIndex;
  }
};
__decorate([property({ type: Number, reflect: true })], SHPagination.prototype, 'pages', void 0);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'current-page' })],
  SHPagination.prototype,
  'currentPage',
  void 0
);
SHPagination = __decorate([customElement('sh-pagination')], SHPagination);
export { SHPagination };
