// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-modal.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-modal-label-wrapper-padding=0rem 1rem] - padding of label wrapper
   */
  --sh-modal-label-wrapper-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-modal-footer-wrapper-padding=1rem] - padding of footer wrapper
   */
  --sh-modal-footer-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-modal-body-wrapper-padding=1rem] - padding of body wrapper
   */
  --sh-modal-body-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-modal-footer-wrapper-margin-left=0.5rem] - left margin between buttons in footer wrapper
   */
  --sh-modal-footer-wrapper-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-modal-close-button-2-margin=1rem 1rem 0rem 0.5rem] - margin of close buttons in header
   */
  --sh-modal-close-button-2-margin: 1rem 1rem 0rem 0.5rem;
  /* 
   * @cssprop [--sh-modal-modal-label-line-height=1.5rem] - line height of modal label
   */
  --sh-modal-modal-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-modal-close-button-margin-left=0.5rem] - left margin of close buttons in functions
   */
  --sh-modal-close-button-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-modal-icon-margin-right=0.5rem] - right margin of modal icons in header
   */
  --sh-modal-icon-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-modal-label-wrapper-height=3.5rem] - height of the label wrapper
   */
  --sh-modal-label-wrapper-height: 3.5rem;
  /* 
   * @cssprop [--sh-modal-wrapper-content-margin-bottom=0.5rem] - margin bottom of the content wrapper
   */
  --sh-modal-wrapper-content-margin-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-modal-functions-wrapper-gap=0.5rem] - gap in the functions wrapper
   */
  --sh-modal-functions-wrapper-gap: 0.5rem;
  /* 
   * @cssprop [--sh-modal-functions-wrapper-margin-left=0.5rem] - margin left of the functions wrapper
   */
  --sh-modal-functions-wrapper-margin-left: 0.5rem;
}

:host {
  visibility: hidden;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 0rem;
  max-height: 0rem;
  overflow: hidden;
  opacity: 0;
  z-index: 5;
  align-items: center;
  justify-content: center;
  display: flex;
  outline: transparent;
  background: var(--sh-public-scrim-fill, var(--sh-scrim-fill)); /* Modal Background (UI 1 - Light / UI 7 - Dark) */
  transition: var(--transition-time) all ease-in-out, 0s height ease-in-out var(--transition-time), 0s max-height ease-in-out var(--transition-time), 0s visibility linear 0s;
}

:host([visible]) {
  visibility: visible;
  opacity: 1;
  height: 100%;
  max-height: 100%;
  transition: var(--transition-time) all ease-in-out var(--transition-time), 0s height ease-in-out, 0s max-height ease-in-out, 0s visibility linear 0s;
}

:host([visible]) .modal-wrapper {
  transform: none;
  transition: var(--transition-time) all ease-in-out var(--transition-time);
}

.modal-wrapper {
  transition: var(--transition-time) all ease-in-out;
  transform: translateY(2.5rem);
  background: var(--base-3);
  box-shadow: var(--shadow-navigation);
  display: flex;
  flex-direction: column;
  border-radius: 0.125rem;
  z-index: 5;
  position: relative;
}

.focus-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.label-wrapper {
  display: flex;
  flex-direction: row;
  padding: var(--sh-modal-label-wrapper-padding);
  align-items: center;
  height: var(--sh-modal-label-wrapper-height);
}

.modal-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
  font: var(--header-2);
  line-height: var(--sh-modal-modal-label-line-height);
  flex: 1;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

.close-button-2 {
  margin: var(--sh-modal-close-button-2-margin);
}

.functions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: var(--sh-modal-functions-wrapper-margin-left);
  gap: var(--sh-modal-functions-wrapper-gap);
}

.footer-wrapper > ::slotted(*) {
  margin-left: var(--sh-modal-footer-wrapper-margin-left);
}

.header-wrapper {
  display: flex;
  flex-direction: row;
}

.header-slot-wrapper > ::slotted(:not(sh-tabs)) {
  margin: 1rem;
}

.header-slot-wrapper > ::slotted(sh-tabs) {
  margin-bottom: 1rem;
}

.header-slot-wrapper {
  flex: 1 1 0%;
  display: flex;
  overflow: hidden;
}

.body-wrapper {
  flex: 1;
  padding: var(--sh-modal-body-wrapper-padding);
  box-sizing: border-box;
  display: block;
  flex-direction: column;
  overflow: auto;
  font: var(--body-1);
  color: var(--text-primary);
  line-height: 1.25rem;
}

.body-wrapper > ::slotted(*:not(:last-child):not(sh-menu-item)) {
  margin-bottom: var(--sh-modal-wrapper-content-margin-bottom);
}

.footer-wrapper {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  padding: var(--sh-modal-footer-wrapper-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.footer-wrapper > ::slotted(*) {
  color: var(--text-primary);
  font: var(--body-2);
  line-height: 1rem;
}

.close-button {
  transition: var(--transition-time) all ease-in-out;
}

.modal-wrapper[empty-header] .header-wrapper {
  display: none;
}

:host(:not([label])) .label-wrapper {
  display: none;
}

.modal-wrapper[empty-footer] .footer-wrapper {
  display: none;
}

.modal-wrapper:not([empty-header]) .label-wrapper {
  display: none;
}

.modal-wrapper:not([empty-header]) .body-wrapper,
:host([label]) .body-wrapper {
  padding-top: 0rem;
}

.modal-wrapper:not([empty-footer]) .body-wrapper {
  padding-bottom: 0rem;
}

/* Modal Type Styles */
.modal-wrapper .label-wrapper sh-icon:not(.close-button) {
  margin-right: var(--sh-modal-icon-margin-right);
  margin-left: 0rem;
}

.body-wrapper > ::slotted(sh-badge) {
  display: block;
}`;