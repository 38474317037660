// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-button.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-button-button-height=2rem] - height of the button
   */
  --sh-button-button-height: 2rem;
  /* 
   * @cssprop [--sh-button-button-compact-height=2rem] - height of the button
   */
  --sh-button-button-compact-height: 2rem;
  /* 
   * @cssprop [--sh-button-width-size-s=6rem] - width of the button s
   */
  --sh-button-width-size-s: 6rem;
  /* 
   * @cssprop [--sh-button-width-size-m=9.5rem] - width of the button m
   */
  --sh-button-width-size-m: 9.5rem;
  /* 
   * @cssprop [--sh-button-width-size-l=11.5rem] - width of the button l
   */
  --sh-button-width-size-l: 11.5rem;
  /* 
   * @cssprop [--sh-button-label-line-height=1.5rem] - line height of the label
   */
  --sh-button-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-button-button-border-radius=2rem] - border radius of the button
   */
  --sh-button-button-border-radius: 2rem;
  /* 
   * @cssprop [--sh-button-compact-border-radius=1rem] - border radius of the button
   */
  --sh-button-compact-border-radius: 1rem;
  /* 
   * @cssprop [--sh-button-button-padding=0.25rem 1rem] - padding of the button
   */
  --sh-button-button-padding: 0.25rem 1rem;
  /* 
   * @cssprop [--sh-button-icon-width=3rem] - width of the icon button
   */
  --sh-button-icon-width: 3rem;
  /* 
   * @cssprop [--sh-button-icon-height=3rem] - height of the icon button
   */
  --sh-button-icon-height: 3rem;
  /* 
   * @cssprop [--sh-button-icon-size=2rem] - size of the icon
   */
  --sh-button-icon-size: 2rem;
  /* 
   * @cssprop [--sh-button-icon-padding=0.5rem] - padding of the icon
   */
  --sh-button-icon-padding: 0.5rem;
  /* 
   * @cssprop [--sh-button-padding-right-left=2rem] - left right padding of the button
   */
  --sh-button-padding-right-left: 2rem;
  /* 
   * @cssprop [--sh-button-compact-padding=0.375rem 1.25rem] - padding of the button
   */
  --sh-button-compact-padding: 0.375rem 1.25rem;
  /* 
   * @cssprop [--sh-button-tooltip-height=1.5rem] - line height and height of the tooltip
   */
  --sh-button-tooltip-height: 1.5rem;
  /* 
   * @cssprop [--sh-button-tooltip-max-width=15rem] - max width of the tooltip
   */
  --sh-button-tooltip-max-width: 15rem;
  /* 
   * @cssprop [--sh-button-tooltip-top-margin=0rem] - margin-top for the tooltip
   */
  --sh-button-tooltip-top-margin: 0rem;
  /* 
   * @cssprop [--sh-button-tooltip-margin=0.25rem] - margin for the tooltip
   */
  --sh-button-tooltip-margin: 0.25rem;
  /* 
   * @cssprop [--sh-button-tooltip-margin-top=-1.5rem] - margin for tooltip top placement
   */
  --sh-button-tooltip-margin-top: -1.5rem;
  /* 
   * @cssprop [--sh-button-tooltip-hover-margin-top=-1.75rem] - margin for tooltip top placement on hover
   */
  --sh-button-tooltip-hover-margin-top: -1.75rem;
  /* 
   * @cssprop [--sh-button-tertiary-border-thickness=0.125rem] - border thickness of a tertiary button
   */
  --sh-button-tertiary-border-thickness: 0.125rem;
  /* 
   * @cssprop [--sh-button-flex-shrink-icon=unset] - flex-shrink of an icon button
   */
  --sh-button-flex-shrink-icon: unset;
  /* 
   * @cssprop [--sh-button-icon-label-margin-right=0.25rem] - padding right of icon when both icon and label present
   */
  --sh-button-icon-label-margin-right: 0.25rem;
  /* 
   * @cssprop [--sh-button-button-padding-icon-position=0.25rem 1.25rem 0.25rem 1rem] - padding of the button when icon is set
   */
  --sh-button-button-padding-icon-position: 0.25rem 1.25rem 0.25rem 1rem;
  /* 
   * @cssprop [--sh-button-icon-size-s=1.5rem] - button icon size when both icon and label present
   */
  --sh-button-icon-size-s: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-size-m=1.5rem] - button icon size when both icon and label present
   */
  --sh-button-icon-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-font-size-m=1.5rem] - font size of the icon button when classic
   */
  --sh-button-icon-font-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-button-compact-icon-size-m=1.5rem] - icon size of the button when compact
   */
  --sh-button-compact-icon-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-button-compact-icon-font-size-m=1.5rem] - font size of the icon button when compact
   */
  --sh-button-compact-icon-font-size-m: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-only-compact-height=2.25rem] - height of the icon when compact
   */
  --sh-button-icon-only-compact-height: 2.25rem;
  /* 
   * @cssprop [--sh-button-icon-only-compact-padding=0.375rem] - height of the icon when compact
   */
  --sh-button-icon-only-compact-padding: 0.375rem;
  /* 
   * @cssprop [--sh-button-icon-only-compact-width=1.5rem] - width of the icon when compact
   */
  --sh-button-icon-only-compact-width: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-only-compact-size=1.5rem] - size of the icon when compact
   */
  --sh-button-icon-only-compact-size: 1.5rem;
  /* 
   * @cssprop [--sh-button-compact-icon-size-s=1.5rem] - size of the compact icon with label
   */
  --sh-button-compact-icon-size-s: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-label-compact-height=2rem] - height of the icon and label when compact
   */
  --sh-button-icon-label-compact-height: 2rem;
  /* 
   * @cssprop [--sh-button-icon-label-compact-padding=0.25rem 1.25rem 0.25rem 1rem] - padding of the icon and label when compact
   */
  --sh-button-icon-label-compact-padding: 0.25rem 1.25rem 0.25rem 1rem;
  /* 
   * @cssprop [--sh-button-icon-label-compact-icon-size=1.5rem] - size of the icon with label when compact
   */
  --sh-button-icon-label-compact-icon-size: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-label-compact-spacing=0.5rem] - spacing between icon and label when compact
   */
  --sh-button-icon-label-compact-spacing: 0.5rem;
  /* 
   * @cssprop [--sh-button-icon-label-compact-border-radius=1rem] - border-radius of compact button
   */
  --sh-button-icon-label-compact-border-radius: 1rem;
  /* 
   * @cssprop [--sh-button-normal-height=2.5rem] - height of the normal button only
   */
  --sh-button-normal-height: 2.5rem;
  /* 
   * @cssprop [--sh-button-normal-padding=0.625rem 1.375rem 0.625rem 1.375rem] - padding of the normal button only
   */
  --sh-button-normal-padding: 0.625rem 1.375rem 0.625rem 1.375rem;
  /* 
   * @cssprop [--sh-button-normal-border-radius=1.25rem] - border-radius of normal button
   */
  --sh-button-normal-border-radius: 1.25rem;
  /* 
   * @cssprop [--sh-button-normal-min-width=7rem] - min-width of normal button
   */
  --sh-button-normal-min-width: 7rem;
  /* 
   * @cssprop [--sh-button-icon-label-normal-height=2.5rem] - height of normal button with icon and label
   */
  --sh-button-icon-label-normal-height: 2.5rem;
  /* 
   * @cssprop [--sh-button-icon-label-normal-padding=0.5rem 1.375rem 0.5rem 1.125rem] - padding of normal button with icon and label
   */
  --sh-button-icon-label-normal-padding: 0.5rem 1.375rem 0.5rem 1.125rem;
  /* 
   * @cssprop [--sh-button-icon-label-normal-icon-size=1.5rem] - icon size of normal button with label
   */
  --sh-button-icon-label-normal-icon-size: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-label-normal-spacing=0.5rem] - spacing between icon and label when normal
   */
  --sh-button-icon-label-normal-spacing: 0.5rem;
  /* 
   * @cssprop [--sh-button-normal-icon-size=1.5rem] - icon size when normal icon only
   */
  --sh-button-normal-icon-size: 1.5rem;
  /* 
   * @cssprop [--sh-button-icon-only-normal-height=2.5rem] - height of icon when normal icon only
   */
  --sh-button-icon-only-normal-height: 2.5rem;
  /* 
   * @cssprop [--sh-button-icon-only-normal-padding=0.5rem] - padding of the icon button when normal
   */
  --sh-button-icon-only-normal-padding: 0.5rem;
  /* 
   * @cssprop [--sh-button-normal-icon-size-s=1.5rem] - icon size of the button when normal
   */
  --sh-button-normal-icon-size-s: 1.5rem;
  /* 
   * @cssprop [--sh-button-tertiary-button-padding=0.25rem 0.9375rem] - padding of the tertiary button
   */
  --sh-button-tertiary-button-padding: 0.25rem 0.9375rem;
  /* 
   * @cssprop [--sh-button-tertiary-button-padding-icon-position=0.25rem 1.1875rem 0.25rem 0.9375rem] - padding of the tertiary button when icon is set
   */
  --sh-button-tertiary-button-padding-icon-position: 0.25rem 1.1875rem 0.25rem 0.9375rem;
}

:host {
  display: flex;
  height: var(--sh-button-button-height);
  padding: var(--sh-button-button-padding);
  position: relative;
  flex-direction: row;
  border-radius: var(--sh-button-button-border-radius);
  cursor: pointer;
  box-sizing: border-box;
  outline: 0;
  justify-content: center;
  transition: var(--hover-transition-time) background;
  align-items: center;
}

:host([icon]) {
  flex-shrink: var(--sh-button-flex-shrink-icon);
}

.label {
  text-align: center;
  align-self: center;
  font: var(--title-1);
  line-height: var(--sh-button-label-line-height) !important;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: bold;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  -moz-user-select: none;
  -webkit-user-select: none;
}

/* size */
:host(:not([icon])[size=s]) .label,
:host(:not([icon])[shape=pill-s]) .label {
  width: calc(var(--sh-button-width-size-s) - var(--sh-button-padding-right-left));
}

:host(:not([icon])[size=m][layout=classic]).label,
:host(:not([icon])[shape=pill-m]) .label {
  width: calc(var(--sh-button-width-size-m) - var(--sh-button-padding-right-left));
}

:host(:not([icon])[size=l]) .label,
:host(:not([icon])[shape=pill-l]) .label {
  width: calc(var(--sh-button-width-size-l) - var(--sh-button-padding-right-left));
}

/* icon button */
:host([layout=classic][icon]:not([shape^=pill-]):not([icon-position])),
:host([icon]:not([icon-position])[shape=circle][layout=classic]) {
  position: relative;
  width: var(--sh-button-icon-width);
  height: var(--sh-button-icon-height);
  padding: var(--sh-button-icon-padding);
  border-radius: 50%;
}

:host([layout=classic]) sh-icon {
  --sh-icon-size-m: var(--sh-button-icon-size);
  --sh-icon-font-size-m: var(--sh-button-icon-size);
  --sh-icon-size-s: var(--sh-button-icon-size-s);
  --sh-icon-font-size-s: var(--sh-button-icon-size-s);
}

/* color='primary' */
:host([color=primary]) {
  background-color: rgba(var(--ui-3), var(--opacity-1000));
}

:host([color=primary]:hover:not(:active):not(.touch-device)) {
  background-color: rgba(var(--ui-2), var(--opacity-1000));
}

:host([color=primary]:active) {
  background-color: rgba(var(--ui-4), var(--opacity-1000));
}

:host([color=primary]) .label,
:host([color=primary]) sh-icon {
  color: var(--text-white);
}

/* color='primary-neutral' */
:host([color=primary-neutral]) {
  background-color: rgba(var(--ui-1), var(--opacity-800));
}

:host([color=primary-neutral]:hover:not(:active):not(.touch-device)) {
  background-color: rgba(var(--ui-1), var(--opacity-1000));
}

:host([color=primary-neutral]:active) {
  background-color: rgba(var(--ui-1), var(--opacity-700));
}

:host([color=primary-neutral]) .label,
:host([color=primary-neutral]) sh-icon {
  color: rgba(var(--ui-7), var(--opacity-900));
}

:host([color=primary-neutral]:hover:not(:active):not(.touch-device)) .label,
:host([color=primary-neutral]:hover:not(:active):not(.touch-device)) sh-icon,
:host([color=primary-neutral]:active) .label,
:host([color=primary-neutral]:active) sh-icon,
:host([color=primary-neutral][disabled]) .label,
:host([color=primary-neutral][disabled]) sh-icon {
  color: rgba(var(--ui-7), var(--opacity-1000));
}

/* color='secondary' */
:host([color=secondary]) {
  background-color: var(--secondary-button-background);
}

:host([color=secondary]:hover:not(:active):not(.touch-device)) {
  background-color: var(--secondary-button-hover-background);
}

:host([color=secondary]:active) {
  background-color: rgba(var(--ui-1), var(--opacity-50));
}

:host([color=secondary]) .label,
:host([color=secondary]) sh-icon {
  color: rgba(var(--ui-1), var(--opacity-900));
}

:host([color=secondary]:hover:not(:active):not(.touch-device)) .label,
:host([color=secondary]:hover:not(:active):not(.touch-device)) sh-icon,
:host([color=secondary]:active) .label,
:host([color=secondary]:active) sh-icon,
:host([color=secondary][disabled]) .label,
:host([color=secondary][disabled]) sh-icon {
  color: rgba(var(--ui-1), var(--opacity-1000));
}

/* color='tertiary' */
:host([color=tertiary]) {
  padding: var(--sh-button-tertiary-button-padding);
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-200));
  color: var(--text-primary);
}

:host([color=tertiary]:hover:not(:active):not(.touch-device)) {
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-300));
}

:host([color=tertiary]:active) {
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-100));
}

:host([color=tertiary]) {
  background-color: transparent;
}

:host([color=tertiary]) .label,
:host([color=tertiary]) sh-icon {
  color: rgba(var(--ui-1), var(--opacity-900));
}

:host([color=tertiary]:hover:not(:active):not(.touch-device)) .label,
:host([color=tertiary]:hover:not(:active):not(.touch-device)) sh-icon,
:host([color=tertiary]:active) .label,
:host([color=tertiary]:active) sh-icon,
:host([color=tertiary][disabled]) .label,
:host([color=tertiary][disabled]) sh-icon {
  color: rgba(var(--ui-1), var(--opacity-1000));
}

/* disabled */
:host([disabled]) {
  pointer-events: none;
  cursor: not-allowed;
}

:host([color=primary][disabled]) .label,
:host([color=primary][disabled]) sh-icon {
  color: var(--text-white);
}

:host([color=primary][disabled]),
:host([color=primary-neutral][disabled]) {
  opacity: var(--opacity-200);
}

:host([color=secondary][disabled]),
:host([color=tertiary][disabled]) {
  opacity: var(--opacity-300);
}

:host([color=primary][disabled]) {
  background-color: rgba(var(--ui-3), var(--opacity-1000));
}

:host([color=primary-neutral][disabled]) {
  background-color: rgba(var(--ui-1), var(--opacity-700));
}

:host([color=secondary][disabled]) {
  background-color: var(--secondary-button-background);
}

:host([color=tertiary][disabled]) {
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-200));
}

/* touch */
:host(:hover:not(:active):not(.touch-device)) .tooltip {
  opacity: 1;
  visibility: visible;
}

:host(:hover:not(:active):not(.touch-device)) .top.tooltip {
  margin-top: var(--sh-button-tooltip-hover-margin-top);
}

:host(:hover:not(:active):not(.touch-device)) .bottom.tooltip {
  margin-top: var(--sh-button-tooltip-margin);
}

:host(:hover:not(:active):not(.touch-device)) .left.tooltip {
  margin-right: var(--sh-button-tooltip-margin);
}

:host(:hover:not(:active):not(.touch-device)) .right.tooltip {
  margin-left: var(--sh-button-tooltip-margin);
}

:host([icon][icon-position]:not([size=full-width]):not([size=max-content]):not([width="100%"])[layout=classic]),
:host(:not([icon])) {
  /**
           * To prevent the browser from
           * shrinking the button when 
           * the window is resized to a
           * smaller size, we set 
           * flex-shrink to 0.
          */
  flex-shrink: 0;
  min-width: var(--sh-button-width-size-s);
  max-width: var(--sh-button-width-size-l);
}

:host([size=s][layout=classic]),
:host([shape=pill-s]) {
  width: var(--sh-button-width-size-s);
}

:host([size=m][layout=classic]),
:host([shape=pill-m][layout=classic]) {
  width: var(--sh-button-width-size-m);
}

:host([size=l][layout=classic]),
:host([shape=pill-l][layout=classic]) {
  width: var(--sh-button-width-size-l);
}

:host([size=auto][layout=classic]),
:host([size=max-content][layout=classic]),
:host([width=auto][layout=compact]),
:host([width=max-content][layout=compact]),
:host([width=auto][layout=normal]),
:host([width=max-content][layout=normal]) {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

:host([size=max-content]),
:host([width=max-content]),
:host([width="100%"]) {
  max-width: unset;
}

:host([size=full-width]),
:host([width=full-width]) {
  max-width: unset;
  width: 100% !important;
}

:host(:not([label=""])[icon-position][layout=classic]) sh-icon {
  margin-right: var(--sh-button-icon-label-margin-right);
}

:host(:not([label=""])[icon][icon-position][layout=classic]) {
  padding: var(--sh-button-button-padding-icon-position);
}

:host([color=tertiary]:not([label=""])[icon][icon-position]) {
  padding: var(--sh-button-tertiary-button-padding-icon-position);
}

:host(.item-focused) {
  outline: var(--focus-outline);
  outline-offset: 0.125rem;
}

.tooltip-wc {
  white-space: nowrap;
}

:host([color=primary]) .btn-icon {
  --ui-1: 255, 255, 255;
}

:host([color=primary-neutral]) .btn-icon {
  --ui-1: var(--ui-7);
}

:host([disabled]) .btn-icon {
  opacity: 1;
}

/* Button - Compact Layout */
:host([layout=compact]:not([icon])) {
  height: var(--sh-button-button-compact-height);
  padding: var(--sh-button-compact-padding);
  border-radius: var(--sh-button-compact-border-radius);
}

/* Icon Only Button - Compact Layout */
:host([layout=compact][icon]) {
  height: var(--sh-button-icon-only-compact-height);
  padding: var(--sh-button-icon-only-compact-padding);
  width: var(--sh-button-icon-only-compact-height);
}
:host([layout=compact][icon]) > sh-icon {
  --sh-icon-size-m: var(--sh-button-compact-icon-size-m);
  --sh-icon-font-size-m: var(--sh-button-compact-icon-font-size-m);
}

/* Button with Icon + Label - Compact Layout */
:host([layout=compact][icon][icon-position]) {
  height: var(--sh-button-icon-label-compact-height);
  padding: var(--sh-button-icon-label-compact-padding);
  border-radius: var(--sh-button-icon-label-compact-border-radius);
  width: fit-content;
}

:host([layout=compact][icon-position]) sh-icon {
  margin-right: var(--sh-button-icon-label-compact-spacing);
  --sh-icon-size-s: var(--sh-button-compact-icon-size-s);
  --sh-icon-font-size-s: var(--sh-button-compact-icon-size-s);
}

/* Button - Normal Layout */
:host([layout=normal]:not([icon])) {
  height: var(--sh-button-normal-height);
  padding: var(--sh-button-normal-padding);
  border-radius: var(--sh-button-normal-border-radius);
  min-width: var(--sh-button-normal-min-width);
}

/*Icon only Button -Normal Layout */
:host([layout=normal][icon]) {
  height: var(--sh-button-icon-only-normal-height);
  padding: var(--sh-button-icon-only-normal-padding);
  width: var(--sh-button-icon-only-normal-height);
  border-radius: var(--sh-button-normal-border-radius);
}
:host([layout=normal][icon]) > sh-icon {
  --sh-icon-size-m: var(--sh-button-icon-size-m);
  --sh-icon-font-size-m: var(--sh-button-icon-font-size-m);
}

/* Icon + Label Button - Normal Layout */
:host([layout=normal][icon][icon-position]) {
  height: var(--sh-button-icon-label-normal-height);
  border-radius: var(--sh-button-normal-border-radius);
  padding: var(--sh-button-icon-label-normal-padding);
  width: fit-content;
}

:host([layout=normal][icon-position]) sh-icon {
  margin-right: var(--sh-button-icon-label-normal-spacing);
  --sh-icon-size-m: var(--sh-button-icon-size-m);
  --sh-icon-font-size-m: var(--sh-button-icon-font-size-m);
}

:host([layout=normal]) sh-icon {
  --sh-icon-size-s: var(--sh-button-icon-size-s);
  --sh-icon-font-size-s: var(--sh-button-normal-icon-size-s);
}

:host([icon][icon-position]:not([width=full-width]):not([width=max-content]):not([width=auto]):not([width="100%"]) [layout=compact]) {
  flex-shrink: 0;
  min-width: var(--sh-button-width-size-s);
  max-width: var(--sh-button-width-size-l);
}

:host([icon][icon-position]:not([width=full-width]):not([width=max-content]):not([width=auto]):not([width="100%"])[layout=normal]) {
  flex-shrink: 0;
  min-width: var(--sh-button-normal-min-width);
  max-width: var(--sh-button-width-size-l);
}`;