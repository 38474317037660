// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { CheckboxToggleMixin } from '../../utils/checkbox-toggle-mixin.js';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import toggleStyles from './sh-toggle.lit.scss.js';
let SHToggle = class SHToggle extends CheckboxToggleMixin(
  ControlStateMixin(FocusBlurMixin(LitElement))
) {
  static get styles() {
    return [toggleStyles, extendInteractiveArea()];
  }
  render() {
    return html`
      <div class="toggle focus-item"></div>
      ${this.label ? html` <span class="toggle-label">${this.label}</span> ` : ''}
    `;
  }
  attributeChangedCallback(name, oldValue, newValue) {
    super.attributeChangedCallback(name, oldValue, newValue);
    const hasValue = newValue !== null;
    if (name === 'active') {
      this.setAttribute('aria-checked', JSON.stringify(hasValue));
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    dispatchAttributeChangedEvent(this, changedProperties, ['label']);
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'switch');
    }
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
  }
  _spaceKeyAction() {
    super._spaceKeyAction();
    this.handleClick();
  }
};
__decorate([property({ type: String, reflect: true })], SHToggle.prototype, 'label', void 0);
SHToggle = __decorate([customElement('sh-toggle')], SHToggle);
export { SHToggle };
