// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { dispatchCustomEvent } from './custom-event-dispatcher.js';

/**
 * @deprecated do NOT bulk-dispatch events on property changes \@event() decorator instead
 * ### dispatchAttributeChangedEvent()
 * ___
 * Function that dispatches `attribute-changed` event for binding.
 * This is intended to be used in `updated` method of SHUI components
 * ___
 * #### How to use this function ?
 *
 * Import like this in the component:
 * ```js
 * import {dispatchAttributeChangedEvent} from '@shui-web-components/utils/attribute-changed-event-dispatcher.js'
 * ```
 * Then use the function like this:
 * ```
 * dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
 * ```
 * ___
 * @param {HTMLElement} component - the component that dispatches the event.
 * @param {Map} changedProperties - the changed properties map obtained from updated.
 * @param {Array} listOfProperties - the array of properties (not attributes) for which attribute-changed event has to be dispatched.
 *
 * By default the dispatchAttributeChangedEvent bubbles as the 'composed' is set to true. It is good to keep the default behavior
 * to not bubble and it should be explicitly set.
 *
 */

export const dispatchAttributeChangedEvent = function (
  component: HTMLElement,
  changedProperties: Map<string, unknown>,
  listOfProperties: string[]
) {
  changedProperties.forEach((_value, key) => {
    const changedProperty = key.toString();
    const propertyInKebabCase = changedProperty
      .toString()
      .replace(/([A-Z])/g, '-$1')
      .toLowerCase();
    if (listOfProperties.includes(changedProperty)) {
      dispatchCustomEvent(component, `${propertyInKebabCase}-changed`, null, false);
    }
  });
};
