// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import worklineStyles from '../src/sh-workline.lit.scss.js';
import { WorklineFloatPositionerMixin } from './workline-float-positioner-mixin.js';
const renderDelay = 400;
let SHWorkline = class SHWorkline extends WorklineFloatPositionerMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**If set to `true`, the active states of the items will be automatically handled upon clicking an item. */
    this.handleActive = false;
    /**Set to true when left slot has elements assigned to it */
    this.onlyleftslot = false;
    /**Set to true when right slot has elements assigned to it */
    this.onlyrightslot = false;
    /**Set to false when no elements are assigned to any slot */
    this.withoutanyslots = true;
    /**Set to true when left slot has no elements */
    this.emptyleft = true;
    /**Set to true when right slot has no elements */
    this.emptyright = true;
  }
  static get styles() {
    return [sharedStyles, worklineStyles];
  }
  render() {
    return html`
      ${this.label
        ? html` <sh-text size="super-header" class="workline-label">${this.label}</sh-text>`
        : ''}
      <div class="total-wrapper">
        <div class="left-float"></div>
        <div class="left-wrapper">
          <slot
            name="left"
            id="left"
            @slotchange="${(e) => this.handleLeftSlotChanged(e.target)}"
          ></slot>
        </div>
        <div class="workline-wrapper" @scroll="${() => this.handleScroll()}">
          <div class="items-wrapper">
            <slot id="body" @slotchange="${() => this.handleBodySlotChanged()}"></slot>
          </div>
        </div>
        <div class="right-wrapper">
          <slot
            name="right"
            id="right"
            @slotchange="${(e) => this.handleRightSlotChanged(e.target)}"
          ></slot>
        </div>
        <div class="right-float"></div>
      </div>
    `;
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
  }
  connectedCallback() {
    super.connectedCallback();
    this.worklineitemfloatchangedListener = (e) => {
      this.handleFloatChange(e);
    };
    this.resizeObserver = new ResizeObserver(() => {
      this.handleSizeFloatChange();
    });
    this.resizeObserver.observe(this);
    this.worklineitemsizechangedListener = this.handleSizeFloatChange.bind(this);
    this.worklineclickListener = this.handleItemActive.bind(this);
    this.addEventListener('click', this.worklineclickListener);
    this.addEventListener('workline-item-float-changed', this.worklineitemfloatchangedListener);
  }
  disconnectedCallback() {
    this.removeEventListener('workline-item-float-changed', this.worklineitemfloatchangedListener);
    this.removeEventListener('workline-item-size-changed', this.worklineitemsizechangedListener);
    this.removeEventListener('click', this.worklineclickListener);
    if (this.resizeObserver) this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }
  // float change handler which adds space for smooth effect
  handleFloatChange(e) {
    const floatElements = this.querySelectorAll('sh-workline-item[float]');
    if (e.target.tagName === 'sh-workline-item'.toUpperCase() && e.target.hasAttribute('float')) {
      this.addEmptySpace(floatElements);
    } else {
      this.removeFloatFix(e.target);
    }
    this.calculateFloatPositions();
    this.fixFloatItems();
  }
  handleSizeFloatChange() {
    const floatElements = this.querySelectorAll('sh-workline-item[float]');
    if (floatElements.length == 0) {
      return;
    }
    this.calculateFloatPositions();
    this.fixFloatItems();
  }
  addEmptySpace(floatElements) {
    floatElements.forEach((element) => {
      var _a, _b;
      if (element.previousElementSibling) {
        if (element.previousElementSibling.tagName !== 'DIV') {
          const emptySpace = document.createElement('div');
          emptySpace.style.minWidth = '0rem';
          (_a = element === null || element === void 0 ? void 0 : element.parentNode) === null ||
          _a === void 0
            ? void 0
            : _a.insertBefore(emptySpace, element);
        }
      } else {
        const emptySpace = document.createElement('div');
        emptySpace.style.minWidth = '0rem';
        (_b = element === null || element === void 0 ? void 0 : element.parentNode) === null ||
        _b === void 0
          ? void 0
          : _b.insertBefore(emptySpace, element);
      }
    });
  }
  handleLeftSlotChanged(slot) {
    const numberOfElementsInLeftSlot = slot.assignedNodes({
      flatten: true,
    }).length;
    if (numberOfElementsInLeftSlot > 0) {
      this.emptyleft = false;
      this.onlyleftslot = true;
      this.withoutanyslots = false;
    }
    if (this.onlyrightslot && this.onlyleftslot) {
      this.onlyrightslot = false;
      this.onlyleftslot = false;
    }
    this.leftSlotDelay = setTimeout(() => {
      var _a, _b;
      clearTimeout(this.leftSlotDelay);
      ((_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.left-float')
      ).style.left = `${
        ((_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.left-wrapper')
        ).getBoundingClientRect().width / calculatedBaseFontSize
      }rem`;
      this.calculateFloatPositions();
      this.fixFloatItems();
    }, renderDelay);
  }
  handleRightSlotChanged(slot) {
    const numberOfElementsInrightSlot = slot.assignedNodes({
      flatten: true,
    }).length;
    if (numberOfElementsInrightSlot > 0) {
      this.emptyright = false;
      this.onlyrightslot = true;
      this.withoutanyslots = false;
    }
    if (this.onlyrightslot && this.onlyleftslot) {
      this.onlyrightslot = false;
      this.onlyleftslot = false;
    }
    this.rightSlotDelay = setTimeout(() => {
      var _a, _b;
      clearTimeout(this.rightSlotDelay);
      ((_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.right-float')
      ).style.right = `${
        ((_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.right-wrapper')
        ).getBoundingClientRect().width / calculatedBaseFontSize
      }rem`;
      this.calculateFloatPositions();
      this.fixFloatItems();
    }, renderDelay);
  }
  handleBodySlotChanged() {
    this.handleSizeFloatChange();
    const floatElements = this.querySelectorAll('sh-workline-item[float]');
    this.addEmptySpace(floatElements);
    this.addEventListener('workline-item-size-changed', this.worklineitemsizechangedListener);
  }
  handleScroll() {
    const floatElements = this.querySelectorAll('sh-workline-item[float]');
    if (floatElements.length === 0) {
      return;
    }
    this.fixFloatItems();
  }
  handleItemActive(event) {
    var _a;
    const BodyChilds = (
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#body')
    ).assignedNodes({
      flatten: true,
    });
    const allChildren = [];
    for (let index = 0; index < BodyChilds.length; index++) {
      if (BodyChilds[index].tagName == 'SH-WORKLINE-GROUP') {
        const worklineGroup = BodyChilds[index].childNodes;
        for (let index1 = 0; index1 < worklineGroup.length; index1++) {
          if (worklineGroup[index1].tagName == 'SH-WORKLINE-ITEM') {
            allChildren.push(worklineGroup[index1]);
          }
        }
      } else if (BodyChilds[index].tagName == 'SH-WORKLINE-ITEM') {
        allChildren.push(BodyChilds[index]);
      }
    }
    // remove active attr from all other items
    if (event.target.tagName === 'SH-WORKLINE-ITEM' && this.handleActive === true) {
      for (let i = 0; i < allChildren.length; i++) {
        if (allChildren[i].hasAttribute('active')) {
          allChildren[i].active = false;
        }
      }
      event.target.active = true;
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHWorkline.prototype, 'label', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'handle-active' })],
  SHWorkline.prototype,
  'handleActive',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-scrollbar' })],
  SHWorkline.prototype,
  'noScrollbar',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorkline.prototype,
  'onlyleftslot',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorkline.prototype,
  'onlyrightslot',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorkline.prototype,
  'withoutanyslots',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorkline.prototype,
  'emptyleft',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorkline.prototype,
  'emptyright',
  void 0
);
SHWorkline = __decorate([customElement('sh-workline')], SHWorkline);
export { SHWorkline };
