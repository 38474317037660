// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-switch-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`[sh-scale=s] {
  /* 
   * @cssprop [--sh-switch-item-width=5.5rem] - switch item width
   */
  --sh-switch-item-width: 5.5rem;
  /* 
   * @cssprop [--sh-switch-item-height=2.5rem] - switch height
   */
  --sh-switch-item-height: 2.5rem;
  /* 
   * @cssprop [--sh-switch-item-padding=0.75rem] - switch item padding
   */
  --sh-switch-item-padding: 0.75rem;
  /* 
   * @cssprop [--sh-switch-item-label-alignment=center] - label alignment
   */
  --sh-switch-item-label-alignment: center;
  /* 
   * @cssprop [--sh-switch-item-border-radius=1.25rem] - switch  border radius
   */
  --sh-switch-item-border-radius: 1.25rem;
  /* 
   * @cssprop [--sh-switch-item-condensed-height=2rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-height: 2rem;
  /* 
   * @cssprop [--sh-switch-item-condensed-border-radius=1rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-border-radius: 1rem;
  /* 
   * @cssprop [--sh-switch-item-condensed-padding=0.5rem 0.75rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-padding: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-switch-item-icon-height=2rem] - switch  item icon height
   */
  --sh-switch-item-icon-height: 2rem;
  /* 
   * @cssprop [--sh-switch-item-icon-width=2rem] - switch  item icon width
   */
  --sh-switch-item-icon-width: 2rem;
  /* 
   * @cssprop [--sh-switch-item-icon-margin=0.25rem 1.75rem] - switch  item icon margin
   */
  --sh-switch-item-icon-margin: 0.25rem 1.75rem;
  /* 
   * @cssprop [--sh-switch-item-label-height=1rem] - switch  item icon margin
   */
  --sh-switch-item-label-height: 1rem;
}

:host {
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
  border-radius: var(--sh-switch-item-border-radius);
  padding: var(--sh-switch-item-padding);
  font: var(--body-1);
  color: var(--text-secondary);
  cursor: pointer;
  min-width: var(--sh-switch-item-width);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  height: var(--sh-switch-item-label-height);
  line-height: var(--sh-switch-item-label-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:host(:hover),
:host([active]) {
  color: var(--text-primary);
}

:host([active]) {
  background-color: rgba(var(--ui-1), var(--opacity-6));
}

:host([icon]:not([label])) {
  padding: 0rem;
}

:host([icon][label]) .switch-icon {
  display: none;
}

:host([icon]) .switch-icon {
  display: flex;
  margin: var(--sh-switch-item-icon-margin);
  color: rgba(var(--ui-1), var(--opacity-3));
  height: var(--sh-switch-item-icon-height);
  width: var(--sh-switch-item-icon-width);
}

:host([icon][active]) .switch-icon {
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon][disabled]) .switch-icon,
:host([disabled]) {
  pointer-events: none;
  color: var(--text-disabled);
}

:host([icon][disabled][icon-color]) .switch-icon {
  pointer-events: none;
  opacity: var(--opacity-5);
}

:host([active][disabled]) {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

:host([condensed]) {
  padding: var(--sh-switch-item-condensed-padding);
  height: var(--sh-switch-item-condensed-height);
  border-radius: var(--sh-switch-item-condensed-border-radius);
}

:host([blue-outline]) {
  box-shadow: inset 0rem 0rem 0rem 0.125rem var(--focus-color);
}`;