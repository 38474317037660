// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-drawer.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-drawer-width=22rem] - drawer width
   */
  --sh-drawer-width: 22rem;
  /* 
   * @cssprop [--sh-drawer-header-wrapper-padding=1rem] - padding of the header-wrapper
   */
  --sh-drawer-header-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-drawer-label-wrapper-padding=1rem] - padding of the label-wrapper
   */
  --sh-drawer-label-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-drawer-gap-between-function-footer-slot-elements=0.5rem] - gap between function and footer slot elements
   */
  --sh-drawer-gap-between-function-footer-slot-elements: 0.5rem;
  /* 
   * @cssprop [--sh-drawer-body-wrapper-padding=0 1rem] - padding of the body-wrapper
   */
  --sh-drawer-body-wrapper-padding: 0 1rem;
  /* 
   * @cssprop [--sh-drawer-menu-item-margin-top=0.5rem] - margin-top of menu-item
   */
  --sh-drawer-menu-item-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-drawer-headerless-body-wrapper-padding-top=1rem] - padding-top of body-wrapper when no header
   */
  --sh-drawer-headerless-body-wrapper-padding-top: 1rem;
  /* 
   * @cssprop [--sh-drawer-footerless-body-wrapper-padding-bottom=var(--sh-drawer-headerless-body-wrapper-padding-top)] - padding bottom of body-wrapper when no footer
   */
  --sh-drawer-footerless-body-wrapper-padding-bottom: var(--sh-drawer-headerless-body-wrapper-padding-top);
  /* 
   * @cssprop [--sh-drawer-body-wrapper-margin-bottom=0.5rem] - margin-bottom of elements in body-wrapper
   */
  --sh-drawer-body-wrapper-margin-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-drawer-footer-wrapper-padding=var(--sh-drawer-footerless-body-wrapper-padding-bottom)] - padding of the footer-wrapper
   */
  --sh-drawer-footer-wrapper-padding: var(--sh-drawer-footerless-body-wrapper-padding-bottom);
  /* 
   * @cssprop [--sh-drawer-top-bottom-min-height=10rem] - min-height of top and bottom drawers
   */
  --sh-drawer-top-bottom-min-height: 10rem;
  /* 
   * @cssprop [--sh-drawer-label-line-height=1.5rem] - line-height of the label
   */
  --sh-drawer-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-drawer-label-padding=0rem] - padding of the label
   */
  --sh-drawer-label-padding: 0rem;
  /* 
   * @cssprop [--sh-drawer-label-margin-right=0.5rem] - margin-right of label
   */
  --sh-drawer-label-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-drawer-touch-gap-between-function-footer-slot-elements=1rem] - gap of the elements in function and footer slot for touch
   */
  --sh-drawer-touch-gap-between-function-footer-slot-elements: 1rem;
  /* 
   * @cssprop [--sh-drawer-body-wrapper-line-height=1.25rem] - line-height of elements in body-wrapper
   */
  --sh-drawer-body-wrapper-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-drawer-scrollpicker-padding=0.5rem 1rem] - padding of the scrollpicker used in datepicker and timepicker
   */
  --sh-drawer-scrollpicker-padding: 0.5rem 1rem;
}

:host {
  position: fixed;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  background-color: var(--sh-public-scrim-fill, var(--sh-scrim-fill));
  /**
    Setting z-index is necessary here inorder to make the drawer appear.

    Why the value is set to 6?

    Since
    pane has z-index=2,
    access-bar has z-index=4,
    a reasonable value of 6 is chosen. 

    Why not a high value like that of popover ?

    Since we have no concrete control in what way the BL intends to display its popovers
    (whether it has to appear above the drawers or below it), we are not choosing such high values.

    Since the z-index is applied directly to the host, the Business Line can any time
    modify the z-index according to the business needs.
    */
  z-index: 6;
  max-height: 0rem;
  opacity: 0;
  transition: var(--transition-time) all ease-in-out, 0s height ease-in-out var(--transition-time), 0s max-height ease-in-out var(--transition-time), 0s visibility linear 0s;
  display: block;
  visibility: hidden;
}

:host(:not([visible])) {
  overflow: hidden;
  pointer-events: none;
}

.drawer-wrapper {
  background: var(--base-3);
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-navigation);
  box-sizing: border-box;
  transition: var(--transition-time) all ease-in-out;
}

/* slot styles */
:host(:not([label])) .drawer-wrapper[empty-function] .label-wrapper,
.drawer-wrapper:not([empty-header]) .label-wrapper {
  display: none;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  padding: var(--sh-drawer-header-wrapper-padding);
}

.label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--sh-drawer-label-wrapper-padding);
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
}

.functions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sh-drawer-gap-between-function-footer-slot-elements);
}

.body-wrapper {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding: var(--sh-drawer-body-wrapper-padding);
  font: var(--body-1);
  color: var(--text-primary);
  line-height: var(--sh-drawer-body-wrapper-line-height) !important;
}

.drawer-wrapper[empty-header] .body-wrapper {
  padding-top: var(--sh-drawer-headerless-body-wrapper-padding-top);
}

.drawer-wrapper[empty-footer] .body-wrapper {
  padding-bottom: var(--sh-drawer-footerless-body-wrapper-padding-bottom);
}

.drawer-wrapper:not([empty-header]) .body-wrapper,
:host([label]) .body-wrapper {
  padding-top: 0rem;
}

.body-wrapper > ::slotted(*:not(:last-child):not(sh-menu-item)) {
  margin-bottom: var(--sh-drawer-body-wrapper-margin-bottom);
}

.footer-wrapper {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  padding: var(--sh-drawer-footer-wrapper-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: var(--sh-drawer-gap-between-function-footer-slot-elements);
}

.drawer-wrapper[empty-header] .header-wrapper,
.drawer-wrapper[empty-footer] .footer-wrapper {
  display: none;
}

/* positioning */
:host([position=top]) .drawer-wrapper,
:host([position=bottom]) .drawer-wrapper {
  height: auto;
  min-height: var(--sh-drawer-top-bottom-min-height);
  max-height: calc(100% - 6.5rem);
  width: 100%;
}

:host([position=left]) .drawer-wrapper,
:host([position=right]) .drawer-wrapper {
  width: var(--drawer-width);
  height: 100%;
}

:host([position=top]) .drawer-wrapper {
  top: 0rem;
  transform: translateY(-5rem);
}

:host([position=bottom]) .drawer-wrapper {
  bottom: 0rem;
  transform: translateY(5rem);
}

:host([position=left]) .drawer-wrapper {
  left: 0rem;
  transform: translateX(-5rem);
}

:host([position=right]) .drawer-wrapper {
  right: 0rem;
  transform: translateX(5rem);
}

/* Margin constraint */
:host([position=left]) .drawer-wrapper,
:host([position=right]) .drawer-wrapper {
  max-width: calc(100% - 6.5rem);
}

/* visible */
:host([visible]) {
  max-height: 100%;
  opacity: 1;
  transition: var(--transition-time) all ease-in-out var(--transition-time), 0s height ease-in-out, 0s max-height ease-in-out, 0s visibility linear 0s;
  visibility: visible;
}

:host([visible]) .drawer-wrapper {
  transition: var(--transition-time) all ease-in-out var(--transition-time);
  transform: none;
}

.drawer-label {
  overflow: hidden;
  width: -webkit-fill-available;
  width: -moz-available;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
  font: var(--header-2);
  line-height: var(--sh-drawer-label-line-height);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--sh-drawer-label-padding);
  margin-right: var(--sh-drawer-label-margin-right);
}

/* touch */
@media (any-pointer: coarse) {
  .functions-wrapper,
  .footer-wrapper {
    gap: var(--sh-drawer-touch-gap-between-function-footer-slot-elements);
  }
}
/* scrollpicker */
:host(.point5-vertical-padding) .body-wrapper {
  padding: var(--sh-drawer-scrollpicker-padding);
}

:host([position=bottom][visible].initial-slide-on-connect) {
  animation: fade-in var(--transition-time) ease-in-out;
}

:host([position=bottom][visible].initial-slide-on-connect) .drawer-wrapper {
  animation: slide-on-connect var(--transition-time) ease-in-out;
  background: var(--base-4);
}

@keyframes slide-on-connect {
  0% {
    bottom: 0rem;
    transform: translateY(5rem);
  }
  100% {
    transform: none;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`;