// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/**
 * ### wait()
 * ___
 * Function that adds to the delay
 * ___
 * #### How to use this function ?
 * Import like this in the component:
 *
 * ```js
 * import { wait } from './utils/wait'
 * ```
 * Then use the function like the following:
 *
 *
 * ```js
 * wait(250);
 * ```
 *
 * @param {Number} delay The number in ms
 */
export const wait = (delay: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};
