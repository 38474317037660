// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
/**
 * ### HtmlDocumentModifier
 *
 * Class that contains a set of methods to modify the HTML document
 *
 * - - -
 *
 * #### Class Methods and Description
 *
 * || Methods | Parameters |  Description |
 * | :---| :--- | :--- | :--- |
 * |1.|`setHTMLAttribute(attribute,value)`|`attribute`,`value`|Sets the given HTML attribute to the given value.|
 * |2.|`removeHTMLAttribute(attribute)`|`attribute`|Removes the given HTML attribute.|
 *
 * ---
 *
 * #### How to use ?
 *
 * Import like this.
 * ```js
 * import { HtmlDocumentModifier } from '@shui-web-components/utils/html-document-modifier.js';
 * ```
 * - - -
 *
 * #### setHTMLAttribute() method
 * Sets the given HTML attribute to the given value.
 *
 * **Example** :
 *
 * To set an attribute to a particular value for the HTML Document,
 *
 * say the `theme` attribute to 'dark',
 * the following code will be written:
 * ```js
 * HtmlDocumentModifier.setHTMLAttribute('theme', 'dark');
 * ```
 *
 * - - -
 *
 * #### removeHTMLAttribute() method
 * Removes the given HTML attribute to the given value.
 *
 * **Example** :
 *
 * To remove an attribute from the HTML Document,
 *
 * say the `theme` attribute,
 * the following code will be written:
 * ```js
 * HtmlDocumentModifier.removeHTMLAttribute('theme');
 * ```
 *
 */
export class HtmlDocumentModifier {
  static setHTMLAttribute(attribute, value) {
    document.documentElement.setAttribute(attribute, value);
  }
  static removeHTMLAttribute(attribute) {
    document.documentElement.removeAttribute(attribute);
  }
}
