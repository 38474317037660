// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

export function getUnFlippedAlgorithm(placement: string) {
  interface AlgorithmInfo {
    [key: string]: unknown[];
  }
  const algorithmInfo: AlgorithmInfo = {
    bottom: [
      {
        from: 'bottom',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-diag-left',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
    ],
    top: [
      {
        from: 'top',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'top',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'top-diag-left',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-right',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
    ],
    right: [
      {
        from: 'right',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'right',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-right',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
    ],
    left: [
      {
        from: 'left',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'left',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-left',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-diag-left',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
    ],
    'bottom-left': [
      {
        from: 'bottom-left',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom-left',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-diag-left',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
    ],
    'bottom-right': [
      {
        from: 'bottom-right',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-right',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-left',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
    ],
    'top-left': [
      {
        from: 'top-left',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'top-left',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'top-diag-left',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-right',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
    ],
    'top-right': [
      {
        from: 'top-right',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'top-right',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'top-diag-right',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-left',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
    ],
    'right-up': [
      {
        from: 'right-up',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'right-up',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-right',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
    ],
    'left-up': [
      {
        from: 'left-up',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'left-up',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'top-diag-left',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-diag-left',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
    ],
    'right-down': [
      {
        from: 'right-down',
        to: 'bottom-diag-right',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'right-down',
        to: 'top-diag-right',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'top-diag-right',
        to: 'top-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
      {
        from: 'bottom-diag-right',
        to: 'bottom-diag-left',
        increment: 'overlayRight',
        incrementBy: -1,
      },
    ],
    'left-down': [
      {
        from: 'left-down',
        to: 'bottom-diag-left',
        increment: 'overlayTop',
        incrementBy: 1,
      },
      {
        from: 'left-down',
        to: 'top-diag-left',
        increment: 'overlayBottom',
        incrementBy: -1,
      },
      {
        from: 'top-diag-left',
        to: 'top-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
      {
        from: 'bottom-diag-left',
        to: 'bottom-diag-right',
        increment: 'overlayLeft',
        incrementBy: 1,
      },
    ],
  };
  return algorithmInfo[placement];
}
