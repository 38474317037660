// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-datepicker.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-datepicker-overlay-container-height=2.5rem] - height of the overlay container
   */
  --sh-datepicker-overlay-container-height: 2.5rem;
  /* 
   * @cssprop [--sh-datepicker-overlay-container-height-condensed=2rem] - height of the overlay container in condensed view
   */
  --sh-datepicker-overlay-container-height-condensed: 2rem;
  /* 
   * @cssprop [--sh-datepicker-months-grid-gap=0.5rem] - gap of months grid
   */
  --sh-datepicker-months-grid-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-width=18rem] - gap of months grid
   */
  --sh-datepicker-date-panel-width: 18rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-padding=1rem 0.5rem 0.5rem 0.5rem] - gap of months grid
   */
  --sh-datepicker-date-panel-padding: 1rem 0.5rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-height=21rem] - gap of months grid
   */
  --sh-datepicker-date-panel-height: 21rem;
  /* 
   * @cssprop [--sh-datepicker-header-area-height=2rem] - height of the header area
   */
  --sh-datepicker-header-area-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-width=9.375rem] - width of the month and year display
   */
  --sh-datepicker-month-and-year-display-width: 9.375rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-line-height=2rem] - line-height of the month and year display
   */
  --sh-datepicker-month-and-year-display-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-border-radius=1rem] - border-radius of the month and year display
   */
  --sh-datepicker-month-and-year-display-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-rows=2rem] - grid template rows of the date grid
   */
  --sh-datepicker-date-grid-template-rows: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-columns=2rem] - grid template columns of the date grid
   */
  --sh-datepicker-date-grid-template-columns: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-gap=0.5rem] - grid gap of the date grid
   */
  --sh-datepicker-date-grid-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-margin-top=0.5rem] - margin top of  date grid
   */
  --sh-datepicker-date-grid-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-week-day-name-line-height=2rem] - line height of week day name
   */
  --sh-datepicker-week-day-name-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-line-height=2rem] - line height of date
   */
  --sh-datepicker-date-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-border-radius=1rem] - border-radius of date
   */
  --sh-datepicker-date-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-height=2rem] - border-radius of date
   */
  --sh-datepicker-date-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-width=2rem] - border-radius of date
   */
  --sh-datepicker-date-width: 2rem;
  /* 
   * @cssprop [--sh-datepicker-icon-dummy-width=2rem] - width of dummy icon
   */
  --sh-datepicker-icon-dummy-width: 2rem;
  /* 
   * @cssprop [--sh-datepicker-icon-dummy-height=2rem] - height of dummy icon
   */
  --sh-datepicker-icon-dummy-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-padding-year-and-month-selector=1rem 0.5rem 1.75rem] - date panel padding of year and month selector
   */
  --sh-datepicker-date-panel-padding-year-and-month-selector: 1rem 0.5rem 1.75rem;
  /* 
   * @cssprop [--sh-datepicker-header-area-height-year-and-month-selector=1.5rem] - height of the header area of year and month selector
   */
  --sh-datepicker-header-area-height-year-and-month-selector: 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-line-height-year-selector=1.5rem] - line height of the year selector
   */
  --sh-datepicker-line-height-year-selector: 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-rows-year-and-month-selector=3rem] - grid template rows of year selector
   */
  --sh-datepicker-date-grid-template-rows-year-and-month-selector: 3rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-columns-year-and-month-selector=4rem] - grid template rows of year selector
   */
  --sh-datepicker-date-grid-template-columns-year-and-month-selector: 4rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-column-gap-year-and-month-selector=1rem] - grid gap of year selector
   */
  --sh-datepicker-date-grid-column-gap-year-and-month-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-row-gap-year-and-month-selector=1rem] - grid row gap of year selector
   */
  --sh-datepicker-date-grid-row-gap-year-and-month-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-margin-year-and-month-selector=1.75rem 1.5rem 0rem 1.5rem] - date grid margin of year selector
   */
  --sh-datepicker-date-grid-margin-year-and-month-selector: 1.75rem 1.5rem 0rem 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-width-year-and-month-selector=14rem] - width of date grid of year selector
   */
  --sh-datepicker-date-grid-width-year-and-month-selector: 14rem;
  /* 
   * @cssprop [--sh-datepicker-year-height-year-selector=2rem] - height of year field in year selector
   */
  --sh-datepicker-year-height-year-selector: 2rem;
  /* 
   * @cssprop [--sh-datepicker-year-line-height-year-selector=2rem] - line height of year field in year selector
   */
  --sh-datepicker-year-line-height-year-selector: 2rem;
  /* 
   * @cssprop [--sh-datepicker-year-border-radius-year-selector=1rem] - border radius of year field in year selector
   */
  --sh-datepicker-year-border-radius-year-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-width=4.5rem] - width of the year display  in month selector
   */
  --sh-datepicker-month-and-year-width: 4.5rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-height=2rem] - hieght of the year display  in month selector
   */
  --sh-datepicker-month-and-year-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-height=2rem] - hieght of the year month  in month selector
   */
  --sh-datepicker-month-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-line-height=2rem] - line hieght of the year month  in month selector
   */
  --sh-datepicker-month-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-border-radius=1rem] - border radius of  month  in month selector
   */
  --sh-datepicker-month-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-footer-padding=1rem] - padding of footer element
   */
  --sh-datepicker-footer-padding: 1rem;
  /* 
   * @cssprop [--sh-datepicker-footer-button-inter-gap=0.5rem] - gap between buttons in footer
   */
  --sh-datepicker-footer-button-inter-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-footer-height=4rem] - height of footer
   */
  --sh-datepicker-footer-height: 4rem;
}

:host {
  display: block;
  width: 100%;
  position: relative;
  height: fit-content;
}

/**
      Correcting the icon opacity to the
      idle state color of button icon so
      that the calendar icon is not so light
      when disabled property is applied to 
      datepicker
       */
:host([disabled]) sh-icon[icon=calendar] {
  color: rgba(var(--ui-1), var(--opacity-3));
}

.overlay-container {
  position: absolute;
  height: var(--sh-datepicker-overlay-container-height);
  top: 0px;
  left: 0px;
}

:host([condensed]) .overlay-container {
  height: var(--sh-datepicker-overlay-container-height-condensed);
}

.input {
  outline: none;
}`;