// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from './attribute-changed-event-dispatcher.js';
export const CheckboxToggleMixin = (superClass) => {
  class CheckboxToggleMixinElement extends superClass {
    updated(changedProperties) {
      super.updated(changedProperties);
      const listOfProperties = ['active', 'neutral'];
      dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    }
    firstUpdated() {
      this.addEventListener('click', () => this.handleClick());
      this.addEventListener('focus', () => this.handleFocus());
      this.addEventListener('blur', () => this.handleBlur());
    }
    handleClick() {
      this.active = !this.active;
    }
  }
  __decorate(
    [property({ type: Boolean, reflect: true })],
    CheckboxToggleMixinElement.prototype,
    'active',
    void 0
  );
  __decorate(
    [property({ type: Boolean, reflect: true })],
    CheckboxToggleMixinElement.prototype,
    'neutral',
    void 0
  );
  return CheckboxToggleMixinElement;
};
