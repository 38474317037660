// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { CheckboxToggleMixin } from '../../utils/checkbox-toggle-mixin';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
import checkboxStyles from './sh-checkbox.lit.scss.js';
/**
 * @fires checked - Dispatched when clicking on checkbox.
 */
let SHCheckbox = class SHCheckbox extends RefrainSpaceScrollMixin(
  CheckboxToggleMixin(ControlStateMixin(FocusBlurMixin(LitElement)))
) {
  static get styles() {
    return [checkboxStyles, extendInteractiveArea()];
  }
  render() {
    return html`
      <input
        tabindex="-1"
        id="checkbox"
        type="checkbox"
        role="presentation"
        ?checked="${this.active}"
        ?readonly="${this.readonly}"
        ?active="${this.active}"
        ?disabled="${this.disabled}"
        ?aria-checked="${this.indeterminate ? 'mixed' : this.active}"
        ?aria-readonly="${this.readonly}"
        ?indeterminate="${this.indeterminate}"
        value="${this.active}"
      />
      <span id="pseudoCheckbox" class="focus-item"></span>
      <span class="checkboxLabel">${this.label}</span>
    `;
  }
  attributeChangedCallback(name, oldval, newVal) {
    super.attributeChangedCallback(name, oldval, newVal);
    const val = newVal !== null;
    if (name === 'active') {
      this.setAttribute('aria-checked', val.toString());
    }
    if (name === 'readonly') {
      this.setAttribute('aria-disabled', val.toString());
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    const listOfProperties = ['label', 'indeterminate'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.addEventListener('click', () => {
      dispatchCustomEvent(this, 'checked');
    });
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'checkbox');
    }
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  _spaceKeyAction() {
    if (!this.readonly) {
      this.handleClick();
      dispatchCustomEvent(this, 'checked');
    }
  }
};
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHCheckbox.prototype,
  'indeterminate',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHCheckbox.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCheckbox.prototype, 'readonly', void 0);
SHCheckbox = __decorate([customElement('sh-checkbox')], SHCheckbox);
export { SHCheckbox };
