// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-empty-state.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-empty-state-icon-size=4rem] - Size of the empty state icon
   */
  --sh-empty-state-icon-size: 4rem;
  /* 
   * @cssprop [--sh-empty-state-text-width=24rem] - Width of the empty state text
   */
  --sh-empty-state-text-width: 24rem;
  /* 
   * @cssprop [--sh-empty-state-text-max-width=calc(100% - 4rem)] - Maximum width of the empty state text 
   */
  --sh-empty-state-text-max-width: calc(100% - 4rem);
  /* 
   * @cssprop [--sh-empty-state-margin=1rem] - Top margin for the footer slot & Bottom margin of the icon 
   */
  --sh-empty-state-margin: 1rem;
  /* 
   * @cssprop [--sh-empty-state-image-wrapper-margin=0rem 2rem 1rem] - margin for the image slot
   */
  --sh-empty-state-image-wrapper-margin: 0rem 2rem 1rem;
  /* 
   * @cssprop [--sh-empty-state-header-line-height=1.5rem] - line-height of header
   */
  --sh-empty-state-header-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-empty-state-thickness=0.125rem] - Border thickness of the dotted line when drag and drop is set
   */
  --sh-empty-state-thickness: 0.125rem;
  /* 
   * @cssprop [--sh-empty-state-left-margin=0.5rem] - left margin of the non first footer slot elements
   */
  --sh-empty-state-left-margin: 0.5rem;
}

:host {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

sh-icon {
  /* WARNING: this rule is only to make */
  /* the rescaled implementation look like the legacy one. */
  /* It nudges the icon down a bit which seems like a change */
  /* that was introduced at some point */
  /* !!!!this rule probably should go!!!! */
  margin-bottom: var(--sh-empty-state-margin);
  --sh-icon-size-m: var(--sh-empty-state-icon-size);
  --sh-icon-font-size-m: var(--sh-empty-state-icon-size);
  color: rgba(var(--ui-1), var(--opacity-5));
}

sh-text {
  text-align: center;
  width: var(--sh-empty-state-text-width);
  max-width: var(--sh-empty-state-text-max-width);
}

.header {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: var(--sh-empty-state-header-line-height);
  margin-bottom: 0.5rem;
  flex-shrink: 0;
}

/* image */
.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--sh-empty-state-image-wrapper-margin);
  overflow: hidden;
  flex-direction: column;
}

.image-wrapper > ::slotted(*:first-child) {
  max-width: 100%;
}

.image-wrapper[empty-image] {
  display: none;
}

/* footer */
.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--sh-empty-state-margin);
}

.footer-wrapper > ::slotted(*:not(:first-child)) {
  margin-left: var(--sh-empty-state-left-margin);
}

.footer-wrapper[empty-footer] {
  display: none;
}

/* drag and drop */
:host([drag-and-drop]) {
  border: var(--sh-empty-state-thickness) dotted rgba(var(--ui-1), var(--opacity-5));
  box-sizing: border-box;
}`;