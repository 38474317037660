import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import badgeStyles from './sh-badge.lit.scss.js';
let SHBadge = class SHBadge extends LitElement {
  constructor() {
    super(...arguments);
    /** Defines if a tag is disabled. If set, disabled styles will be applied. */
    this.disabled = false;
    /** Defines label together with the type property can only be shown when show-label is enabled */
    this.showLabel = false;
    /** When set, badge is shown with neutral styles*/
    this.neutral = false;
  }
  static get styles() {
    return [badgeStyles];
  }
  render() {
    return html`
      ${this.showLabel
        ? html` ${this.type === 'error' || this.type === 'warning' || this.type === 'success'
              ? html`<sh-icon icon="${this.type}" size="xs"></sh-icon>`
              : ``}
            <!-- added span tag to handle elipses with display flex while type and label are provided-->
            ${this.label ? html`<span>${this.label}</span>` : ``}`
        : html`${
            this.label || // if there is a label, render that and nothing else
            (this.type === 'error' || this.type === 'warning' || this.type === 'success'
              ? html`<sh-icon icon="${this.type}" size="xs"></sh-icon>`
              : '') // render icon otherwise in case it is legal
          }`}
    `;
  }
  updated(changedProperties) {
    if (changedProperties.has('label')) {
      this.processLabel();
    }
  }
  processLabel() {
    if (this.label) {
      // truncate numbers
      if (this.label.match('^\\d+$|^\\d+[+]$') !== null) {
        const badgeLabel = parseInt(this.label, 10);
        if (badgeLabel >= 1000 || this.label.toString().length > 3) {
          this.label = '999+';
        }
      }
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHBadge.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHBadge.prototype, 'type', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHBadge.prototype, 'disabled', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-label' })],
  SHBadge.prototype,
  'showLabel',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'neutral' })],
  SHBadge.prototype,
  'neutral',
  void 0
);
SHBadge = __decorate([customElement('sh-badge')], SHBadge);
export { SHBadge };
