// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-progress.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-progress-header-line-height=1.25rem] - line-height of header
   */
  --sh-progress-header-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-progress-progress-bar-height=0.5rem] - height of the progress bar
   */
  --sh-progress-progress-bar-height: 0.5rem;
  /* 
   * @cssprop [--sh-progress-indeterminate-bar-length=7.5rem] - the length of the inner bar of the indeterminate progress bar
   */
  --sh-progress-indeterminate-bar-length: 7.5rem;
  /* 
   * @cssprop [--sh-progress-info-line-height=1rem] - line height of info text
   */
  --sh-progress-info-line-height: 1rem;
  /* 
   * @cssprop [--sh-progress-header-margin-bottom=0.5rem] - margin bottom of header
   */
  --sh-progress-header-margin-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-progress-footer-margin-top=0.5rem] - margin-top of footer of linear-progress (the radial-progress footer has same margin-top as radial-progress header margin-bottom)
   */
  --sh-progress-footer-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-progress-footer-height=1rem] - the height of the footer of linear-progress
   */
  --sh-progress-footer-height: 1rem;
  /* 
   * @cssprop [--sh-progress-radial-header-margin-bottom=var(--sh-progress-header-margin-bottom)] - margin-bottom of header for radial progress
   */
  --sh-progress-radial-header-margin-bottom: var(--sh-progress-header-margin-bottom);
  /* 
   * @cssprop [--sh-progress-radial-progress-height-xs=1rem] - height of radial progress of size xs
   */
  --sh-progress-radial-progress-height-xs: 1rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-ring-width-xs=0.25rem] - ring width of radial progress of size xs
   */
  --sh-progress-radial-progress-ring-width-xs: 0.25rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-height-s=2rem] - height of radial progress of size s
   */
  --sh-progress-radial-progress-height-s: 2rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-ring-width-s=0.375rem] - ring width of radial progress of size s
   */
  --sh-progress-radial-progress-ring-width-s: 0.375rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-height-m=4rem] - height of radial progress of size m
   */
  --sh-progress-radial-progress-height-m: 4rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-ring-width-m=0.5rem] - ring width of radial progress of size m
   */
  --sh-progress-radial-progress-ring-width-m: 0.5rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-height-l=8rem] - height of radial progress of size l
   */
  --sh-progress-radial-progress-height-l: 8rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-ring-width-l=0.625rem] - ring width of radial progress of size l
   */
  --sh-progress-radial-progress-ring-width-l: 0.625rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-height-xl=16rem] - height of radial progress of size xl
   */
  --sh-progress-radial-progress-height-xl: 16rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-ring-width-xl=0.75rem] - ring width of radial progress of size xl
   */
  --sh-progress-radial-progress-ring-width-xl: 0.75rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-label-width-m=2.5rem] - width of the radial progress label when progress size is m
   */
  --sh-progress-radial-progress-label-width-m: 2.5rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-label-width-l=5.5rem] - width of the radial progress label when progress size is l
   */
  --sh-progress-radial-progress-label-width-l: 5.5rem;
  /* 
   * @cssprop [--sh-progress-radial-progress-label-width-xl=10.25rem] - width of the radial progress label when progress size is xl
   */
  --sh-progress-radial-progress-label-width-xl: 10.25rem;
}

:host {
  width: 100%;
  display: flex;
  flex-direction: column;
}

:host([overlay]) {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(var(--ui-7), var(--opacity-3));
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:host([overlay]) .header-wrapper,
:host([overlay]) .bar-wrapper,
:host([overlay]) .footer-wrapper {
  width: 17.5rem;
}

.header-wrapper,
.footer {
  display: flex;
  align-items: center;
}

.info {
  line-height: var(--sh-progress-info-line-height);
}

:host(:not([radial])) .header,
:host(:not([radial])) .info {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header,
.label {
  line-height: var(--sh-progress-header-line-height);
}

.label {
  width: auto;
}

.header + .label {
  margin-left: 0.5rem;
}

:host(:not([header])) .label {
  flex: 1;
  text-align: center;
}

.footer {
  width: 100%;
}

:host(:not([radial])) .header-wrapper {
  margin-bottom: var(--sh-progress-header-margin-bottom);
}

:host(:not([radial])) .footer {
  margin-top: var(--sh-progress-footer-margin-top);
  height: fit-content;
}

:host(:not([radial])) .footer:not(.has-function-icons) {
  margin-top: var(--sh-progress-header-margin-bottom);
}

.linear-progress {
  width: 100%;
  height: var(--sh-progress-progress-bar-height);
  border-radius: calc(var(--sh-progress-progress-bar-height) / 2);
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.linear-progress:not(.indeterminate) {
  background: linear-gradient(to right, var(--progress-color) var(--progress-value), rgba(var(--ui-1), var(--opacity-6)) 0%);
}

/* radial */
:host([radial]) {
  align-items: center;
  justify-content: center;
}

:host([radial]) .info,
:host([radial]) .header {
  text-align: center;
}

:host([radial]) .header-wrapper {
  margin-bottom: var(--sh-progress-radial-header-margin-bottom);
}

:host([radial]) .footer {
  margin-top: var(--sh-progress-radial-header-margin-bottom);
}

.radial-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  width: 100%;
  text-align: center;
}

:host([size=xs]) .radial-wrapper {
  --radial-height: var(--sh-progress-radial-progress-height-xs);
  --ring-width: var(--sh-progress-radial-progress-ring-width-xs);
}

:host([size=s]) .radial-wrapper {
  --radial-height: var(--sh-progress-radial-progress-height-s);
  --ring-width: var(--sh-progress-radial-progress-ring-width-s);
}

:host([size=m]) .radial-wrapper {
  --radial-height: var(--sh-progress-radial-progress-height-m);
  --ring-width: var(--sh-progress-radial-progress-ring-width-m);
}

:host([size=l]) .radial-wrapper {
  --radial-height: var(--sh-progress-radial-progress-height-l);
  --ring-width: var(--sh-progress-radial-progress-ring-width-l);
}

:host([size=xl]) .radial-wrapper {
  --radial-height: var(--sh-progress-radial-progress-height-xl);
  --ring-width: var(--sh-progress-radial-progress-ring-width-xl);
}

.radial-wrapper {
  height: var(--radial-height);
  width: var(--radial-height);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  box-shadow: inset 0 0 0 var(--ring-width) rgba(var(--ui-1), var(--opacity-6));
}

.radial-wrapper::before {
  position: absolute;
  content: "";
  width: calc(var(--radial-height) / 2 - var(--ring-width) / 2);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 0;
}

.radial-progress {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}

.radial-wrapper.hundred-value {
  box-shadow: inset 0 0 0 var(--ring-width) var(--progress-color);
}

.radial-progress-indicator {
  r: calc(var(--radial-height) / 2 - var(--ring-width) / 2);
  cx: calc(var(--radial-height) / 2);
  cy: calc(var(--radial-height) / 2);
  stroke-width: calc(var(--ring-width));
  fill: transparent;
  stroke: var(--progress-color);
  stroke-dasharray: calc(var(--progress-value) / 100 * var(--circle-circumference)) calc((100 - var(--progress-value)) / 100 * var(--circle-circumference));
}

.radial-wrapper.indeterminate .radial-progress {
  animation: radial-indeterminate 2s linear infinite;
  transform-origin: 50% 50%;
}

:host([radial][size=m]) .radial-label {
  width: var(--sh-progress-radial-progress-label-width-m);
}

:host([radial][size=l]) .radial-label {
  width: var(--sh-progress-radial-progress-label-width-l);
  font-size: calc(var(--font-size-title-1) * 2);
  line-height: calc(var(--sh-text-title1-line-height) * 2);
}

:host([radial][size=xl]) .radial-label {
  width: var(--sh-progress-radial-progress-label-width-xl);
  font-size: calc(var(--font-size-title-1) * 4);
  line-height: calc(var(--sh-text-title1-line-height) * 4);
}

/* status */
.status-icon {
  margin-right: 0.5rem;
}

.status-icon[icon=cancel] {
  color: rgb(var(--functional-red));
}

.status-icon[icon=error] {
  color: rgb(var(--functional-yellow));
}

.status-icon[icon=check_circle] {
  color: rgb(var(--functional-green));
}

/* functions slot */
slot[name=functions]::slotted(*) {
  margin-left: 0.5rem;
}

/* indeterminate */
.linear-progress.indeterminate {
  background: rgba(var(--ui-1), var(--opacity-6));
}

.indeterminate-bar {
  width: calc(100% + var(--sh-progress-indeterminate-bar-length) + 100%);
  background: linear-gradient(to right, transparent calc((100% - var(--sh-progress-indeterminate-bar-length)) / 2), var(--progress-color) calc((100% - var(--sh-progress-indeterminate-bar-length)) / 2), var(--progress-color) calc((100% + var(--sh-progress-indeterminate-bar-length)) / 2), transparent 0);
  animation: indeterminate 2s linear infinite;
  position: absolute;
  height: 100%;
  will-change: transform;
}

@keyframes indeterminate {
  from {
    transform: translate3d(calc(-1 * (100% + var(--sh-progress-indeterminate-bar-length)) / 2), 0, 0);
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes radial-indeterminate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`;