// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-workline-group.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  display: flex;
  border-radius: 5rem;
  background: rgba(var(--ui-1), var(--opacity-5));
  box-sizing: border-box;
  align-items: center;
  box-shadow: inset 0 0 0 0.0625rem rgba(var(--ui-1), var(--opacity-5));
  margin-left: var(--sh-workline-item-margin);
}

:host > ::slotted(:last-child[type=information]) {
  margin-right: 1rem;
}

:host > ::slotted(*[type=information]),
:host > ::slotted(*:not(sh-workline-item:first-of-type):not([slot=badge])) {
  margin-left: var(--sh-workline-item-margin);
}

:host > ::slotted(div) {
  margin-left: 0rem !important;
}

:host > ::slotted(.sentinel:first-child) {
  transform: translateX(-1rem);
}

:host > ::slotted(sh-workline-item:not([active])) {
  background: transparent;
}

:host > ::slotted(sh-workline-item[current]) {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host > ::slotted(sh-workline-item.leftfix),
:host > ::slotted(sh-workline-item.rightfix),
:host > ::slotted(sh-workline-item.leftfix[current]),
:host > ::slotted(sh-workline-item.rightfix[current]),
:host > ::slotted(sh-workline-item.rightfix:not(:first-child):not([slot=badge])),
:host > ::slotted(sh-workline-item.leftfix:not(:first-child):not([slot=badge])),
:host > ::slotted(sh-workline-item.leftfix[type=information]),
:host > ::slotted(sh-workline-item.rightfix[type=information]) {
  z-index: 3;
  background-color: rgba(var(--ui-5), var(--opacity-1));
  box-shadow: var(--shadow-overlay);
  /* margin-right: 0; */
}

:host > ::slotted(*.rightfix) {
  background-color: rgba(var(--ui-5), var(--opacity-1));
  box-shadow: var(--shadow-overlay);
}

:host > ::slotted(*.rightfix[active]),
:host > ::slotted(*.leftfix[active]),
:host > ::slotted(sh-workline-item.leftfix[active]),
:host > ::slotted(sh-workline-item.rightfix[active]),
:host > ::slotted(sh-workline-item.rightfix:not(:first-child):not([slot=badge])[active]),
:host > ::slotted(sh-workline-item.leftfix:not(:first-child):not([slot=badge])[active]),
:host > ::slotted(sh-workline-item.leftfix[type=information][active]),
:host > ::slotted(sh-workline-item.rightfix[type=information][active]) {
  box-shadow: var(--shadow-overlay), inset 0rem 0rem 0rem 0.0625rem rgba(var(--ui-1), var(--opacity-1));
}

:host > ::slotted(*.leftfix) {
  background-color: rgba(var(--ui-5), var(--opacity-1));
  box-shadow: var(--shadow-overlay);
}`;