// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import tagStyles from './sh-tag.lit.scss.js';
/**
 * @fires closed - Dispatched when clicking on the "close" icon.
 */
let SHTag = class SHTag extends LitElement {
  constructor() {
    super(...arguments);
    /** Default Label. Defines the label shown on the tag. */
    this.label = 'Label';
    /** Defines whether the tag is selectable or not. */
    this.selectable = false;
    /** Default false.Defines whether a tag can be removed or not. Adds a close icon next to the tag. */
    this.removable = false;
    /** Default false. If set to `true`, will update the styles and disable pointer events. */
    this.disabled = false;
    /** Default false. Adds condensed styles if set to true. */
    this.condensed = false;
  }
  static get styles() {
    return [sharedStyles, tagStyles];
  }
  render() {
    return html`
      <div
        class="tag-wrapper"
        ?disabled="${this.disabled}"
        @click="${this.onClickTag}"
        style="--color:${this.color ? this.color : 'var(--ui-1)'}"
      >
        <span class="tag-label">${this.label}</span>
        ${this.removable
          ? html`<sh-icon
              id="clear"
              button=""
              icon="cancel"
              size="xs"
              @click="${this.onRemoveTag}"
              tabindex="${!this.disabled ? '0' : '-1'}"
            ></sh-icon>`
          : ''}
      </div>
    `;
  }
  onRemoveTag() {
    //custom event for close tag
    if (!this.disabled) {
      dispatchCustomEvent(this, 'closed');
    }
  }
  onClickTag() {
    if (this.selectable) {
      this.active = !this.active;
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHTag.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTag.prototype, 'selectable', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTag.prototype, 'removable', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTag.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTag.prototype, 'disabled', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTag.prototype, 'condensed', void 0);
__decorate([property({ type: String, reflect: true })], SHTag.prototype, 'color', void 0);
SHTag = __decorate([customElement('sh-tag')], SHTag);
export { SHTag };
