// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/**
 * ### prependZero()
 * ___
 * Function that prepends zero to a string if it's value is less than 10
 * ___
 * #### How to use this function ?
 * Import like this in the component:
 *
 * ```js
 * import { prependZero } from './utils/number-formatter'
 * ```
 * Then use the function like the following:
 *
 * Suppose you want to convert 8 to 08
 * For that, you can do as following:
 *
 * ```js
 * const value = prependZero(8);
 * ```
 *
 * @param {String | number} number The rem value in number
 * @returns value in string
 */

export const prependZero = (number: string | number) => {
  const maxDigits = 2;
  const zerosReq = maxDigits - number.toString().length;
  const requiredValue = `${'0'.repeat(zerosReq)}${number}`;
  return requiredValue;
};
