// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';
import { LegacyPropertyChangedEvent } from './events';

/**
 * Sole purpose of this mixin is to deprecate raw dispatchEvent method
 * Not absolutely neccessary but might be a good opertunity to introduce a shared base class
 * SpectrumElement does this as well:
 * https://github.com/adobe/spectrum-web-components/blob/794263ed93fb498a8f4c9f22a02408c6ebbf5170/tools/base/src/Base.ts#L188
 * https://github.com/search?q=repo%3Aadobe%2Fspectrum-web-components%20spectrumElement&type=code
 */
export class ShuiLitElement extends LitElement {
  /** @deprecated Use \@event() property decorator instead. */
  override dispatchEvent(event: CustomEvent<unknown>): boolean {
    // eslint-disable-next-line shui/check-events
    return super.dispatchEvent(event);
  }
}

export type EventEmitter<TEvent extends Event> = {
  /**
   * emits either
   * 1) raw custom event (in case instance of CustomEvent is passed)
   * 2) automatically created custom event with the given detail + composed: true, bubbles: true (in case detail is passed)
   */
  emit: (detailOrCustomEvent: TEvent) => void;
};

/**
 * Options for \@event() decorator
 */
export interface EventOptions<Type extends string> {
  // name of event to be emitted
  type: Type; // default kebabized property key
  init?: CustomEventInit; // default { composed: true, bubbles: true }
}

/**
 * Event decorator for components and mixins
 * Will emit events with kebabized property key as event name.
 *
 * @example
 * ```typescript
 *   // declare event emitter, with detail type string (note the '!' definite assignment assertion)
 *   ＠event() myEvent!: EventEmitter<MyEvent>;
 *   // ..
 *   // emit CustomEvent 'my-event' with 'message' as detail
 *   this.myEvent.emit(new MyEvent('message'));
 * ```
 * @param options - event options
 */
export function event<
  TEvent extends Event & { type: Type },
  Type extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(): any {
  return (): TypedPropertyDescriptor<EventEmitter<TEvent>> => {
    return {
      configurable: false,
      enumerable: true,
      get: function (this: HTMLElement): EventEmitter<TEvent> {
        return {
          emit: (event: TEvent) => {
            // eslint-disable-next-line shui/check-events
            this.dispatchEvent(event);
          },
        };
      },
    };
  };
}

/**
 * Replacement for our helper dispatchAttributeChangedEvent.
 * Dispatches attribute changed events for a list of properties
 *
 * Usage is identical, except: `listOfProperties` must be a tuple.
 *
 * @example
 * ```typescript
 *  // commponent MUST have 'icon' property and 'iconChangedEvent' EventEmitter<void>
 *  const listOfProperties = ['icon', 'label', ... ] as const; // <- as const is important!
 *  dispatchAttributeChangedEventTypesafe(this, changedProperties, listOfProperties);
 * ```
 */
export function dispatchAttributeChangedEventTypesafe<
  Component extends HTMLElement,
  Events extends readonly (keyof Component & string)[],
>(component: Component, changedProperties: Map<string, unknown>, listOfProperties: Events) {
  changedProperties.forEach((_value, changedProperty) => {
    if (listOfProperties.includes(changedProperty as keyof Component & string)) {
      const emitterName = (changedProperty + 'ChangedEvent') as keyof Component;
      const emitter = component[emitterName] as unknown as EventEmitter<Event>;
      if (!emitter) {
        throw new Error(
          `EventEmitter '${String(emitterName)}' for property '${changedProperty}' not found`
        );
      } else {
        emitter.emit(new LegacyPropertyChangedEvent(changedProperty));
      }
    }
  });
}
