// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import inputTextStyles from './sh-input-text.lit.scss.js';
/**
 * @slot icon - Takes sh-icon as children. Will render icon in the sh-input-text.
 * @fires input-clicked - *hide Fired when input is clicked (if notifyInputClick is set to true)
 * @fires clear-clicked -  Fired when clear icon is clicked (if notifyClearClick is set to true)
 * @fires input-focused - *hide Fired when input is focused (if notifyInputFocus is set to true)
 * @fires hint-clicked - *hide Fired when hint is clicked (if notifyInputFocus is set to true)
 */
let SHInputText = class SHInputText extends LitElement {
  constructor() {
    super(...arguments);
    /** Defines the label of the input. If the value is empty, it will be shown as a placeholder. */
    this.label = 'label';
    /** If set to password, the value will be shown as dots. */
    this.type = 'text';
    /** Represents the current value of the input. Can be used for data binding and changes upon user input.  */
    this.value = '';
    /** If set to `true`, the first sh-input-text having this attribute as true, will have focus on page load. Behaviour similar to autofocus attribute of HTML5 text input.
     * **Note:** There is a known limitation in Safari on iOS where autofocus is not maintained after the keyboard appears. This behavior is consistent across iOS devices and versions as iOS doesn't support autofocus.*/
    this.isAutofocus = false;
    this.textareaLabelClick = this.handleTextareaLabelClick.bind(this);
  }
  static get styles() {
    return [inputTextStyles, sharedStyles];
  }
  render() {
    return html`
      <div
        class="outer-wrapper"
        tabindex="${this.disabled || this.readonly || !this.showTooltip ? -1 : 0}"
      >
        <div class="input-wrapper">
          <div id="shinputDiv" class="content-wrapper">
            ${!this.textarea
              ? html`
                  <input
                    type="${this.type}"
                    @input="${(e) => {
                      this.value = e.target.value;
                      dispatchCustomEvent(this, 'value-changed', null, false);
                    }}"
                    ?readonly="${this.readonly || this.inputReadonly}"
                    .value="${!this.value ? '' : this.value}"
                    maxlength="${ifDefined(this.maxlength)}"
                    id="input"
                    ?disabled="${this.disabled}"
                    ?autofocus="${this.isAutofocus}"
                    tabindex="${this.disabled ||
                    (this.value === '' && (this.showTooltip || this.readonly))
                      ? '-1'
                      : '0'}"
                    @click="${() => {
                      if (this.notifyInputClick) {
                        dispatchCustomEvent(this, 'input-clicked', null, false, false);
                      }
                    }}"
                    @focus="${() => {
                      if (this.notifyInputFocus) {
                        dispatchCustomEvent(this, 'input-focused', null, false, false);
                      }
                    }}"
                    @mouseover=${() => {
                      if (this.showTooltip) {
                        dispatchCustomEvent(this, 'input-hover', null, false, false);
                      }
                    }}
                  />
                `
              : nothing}
            ${this.textarea && this.condensed
              ? html`
                  <textarea
                    class="textarea"
                    .value="${!this.value ? '' : this.value}"
                    @input="${(e) => {
                      this.value = e.target.value;
                      dispatchCustomEvent(this, 'value-changed', null, false);
                    }}"
                    maxlength="${ifDefined(this.maxlength)}"
                    rows="${this.rows ? this.rows : '3'}"
                    ?readonly="${this.readonly}"
                    ?disabled="${this.disabled}"
                    ?autofocus="${this.isAutofocus}"
                    tabindex="${this.disabled || (this.value === '' && this.readonly) ? '-1' : '0'}"
                  >
${this.value}</textarea
                  >
                `
              : nothing}

            <div class="input-text-label">
              ${this.label
                ? html`
                    <div
                      class="label-wrapper"
                      @mouseover="${() => {
                        if (this.showTooltip) {
                          dispatchCustomEvent(this, 'label-hover', null, false, false);
                        }
                      }}"
                    >
                      ${this.label}
                    </div>
                  `
                : nothing}
              ${this.mandatory ? html` <span id="mandatory"> *</span> ` : ''}
            </div>
          </div>
          <div class="icon-wrapper">
            ${this.value && !this.noClear && !this.disabled && !this.readonly
              ? html`
                  <sh-icon
                    size="s"
                    button
                    icon="cancel"
                    id="clear"
                    @click="${() => {
                      this.clearField();
                      dispatchCustomEvent(this, 'clear-clicked', null, false, true);
                    }}"
                  ></sh-icon>
                `
              : nothing}
            ${this.success || this.error
              ? html`
                  <sh-icon
                    icon="${this.error ? 'error' : 'success'}"
                    size="s"
                    ?disabled="${this.disabled}"
                  ></sh-icon>
                `
              : nothing}
            <slot name="icon" id="icon"></slot>
          </div>
        </div>
        ${this.textarea && !this.condensed
          ? html` <textarea
              class="textarea"
              .value="${!this.value ? '' : this.value}"
              @input="${(e) => {
                this.value = e.target.value;
                dispatchCustomEvent(this, 'value-changed', null, false);
              }}"
              maxlength="${ifDefined(this.maxlength)}"
              rows="${this.rows ? this.rows : '3'}"
              ?readonly="${this.readonly}"
              ?disabled="${this.disabled}"
              ?autofocus="${this.isAutofocus}"
              tabindex="${this.disabled || (this.value === '' && this.readonly) ? '-1' : '0'}"
              @focus=${() => {
                if (!this.readonly) {
                  this.setAttribute('textarea-focused', '');
                }
              }}
              @blur=${() => {
                this.removeAttribute('textarea-focused');
              }}
            >
${this.value}</textarea
            >`
          : nothing}
      </div>
      ${(this.hint && !this.errorMessage) || (this.hint && this.errorMessage && !this.error)
        ? html`
            <sh-text
              size="body-2"
              class="helper-text hint"
              color="secondary"
              @click="${() => {
                if (this.notifyInputClick) {
                  dispatchCustomEvent(this, 'hint-clicked', null, false, false);
                }
              }}"
              >${this.hint}</sh-text
            >
          `
        : nothing}
      ${this.error && this.errorMessage
        ? html`
            <sh-text
              size="body-2"
              class="helper-text error-message"
              color="secondary"
              @click="${() => {
                if (this.notifyInputClick) {
                  dispatchCustomEvent(this, 'hint-clicked', null, false, false);
                }
              }}"
              >${this.errorMessage}</sh-text
            >
          `
        : nothing}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'textbox');
    }
    if (this.textarea) {
      document.body.addEventListener('click', this.textareaLabelClick);
    }
    this.addTabindex();
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.textareaLabelClick);
    super.disconnectedCallback();
  }
  update(changedProperties) {
    if (changedProperties.has('value')) {
      this.handleValueChanged();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    const listOfProperties = [
      'label',
      'textarea',
      'resizable',
      'type',
      'condensed',
      'error',
      'readonly',
      'disabled',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  focus() {
    var _a, _b, _c, _d;
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.focus();
    }
  }
  defocus() {
    var _a, _b;
    (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('input')) ===
      null || _b === void 0
      ? void 0
      : _b.blur();
  }
  select() {
    var _a, _b, _c, _d;
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.select();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.select();
    }
  }
  addTabindex() {
    const iconLength = this.querySelectorAll('sh-icon').length;
    if (!this.disabled && iconLength > 0) {
      for (let i = 0; i < iconLength; i++) {
        this.querySelectorAll('sh-icon')[i].setAttribute('tabindex', '0');
      }
    }
  }
  handleTextareaLabelClick(e) {
    var _a, _b;
    if (e.target === this && this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    }
  }
  handleValueChanged() {
    if (this.value) {
      this.classList.remove('empty');
    } else {
      this.classList.add('empty');
    }
  }
  clearField() {
    var _a, _b, _c, _d;
    this.value = '';
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.focus();
    }
    dispatchCustomEvent(this, 'value-changed', null, false);
  }
};
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'type', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'value', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'textarea', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputText.prototype, 'rows', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'resizable',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'mandatory',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'disabled', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'maxlength', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'error', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'success', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'safety', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'hint', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHInputText.prototype,
  'errorMessage',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'isAutofocus',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHInputText.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHInputText.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-tooltip' })],
  SHInputText.prototype,
  'showTooltip',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-outline' })],
  SHInputText.prototype,
  'showOutline',
  void 0
);
__decorate([state()], SHInputText.prototype, 'notifyInputClick', void 0);
__decorate([state()], SHInputText.prototype, 'inputReadonly', void 0);
__decorate([state()], SHInputText.prototype, 'notifyInputFocus', void 0);
SHInputText = __decorate([customElement('sh-input-text')], SHInputText);
export { SHInputText };
