// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-table-cell.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-table-cell-wrapper-margin=0.5rem 0.75rem] - margin of cell wrapper
   */
  --sh-table-cell-wrapper-margin: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-table-cell-line-height=2rem] - line-height of cell wrapper
   */
  --sh-table-cell-line-height: 2rem;
  /* 
   * @cssprop [--sh-table-cell-mobile-wrapper-margin=0.5rem] - margin of mobile cell wrapper
   */
  --sh-table-cell-mobile-wrapper-margin: 0.5rem;
  /* 
   * @cssprop [--sh-table-cell-condensed-wrapper-margin=0.25rem 0.75rem] - margin of cell wrapper
   */
  --sh-table-cell-condensed-wrapper-margin: 0.25rem 0.75rem;
  /* 
   * @cssprop [--sh-table-cell-first-nested-padding=3.5rem] - first-nested-padding
   */
  --sh-table-cell-first-nested-padding: 3.5rem;
  /* 
   * @cssprop [--sh-table-cell-first-nested-padding-checkbox=4.5rem] - first-nested-padding
   */
  --sh-table-cell-first-nested-padding-checkbox: 4.5rem;
  /* 
   * @cssprop [--sh-table-cell-nested-padding=2.75rem] - nested-padding
   */
  --sh-table-cell-nested-padding: 2.75rem;
  /* 
   * @cssprop [--sh-table-cell-nested-padding-checkbox=2.5rem] - nested-padding
   */
  --sh-table-cell-nested-padding-checkbox: 2.5rem;
  /* 
   * @cssprop [--sh-table-cell-icon-min-width=3rem] - icon icon-min-width
   */
  --sh-table-cell-icon-min-width: 3rem;
  /* 
   * @cssprop [--sh-table-cell-icon-cell-min-width=3.5rem] - min-width of the icon cell wrapper
   */
  --sh-table-cell-icon-cell-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-table-cell-icon-checkbox-cell-min-width=3.5rem] - icon checkbox -min-width
   */
  --sh-table-cell-icon-checkbox-cell-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-table-cell-padding-left=2.5rem] - padding left checkbox
   */
  --sh-table-cell-padding-left: 2.5rem;
  /* 
   * @cssprop [--sh-table-cell-inset=2.5rem] - inset left of checkbox
   */
  --sh-table-cell-inset: 2.5rem;
  /* 
   * @cssprop [--sh-table-cell-checkbox-margin-top=0.2rem] - checkbox margin top
   */
  --sh-table-cell-checkbox-margin-top: 0.2rem;
}

:host {
  display: inline-flex;
  box-sizing: border-box;
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  align-items: center;
}

* {
  box-sizing: border-box;
}

.cell-wrapper {
  display: flex;
  flex-direction: column;
  font: var(--body-1);
  margin: var(--sh-table-cell-wrapper-margin);
  line-height: var(--sh-table-cell-line-height);
  color: var(--text-primary);
  word-wrap: break-word;
  cursor: default;
  width: calc(100% - 1.5rem);
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  font-weight: normal;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
}

/* number cells */
:host([number]) .cell-wrapper {
  text-align: end;
  word-break: break-word;
}

/* icon cells */
:host([icon]),
:host([checkbox]) {
  flex: 0;
  min-width: var(--sh-table-cell-icon-checkbox-cell-min-width) !important;
}

:host([checkbox]) {
  margin-top: var(--sh-table-cell-checkbox-margin-top);
}

/* medium screens */
@media only screen and (max-width: 64.0625rem) {
  :host([icon]),
  :host([checkbox]) {
    min-width: var(--sh-table-cell-icon-cell-min-width) !important;
  }
  .cell-wrapper {
    margin: var(--sh-table-cell-mobile-wrapper-margin);
    width: calc(100% - 1rem);
  }
}
/* condensed */
:host([condensed]) .cell-wrapper {
  margin: var(--sh-table-cell-condensed-wrapper-margin);
}

/* first nested cell */
:host(.first-nested-cell) {
  padding-left: var(--sh-table-cell-first-nested-padding);
}

:host(.first-nested-cell.checkbox) {
  padding-left: calc(var(--sh-table-cell-first-nested-padding) + 1.5rem);
}

/* nested cell */
:host(.nested-cell) {
  padding-left: calc(var(--sh-table-cell-first-nested-padding) + var(--sh-table-cell-nested-padding) * var(--nesting-level));
}

:host(.nested-cell.checkbox) {
  padding-left: calc(var(--sh-table-cell-first-nested-padding-checkbox) + var(--sh-table-cell-inset) * var(--nesting-level));
}

:host(.checkbox-cell) {
  position: absolute;
  left: calc(var(--sh-table-cell-nested-padding-checkbox) * var(--nesting-level));
}

:host(.non-nested-cell) {
  position: absolute;
  min-width: 2.5rem;
  left: 0rem;
}

:host(.checkbox) {
  min-width: 2.5rem;
  padding-left: var(--sh-table-cell-padding-left);
}`;