// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';
export * from '../sh-access-bar/src/index.js';
export * from '../sh-accordion/src/index.js';
export * from '../sh-badge/src/index.js';
export * from '../sh-breadcrumbs/src/index.js';
export * from '../sh-button/src/index.js';
export * from '../sh-card/src/index.js';
export * from '../sh-carousel/src/index.js';
export * from '../sh-chart/src/index.js';
export * from '../sh-checkbox/src/index.js';
export * from '../sh-datepicker/src/index.js';
export * from '../sh-divider/src/index.js';
export * from '../sh-drawer/src/index.js';
export * from '../sh-dropdown/src/index.js';
export * from '../sh-empty-state/src/index.js';
export * from '../sh-grid/src/index.js';
export * from '../sh-home-card/src/index.js';
export * from '../sh-home-screen/src/index.js';
export * from '../sh-icon/src/index.js';
export * from '../sh-image-segment/src/index.js';
export * from '../sh-input-number/src/index.js';
export * from '../sh-input-text/src/index.js';
export * from '../sh-list/src/index.js';
export * from '../sh-media-player/src/index.js';
export * from '../sh-menu-group/src/index.js';
export * from '../sh-menu-item/src/index.js';
export * from '../sh-modal/src/index.js';
export * from '../sh-nav-bar/src/index.js';
export * from '../sh-notifications/src/index.js';
export * from '../sh-overlay/src/index.js';
export * from '../sh-page/src/index.js';
export * from '../sh-pagination/src/index.js';
export * from '../sh-pane/src/index.js';
export * from '../sh-patient-illustration/src/index.js';
export * from '../sh-popover/src/index.js';
export * from '../sh-progress/src/index.js';
export * from '../sh-radio-button/src/index.js';
export * from '../sh-risk-score/src/index.js';
export * from '../sh-scrollpicker/src/index.js';
export * from '../sh-search/src/index.js';
export * from '../sh-slider/src/index.js';
export * from '../sh-spinner/src/index.js';
export * from '../sh-splash-screen/src/index.js';
export * from '../sh-split-button/src/index.js';
export * from '../sh-stepper/src/index.js';
export * from '../sh-switch/src/index.js';
export * from '../sh-table/src/index.js';
export * from '../sh-tabs/src/index.js';
export * from '../sh-tag/src/index.js';
export * from '../sh-text/src/index.js';
export * from '../sh-thumbnail/src/index.js';
export * from '../sh-timepicker/src/index.js';
export * from '../sh-toggle/src/index.js';
export * from '../sh-tool/src/index.js';
export * from '../sh-tooltip/src/index.js';
export * from '../sh-user-identifier/src/index.js';
export * from '../sh-value-indicator/src/index.js';
export * from '../sh-workline/src/index.js';
export * from '../sh-wrapper/src/index.js';
export * from '../utils/index.js';

console.warn(`The bundles at @shui/core/shui/shui(.min).js will be moved soon.`);
console.warn(`If you KNOW you need all components, use this: '@shui/core';`);

// Turn off update warnings on all LitElements
LitElement.disableWarning?.('change-in-update');