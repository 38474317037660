// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-toggle.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-toggle-height=1.25rem] - toggle height
   */
  --sh-toggle-height: 1.25rem;
  /* 
   * @cssprop [--sh-toggle-border-radius=0.625rem] - border radius
   */
  --sh-toggle-border-radius: 0.625rem;
  /* 
   * @cssprop [--sh-toggle-border-width=0.0625rem] - border width
   */
  --sh-toggle-border-width: 0.0625rem;
  /* 
   * @cssprop [--sh-toggle-circle-dimension=1rem] - toggle circle width and height
   */
  --sh-toggle-circle-dimension: 1rem;
  /* 
   * @cssprop [--sh-toggle-width=2.25rem] - toggle width
   */
  --sh-toggle-width: 2.25rem;
  /* 
   * @cssprop [--sh-toggle-margin=0.125rem] - toggle margin
   */
  --sh-toggle-margin: 0.125rem;
  /* 
   * @cssprop [--sh-toggle-circle-top=0.0625rem] - toggle circle top
   */
  --sh-toggle-circle-top: 0.0625rem;
  /* 
   * @cssprop [--sh-toggle-circle-left=0.0625rem] - toggle circle left
   */
  --sh-toggle-circle-left: 0.0625rem;
  /* 
   * @cssprop [--sh-toggle-active-left=1.0625rem] - toggle circle left
   */
  --sh-toggle-active-left: 1.0625rem;
  /* 
   * @cssprop [--sh-toggle-line-height=1.5rem] - toggle line height
   */
  --sh-toggle-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-toggle-label-line-height=1.25rem] - label line height
   */
  --sh-toggle-label-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-toggle-label-margin=0.125rem 0] - label margin
   */
  --sh-toggle-label-margin: 0.125rem 0;
  /* 
   * @cssprop [--sh-toggle-max-height=2.5rem] - max height of label
   */
  --sh-toggle-max-height: 2.5rem;
  /* 
   * @cssprop [--sh-toggle-gap=0.5rem] - toggle gap
   */
  --sh-toggle-gap: 0.5rem;
  /* 
   * @cssprop [--sh-toggle-label-padding=0.125rem] - label padding
   */
  --sh-toggle-label-padding: 0.125rem;
}

:host {
  display: flex;
  gap: var(--sh-toggle-gap);
  flex-direction: row;
  width: 100%;
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  font: var(--body-1);
  color: var(--text-primary);
  line-height: var(--sh-toggle-line-height);
  outline: 0;
  cursor: pointer;
  height: fit-content;
  position: relative;
}

.toggle {
  height: var(--sh-toggle-height);
  min-width: var(--sh-toggle-width);
  border-radius: var(--sh-toggle-border-radius);
  margin: var(--sh-toggle-margin);
  border: var(--sh-toggle-border-width) solid var(--sh-toggle-stroke-idle);
  box-sizing: border-box;
  background-color: var(--sh-toggle-bg-idle);
  position: relative;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
}

:host([label=""]) .toggle,
:host(:not([label])) .toggle {
  margin-right: 0;
}

:host([label=""]),
:host(:not([label])) {
  width: fit-content;
  width: -moz-fit-content;
}

.toggle-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: calc(100% - var(--sh-toggle-width));
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--sh-toggle-label-line-height);
  margin: var(--sh-toggle-label-margin);
  max-height: var(--sh-toggle-max-height);
  word-break: break-word;
}

.toggle::before {
  position: absolute;
  left: var(--sh-toggle-circle-left);
  top: var(--sh-toggle-circle-top);
  height: var(--sh-toggle-circle-dimension);
  width: var(--sh-toggle-circle-dimension);
  content: "";
  background: var(--sh-toggle-circle-subdued);
  border-radius: 50%;
  transition: left 0.15s ease-in-out;
  box-shadow: var(--shadow-s-strong);
}

:host([active]) .toggle {
  background-color: rgba(var(--ui-2), var(--opacity-1000));
  border-color: transparent;
}

:host([active]) .toggle::before {
  background: var(--sh-toggle-circle);
  left: var(--sh-toggle-active-left);
}

:host([active][disabled]) .toggle {
  opacity: 0.2;
}

:host([disabled]) .toggle {
  opacity: 0.3;
}

:host([active][neutral]) .toggle {
  background-color: var(--sh-toggle-bg-checked-neutral-idle);
}

:host([disabled]) {
  cursor: default;
  pointer-events: none;
  color: var(--text-disabled);
}

:host([disabled]:not([active])) .toggle {
  border-color: rgba(var(--ui-1), var(--opacity-100));
}

:host([disabled]:not([active])) .toggle::before {
  background: var(--sh-toggle-circle);
}

:host([disabled][active][neutral]) .toggle {
  background: var(--sh-toggle-bg-checked-neutral-idle);
}

/* hover */
:host(:not(.touch-device):not([active]):hover) .toggle {
  border-color: var(--sh-toggle-stroke-hover);
  background-color: var(--sh-toggle-bg-hover);
}

:host(:not(.touch-device)[active]:hover) .toggle {
  background: rgba(var(--ui-3), var(--opacity-1000));
}

:host(:not(.touch-device)[active][neutral]:hover) .toggle {
  background: var(--sh-toggle-bg-checked-neutral-hover);
}

:host(:not(.touch-device):not([active]):hover) .toggle::before {
  background: var(--sh-toggle-circle);
}

/* focus */
.item-focused {
  outline: var(--focus-outline);
}

/* Pressed*/
:host(:not(.touch-device):not([active]):active) .toggle {
  background-color: var(--sh-toggle-bg-pressed);
}

:host(:not(.touch-device)[active][neutral]:active) .toggle {
  background: var(--sh-toggle-bg-checked-neutral-pressed);
}

:host(:not(.touch-device)[active]:active) .toggle {
  background: rgba(var(--ui-2), var(--opacity-1000));
}`;