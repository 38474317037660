// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from "tslib";
import { property } from 'lit/decorators.js';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import { convertToRem } from '../../utils/pixel-to-rem-convertor.js';
let marginPadding = calculatedBaseFontSize;
export const WorklineFloatPositionerMixin = (superClass) => {
    class WorklineFloatPositionerMixin extends superClass {
        constructor() {
            super(...arguments);
            /** floatPositions*/
            this.floatPositions = {
                leftFloatPosLists: [],
                rightFloatPosLists: [],
                leftFloatMarginLists: [],
                rightFloatMarginLists: [],
            };
        }
        /**
         *function for to calculate and get postion to start and stop float, margin
         */
        calculateFloatPositions() {
            var _a, _b, _c, _d;
            marginPadding =
                parseFloat(getComputedStyle(this).getPropertyValue('--sh-workline-item-margin')) * 16;
            const BodyChilds = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#body')) === null || _b === void 0 ? void 0 : _b.assignedNodes({
                flatten: true,
            });
            if (!BodyChilds) {
                return;
            }
            const floatElements = this.querySelectorAll('sh-workline-item[float]');
            if (floatElements.length == 0) {
                return;
            }
            const allChildren = [];
            for (const bodyChild of BodyChilds) {
                if (bodyChild.tagName === 'SH-WORKLINE-GROUP') {
                    const worklineItems = bodyChild.childNodes;
                    for (const worklineItem of worklineItems) {
                        if (worklineItem.tagName === 'SH-WORKLINE-ITEM') {
                            allChildren.push(worklineItem);
                        }
                    }
                }
                else if (bodyChild.tagName === 'SH-WORKLINE-ITEM') {
                    allChildren.push(bodyChild);
                }
            }
            const workline = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.workline-wrapper');
            const total = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('.total-wrapper');
            const worklineLeft = workline.getBoundingClientRect().x - total.getBoundingClientRect().x;
            const worklineRight = worklineLeft + workline.getBoundingClientRect().width;
            const leftFloatMarginLists = this.getLeftPositionAfterfloat(floatElements, worklineLeft);
            const leftFloatPosLists = this.getLeftPositionTostartfloat(allChildren, floatElements);
            const fixRightList = this.getRightFixedElement(allChildren, floatElements);
            const rightFloatPosLists = this.getRightPositionTostartfloat(floatElements, fixRightList, worklineLeft, worklineRight);
            let rightFloatMarginLists = [];
            const marginRightFloat = convertToRem(worklineRight -
                floatElements[floatElements.length - 1].getBoundingClientRect().width -
                marginPadding -
                marginPadding);
            rightFloatMarginLists = this.rightPositionAfterfloat(floatElements, marginRightFloat);
            this.floatPositions = {
                leftFloatPosLists: leftFloatPosLists,
                rightFloatPosLists: rightFloatPosLists,
                leftFloatMarginLists: leftFloatMarginLists,
                rightFloatMarginLists: rightFloatMarginLists,
            };
        }
        /**
         * On scroll, start/stop fix
         */
        fixFloatItems() {
            var _a, _b, _c, _d, _e, _f;
            const floatElements = this.querySelectorAll('sh-workline-item[float]');
            if (floatElements.length == 0)
                return;
            const BodyChilds = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#body')) === null || _b === void 0 ? void 0 : _b.assignedNodes({
                flatten: true,
            });
            if (!BodyChilds) {
                return;
            }
            const scrollelement = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.workline-wrapper');
            for (let i = 0; i < floatElements.length; i++) {
                const fixedEl = floatElements[i];
                const refLeft = this.floatPositions.leftFloatPosLists[i];
                const refRight = this.floatPositions.rightFloatPosLists[i];
                if (convertToRem(scrollelement.scrollLeft) > refLeft) {
                    this.leftFloatFix(fixedEl, this.floatPositions.leftFloatMarginLists, i);
                    this.updateFloatwrapper('leftfix', (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('.left-float'));
                }
                else if (convertToRem(scrollelement.scrollLeft) < refRight) {
                    this.rightFloatFix(fixedEl, (_e = this.floatPositions) === null || _e === void 0 ? void 0 : _e.rightFloatMarginLists, i);
                    this.updateFloatwrapper('rightfix', (_f = this.shadowRoot) === null || _f === void 0 ? void 0 : _f.querySelector('.right-float'));
                }
                else {
                    this.removeFloatFix(fixedEl);
                }
            }
        }
        rightFloatFix(fixedElement, rightPosList, index) {
            var _a;
            let elementWidth = 0;
            //last element add extra margin
            const IsLastElement = ((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#body'))
                .assignedNodes({
                flatten: true,
            })
                .filter((x) => x.tagName == 'SH-WORKLINE-ITEM')
                .reverse()[0] === fixedElement;
            if (IsLastElement) {
                elementWidth = fixedElement.getBoundingClientRect().width + marginPadding;
            }
            else {
                elementWidth = fixedElement.getBoundingClientRect().width;
            }
            fixedElement.previousElementSibling.style.minWidth =
                convertToRem(elementWidth) + 'rem';
            fixedElement.previousElementSibling.style.marginLeft =
                fixedElement.style.marginLeft;
            fixedElement.previousElementSibling.style.visibility = 'visible';
            fixedElement.classList.add('rightfix');
            fixedElement.style.left = rightPosList[index] + 'rem';
        }
        leftFloatFix(fixedElement, leftFloatMarginLists, index) {
            fixedElement.previousElementSibling.style.marginLeft = '1rem';
            fixedElement.previousElementSibling.style.minWidth =
                convertToRem(fixedElement.getBoundingClientRect().width) + 'rem';
            fixedElement.previousElementSibling.style.visibility = 'visible';
            fixedElement.classList.add('leftfix');
            fixedElement.style.left = leftFloatMarginLists[index] + 'rem';
        }
        updateFloatwrapper(fix, floatwrapper) {
            let wrapperWidth = 0;
            this.querySelectorAll('sh-workline-item[float]').forEach((element) => {
                if (element.classList.contains(fix)) {
                    wrapperWidth += element.getBoundingClientRect().width + marginPadding;
                }
            });
            floatwrapper.style.zIndex = '2';
            floatwrapper.style.minWidth = convertToRem(wrapperWidth) + 'rem';
            if (floatwrapper.style.minWidth === '0rem') {
                floatwrapper.style.display = 'none';
            }
            else {
                floatwrapper.style.display = 'flex';
            }
        }
        removeFloatFix(fixedElement) {
            var _a, _b;
            if (fixedElement.classList.contains('leftfix')) {
                fixedElement.classList.remove('leftfix');
                fixedElement.style.left = 'unset';
                fixedElement.previousElementSibling.style.minWidth = '0rem';
                fixedElement.previousElementSibling.style.marginLeft = '0rem';
                fixedElement.previousElementSibling.style.visibility = 'collapse';
                this.updateFloatwrapper('leftfix', (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.left-float'));
                return;
            }
            else if (fixedElement.classList.contains('rightfix')) {
                fixedElement.classList.remove('rightfix');
                fixedElement.style.left = 'unset';
                fixedElement.previousElementSibling.style.minWidth = '0rem';
                fixedElement.previousElementSibling.style.marginLeft = '0rem';
                fixedElement.previousElementSibling.style.visibility = 'collapse';
                this.updateFloatwrapper('rightfix', (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.right-float'));
                return;
            }
            return;
        }
        getLeftPositionAfterfloat(floatElements, worklineLeft) {
            let leftPosSum = worklineLeft;
            const leftPosList = [];
            for (let i = 0; i < floatElements.length; i++) {
                const fixedEl = floatElements[i];
                if (i === 0) {
                    leftPosList[0] = convertToRem(leftPosSum);
                }
                else {
                    leftPosList[i] = convertToRem(leftPosSum);
                }
                leftPosSum += fixedEl.getBoundingClientRect().width + marginPadding;
            }
            return leftPosList;
        }
        getLeftPositionTostartfloat(allChildren, floatElements) {
            let leftPosSum = 0;
            const refLeftList = [];
            for (let i = 0; i < floatElements.length; i++) {
                let fixLeft = 0;
                const fixedEl = floatElements[i];
                const fixedElIndex = Array.from(allChildren).indexOf(fixedEl);
                for (let i = 0; i < fixedElIndex; i++) {
                    fixLeft += allChildren[i].getBoundingClientRect().width + marginPadding;
                }
                if (i === 0) {
                    refLeftList[i] = convertToRem(fixLeft);
                }
                else {
                    refLeftList[i] = convertToRem(fixLeft - leftPosSum);
                }
                leftPosSum += fixedEl.getBoundingClientRect().width + marginPadding;
            }
            return refLeftList;
        }
        getRightFixedElement(allChildren, floatElements) {
            const fixRightList = [];
            for (let i = 0; i < floatElements.length; i++) {
                let fixLeft = 0;
                const fixedEl = floatElements[i];
                const fixedElIndex = Array.from(allChildren).indexOf(fixedEl);
                for (let i = 0; i < fixedElIndex; i++) {
                    fixLeft += allChildren[i].getBoundingClientRect().width + marginPadding;
                }
                if (i == 0) {
                    fixRightList[i] = convertToRem(fixLeft + fixedEl.getBoundingClientRect().width);
                }
                else {
                    fixRightList[i] = convertToRem(fixLeft + fixedEl.getBoundingClientRect().width + marginPadding);
                }
            }
            return fixRightList;
        }
        getRightPositionTostartfloat(floatElements, fixRightList, worklineLeft, worklineRight) {
            const refRightList = [];
            let rightPosSum = 0;
            for (let i = 0; i < floatElements.length; i++) {
                const j = floatElements.length - 1 - i;
                const fixedEl = floatElements[j];
                if (j === floatElements.length - 1) {
                    refRightList[j] =
                        fixRightList[j] +
                            convertToRem(worklineLeft) -
                            convertToRem(worklineRight) +
                            convertToRem(marginPadding + 0.0001); //flickering issue,so added
                }
                else {
                    refRightList[j] =
                        fixRightList[j] +
                            convertToRem(worklineLeft) -
                            convertToRem(worklineRight) +
                            convertToRem(rightPosSum) +
                            convertToRem(marginPadding);
                }
                rightPosSum += fixedEl.getBoundingClientRect().width + marginPadding;
            }
            return refRightList;
        }
        rightPositionAfterfloat(floatElements, rightPos) {
            const rightPosList = [];
            for (let i = 0; i < floatElements.length; i++) {
                if (floatElements[i].parentNode.tagName == 'SH-WORKLINE-GROUP') {
                    const worklineitems = floatElements[i].parentNode.querySelectorAll('sh-workline-item')[0];
                    if (floatElements[i] == worklineitems) {
                        rightPos += convertToRem(marginPadding);
                    }
                }
                const j = floatElements.length - 1 - i;
                if (j !== floatElements.length - 1) {
                    const fixedEl = floatElements[j];
                    rightPosList[j] =
                        Number(rightPosList[j + 1]) -
                            convertToRem(marginPadding) -
                            convertToRem(fixedEl.getBoundingClientRect().width);
                }
                else {
                    rightPosList[j] = rightPos;
                }
            }
            return rightPosList;
        }
    }
    __decorate([
        property({ type: Object, reflect: false })
        /**@ignore*/
    ], WorklineFloatPositionerMixin.prototype, "floatPositions", void 0);
    return WorklineFloatPositionerMixin;
};
