// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-wrapper.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  display: flex;
  flex-wrap: wrap;
}

/* direction */
:host([direction=row]) {
  flex-direction: row;
}

:host([direction=column]) {
  flex-direction: column;
}

/* spacing */
:host([spacing=none]) {
  width: 100%;
}

:host([spacing=xs]) {
  width: 100%;
  gap: 0.125rem;
}

:host([spacing=s]) {
  width: 100%;
  gap: 0.25rem;
}

:host([spacing=m]) {
  width: 100%;
  gap: 0.5rem;
}

:host([spacing=l]) {
  width: 100%;
  gap: 1rem;
}`;