// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-access-bar.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-access-bar-height=4.5rem] - access bar height
   */
  --sh-access-bar-height: 4.5rem;
  /* 
   * @cssprop [--sh-access-bar-padding=1.25rem 1rem] - access bar item padding
   */
  --sh-access-bar-padding: 1.25rem 1rem;
  /* 
   * @cssprop [--sh-access-bar-logo-height=2rem] - access bar logo height
   */
  --sh-access-bar-logo-height: 2rem;
  /* 
   * @cssprop [--sh-access-bar-logo-margin-left=0.5rem] - access bar logo margin left
   */
  --sh-access-bar-logo-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-access-bar-logo-margin-right=2.5rem] - access bar logo margin right
   */
  --sh-access-bar-logo-margin-right: 2.5rem;
  /* 
   * @cssprop [--sh-access-bar-label-line-height=1.5rem] - label line height
   */
  --sh-access-bar-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-access-bar-label-margin-right=2.5rem] - access bar label margin right
   */
  --sh-access-bar-label-margin-right: 2.5rem;
  /* 
   * @cssprop [--sh-access-bar-condensed-height=2.5rem] - height of condensed access bar
   */
  --sh-access-bar-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-access-bar-functions-slot-margin-left=1rem] - margin left of functions slot functions wrapper
   */
  --sh-access-bar-functions-slot-margin-left: 1rem;
  /* 
   * @cssprop [--sh-access-bar-condensed-padding=0.5rem 1rem] - access bar condensed height
   */
  --sh-access-bar-condensed-padding: 0.5rem 1rem;
  /* 
   * @cssprop [--sh-access-bar-condensed-logo-height=1.5rem] - height of the svg logo in condensed access bar
   */
  --sh-access-bar-condensed-logo-height: 1.5rem;
  /* 
   * @cssprop [--sh-access-bar-user-identifier-margin-left=1.5rem] - margin left of the user identifier in access bar
   */
  --sh-access-bar-user-identifier-margin-left: 1.5rem;
  /* 
   * @cssprop [--sh-access-bar-window-controls-margins=0.5rem -0.5rem 2.5rem 1rem] - Window control margins when native is set
   */
  --sh-access-bar-window-controls-margins: 0.5rem -0.5rem 2.5rem 1rem;
  /* 
   * @cssprop [--sh-access-bar-window-controls-height=1.5rem] - height of the window controls
   */
  --sh-access-bar-window-controls-height: 1.5rem;
  /* 
   * @cssprop [--sh-access-bar-mobile-wrapper-padding=0.75rem 1rem] - padding of the access-bar when mobile property is set
   */
  --sh-access-bar-mobile-wrapper-padding: 0.75rem 1rem;
  /* 
   * @cssprop [--sh-access-bar-mobile-height=3.5rem] - height of the access bar in mobile
   */
  --sh-access-bar-mobile-height: 3.5rem;
  /* 
   * @cssprop [--sh-access-bar-mobile-tabs-wrapper-margin=0rem] - margin of the tabs-wrapper in mobile access bar
   */
  --sh-access-bar-mobile-tabs-wrapper-margin: 0rem;
  /* 
   * @cssprop [--sh-access-bar-mobile-left-slot-margin-left=0.5rem] - margin-left of slot left mobile access bar
   */
  --sh-access-bar-mobile-left-slot-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-access-bar-mobile-right-slot-margin-right=0.5rem] - margin right of slot right mobile access bar
   */
  --sh-access-bar-mobile-right-slot-margin-right: 0.5rem;
}

:host {
  background: var(--base-0);
  transition: var(--transition-time) all ease-in-out;
  box-shadow: var(--shadow-navigation);
  display: block;
  padding: 0rem;
  z-index: 4;
  height: var(--sh-access-bar-height);
}

.access-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--sh-access-bar-padding);
  box-sizing: border-box;
  align-items: center;
}

.logo {
  height: var(--sh-access-bar-logo-height);
  margin-left: var(--sh-access-bar-logo-margin-left);
  margin-right: var(--sh-access-bar-logo-margin-right);
  background-size: cover;
  display: flex;
  align-items: center;
}

:host([cursor-change]) .logo {
  cursor: pointer;
}

.siemens {
  fill: var(--logo-2);
}

.healthineers {
  fill: var(--logo-1);
}

.label {
  margin-right: var(--sh-access-bar-label-margin-right);
  font: var(--header-1);
  color: var(--text-primary);
  line-height: var(--sh-access-bar-label-line-height);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.tabs-wrapper {
  margin: -1.25rem 0;
  display: flex;
  flex-direction: row;
}

:host([empty-info]) .tabs-wrapper {
  flex: 1;
}

.overflow-hidden {
  overflow: hidden;
}

:host([condensed]) .tabs-wrapper {
  margin: -0.5rem auto -0.5rem 0;
}

.functions-wrapper > ::slotted([slot=functions]) {
  margin-left: var(--sh-access-bar-functions-slot-margin-left);
}

.user-wrapper > ::slotted(sh-user-identifier[slot=user]) {
  margin-left: var(--sh-access-bar-user-identifier-margin-left);
}

.functions-wrapper {
  display: contents;
  align-items: center;
}

/* native (window controls) */
.window-controls {
  display: inline-flex;
  height: var(--sh-access-bar-window-controls-height);
  gap: 0.5rem;
  margin: var(--sh-access-bar-window-controls-margins);
}

:host([condensed]) .window-controls {
  align-self: center;
  margin-top: unset;
  margin-bottom: unset;
}

/* condensed */
:host([condensed]) {
  height: var(--sh-access-bar-condensed-height);
}

:host([condensed]) .sh-access-wrapper,
:host([condensed]) .access-wrapper {
  padding: var(--sh-access-bar-condensed-padding);
}

:host([condensed]) .logo svg {
  height: var(--sh-access-bar-condensed-logo-height);
}

:host([condensed]) .logo {
  align-self: center;
}

:host([condensed]) .user-wrapper {
  display: flex;
  align-items: center;
}

/* mobile */
:host([mobile]) {
  height: var(--sh-access-bar-mobile-height);
}

.access-wrapper > ::slotted([slot=left]),
.access-wrapper > ::slotted([slot=right]),
:host([mobile]) .logo,
:host([mobile]) .user-wrapper > ::slotted([slot=user]),
:host([mobile]) .functions-wrapper > ::slotted([slot=functions]),
:host([mobile]) .access-wrapper > .tabs-wrapper > ::slotted([slot=tabs]),
:host([mobile]) .access-wrapper > .info-wrapper > ::slotted([slot=info]) {
  display: none;
}

:host(:not([empty-info])) .info-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}

:host(:not([empty-info])) .info-wrapper > ::slotted([slot=info]) {
  margin-left: 1rem;
}

:host([mobile]) .tabs-wrapper {
  margin: var(--sh-access-bar-mobile-tabs-wrapper-margin);
}

:host([mobile]) .access-wrapper > ::slotted([slot=left]),
:host([mobile]) .access-wrapper > ::slotted([slot=right]) {
  display: flex;
}

:host([mobile]) .access-wrapper > ::slotted(*[slot=left]:not(:first-child)) {
  margin-left: var(--sh-access-bar-mobile-left-slot-margin-left);
}

:host([mobile]) .access-wrapper > slot[name=right]::slotted(*:not(:last-child)) {
  margin-right: var(--sh-access-bar-mobile-right-slot-margin-right);
}

:host([mobile]) .access-wrapper {
  justify-content: space-between;
  padding: var(--sh-access-bar-mobile-wrapper-padding);
  align-items: center;
}

:host([mobile]) .label {
  margin-right: 0;
  z-index: 5;
  max-width: calc(100% - 13rem);
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
}

/* slots */
slot[name=right],
slot[name=left] {
  display: flex;
}

slot[name=left] {
  margin-right: 1rem;
}

slot[name=right] {
  margin-left: 1rem;
}

/* 3rd party logo or any svg logo */
.logo > img,
.logo > svg {
  height: 100%;
}

:host([logo]) .logo {
  width: auto;
}`;