// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { resizeMixin } from '../../utils/resize-mixin.js';
import { windowSize } from '../../utils/window-size.js';
import notificationStyles from './sh-notification.lit.scss.js';
/**
 * @prop {String} position - Defines on which corner of the screen the notifications will be shown. Possible values are `top-right`, `top-left`, `bottom-right` and `bottom-left`
 * @prop {Boolean} mobile - Enables mobile mode. Managed by component automatically.
 * @slot - Takes notification items as children.
 */
let SHNotifications = class SHNotifications extends resizeMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**Defines on which corner of the screen the notifications will be shown. Possible values are `top-right`, `top-center`, `top-left`, `bottom-right`, `bottom-center` and `bottom-left` */
    this.position = 'top-right';
  }
  static get styles() {
    return [sharedStyles, notificationStyles];
  }
  render() {
    return html` <slot></slot> `;
  }
  handleResize() {
    this.mobile = windowSize.util.valueMatches(document.documentElement.clientWidth, '<', 640);
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHNotifications.prototype,
  'position',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotifications.prototype,
  'mobile',
  void 0
);
SHNotifications = __decorate([customElement('sh-notifications')], SHNotifications);
export { SHNotifications };
