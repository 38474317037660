// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-page.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-page-body-wrapper-padding=1rem] - padding of body wrapper
   */
  --sh-page-body-wrapper-padding: 1rem;
}

:host {
  width: 100%;
  height: 100%;
  display: block;
}

:host([sh-vrt-mode]) {
  width: initial;
  height: initial;
  display: inline-block;
}

:host([sh-vrt-mode]) .page-content {
  /* background-color: initial; */
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: var(--base-1);
  transition: var(--transition-time) all ease-in-out;
  overflow: hidden;
}

:host([flat]) .page-content {
  background-color: var(--base-3);
}

.main-wrapper {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.body-wrapper {
  flex: 1;
  height: 100%;
  overflow: hidden;
  padding: var(--sh-page-body-wrapper-padding);
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
}

.body-wrapper[scrollable] {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  overflow-y: auto;
}

:host([no-padding]) .body-wrapper {
  padding: 0;
}

.body-wrapper > ::slotted(*:not(sh-button)),
.body-wrapper > ::slotted(sh-button[size=full-width]) {
  width: 100%;
}

.body-wrapper > ::slotted(sh-button:not([icon-position])),
.body-wrapper > ::slotted(sh-badge),
.body-wrapper > ::slotted(sh-tag),
.body-wrapper > ::slotted(sh-tooltip),
.body-wrapper > ::slotted(sh-notifications) {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}`;