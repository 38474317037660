// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement, svg } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher';
import { KEYCODE } from '../../utils/keycode';
import progressStyles from './sh-progress.lit.scss.js';
/**
 * @slot functions - Takes Icons and Text Links as children. Should be used for actions such as pause, cancel, resume. Only applies to linear variation.
 */
let SHProgress = class SHProgress extends LitElement {
  constructor() {
    super(...arguments);
    /**  Represents the progress in percentage. Defines the width/angle of the progress bar/circle. */
    this.value = 0;
    /**  If set to `true`, the progress will be shown in a radial orientation instead of linear. */
    this.radial = false;
    /**  If radial is set to `true`, defines the size of the progress circle (xs, s, m, l, xl).
     * @type {xs|s|m|l|xl}
     */
    this.size = 'm';
    this.documentKeydownListenerRef = this.handleDocumentKeydown.bind(this);
  }
  static get styles() {
    return [progressStyles, sharedStyles];
  }
  render() {
    return html`
      <!-- header -->
      ${(this.header || this.label) && this.size !== 'xs'
        ? html`
            <div class="header-wrapper">
              ${this.header && this.size !== 'xs'
                ? html` <sh-text size="header-2" class="header">${this.header}</sh-text> `
                : ''}
              ${this.label && !this.radial
                ? html` <sh-text size="title-1" class="label">${this.label}</sh-text> `
                : ''}
            </div>
          `
        : ''}
      ${!this.radial
        ? html`
            <!-- linear -->
            <div
              class="linear-progress ${this.indeterminate ? 'indeterminate' : ''}"
              style="
                --progress-color: ${this.paused
                ? 'rgba(var(--ui-1), var(--opacity-3))'
                : this.color
                  ? this.color
                  : 'rgb(var(--ui-2))'};
                  --progress-value: ${this.value}%;"
            >
              ${this.indeterminate ? html`<div class="indeterminate-bar"></div>` : ``}
            </div>
          `
        : html`
            <!-- radial -->
            <div
              class="radial-wrapper ${this.indeterminate
                ? 'indeterminate'
                : this.value >= 100
                  ? 'hundred-value'
                  : ''}"
              style="
                --progress-color: ${this.color ? this.color : 'rgb(var(--ui-2))'};
                --progress-value: ${this.indeterminate ? 25 : this.value <= 0 ? 0 : this.value};
                --circle-circumference : ${this.circleCircumference}"
            >
              ${(this.value > 0 && this.value < 100) || this.indeterminate
                ? svg`
              <svg class="radial-progress">
                <circle class="radial-progress-indicator" />
              </svg>
              `
                : ''}
              ${this.label && this.size !== 's' && this.size !== 'xs'
                ? html` <sh-text size="title-1" class="radial-label">${this.label}</sh-text> `
                : ''}
            </div>
          `}
      ${(this.info || this.status) && this.size !== 'xs'
        ? html`
            <div class="footer${this.hasFunctionIcons ? ' has-function-icons' : ''}">
              <!-- status -->
              ${this.status && !this.radial
                ? html` <sh-icon size="xs" class="status-icon" icon="${this.status}"></sh-icon> `
                : ''}
              <!-- info -->
              ${this.info && this.size !== 'xs'
                ? html`
                    <sh-text size="body-1" color="secondary" class="info">${this.info}</sh-text>
                  `
                : ''}
              ${!this.radial
                ? html`<slot
                    name="functions"
                    @slotchange="${(e) => {
                      this.hasFunctionIcons = e.target.assignedElements({
                        flatten: true,
                      }).length;
                    }}"
                  ></slot>`
                : ''}
            </div>
          `
        : ''}
    `;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    // handle legacy 'type' property
    if (name === 'type' && this.type) {
      this.status = this.type;
      console.warn(
        `You are using a legacy property ('type') of sh-progress component. Please use the new 'status' property instead.`
      );
    }
  }
  updated(changedProperties) {
    var _a;
    super.updated(changedProperties);
    const listOfProperties = [
      'header',
      'label',
      'info',
      'status',
      'color',
      'size',
      'value',
      'indeterminate',
      'paused',
      'radial',
      'type',
      'overlay',
    ];
    if (changedProperties.has('overlay')) {
      this.overlayStatus();
    }
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    if (!this.resizeObserver && this.radial) {
      this.resizeObserver = new ResizeObserver((e) => {
        var _a;
        if (this.radial) {
          // the radius of inner circle will be (total-height of container / 2 - ring-width / 2)
          // [why ring-width / 2 ? because stroke-width grows towards both sides from the main circumference.
          // so a stroke-width of 2px adds an inner border of 1px and an outer border of 1px. So technically
          // we will have to subtract half of the stroke-width from the radius of the outer circle to get the
          // radius of the inner circle (if we imagine the ring structure to be composed of 2 circles , one inner
          // and one outer {in this case the outer circle is not there. the outer radius will be equal to container-height / 2})]
          // which is assigned to the width of the before pseudoelement of radial-wrapper.
          // this width(radius of inner circle) * 2 * 3.14 gives the circumference of the inner circle.
          this.circleCircumference = `${parseFloat(getComputedStyle(e[0].target, ':before').width) * 2 * Math.PI}px`;
        } else {
          (_a = this.resizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
          this.resizeObserver = null;
        }
      });
      this.resizeObserver.observe(
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.radial-wrapper')
      );
    }
  }
  firstUpdated() {
    this.documentKeydownListenerRef = this.handleDocumentKeydown.bind(this);
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'progressbar');
    }
  }
  disconnectedCallback() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    document.body.removeEventListener('keydown', this.documentKeydownListenerRef);
    super.disconnectedCallback();
  }
  overlayStatus() {
    if (this.overlay) {
      document.body.addEventListener('keydown', this.documentKeydownListenerRef);
    } else {
      document.body.removeEventListener('keydown', this.documentKeydownListenerRef);
    }
  }
  handleDocumentKeydown(event) {
    if (event.code === KEYCODE.TAB.code) {
      event.preventDefault();
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'header', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'status', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'color', void 0);
__decorate([property({ type: Number, reflect: true })], SHProgress.prototype, 'value', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHProgress.prototype,
  'indeterminate',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHProgress.prototype, 'paused', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHProgress.prototype, 'radial', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHProgress.prototype, 'type', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHProgress.prototype,
  'circleCircumference',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHProgress.prototype,
  'hasFunctionIcons',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHProgress.prototype, 'overlay', void 0);
SHProgress = __decorate([customElement('sh-progress')], SHProgress);
export { SHProgress };
