// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import tableCellStyles from './sh-table-cell.lit.scss.js';
/**
 * @slot Takes any component or text string as children.
 */
let SHTableCell = class SHTableCell extends LitElement {
  constructor() {
    super(...arguments);
    /**If a value in px is defined, the cell won't scale horizontally below that value, and horizontal scrolling may be applied. */
    this.minWidth = '2.5rem';
  }
  static get styles() {
    return [tableCellStyles];
  }
  render() {
    return html`
      <div class="cell-wrapper">
        <slot></slot>
      </div>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    const icons = this.querySelectorAll('sh-icon');
    if (icons) {
      for (let i = 0; i < icons.length; i++) {
        if (icons[i].button && !icons[i].hasAttribute('tabindex')) {
          icons[i].setAttribute('tabindex', '0');
        }
      }
    }
  }
  update(changedProperties) {
    if (changedProperties.has('minWidth')) {
      this.style.minWidth = String(this.minWidth);
    }
    /* - Use case:  Below code for fixing issue in frozen table with Number property.
        The text remains on left side even when number property was  present on table cell.
        Since the frozen table has display flex - margin-left style needs to be set to align the text content to right */
    /*marginLeft style needs to be set up this way (on ts file) as it is not working when adding a class
        (the page is rendered before the class is added)*/
    if ((this.slot == 'frozen-left' || this.slot == 'frozen-right') && this.number) {
      this.style.marginLeft = 'auto';
    }
    super.update(changedProperties);
  }
};
__decorate([property({ type: Boolean, reflect: true })], SHTableCell.prototype, 'number', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableCell.prototype, 'icon', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'min-width' })],
  SHTableCell.prototype,
  'minWidth',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTableCell.prototype, 'columns', void 0);
__decorate([property({ type: String, reflect: true })], SHTableCell.prototype, 'checkbox', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTableCell.prototype,
  'condensed',
  void 0
);
SHTableCell = __decorate([customElement('sh-table-cell')], SHTableCell);
export { SHTableCell };
