// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-pagination.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-pagination-wrapper-height=2rem] - height of the pagination wrapper
   */
  --sh-pagination-wrapper-height: 2rem;
  /* 
   * @cssprop [--sh-pagination-height=1.5rem] - height of the page number box
   */
  --sh-pagination-height: 1.5rem;
  /* 
   * @cssprop [--sh-pagination-width=1.25rem] - width of the page number box
   */
  --sh-pagination-width: 1.25rem;
  /* 
   * @cssprop [--sh-pagination-line-height=1.5rem] - line-height of the page number box
   */
  --sh-pagination-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-pagination-li-right-margin=0.5rem] - right margin of the li
   */
  --sh-pagination-li-right-margin: 0.5rem;
  /* 
   * @cssprop [--sh-pagination-border-radius=0.75rem] - border-radius of the li
   */
  --sh-pagination-border-radius: 0.75rem;
  /* 
   * @cssprop [--sh-pagination-prev-page-icon-right-margin=1rem] - right margin of the page icon
   */
  --sh-pagination-prev-page-icon-right-margin: 1rem;
  /* 
   * @cssprop [--sh-pagination-next-page-icon-left-margin=0.5rem] - left margin of the next page icon
   */
  --sh-pagination-next-page-icon-left-margin: 0.5rem;
  /* 
   * @cssprop [--sh-pagination-page-number-padding=0rem 0.125rem] - padding of the li containing page number
   */
  --sh-pagination-page-number-padding: 0rem 0.125rem;
}

:host {
  position: relative;
  width: fit-content;
  white-space: nowrap;
  list-style: none;
  display: flex;
  height: var(--sh-pagination-wrapper-height);
  margin: 0 auto;
  transition: all var(--transition-time) ease-in-out;
}

:host ul {
  display: inline-flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  align-items: center;
}

:host ul li {
  text-align: center;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  font: var(--title-1);
  line-height: var(--sh-pagination-line-height);
  color: var(--text-secondary);
  background: transparent;
  cursor: pointer;
  transition: all var(--transition-time) ease-in-out, var(--hover-transition-time) background, var(--hover-transition-time) color;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host ul li:not(:first-child):not(:last-child) {
  height: var(--sh-pagination-height);
  min-width: var(--sh-pagination-width);
  border-radius: var(--sh-pagination-border-radius);
  margin-right: var(--sh-pagination-li-right-margin);
  padding: var(--sh-pagination-page-number-padding);
}

:host ul li:first-child,
:host ul li:last-child {
  height: var(--sh-pagination-wrapper-height);
  width: var(--sh-pagination-wrapper-height);
}

:host li[active] {
  background: rgba(var(--ui-1), var(--opacity-6));
  color: var(--text-primary);
}

.prevPageIcon {
  margin-right: var(--sh-pagination-prev-page-icon-right-margin);
}

.nextPageIcon {
  margin-left: var(--sh-pagination-next-page-icon-left-margin);
}

:host ul li sh-icon {
  position: unset;
}

/* State Styles */
:host ul .sh-pagination-item[click-disabled] {
  pointer-events: none;
}

/* disabled styles */
.prevPageIcon.disabled,
.nextPageIcon.disabled {
  cursor: default;
  pointer-events: none;
}

/* Focus Styles - Accessibility */
:active {
  outline: 0;
}

/* hover */
:host(:not(.touch-device)) ul .sh-pagination-item:hover,
:host .sh-pagination-item.hover {
  background: rgba(var(--ui-1), var(--opacity-7));
  color: var(--text-primary);
}

.sh-pagination-item:focus-visible {
  outline: var(--focus-outline);
}`;