// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-split-button.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-split-button-icon-wrapper-width=2rem] - width of icon wrapper
   */
  --sh-split-button-icon-wrapper-width: 2rem;
  /* 
   * @cssprop [--sh-split-button-current-scale=s] - the current scale of split-button used to set overlayTargetDistance for popover
   */
  --sh-split-button-current-scale: s;
}

:host {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  height: var(--sh-button-button-height);
  width: fit-content;
  width: -moz-fit-content;
  --current-scale: var(--sh-split-button-current-scale);
  position: relative;
}

.popup-menu {
  outline: none;
}

.main-btn {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.icon-wrapper {
  box-sizing: border-box;
  height: 100%;
  border-top-right-radius: var(--sh-button-button-border-radius);
  border-bottom-right-radius: var(--sh-button-button-border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sh-split-button-icon-wrapper-width);
}

/* color='primary' */
:host([color=primary]) .icon-wrapper {
  background-color: rgba(var(--ui-3), var(--opacity-1));
  border-left: 0.0625rem solid rgba(var(--ui-7), var(--opacity-5));
}

:host([color=primary]) .icon-wrapper[active] {
  background-color: rgba(var(--ui-4), var(--opacity-1));
}

:host([color=primary]) .split-button-icon {
  color: rgb(255, 255, 255);
}

:host([color=primary]:not(.touch-device):not([arrow-disabled])) .icon-wrapper:hover:not([active]) {
  background-color: rgba(var(--ui-2), var(--opacity-1));
}

/* color='secondary' */
:host([color=secondary]) .icon-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-6));
  border-left: 0.0625rem solid rgba(var(--ui-7), var(--opacity-4));
}

:host([color=secondary]) .icon-wrapper[active] {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

:host([color=secondary]) .split-button-icon {
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([color=secondary]:not(.touch-device):not([arrow-disabled])) .icon-wrapper:hover:not([active]) {
  background-color: rgba(var(--ui-1), var(--opacity-5));
}

/* color='tertiary' */
:host([color=tertiary]:not(.touch-device)) .main-btn:hover:not(:active) {
  border-color: rgba(var(--ui-1), var(--opacity-75));
  background: rgba(var(--ui-1), var(--opacity-100));
}

:host([color=tertiary]) .main-btn:active {
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-100));
  background: rgba(var(--ui-1), var(--opacity-50));
  border-right: none;
}

:host([color=tertiary]) .icon-wrapper {
  background-color: transparent;
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-200));
  padding-left: 0.0625rem;
  border-left-width: 0.0625rem;
}

:host([color=tertiary]:not([arrow-disabled])) .icon-wrapper:active {
  border-color: rgba(var(--ui-1), var(--opacity-100));
  background-color: rgba(var(--ui-1), var(--opacity-50));
}

:host([color=tertiary]) .split-button-icon {
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([color=tertiary]:not(.touch-device):not([arrow-disabled])) .icon-wrapper:hover:not(:active) {
  background-color: rgba(var(--ui-1), var(--opacity-100));
  border-color: rgba(var(--ui-1), var(--opacity-75));
}

/* disabled */
:host([disabled]) {
  pointer-events: none;
  cursor: default;
}

:host([arrow-disabled]) .icon-wrapper {
  cursor: default;
}

:host([color=primary][disabled]) .icon-wrapper {
  background-color: rgba(var(--ui-2), var(--opacity-5));
}

:host([color=secondary][disabled]) .icon-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

:host([color=tertiary][disabled]) .icon-wrapper {
  opacity: var(--opacity-300);
  border: var(--sh-button-tertiary-border-thickness) solid rgba(var(--ui-1), var(--opacity-200));
  border-left: 0.0625rem solid rgba(var(--ui-1), var(--opacity-200));
}

.split-button-icon {
  margin-left: -0.125rem;
}

sh-button[color=tertiary] {
  border-right: none;
}`;