// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-badge.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-badge-font-size=0.625rem] - font size for badge
   */
  --sh-badge-font-size: 0.625rem;
  /* 
   * @cssprop [--sh-badge-min-width=1rem] - min width of badge
   */
  --sh-badge-min-width: 1rem;
  /* 
   * @cssprop [--sh-badge-max-width=4rem] - max width of badge
   */
  --sh-badge-max-width: 4rem;
  /* 
   * @cssprop [--sh-badge-height=1rem] - height of badge
   */
  --sh-badge-height: 1rem;
  /* 
   * @cssprop [--sh-badge-line-height=0.5rem] - line height of badge
   */
  --sh-badge-line-height: 0.5rem;
  /* 
   * @cssprop [--sh-badge-no-label-size=0.5rem] - size of empty badge in case no label is set
   */
  --sh-badge-no-label-size: 0.5rem;
  /* 
   * @cssprop [--sh-badge-padding=0.25rem 0.25rem] - padding for text labels
   */
  --sh-badge-padding: 0.25rem 0.25rem;
  /* 
   * @cssprop [--sh-badge-icon-font-size=1.5rem] - applied to interllay used icon as --sh-icon-font-size-xs
   */
  --sh-badge-icon-font-size: 1.5rem;
  /* 
   * @cssprop [--sh-badge-label-type-padding=0rem 0.25rem 0rem 0.125rem] - padding is applied if both type and label are defined 
   */
  --sh-badge-label-type-padding: 0rem 0.25rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-badge-label-line-height=1rem] - line-height is applied if both type and label are defined 
   */
  --sh-badge-label-line-height: 1rem;
}

:host {
  display: block;
  flex: none;
  align-items: center;
  padding: var(--sh-badge-padding);
  border-radius: var(--sh-badge-font-size);
  background: rgba(var(--ui-0), var(--opacity-1));
  font: var(--title-2);
  font-size: var(--sh-badge-font-size);
  color: var(--text-white);
  max-width: var(--sh-public-badge-max-width, var(--sh-badge-max-width));
  min-width: var(--sh-badge-min-width);
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  height: var(--sh-badge-height);
  line-height: var(--sh-badge-line-height);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

sh-icon {
  --sh-icon-font-size-xs: var(--sh-badge-icon-font-size);
}

:host([label=""]:not([type=error]):not([type=success]):not([type=warning])),
:host(:not([label]):not([type=error]):not([type=success]):not([type=warning])) {
  width: var(--sh-badge-no-label-size);
  height: var(--sh-badge-no-label-size);
  max-width: var(--sh-badge-no-label-size);
  max-height: var(--sh-badge-no-label-size);
  min-width: var(--sh-badge-no-label-size);
  min-height: var(--sh-badge-no-label-size);
}

:host([show-label][label][type=error]),
:host([show-label][label][type=warning]),
:host([show-label][label][type=success]) {
  display: flex;
  padding: var(--sh-badge-label-type-padding);
  line-height: var(--sh-badge-label-line-height);
}

:host([label][type=error]) span,
:host([label][type=warning]) span,
:host([label][type=success]) span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:host([label][type=warning]) span {
  color: black;
}

:host([type]:not([label])) {
  padding: 0rem;
  background: transparent;
}

:host([type=error]) sh-icon {
  color: rgb(var(--functional-red));
}

:host([type=warning]) sh-icon {
  color: rgb(var(--functional-yellow));
}

:host([type=success]) sh-icon {
  color: rgb(var(--functional-green));
}

:host([show-label][type=error]) {
  background: rgb(var(--functional-red));
}

:host([show-label][type=warning]) {
  background: rgb(var(--functional-yellow));
}

:host([show-label][type=success]) {
  background: rgb(var(--functional-green));
}

:host([disabled]:not([type])) {
  color: var(--text-white-disabled);
  background: rgba(var(--ui-0), var(--opacity-5));
}

:host([disabled][type]) {
  opacity: 0.2;
}

:host([neutral]:not([type=error]):not([type=success]):not([type=warning])) {
  background: rgb(var(--badge-no-label-bg));
  box-shadow: inset 0 0 0 1px rgba(var(--ui-1), var(--opacity-6));
}

:host([neutral][label]:not([type=error]):not([type=success]):not([type=warning])) {
  color: var(--text-secondary);
  background: rgb(var(--ui-9));
}

:host([neutral][disabled][label]:not([type=error]):not([type=success]):not([type=warning])) {
  color: var(--text-disabled);
  background: rgba(var(--ui-9), var(--opacity-4));
}

:host([neutral][disabled]:not([type=error]):not([type=success]):not([type=warning])) {
  background: rgba(var(--badge-no-label-bg), var(--opacity-4));
}`;