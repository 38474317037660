// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-private-month-selector.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-datepicker-overlay-container-height=2.5rem] - height of the overlay container
   */
  --sh-datepicker-overlay-container-height: 2.5rem;
  /* 
   * @cssprop [--sh-datepicker-overlay-container-height-condensed=2rem] - height of the overlay container in condensed view
   */
  --sh-datepicker-overlay-container-height-condensed: 2rem;
  /* 
   * @cssprop [--sh-datepicker-months-grid-gap=0.5rem] - gap of months grid
   */
  --sh-datepicker-months-grid-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-width=18rem] - gap of months grid
   */
  --sh-datepicker-date-panel-width: 18rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-padding=1rem 0.5rem 0.5rem 0.5rem] - gap of months grid
   */
  --sh-datepicker-date-panel-padding: 1rem 0.5rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-height=21rem] - gap of months grid
   */
  --sh-datepicker-date-panel-height: 21rem;
  /* 
   * @cssprop [--sh-datepicker-header-area-height=2rem] - height of the header area
   */
  --sh-datepicker-header-area-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-width=9.375rem] - width of the month and year display
   */
  --sh-datepicker-month-and-year-display-width: 9.375rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-line-height=2rem] - line-height of the month and year display
   */
  --sh-datepicker-month-and-year-display-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-display-border-radius=1rem] - border-radius of the month and year display
   */
  --sh-datepicker-month-and-year-display-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-rows=2rem] - grid template rows of the date grid
   */
  --sh-datepicker-date-grid-template-rows: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-columns=2rem] - grid template columns of the date grid
   */
  --sh-datepicker-date-grid-template-columns: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-gap=0.5rem] - grid gap of the date grid
   */
  --sh-datepicker-date-grid-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-margin-top=0.5rem] - margin top of  date grid
   */
  --sh-datepicker-date-grid-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-week-day-name-line-height=2rem] - line height of week day name
   */
  --sh-datepicker-week-day-name-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-line-height=2rem] - line height of date
   */
  --sh-datepicker-date-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-border-radius=1rem] - border-radius of date
   */
  --sh-datepicker-date-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-height=2rem] - border-radius of date
   */
  --sh-datepicker-date-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-width=2rem] - border-radius of date
   */
  --sh-datepicker-date-width: 2rem;
  /* 
   * @cssprop [--sh-datepicker-icon-dummy-width=2rem] - width of dummy icon
   */
  --sh-datepicker-icon-dummy-width: 2rem;
  /* 
   * @cssprop [--sh-datepicker-icon-dummy-height=2rem] - height of dummy icon
   */
  --sh-datepicker-icon-dummy-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-date-panel-padding-year-and-month-selector=1rem 0.5rem 1.75rem] - date panel padding of year and month selector
   */
  --sh-datepicker-date-panel-padding-year-and-month-selector: 1rem 0.5rem 1.75rem;
  /* 
   * @cssprop [--sh-datepicker-header-area-height-year-and-month-selector=1.5rem] - height of the header area of year and month selector
   */
  --sh-datepicker-header-area-height-year-and-month-selector: 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-line-height-year-selector=1.5rem] - line height of the year selector
   */
  --sh-datepicker-line-height-year-selector: 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-rows-year-and-month-selector=3rem] - grid template rows of year selector
   */
  --sh-datepicker-date-grid-template-rows-year-and-month-selector: 3rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-template-columns-year-and-month-selector=4rem] - grid template rows of year selector
   */
  --sh-datepicker-date-grid-template-columns-year-and-month-selector: 4rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-column-gap-year-and-month-selector=1rem] - grid gap of year selector
   */
  --sh-datepicker-date-grid-column-gap-year-and-month-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-row-gap-year-and-month-selector=1rem] - grid row gap of year selector
   */
  --sh-datepicker-date-grid-row-gap-year-and-month-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-margin-year-and-month-selector=1.75rem 1.5rem 0rem 1.5rem] - date grid margin of year selector
   */
  --sh-datepicker-date-grid-margin-year-and-month-selector: 1.75rem 1.5rem 0rem 1.5rem;
  /* 
   * @cssprop [--sh-datepicker-date-grid-width-year-and-month-selector=14rem] - width of date grid of year selector
   */
  --sh-datepicker-date-grid-width-year-and-month-selector: 14rem;
  /* 
   * @cssprop [--sh-datepicker-year-height-year-selector=2rem] - height of year field in year selector
   */
  --sh-datepicker-year-height-year-selector: 2rem;
  /* 
   * @cssprop [--sh-datepicker-year-line-height-year-selector=2rem] - line height of year field in year selector
   */
  --sh-datepicker-year-line-height-year-selector: 2rem;
  /* 
   * @cssprop [--sh-datepicker-year-border-radius-year-selector=1rem] - border radius of year field in year selector
   */
  --sh-datepicker-year-border-radius-year-selector: 1rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-width=4.5rem] - width of the year display  in month selector
   */
  --sh-datepicker-month-and-year-width: 4.5rem;
  /* 
   * @cssprop [--sh-datepicker-month-and-year-height=2rem] - hieght of the year display  in month selector
   */
  --sh-datepicker-month-and-year-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-height=2rem] - hieght of the year month  in month selector
   */
  --sh-datepicker-month-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-line-height=2rem] - line hieght of the year month  in month selector
   */
  --sh-datepicker-month-line-height: 2rem;
  /* 
   * @cssprop [--sh-datepicker-month-border-radius=1rem] - border radius of  month  in month selector
   */
  --sh-datepicker-month-border-radius: 1rem;
  /* 
   * @cssprop [--sh-datepicker-footer-padding=1rem] - padding of footer element
   */
  --sh-datepicker-footer-padding: 1rem;
  /* 
   * @cssprop [--sh-datepicker-footer-button-inter-gap=0.5rem] - gap between buttons in footer
   */
  --sh-datepicker-footer-button-inter-gap: 0.5rem;
  /* 
   * @cssprop [--sh-datepicker-footer-height=4rem] - height of footer
   */
  --sh-datepicker-footer-height: 4rem;
}

.months {
  display: flex;
  flex-direction: row;
  grid-gap: var(--sh-datepicker-months-grid-gap);
  width: fit-content;
  box-sizing: border-box;
  overflow: hidden;
}

.date-panel {
  width: var(--sh-datepicker-date-panel-width);
  box-sizing: border-box;
  padding: var(--sh-datepicker-date-panel-padding-year-and-month-selector);
  display: flex;
  flex-direction: column;
  height: var(--sh-datepicker-date-panel-height);
}

.header-area {
  height: var(--sh-datepicker-header-area-height-year-and-month-selector);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.month-and-year-display {
  width: var(--sh-datepicker-month-and-year-width);
  border-radius: var(--sh-datepicker-month-and-year-display-border-radius);
  font: var(--title-1);
  color: var(--text-primary);
  text-align: center;
  line-height: var(--sh-datepicker-month-and-year-display-line-height);
  box-sizing: border-box;
  cursor: pointer;
  height: var(--sh-datepicker-month-and-year-height);
}

:host(:not(.touch-device)) .month-and-year-display:hover {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

.date-grid {
  display: grid;
  grid-template-rows: repeat(4, var(--sh-datepicker-date-grid-template-rows-year-and-month-selector));
  grid-template-columns: repeat(3, var(--sh-datepicker-date-grid-template-columns-year-and-month-selector));
  grid-row-gap: var(--sh-datepicker-date-grid-row-gap-year-and-month-selector);
  grid-column-gap: var(--sh-datepicker-date-grid-column-gap-year-and-month-selector);
  margin: var(--sh-datepicker-date-grid-margin-year-and-month-selector);
  box-sizing: border-box;
  width: var(--sh-datepicker-date-grid-width-year-and-month-selector);
  align-items: center;
}

.month {
  font: var(--body-1);
  color: var(--text-primary);
  text-align: center;
  height: var(--sh-datepicker-month-height);
  line-height: var(--sh-datepicker-month-line-height);
  border-radius: var(--sh-datepicker-month-border-radius);
  cursor: pointer;
  transition: var(--hover-transition-time) background;
}

:host(:not(.touch-device)) .month:hover:not(.selected) {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

.month.selected:not(.disabled) {
  background-color: rgb(var(--ui-3), var(--opacity-1)) !important;
  font: var(--title-1);
  color: var(--text-white);
  line-height: var(--sh-datepicker-month-line-height);
}

.month.today:not(.selected):not(.disabled) {
  font: var(--title-1);
  color: var(--text-highlight);
  line-height: var(--sh-datepicker-month-line-height);
}

.month.disabled {
  color: var(--text-disabled);
  pointer-events: none;
}

.icon-dummy {
  width: var(--sh-datepicker-icon-dummy-width);
  height: var(--sh-datepicker-icon-dummy-height);
}

:host([responsive]) .months {
  justify-content: center;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
}

:host {
  display: block;
}`;