import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher';
import { deviceIdentifier } from '../../utils/device-identifier';
import { getOverlayPlacementInfo } from '../../utils/getOverlayPlacement';
import tooltipStyles from './sh-tooltip.lit.scss.js';
let SHTooltip = class SHTooltip extends LitElement {
  constructor() {
    super(...arguments);
    /**  Default tooltip. Defines the title for the `long' variation of tooltip. */
    this.title = '';
    /**  Default long. Defines the type of tooltip. Two variations - short and long. */
    this.variation = 'long';
    /**  Default false. If set to `true`, the tooltip displays styles for error tooltip. */
    this.error = false;
    /**  Default false. If set to `true`, the tooltip displays styles for error tooltip. */
    this.visible = false;
    /** Default top. Defines where the tooltip will be shown. Accepted values are - top, bottom, left, right, top-right, top-left, 'bottom-left, bottom-right, left-up, left-down, right-up, right-down.
     * @type {top|bottom|left|right|top-right|top-left|bottom-left|bottom-right|left-up|left-down|right-up|right-down}
     */
    this.placement = 'top';
    /**  If set to `true`, the tooltip is shown with a 400ms delay after hover. */
    this.delay = false;
    /**  This component has position:fixed as its css. So when giving left/top to the component, it will
      be with respect to viewport. But if the parent element has either transform/perspective or other
      properties, then this component will position with respect to that parent component. Refer position fixed in https://developer.mozilla.org/en-US/docs/Web/CSS/position?v=example#values
      and https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block This might position this component somewhere which is not desirable. So set this property to true, if any
      parent element has any of this css to correctly readjust this component to position with respect to the
      viewport. */
    this.positionFixedAdjustment = false;
  }
  static get styles() {
    return [sharedStyles, tooltipStyles];
  }
  render() {
    return html`
      ${this.title && this.variation === 'long'
        ? html` <div class="tooltip-title">${this.title}</div> `
        : ''}
      <div class="label-wrapper">
        ${this.icon
          ? html` <sh-icon icon="${this.icon}" id="tooltipIcon" size="s"></sh-icon> `
          : ''}
        ${this.label ? html` <div class="tooltip-label">${this.label}</div> ` : ''}
      </div>
      <slot></slot>
    `;
  }
  updated(changedProperties) {
    if (changedProperties.has('target')) {
      this._handleTarget();
    }
    if (
      this.visible &&
      (changedProperties.has('visible') || changedProperties.has('placement')) &&
      this._targetEl &&
      this.placement !== 'pointer'
    ) {
      this._setOverlayPlacement(this._targetEl);
    }
    if (changedProperties.has('error')) {
      this._handleError();
    }
    if (changedProperties.has('_targetEl') && changedProperties.get('_targetEl')) {
      this._removeTargetEventListener(changedProperties.get('_targetEl'));
    }
    const listOfProperties = [
      'label',
      'title',
      'variation',
      'target',
      'visible',
      'placement',
      'icon',
      'error',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  getTarget() {
    const target = this.target;
    if (target) {
      if (typeof target === 'string') {
        return this.parentElement
          ? this.parentElement.querySelector(`[id="${target}"]`) || document.getElementById(target)
          : document.getElementById(target);
      } else if (target instanceof HTMLElement) {
        return target;
      } else {
        throw new Error(
          `\n\nThe "target" property is not of valid type.` +
            `\nThe "target" property can take string value corresponding to the id of the target.` +
            `\nIt can also have its value equal to a DOM Node reference (instance of HTMLElement)\n`
        );
      }
    }
    return null;
  }
  _setOverlayPlacement(attachNode) {
    const placementInfo = getOverlayPlacementInfo(
      this.placement,
      this.getBoundingClientRect(),
      attachNode.getBoundingClientRect(),
      JSON.parse(JSON.stringify(document.body.getBoundingClientRect())),
      0.5,
      0.5,
      null,
      null,
      true
    );
    let calculatedLeft = placementInfo.requiredLeft;
    let calculatedTop = placementInfo.requiredTop;
    if (this.positionFixedAdjustment) {
      const translateX = this.getBoundingClientRect().left - this.offsetLeft;
      const translateY = this.getBoundingClientRect().top - this.offsetTop;
      calculatedLeft -= translateX;
      calculatedTop -= translateY;
    }
    this.style.left = calculatedLeft + 'px';
    this.style.top = calculatedTop + 'px';
  }
  firstUpdated() {
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this._showListener = this._setVisible.bind(this);
    this._hideListener = this._clearVisible.bind(this);
    this._tooltipMouseEnter = this._tooltipMouseEnterActions.bind(this);
    this._tooltipMouseLeave = this._tooltipMouseLeaveActions.bind(this);
    this.addEventListener('mouseenter', this._tooltipMouseEnter);
    this.addEventListener('mouseleave', this._tooltipMouseLeave);
    // Remove the empty 'title' attribute from the tooltip if no title is provided.
    if (this.hasAttribute('title') && this.getAttribute('title') == '') {
      this.removeAttribute('title');
    }
  }
  _tooltipMouseEnterActions() {
    this.visible = true;
  }
  _tooltipMouseLeaveActions() {
    this.visible = false;
  }
  _handleTarget() {
    this._targetEl = this.getTarget();
    if (this._targetEl) {
      this._targetEl.addEventListener('mouseenter', this._showListener);
      this._targetEl.addEventListener('mouseleave', this._hideListener);
      this._targetEl.addEventListener('focus', this._showListener);
      this._targetEl.addEventListener('blur', this._hideListener);
      this._targetEl.addEventListener('tap', this._hideListener);
    }
  }
  _removeTargetEventListener(oldTarget) {
    oldTarget.removeEventListener('mouseenter', this._showListener);
    oldTarget.removeEventListener('focus', this._showListener);
    oldTarget.removeEventListener('mouseleave', this._hideListener);
    oldTarget.removeEventListener('blur', this._hideListener);
    oldTarget.removeEventListener('tap', this._hideListener);
  }
  disconnectedCallback() {
    if (this._targetEl) {
      this._removeTargetEventListener(this._targetEl);
    }
    this.removeEventListener('mouseenter', this._tooltipMouseEnter);
    this.removeEventListener('mouseleave', this._tooltipMouseLeave);
    super.disconnectedCallback();
  }
  _setVisible(e) {
    if (this.placement === 'pointer') {
      const pointerDistance = 8;
      //Flip when pointer position + tooltip's width exeeds the viewport's width
      const evt = e;
      this.style.left =
        evt.clientX + this.offsetWidth > document.body.getBoundingClientRect().right
          ? `${evt.clientX - this.offsetWidth + pointerDistance}px`
          : `${evt.clientX + pointerDistance}px`;
      //Flip when pointer position + tooltip's height exeeds the viewport's height
      this.style.top =
        evt.clientY + this.offsetHeight > document.body.getBoundingClientRect().bottom
          ? `${evt.clientY - this.offsetHeight + pointerDistance}px`
          : `${evt.clientY + pointerDistance}px`;
    }
    if (!this.classList.contains('touch-device')) {
      this.visible = true;
    }
  }
  _clearVisible() {
    this.visible = false;
  }
  _handleError() {
    if (this.error) {
      this.icon = 'error';
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'title', void 0);
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'variation', void 0);
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'icon', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTooltip.prototype, 'error', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTooltip.prototype, 'visible', void 0);
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'target', void 0);
__decorate([property({ type: String, reflect: true })], SHTooltip.prototype, 'placement', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTooltip.prototype, 'delay', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'position-fixed-adjustment' })],
  SHTooltip.prototype,
  'positionFixedAdjustment',
  void 0
);
__decorate([property({ type: Element })], SHTooltip.prototype, '_targetEl', void 0);
SHTooltip = __decorate([customElement('sh-tooltip')], SHTooltip);
export { SHTooltip };
