// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-input-text.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-input-text-label-left=0.5rem] - left of the input label
   */
  --sh-input-text-label-left: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-empty-label-top=0.5rem] - top of the input label when no value
   */
  --sh-input-text-empty-label-top: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-empty-label-line-height=1.5rem] - line-height of the input label when no value
   */
  --sh-input-text-empty-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-input-text-empty-padding=0.5rem 0 0.4375rem 0.5rem] - padding of the input-text when no value
   */
  --sh-input-text-empty-padding: 0.5rem 0 0.4375rem 0.5rem;
  /* 
   * @cssprop [--sh-input-text-height=2.4375rem] - height of the input-text
   */
  --sh-input-text-height: 2.4375rem;
  /* 
   * @cssprop [--sh-input-text-padding=1.25rem 0 0.1875rem 0.5rem] - padding of the input-text with value
   */
  --sh-input-text-padding: 1.25rem 0 0.1875rem 0.5rem;
  /* 
   * @cssprop [--sh-input-text-label-top=0.25rem] - top of the input label with value
   */
  --sh-input-text-label-top: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-label-line-height=1rem] - line-height of the input label with value
   */
  --sh-input-text-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-input-text-empty-no-border-padding-bottom=0.5rem] - padding-bottom of the input when no border
   */
  --sh-input-text-empty-no-border-padding-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-no-border-padding-bottom=0.25rem] - padding-bottom of the input with value when no border
   */
  --sh-input-text-no-border-padding-bottom: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-no-border-condensed-padding-bottom=0.25rem] - padding-bottom of the condensed input when no border
   */
  --sh-input-text-no-border-condensed-padding-bottom: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-icon-left-margin=0.5rem] - margin-left of the icon
   */
  --sh-input-text-icon-left-margin: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-slot-icon-left-margin=0.5rem] - margin-left of the icon placed in the slot
   */
  --sh-input-text-slot-icon-left-margin: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-icon-wrapper-right-padding=0.5rem] - right-padding of the icon-wrapper
   */
  --sh-input-text-icon-wrapper-right-padding: 0.5rem;
  /* 
   * @cssprop [--sh-input-text-icon-size=1.5rem] - size of the icon
   */
  --sh-input-text-icon-size: 1.5rem;
  /* 
   * @cssprop [--sh-input-text-icon-wrapper-margin=0rem] - margin-bottom of the icon
   */
  --sh-input-text-icon-wrapper-margin: 0rem;
  /* 
   * @cssprop [--sh-input-text-helper-text-padding=0.25rem 0.5rem 0] - padding of the helper-text
   */
  --sh-input-text-helper-text-padding: 0.25rem 0.5rem 0;
  /* 
   * @cssprop [--sh-input-text-condensed-label-top=0.25rem] - top of the condensed label
   */
  --sh-input-text-condensed-label-top: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-condensed-padding=0.25rem 0 0.1875rem 0.5rem] - padding of the condensed input
   */
  --sh-input-text-condensed-padding: 0.25rem 0 0.1875rem 0.5rem;
  /* 
   * @cssprop [--sh-input-text-condensed-height=1.9375rem] - height of the condensed input
   */
  --sh-input-text-condensed-height: 1.9375rem;
  /* 
   * @cssprop [--sh-input-text-textarea-label-height=1.5rem] - height of the textarea label
   */
  --sh-input-text-textarea-label-height: 1.5rem;
  /* 
   * @cssprop [--sh-input-text-textarea-label-padding=0.25rem 0 0.25rem 0.5rem] - padding of the textarea label
   */
  --sh-input-text-textarea-label-padding: 0.25rem 0 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-input-text-textarea-padding=0 0.5rem 0.3125rem] - padding of the textarea
   */
  --sh-input-text-textarea-padding: 0 0.5rem 0.3125rem;
  /* 
   * @cssprop [--sh-input-text-textarea-line-height=1.0625rem] - line-height of the textarea
   */
  --sh-input-text-textarea-line-height: 1.0625rem;
  /* 
   * @cssprop [--sh-input-text-textarea-empty-label-line-height=1.25rem] - line-height of the empty textarea (without value)
   */
  --sh-input-text-textarea-empty-label-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-input-text-condensed-textarea-top=0.25rem] - top of the icon-wrapper and label of condensed textarea
   */
  --sh-input-text-condensed-textarea-top: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-condensed-textarea-padding=0.25rem 0 0.3125rem 0.5rem] - padding of the div of condensed textarea
   */
  --sh-input-text-condensed-textarea-padding: 0.25rem 0 0.3125rem 0.5rem;
  /* 
   * @cssprop [--sh-input-text-textarea-label-line-height=1rem] - line-height of the label of textarea with value
   */
  --sh-input-text-textarea-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-input-text-condensed-textarea-icon-top=0.25rem] - top of the icon of condensed textarea with value
   */
  --sh-input-text-condensed-textarea-icon-top: 0.25rem;
  /* 
   * @cssprop [--sh-input-text-pointer-events=none] - pointer events of the input text label
   */
  --sh-input-text-pointer-events: none;
  /* 
   * @cssprop [--sh-input-text-label-condensed-display=none] - condensed label display
   */
  --sh-input-text-label-condensed-display: none;
  /* 
   * @cssprop [--sh-input-text-input-cursor=text] - cursor for the input
   */
  --sh-input-text-input-cursor: text;
}

:host {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.outer-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 0.125rem 0.125rem 0 0;
  background: rgba(var(--ui-1), var(--opacity-7));
  border-bottom: 0.0625rem solid transparent;
  border-color: rgba(var(--ui-1), var(--opacity-4));
  transition: var(--hover-transition-time) background;
}

:host(.touch-device) .input-wrapper {
  transition: none;
}

.outer-wrapper:focus-visible {
  outline: var(--focus-outline);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.input-text-label {
  color: var(--text-secondary);
  position: absolute;
  left: var(--sh-input-text-label-left);
  width: calc(100% - var(--sh-input-text-label-left));
  transition: var(--transition-time) all ease-in-out;
  pointer-events: var(--sh-input-text-pointer-events);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  top: var(--sh-input-text-empty-label-top);
  font: var(--body-1);
  line-height: var(--sh-input-text-empty-label-line-height);
  display: flex;
  align-items: center;
}

.label-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: fit-content;
}

input {
  outline: none;
  border: none;
  font: var(--body-1);
  color: var(--text-primary);
  background: transparent;
  height: var(--sh-input-text-height);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: var(--transition-time) all ease-in-out;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  cursor: var(--sh-input-text-input-cursor);
  padding: var(--sh-input-text-padding);
}

:host(:not([disabled])) input {
  -webkit-background-clip: text;
  background-clip: text;
  caret-color: var(--text-primary);
  -webkit-text-fill-color: var(--text-primary);
}

:host(.empty) input {
  padding: var(--sh-input-text-empty-padding);
}

:host([no-border]:not([textarea]):not([condensed])) input {
  height: calc(var(--sh-input-text-height) + 0.0625rem);
  padding-bottom: var(--sh-input-text-empty-no-border-padding-bottom);
}

:host([no-border]:not([textarea]):not([condensed]):not(.empty)) input {
  height: calc(var(--sh-input-text-height) + 0.0625rem);
  padding-bottom: var(--sh-input-text-no-border-padding-bottom);
}

:host([condensed][no-border]:not([textarea])) input,
:host([condensed][no-clear][no-border]:not([textarea])) input {
  padding-bottom: var(--sh-input-text-no-border-condensed-padding-bottom);
  height: calc(var(--sh-input-text-condensed-height) + 0.0625rem);
}

:host(:not(.empty)) .input-text-label,
:host(:not([readonly])) input:focus + .input-text-label,
:host(:not([readonly]):not([disabled])[show-outline]) .input-text-label {
  top: var(--sh-input-text-label-top);
  font: var(--body-2);
  line-height: var(--sh-input-text-label-line-height);
}

:host(:not([show-outline])[value=""][show-tooltip]) input:focus + .input-text-label {
  top: var(--sh-input-text-empty-label-top);
  font: var(--body-1);
  line-height: var(--sh-input-text-empty-label-line-height);
}

:host(:not([show-outline])[condensed][value=""][show-tooltip]) input:focus + .input-text-label {
  top: var(--sh-input-text-condensed-label-top);
  font: var(--body-1);
  line-height: var(--sh-input-text-empty-label-line-height);
}

:host(:not([readonly])) .textarea:focus + .input-text-label,
:host([textarea]:not(.empty)) .input-text-label,
:host([textarea][textarea-focused]) .input-text-label {
  font: var(--body-2);
  line-height: var(--sh-input-text-textarea-label-line-height);
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.icon-wrapper {
  position: relative;
  align-items: center;
  right: 0;
  display: flex;
  padding-right: var(--sh-input-text-icon-wrapper-right-padding);
  width: fit-content;
  border-radius: 0 0.125rem 0 0;
}

:host([textarea]) .icon-wrapper {
  margin-bottom: var(--sh-input-text-icon-wrapper-margin);
}

:host([textarea][condensed]) .icon-wrapper {
  height: fit-content;
  top: var(--sh-input-text-condensed-textarea-top);
}

:host([textarea][condensed]:not(.empty):hover:not(.touch-device)) .icon-wrapper,
:host([textarea][condensed][error]:not(.empty)) .icon-wrapper {
  top: var(--sh-input-text-condensed-textarea-icon-top);
}

.icon-wrapper > sh-icon[icon=error],
.icon-wrapper > sh-icon[icon=success] {
  margin-left: var(--sh-input-text-icon-left-margin);
}

:host([condensed]) .icon-wrapper > ::slotted(sh-icon),
.icon-wrapper > ::slotted(sh-icon) {
  margin-left: var(--sh-input-text-slot-icon-left-margin);
}

:host([condensed]:not([textarea])) #clear,
:host([condensed]:not([textarea])) .icon-wrapper > ::slotted(sh-icon) {
  --sh-icon-size-s: var(--sh-input-text-icon-size) !important;
}

/* disabled */
input:disabled,
textarea:disabled {
  opacity: unset;
}

:host([disabled]) .input-text-label,
:host([disabled][textarea]) .input-text-label,
:host([disabled]) input,
:host([disabled]) .textarea {
  color: var(--text-disabled);
}

:host([mandatory][disabled]) #mandatory {
  color: rgba(var(--ui-2), var(--opacity-5));
}

:host([disabled]) .icon-wrapper > ::slotted(*) {
  pointer-events: none;
  color: var(--text-disabled);
}

:host([disabled][textarea]) .textarea {
  color: var(--text-disabled);
}

/* Validation Styles */
:host([mandatory]) #mandatory {
  display: inline-block;
  color: rgba(var(--ui-2), var(--opacity-1));
}

:host([error]) .input-wrapper,
:host([error]) input:focus,
:host([textarea][error][condensed]) .input-wrapper,
:host([textarea][error]:not([condensed])) .outer-wrapper {
  border-color: rgba(var(--functional-red), var(--opacity-1));
}

:host([success]) .input-wrapper,
:host([success]) input:focus,
:host([textarea][success][condensed]) .input-wrapper,
:host([textarea][success]:not([condensed])) .outer-wrapper {
  border-color: rgba(var(--functional-green), var(--opacity-1));
}

:host([safety]) .input-wrapper,
:host([safety]) input:focus,
:host([textarea][safety][condensed]) .input-wrapper,
:host([textarea][safety]:not([condensed])) .outer-wrapper {
  border-color: rgba(var(--functional-yellow), var(--opacity-2));
}

/* focus */
:host(:not([error]):not([success]):not([safety]):not([readonly]):not([disabled])) .input-wrapper:focus-within,
:host([show-outline]:not([error]):not([success]):not([safety]):not([readonly]):not([disabled])) .input-wrapper {
  border-color: rgba(var(--ui-1), var(--opacity-2));
}

:host(.empty:not([condensed])) input:focus,
.textarea:focus {
  padding: var(--sh-input-text-padding);
}

/* readonly */
:host([readonly]) .input-wrapper,
:host([readonly]) .textarea {
  background: transparent;
}

:host([readonly]) input,
:host([readonly]) .textarea,
:host([disabled]) input,
:host([disabled]) .textarea {
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
}

:host([value=""][readonly]),
:host([disabled]) input,
:host([disabled]) .textarea {
  pointer-events: none;
}

/* Clear Field Icon */
#clear {
  opacity: 0;
  z-index: -1;
  padding: unset;
  pointer-events: none;
  transition: none;
  cursor: pointer;
  --sh-icon-size-s: 0;
  --sh-icon-font-size-s: 0;
}

:host([error]:not([value=""])) #clear {
  opacity: 1;
  z-index: auto;
  margin-left: var(--sh-input-text-icon-left-margin);
  --sh-icon-size-s: unset;
  --sh-icon-font-size-s: unset;
}

:host(.touch-device) #clear {
  opacity: 1;
  z-index: auto;
  pointer-events: all;
  --sh-icon-size-s: unset;
  --sh-icon-font-size-s: unset;
}

/* No Clear */
/* hover */
:host(:not([readonly]):not([disabled]):not([textarea]):not(.touch-device)) .input-wrapper:hover {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([readonly]):not([disabled]):not(.empty)[textarea]) .outer-wrapper:hover #clear,
:host(:not([readonly]):not([disabled]):not(.empty):hover:not(.touch-device)) .input-wrapper:hover #clear,
:host(:not([readonly]):not([disabled]):not(.empty).touch-device):hover .input-wrapper:hover #clear,
#clear:focus {
  pointer-events: all;
  opacity: 1;
  z-index: auto;
  margin-left: var(--sh-input-text-icon-left-margin);
  --sh-icon-size-s: unset;
  --sh-icon-font-size-s: unset;
}

:host([condensed]:not([readonly]):not([disabled]):not(.empty):hover:not(.touch-device)) .input-wrapper:hover #clear,
:host([condensed]:not([readonly]):not([disabled]):not(.empty).touch-device):hover .input-wrapper:hover #clear,
:host([condensed]) #clear:focus,
:host([condensed]) .icon-wrapper > sh-icon[icon=error],
:host([condensed]) .icon-wrapper > sh-icon[icon=success],
:host([condensed][error]:not([value=""])) #clear {
  margin-left: var(--sh-input-text-icon-left-margin);
}

/* helper text */
.helper-text {
  padding: var(--sh-input-text-helper-text-padding);
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
  cursor: text !important;
}

:host([disabled]) .helper-text {
  color: var(--text-disabled);
}

/*condensed*/
:host([condensed]) .input-text-label,
:host([condensed]:not([show-tooltip])) input:focus + .input-text-label,
:host([condensed][textarea]) .textarea:focus + .input-text-label {
  display: none;
}

:host([condensed][textarea]) #shinputDiv {
  padding: var(--sh-input-text-condensed-textarea-padding);
}

:host(.empty[condensed][readonly]) input:focus + .input-text-label {
  display: flex;
}

:host(.empty[condensed]) .input-text-label {
  display: flex;
  top: var(--sh-input-text-condensed-label-top);
}

:host(.empty[textarea][condensed]) .input-text-label {
  line-height: var(--sh-input-text-textarea-line-height);
  top: unset;
  height: unset;
}

:host([condensed]) input,
:host([condensed][no-clear]) input {
  padding: var(--sh-input-text-condensed-padding);
  height: var(--sh-input-text-condensed-height);
}

/*textarea*/
:host([textarea]) .outer-wrapper {
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) background, 0s height;
  background: rgba(var(--ui-1), var(--opacity-7));
  border-bottom: 0.0625rem solid transparent;
  border-color: rgba(var(--ui-1), var(--opacity-5));
  border-radius: 0.125rem 0.125rem 0 0;
}

:host([textarea]) .input-text-label {
  background-color: transparent;
  transition: color var(--transition-time) ease-in-out, font var(--transition-time) ease-in-out, top var(--transition-time) ease-in-out, line-height var(--transition-time) ease-in-out;
  height: var(--sh-input-text-textarea-label-height);
  line-height: var(--sh-input-text-textarea-empty-label-line-height);
}

:host([textarea]:not([condensed])) .input-text-label {
  position: relative;
  width: 100%;
  top: unset;
  left: unset;
}

:host([textarea]:not([condensed])) .content-wrapper {
  border-radius: 0.125rem 0 0 0;
}

:host([textarea]) .textarea,
:host([readonly][textarea]) .textarea:focus {
  outline: none;
  border: none;
  font: var(--body-1);
  color: var(--text-primary);
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem;
  resize: none;
  overflow-y: auto;
  line-height: var(--sh-input-text-textarea-line-height);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

:host([textarea]) .input-wrapper {
  transition: none;
  border-bottom: none;
  border-color: unset;
  border-radius: unset;
  background: none;
}

:host([textarea]:not([condensed])) .textarea,
:host([textarea][readonly]:not([condensed])) .textarea {
  padding: var(--sh-input-text-textarea-padding);
}

:host(:not([disabled]):not([readonly])[textarea]) .outer-wrapper:hover {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([readonly]):not(.touch-device)[textarea]:hover) input:hover:not(:focus),
:host(:not([readonly]):not(.touch-device)[textarea]:hover) .textarea:hover:not(:focus),
:host([textarea][readonly]) .outer-wrapper,
:host([textarea][readonly]) .input-wrapper {
  background-color: transparent;
}

:host([textarea]) #shinputDiv {
  padding: var(--sh-input-text-textarea-label-padding);
  box-sizing: border-box;
}

:host([textarea]:not([readonly]):not([disabled])) #shinputDiv {
  cursor: text;
}

/* textarea auto height */
:host([textarea][rows=auto]) .content-wrapper,
:host([textarea][rows=auto]) textarea {
  display: flex;
  flex: 1;
}

:host([no-border]) .input-wrapper,
:host([no-border][readonly]) input:focus .input-wrapper,
:host([textarea][no-border]) .outer-wrapper,
:host([textarea][error][condensed][no-border]) .input-wrapper,
:host([textarea][error][no-border]:not([condensed])) .outer-wrapper,
:host([textarea][success][condensed][no-border]) .input-wrapper,
:host([textarea][success][no-border]:not([condensed])) .outer-wrapper,
:host([textarea][safety][condensed][no-border]) .input-wrapper,
:host([textarea][safety][no-border]:not([condensed])) .outer-wrapper {
  border-bottom: none;
}

:host([textarea]) .textarea {
  resize: none;
}

:host([textarea][resizable]) .textarea {
  resize: vertical;
}`;