// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-numeric-keypad.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-numeric-keypad-width=11rem] - width of the keypad container
   */
  --sh-numeric-keypad-width: 11rem;
  /* 
   * @cssprop [--sh-numeric-keypad-height=15rem] - height of the keypad container
   */
  --sh-numeric-keypad-height: 15rem;
  /* 
   * @cssprop [--sh-numeric-keypad-circle-button-width=3rem] - width of the circle buttons
   */
  --sh-numeric-keypad-circle-button-width: 3rem;
  /* 
   * @cssprop [--sh-numeric-keypad-circle-button-height=3rem] - height of the circle buttons
   */
  --sh-numeric-keypad-circle-button-height: 3rem;
  /* 
   * @cssprop [--sh-numeric-keypad-container-padding=1rem] - padding of the keypad container
   */
  --sh-numeric-keypad-container-padding: 1rem;
  /* 
   * @cssprop [--sh-numeric-keypad-button-margins=0.5rem] - margin for the circle buttons
   */
  --sh-numeric-keypad-button-margins: 0.5rem;
  /* 
   * @cssprop [--sh-numeric-keypad-button-center-margin=0 0.5rem] - margin for the circle buttons in the center column
   */
  --sh-numeric-keypad-button-center-margin: 0 0.5rem;
}

:host {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  max-height: 0rem;
  height: 0rem;
  width: 13rem;
  z-index: 9999;
  position: fixed;
  background-color: var(--base-3);
  box-sizing: border-box;
  top: 2.5rem;
  box-shadow: var(--shadow-raised);
  transition: var(--transition-time) opacity var(--transition-time) ease-in-out;
  border-radius: 0.125rem;
}

:host([visible]) {
  visibility: visible;
  opacity: 1;
  overflow: visible;
  max-height: fit-content;
  height: fit-content;
  padding: var(--sh-numeric-keypad-container-padding);
  display: table;
}

:host([condensed]) {
  top: 2rem;
}

.functions-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;
  box-sizing: border-box;
}

.functions-wrapper > ::slotted(*:first-child) {
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.functions-wrapper > ::slotted(:not(*:first-child)) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.keypad-main-frame {
  width: var(--sh-numeric-keypad-width);
  height: var(--sh-numeric-keypad-height);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.circle-buttons {
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) background;
  border-radius: 50%;
  width: var(--sh-numeric-keypad-circle-button-width);
  height: var(--sh-numeric-keypad-circle-button-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--ui-1), var(--opacity-6));
  font: var(--header-2);
  color: var(--text-primary);
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  -moz-user-select: none;
  line-height: 1.5rem;
}

.circle-buttons[value="1"],
.circle-buttons[value="4"],
.circle-buttons[value="7"],
.circle-buttons[value="."],
.circle-buttons[value=","] {
  margin-right: var(--sh-numeric-keypad-button-margins);
}

.circle-buttons[value="2"],
.circle-buttons[value="5"],
.circle-buttons[value="8"],
.circle-buttons[value="0"] {
  margin: var(--sh-numeric-keypad-button-center-margin);
}

.circle-buttons[value="3"],
.circle-buttons[value="6"],
.circle-buttons[value="9"],
.erase-button {
  margin-left: var(--sh-numeric-keypad-button-margins);
}

.erase-button {
  background-color: transparent;
}

:host(:not(.touch-device)) .circle-buttons:hover {
  background-color: rgba(var(--ui-1), var(--opacity-5));
}

.circle-buttons:active {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

:host([disabled]) .circle-buttons.dont-disable {
  pointer-events: all;
}

:host([disabled]) .circle-buttons:not(.dont-disable) {
  pointer-events: none;
  background-color: rgba(var(--ui-1), var(--opacity-7)) !important;
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([decimals="0"]) .circle-buttons.decimals-point {
  pointer-events: none;
  opacity: 0;
}

.footer-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}`;