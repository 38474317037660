// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tool.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tool-padding=0.1875rem] - padding of tool
   */
  --sh-tool-padding: 0.1875rem;
  /* 
   * @cssprop [--sh-tool-bottom-padding-with-icon=0.125rem] - padding-bottom of the tool when icon is present
   */
  --sh-tool-bottom-padding-with-icon: 0.125rem;
  /* 
   * @cssprop [--sh-tool-height=4.25rem] - height of tool
   */
  --sh-tool-height: 4.25rem;
  /* 
   * @cssprop [--sh-tool-width=var(--sh-tool-height)] - width of tool
   */
  --sh-tool-width: var(--sh-tool-height);
  /* 
   * @cssprop [--sh-tool-condensed-padding=0.0625rem] - padding of the condensed tool
   */
  --sh-tool-condensed-padding: 0.0625rem;
  /* 
   * @cssprop [--sh-tool-condensed-height=2.5rem] - height of the condensed tool
   */
  --sh-tool-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tool-condensed-width=var(--sh-tool-condensed-height)] - width of the condesed tool
   */
  --sh-tool-condensed-width: var(--sh-tool-condensed-height);
  /* 
   * @cssprop [--sh-tool-icon-value-label-font-size=0.75rem] - label font-size for select value and palette value of tool
   */
  --sh-tool-icon-value-label-font-size: 0.75rem;
  /* 
   * @cssprop [--sh-tool-icon-value-label-line-height=var(--sh-tool-icon-value-label-font-size)] - label line-height for select value and palette value of tool
   */
  --sh-tool-icon-value-label-line-height: var(--sh-tool-icon-value-label-font-size);
  /* 
   * @cssprop [--sh-tool-value-label-line-height=0.75rem] - label line-height for select and palette with value and without icon
   */
  --sh-tool-value-label-line-height: 0.75rem;
  /* 
   * @cssprop [--sh-tool-label-line-height=1rem] - line-height of label
   */
  --sh-tool-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tool-label-with-icon-height=1.5625rem] - height of label with icon property
   */
  --sh-tool-label-with-icon-height: 1.5625rem;
  /* 
   * @cssprop [--sh-tool-icon-label-margin-top=0.25rem] - margin-top of label with icon property
   */
  --sh-tool-icon-label-margin-top: 0.25rem;
  /* 
   * @cssprop [--sh-tool-palette-height=1.25rem] - height of the palette
   */
  --sh-tool-palette-height: 1.25rem;
  /* 
   * @cssprop [--sh-tool-palette-width=var(--sh-tool-palette-height)] - width of the palette
   */
  --sh-tool-palette-width: var(--sh-tool-palette-height);
  /* 
   * @cssprop [--sh-tool-arrow-height=0.375rem] - height of the arrow in palette
   */
  --sh-tool-arrow-height: 0.375rem;
  /* 
   * @cssprop [--sh-tool-arrow-width=var(--sh-tool-arrow-height)] - width of the arrow in palette
   */
  --sh-tool-arrow-width: var(--sh-tool-arrow-height);
  /* 
   * @cssprop [--sh-tool-arrow-border=0.188rem] - border of the arrow in palette
   */
  --sh-tool-arrow-border: 0.188rem;
  /* 
   * @cssprop [--sh-tool-popover-max-height=12.25rem] - max-height of the popover
   */
  --sh-tool-popover-max-height: 12.25rem;
  /* 
   * @cssprop [--sh-tool-condensed-palette-height=0.75rem] - height of condensed palette
   */
  --sh-tool-condensed-palette-height: 0.75rem;
  /* 
   * @cssprop [--sh-tool-condensed-palette-width=var(--sh-tool-condensed-palette-height)] - width of condensed palette
   */
  --sh-tool-condensed-palette-width: var(--sh-tool-condensed-palette-height);
  /* 
   * @cssprop [--sh-tool-bg-size=contain] - background size
   */
  --sh-tool-bg-size: contain;
  /* 
   * @cssprop [--sh-tool-bg-position=center center] - background position
   */
  --sh-tool-bg-position: center center;
  /* 
   * @cssprop [--sh-tool-bg-repeat=no-repeat] - background repeat
   */
  --sh-tool-bg-repeat: no-repeat;
}

:host {
  padding: var(--sh-tool-padding);
  border: 0.0625rem solid transparent;
  background: rgba(var(--ui-1), var(--opacity-7));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  cursor: pointer;
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) background;
  height: var(--sh-tool-height);
  min-height: var(--sh-tool-height);
  max-height: var(--sh-tool-height);
  flex: 1;
  position: relative;
  outline: 0rem;
}

:host([active]) {
  background: rgba(var(--ui-1), var(--opacity-5));
  border-color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon][label]:not([condensed]):not([label=""])) {
  padding-bottom: var(--sh-tool-bottom-padding-with-icon);
}

:host(:not([active]):not([disabled]):not(.touch-device):hover),
:host(:not([active]):not([disabled]):not(.touch-device):active) {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([stretch])) {
  width: var(--sh-tool-width);
  min-width: var(--sh-tool-width);
  max-width: var(--sh-tool-width);
  flex: 0;
}

:host([select][value]) .tool-label,
:host([palette][value]) .tool-label {
  font-size: var(--sh-tool-icon-value-label-font-size);
  line-height: var(--sh-tool-value-label-line-height);
}

:host([icononly][icon]) .tool-label,
:host([condensed][icon]) .tool-label {
  display: none;
}

:host([icon]) .tool-value {
  font: var(--body-2);
}

.tool-label,
.tool-value {
  height: auto;
  text-align: center;
  color: var(--text-primary);
  line-height: var(--sh-tool-label-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow-wrap: normal;
  word-break: normal;
}

:host([icon]) .tool-label {
  height: var(--sh-tool-label-with-icon-height);
  line-height: var(--sh-tool-icon-value-label-line-height);
  font-size: var(--sh-tool-icon-value-label-font-size);
  margin-top: var(--sh-tool-icon-label-margin-top);
}

.palette {
  box-sizing: border-box;
  height: var(--sh-tool-palette-height);
  width: var(--sh-tool-palette-width);
  position: absolute;
  top: -0.0625rem;
  right: -0.0625rem;
}

.arrow {
  box-sizing: border-box;
  height: var(--sh-tool-arrow-height);
  width: var(--sh-tool-arrow-width);
  border-top: solid var(--sh-tool-arrow-border) rgba(var(--ui-1), var(--opacity-4));
  border-right: solid var(--sh-tool-arrow-border) rgba(var(--ui-1), var(--opacity-4));
  border-left: solid var(--sh-tool-arrow-border) transparent;
  border-bottom: solid var(--sh-tool-arrow-border) transparent;
  position: absolute;
  top: 0.1875rem;
  right: 0.1875rem;
}

:host([readonly]) .arrow {
  opacity: 0;
}

:host(:not([palette]):not([select])) sh-popover {
  transition: none;
}

/* flat styles */
:host([flat]) {
  border-style: none;
  background: transparent;
  box-shadow: none;
}

:host([active][flat]) {
  border: 0.0625rem solid rgba(var(--ui-1), var(--opacity-4));
  background: rgba(var(--ui-1), var(--opacity-5));
}

:host(:not([active]):not([disabled]):not(.touch-device):hover[flat]),
:host(:not([active]):not([disabled]):not(.touch-device):active[flat]) {
  background: rgba(var(--ui-1), var(--opacity-7));
}

/* condensed styles */
:host([condensed]) {
  padding: var(--sh-tool-condensed-padding);
  height: var(--sh-tool-condensed-height);
  min-height: var(--sh-tool-condensed-height);
  max-height: var(--sh-tool-condensed-height);
}

:host([condensed]:not([stretch])) {
  min-width: var(--sh-tool-condensed-width);
  max-width: var(--sh-tool-condensed-width);
}

:host([condensed]) .palette {
  height: var(--sh-tool-condensed-palette-height);
  width: var(--sh-tool-condensed-palette-width);
}

/* disabled styles */
:host([disabled]) {
  cursor: default;
  pointer-events: none;
}

:host([disabled]) .tool-label,
:host([disabled]) .tool-value {
  color: var(--text-disabled);
}

:host([disabled]) .arrow {
  opacity: 0.2;
}

sh-popover:not([mobile]) .popover-slot-wrapper {
  max-height: calc(var(--sh-tool-popover-max-height) - 2 * var(--sh-popover-headerless-body-top-padding));
}

.popover-slot-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--sh-popover-headerless-body-top-padding);
  margin: calc(-1 * var(--sh-popover-headerless-body-top-padding));
}`;