// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getMultiLayerPatientList = () => ['xp_all_in_one', 'xp_adult_fluoro','adult_multi-level','child_multi-level','adult_multi-level_adipose'];
export const getPatientStates = (image) => {
  switch (image) {
    case 'xp_all_in_one':
      return _getXpAllInOneStates();
    case 'xp_adult_fluoro':
      return _getXpAdultFluoroStates();
    case 'adult_multi-level':
      return _getMultilineStates();
    case 'child_multi-level':
      return _getMultilineStates();
    case 'adult_multi-level_adipose':
      return _getMultilineStates();
    default:
      return _getXpAllInOneStates();
  }
};

const _getXpAllInOneStates = () => {
  return {
    xp_skull: {
      hidden: ['xp_skull_selection'],
      disabled: ['xp_skull_bone'],
    },
    xp_upper_extremities: {
      hidden: ['xp_upper_extremities_selection'],
      disabled: ['xp_upper_extremities_bone'],
    },
    xp_thorax: {
      hidden: ['xp_thorax_selection'],
      disabled: ['xp_thorax_bone'],
      active: ['xp_thorax_1'],
    },
    xp_shoulder_girdle: {
      hidden: [
        'xp_shoulder_girdle_selection',
        'xp_shoulder_girdle_bone',
        'xp_shoulder_girdle_default',
        'xp_thorax_bone',
      ],
      disabled: [],
    },
    xp_vertebral_column: {
      hidden: [
        'xp_vertebral_column_default',
        'xp_vertebral_column_bone',
        'xp_vertebral_column_selection',
        'xp_thorax_bone',
        'xp_clavicula_default',
      ],
      disabled: [],
    },
    xp_lower_extremities: {
      hidden: ['xp_lower_extremities_selection'],
      disabled: ['xp_lower_extremities_bone'],
    },
    xp_abdomen: {
      hidden: ['xp_abdomen_selection'],
      disabled: [],
      active: ['xp_abdomen_1'],
    },
    xp_pelvis: {
      hidden: ['xp_pelvis_selection'],
      disabled: ['xp_pelvis_bone'],
    },
  };
};
const _getXpAdultFluoroStates = () => {
  return {
    xp_fluoro_head: {
      disabled: ['oral_cavity'],
      active: ['xp_fluoro_head'],
    },
    xp_fluoro_esophagus: {
      disabled: ['esophagus_1', 'esophagus_2'],
      active: ['xp_fluoro_esophagus'],
    },
    xp_fluoro_lung: {
      disabled: ['lung_segments'],
      active: ['xp_fluoro_lung'],
    },
    xp_fluoro_upper_abdomen: {
      disabled: ['stomach_segments', 'esophagus_2', 'pancreas'],
      active: ['xp_fluoro_upper_abdomen'],
    },
    xp_fluoro_urinary_system: {
      disabled: ['bladder_ureter', 'kidneys'],
      active: ['xp_fluoro_urinary_system'],
    },
    xp_fluoro_digestive_system: {
      disabled: ['small_intestine', 'colon'],
      active: ['xp_fluoro_digestive_system'],
    },
    xp_fluoro_reproductive_system: {
      disabled: ['uterus', 'bladder_ureter'],
      active: ['xp_fluoro_reproductive_system'],
    },
  };
};
const _getMultilineStates = () => {
  return {
    xp_skull: {
      hidden: ['xp_skull_selection','xp_contrast_seperator'],
      disabled: ['xp_skull_bone'],
    },
    xp_upper_extremities: {
      hidden: ['xp_upper_extremities_selection','xp_contrast_seperator'],
      disabled: ['xp_upper_extremities_bone'],
    },
    xp_thorax: {
      hidden: ['xp_thorax_selection','xp_contrast_seperator'],
      disabled: ['xp_thorax_bone','xp_contrast_seperator'],
      active: ['xp_thorax_1'],
    },
    xp_shoulder_girdle: {
      hidden: [
        'xp_shoulder_girdle_selection',
        'xp_shoulder_girdle_bone',
        'xp_shoulder_girdle_default',
        'xp_thorax_bone',
        'xp_contrast_seperator',
      ],
      disabled: [],
    },
    xp_vertebral_column: {
      hidden: [
        'xp_vertebral_column_default',
        'xp_vertebral_column_bone',
        'xp_vertebral_column_selection',
        'xp_thorax_bone',
        'xp_clavicula_default',
        'xp_contrast_seperator',
      ],
      disabled: [],
    },
    xp_lower_extremities: {
      hidden: ['xp_lower_extremities_selection','xp_contrast_seperator'],
      disabled: ['xp_lower_extremities_bone'],
    },
    xp_abdomen: {
      hidden: ['xp_abdomen_selection','xp_contrast_seperator'],
      disabled: [],
      active: ['xp_abdomen_1'],
    },
    xp_pelvis: {
      hidden: ['xp_pelvis_selection','xp_contrast_seperator'],
      disabled: ['xp_pelvis_bone'],
    },
  };
};
