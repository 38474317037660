// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-media-progress.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-media-progress-video-progress-top=-0.25rem] - video progress bar top position
   */
  --sh-media-progress-video-progress-top: -0.25rem;
  /* 
   * @cssprop [--sh-media-progress-buffer-progress-top=-0.375rem] - buffer progress bar top position
   */
  --sh-media-progress-buffer-progress-top: -0.375rem;
  /* 
   * @cssprop [--sh-media-progress-thumb-diameter=0.875rem] - Diameter of the progress thumb on hover
   */
  --sh-media-progress-thumb-diameter: 0.875rem;
  /* 
   * @cssprop [--sh-media-progress-tooltip-min-width=3rem] - Min width of the progress tooltip
   */
  --sh-media-progress-tooltip-min-width: 3rem;
  /* 
   * @cssprop [--sh-media-progress-tooltip-height=1.5rem] - Height of the progress tooltip
   */
  --sh-media-progress-tooltip-height: 1.5rem;
  /* 
   * @cssprop [--sh-media-progress-progress-border-radius=0.125rem] - border radius of the progress input
   */
  --sh-media-progress-progress-border-radius: 0.125rem;
}

:host {
  width: 100%;
  display: flex;
  align-self: center;
}

.progress-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --halo-ring-radius: calc((2.5rem - var(--sh-media-progress-thumb-diameter)) / 2);
  padding: 0.25rem 0;
  margin: -0.25rem 0;
}

.input {
  height: var(--sh-media-controls-progress-height);
  position: absolute;
  z-index: 1;
  appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: rgba(var(--ui-1), var(--opacity-6));
  border-radius: var(--sh-media-progress-progress-border-radius);
  left: var(--sh-media-controls-input-left);
}

input[type=range]::-webkit-slider-thumb {
  display: none;
}

:host(.touch-device) input[type=range]::-webkit-slider-thumb,
:host(:hover) input[type=range]::-webkit-slider-thumb {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  width: var(--sh-media-progress-thumb-diameter);
  height: var(--sh-media-progress-thumb-diameter);
  border-radius: 50%;
  background: rgba(var(--ui-2), var(--opacity-1));
  transition: 0s ease-in-out;
  position: relative;
  z-index: 2;
}

input[type=range]::-moz-range-thumb {
  display: none;
  appearance: none;
  background: transparent;
  border: none;
}

:host(:hover) input[type=range]::-moz-range-thumb {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  width: var(--sh-media-progress-thumb-diameter);
  height: var(--sh-media-progress-thumb-diameter);
  border-radius: 50%;
  background: rgba(var(--ui-2), var(--opacity-1));
  transition: 0s ease-in-out;
  position: relative;
  z-index: 2;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.slider-progress {
  height: var(--sh-media-controls-progress-height);
  width: 0%;
  background-color: rgba(var(--ui-3), var(--opacity-1));
  border-radius: var(--sh-media-progress-progress-border-radius);
  position: absolute;
  left: var(--sh-media-controls-progress-left);
  z-index: 1;
  pointer-events: none;
}

:host(:hover) .slider-progress {
  background-color: rgba(var(--ui-2), var(--opacity-1));
}

.buffer-progress {
  height: var(--sh-media-controls-progress-height);
  width: 0%;
  background-color: rgba(var(--ui-1), var(--opacity-5));
  border-radius: var(--sh-media-progress-progress-border-radius);
  position: absolute;
  left: var(--sh-media-controls-progress-left);
  pointer-events: none;
  z-index: 0;
}

.tooltip {
  display: none;
  position: absolute;
  pointer-events: none;
  min-width: var(--sh-media-progress-tooltip-min-width);
  width: max-content;
  left: var(--thumb-mid-position);
  transform: translateX(-50%) translateY(-100%);
  --thumb-radius: calc(var(--sh-media-progress-thumb-diameter) / 2);
  --value-percent: calc((var(--gradient-value) - 0) / 100);
  --available-slider-length: calc((100% - var(--sh-media-progress-thumb-diameter)));
  --thumb-left-position: calc((var(--value-percent)) * (var(--available-slider-length)));
  --thumb-mid-position: calc(var(--thumb-left-position) + var(--thumb-radius));
}

:host([isdrag]) .tooltip {
  display: flex;
}

:host(.touch-device) input[type=range]::-webkit-slider-thumb {
  background-clip: padding-box;
  box-sizing: unset;
}

:host(:hover) input[type=range]::-webkit-slider-thumb {
  display: block;
}

input[type=range]::-webkit-slider-runnable-track {
  cursor: pointer;
}

:host(.touch-device) input[type=range]::-webkit-slider-runnable-track {
  margin-left: calc(0rem - var(--halo-ring-radius));
  margin-right: calc(0rem - var(--halo-ring-radius));
}

:host(.touch-device) .active.input::-webkit-slider-thumb {
  display: block;
  border: calc((2.5rem - var(--sh-media-progress-thumb-diameter)) / 2) solid rgba(var(--ui-3), var(--opacity-5));
}`;