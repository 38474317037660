// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-breadcrumb-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-breadcrumb-item-item-height=1.5rem] - height of breadcrumb item
   */
  --sh-breadcrumb-item-item-height: 1.5rem;
  /* 
   * @cssprop [--sh-breadcrumb-item-item-max-width=10rem] - max width of breadcrumb item
   */
  --sh-breadcrumb-item-item-max-width: 10rem;
  /* 
   * @cssprop [--sh-breadcrumb-item-item-line-height=1.25rem] - line height of breadcrumb item
   */
  --sh-breadcrumb-item-item-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-breadcrumb-item-item-icon-margin=-0.25rem 0] - icon margin of breadcrumb
   */
  --sh-breadcrumb-item-item-icon-margin: -0.25rem 0;
  /* 
   * @cssprop [--sh-breadcrumb-item-item-icon-width=2rem] - icon max width
   */
  --sh-breadcrumb-item-item-icon-width: 2rem;
}

:host {
  float: left;
  margin-right: 0.25rem;
  height: var(--sh-breadcrumb-item-item-height);
  display: inline-flex;
  align-items: center;
}

sh-text {
  color: var(--text-secondary);
  text-decoration: none;
  margin-right: 0.25rem;
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
  max-width: var(--sh-breadcrumb-item-item-max-width);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font: var(--body-1);
  line-height: var(--sh-breadcrumb-item-item-line-height);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  outline: transparent;
}

sh-icon {
  color: rgba(var(--ui-1), var(--opacity-600));
  margin: -0.25rem 0;
  max-width: var(--sh-breadcrumb-item-item-icon-width);
}

/* State Styles */
:host([active]) sh-text {
  color: var(--color-text-active);
  cursor: default;
  max-width: none;
}

/* onPress */
:host sh-text:active {
  color: var(--color-text-active);
}

/* hover */
:host(:not(.no-hovermq)) sh-text:hover:not(:active),
sh-text.hover:not(:active) {
  color: var(--text-primary);
}

/* focus */
.item-focused {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}`;