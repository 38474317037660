// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import toolbarStyles from './sh-toolbar.lit.scss.js';
/**
 * @slot - Takes plain text as a child element.
 */
let SHToolbar = class SHToolbar extends LitElement {
  static get styles() {
    return [toolbarStyles];
  }
  render() {
    return html` <slot></slot> `;
  }
};
__decorate([property({ type: String, reflect: true })], SHToolbar.prototype, 'orientation', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'in-segment-theme' })],
  SHToolbar.prototype,
  'inSegmentTheme',
  void 0
);
SHToolbar = __decorate([customElement('sh-toolbar')], SHToolbar);
export { SHToolbar };
