// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-patient-illustration.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:host(:not([image=xp_adult_fluoro])) svg {
  height: var(--image-height, 36.25rem);
}

:host([image=xp_adult_fluoro]) svg {
  height: var(--image-height, 57.375rem);
}

.region[hover] {
  fill: rgba(var(--ui-1), var(--opacity-5));
}

.region {
  fill: rgba(var(--ui-1), var(--opacity-6));
  cursor: pointer;
  transition: var(--transition-time) all ease-in-out;
}

.region[disabled] {
  fill: transparent;
  pointer-events: none;
}

.region[active] {
  fill: rgba(var(--ui-2), var(--opacity-3));
}

.outline {
  fill: rgba(var(--ui-1), var(--opacity-5));
}

.vascular {
  fill: rgba(var(--functional-red), 1);
  pointer-events: none;
}

.bone,
.organ {
  fill: rgba(var(--ui-1), var(--opacity-5));
  pointer-events: none;
}

.bone[disabled],
.organ[disabled] {
  fill: rgba(var(--ui-1), var(--opacity-7));
  pointer-events: none;
}

.transparent {
  fill: transparent;
}

svg {
  -webkit-tap-highlight-color: transparent;
}

:host([image=adult_anatomical]) svg #contrast_seperator,
:host([image=child_anatomical]) svg #contrast_seperator,
:host([image=adult_anatomical_adipose]) svg #contrast_seperator {
  fill: var(--contrast-seperator);
}
:host([image=adult_anatomical]) svg .region,
:host([image=child_anatomical]) svg .region,
:host([image=adult_anatomical_adipose]) svg .region {
  fill: var(--patient-illustration-region-idle, rgba(var(--ui-1), 0.08));
  cursor: pointer;
  transition: var(--transition-time) all ease-in-out;
  isolation: isolate;
}
:host([image=adult_anatomical]) svg .region[hover],
:host([image=child_anatomical]) svg .region[hover],
:host([image=adult_anatomical_adipose]) svg .region[hover] {
  fill: var(--patient-illustration-region-hover, rgba(var(--ui-1), 0.2));
}
:host([image=adult_anatomical]) svg .region[active],
:host([image=child_anatomical]) svg .region[active],
:host([image=adult_anatomical_adipose]) svg .region[active] {
  fill: var(--patient-illustration-region-active, rgba(var(--ui-0), 0.6)) !important;
}
:host([image=adult_anatomical]) svg .region[disabled],
:host([image=child_anatomical]) svg .region[disabled],
:host([image=adult_anatomical_adipose]) svg .region[disabled] {
  fill: transparent;
  pointer-events: none;
}
:host([image=adult_anatomical]) svg .outline,
:host([image=child_anatomical]) svg .outline,
:host([image=adult_anatomical_adipose]) svg .outline {
  fill: var(--patient-illustration-outline, rgba(var(--ui-1), 0.06));
}
:host([image=adult_anatomical]) svg .bone,
:host([image=child_anatomical]) svg .bone,
:host([image=adult_anatomical_adipose]) svg .bone {
  isolation: isolate;
}

:host([image=adult_multi-level]) svg #xp_contrast_seperator,
:host([image=child_multi-level]) svg #xp_contrast_seperator,
:host([image=adult_multi-level_adipose]) svg #xp_contrast_seperator {
  fill: var(--contrast-seperator);
  pointer-events: none;
}
:host([image=adult_multi-level]) svg .outline,
:host([image=child_multi-level]) svg .outline,
:host([image=adult_multi-level_adipose]) svg .outline {
  fill: var(--patient-illustration-multilevel-outline, rgba(var(--ui-1), 0.06));
}
:host([image=adult_multi-level]) svg .region,
:host([image=child_multi-level]) svg .region,
:host([image=adult_multi-level_adipose]) svg .region {
  fill: var(--patient-illustration-multilevel-region-idle, rgba(var(--ui-1), 0.08));
  cursor: pointer;
  transition: var(--transition-time) all ease-in-out;
  isolation: isolate;
}
:host([image=adult_multi-level]) svg .region[disabled],
:host([image=child_multi-level]) svg .region[disabled],
:host([image=adult_multi-level_adipose]) svg .region[disabled] {
  fill: transparent;
  pointer-events: none;
}
:host([image=adult_multi-level]) svg .region[hover],
:host([image=child_multi-level]) svg .region[hover],
:host([image=adult_multi-level_adipose]) svg .region[hover] {
  fill: var(--patient-illustration-multilevel-region-hover, rgba(var(--ui-1), 0.2));
}
:host([image=adult_multi-level]) svg .bone[disabled],
:host([image=child_multi-level]) svg .bone[disabled],
:host([image=adult_multi-level_adipose]) svg .bone[disabled] {
  fill: rgba(var(--ui-1), 0.1);
}
:host([image=adult_multi-level]) svg .bone,
:host([image=child_multi-level]) svg .bone,
:host([image=adult_multi-level_adipose]) svg .bone {
  fill: var(--patient-illustration-multilevel-bone, rgba(var(--ui-1), 0.5));
  isolation: isolate;
}
:host([image=adult_multi-level]) svg #subselection .region,
:host([image=child_multi-level]) svg #subselection .region,
:host([image=adult_multi-level_adipose]) svg #subselection .region {
  fill: var(--patient-illustration-subselection-region-idle, rgba(var(--ui-1), 0.2));
}
:host([image=adult_multi-level]) svg #subselection .region[hover],
:host([image=child_multi-level]) svg #subselection .region[hover],
:host([image=adult_multi-level_adipose]) svg #subselection .region[hover] {
  fill: var(--patient-illustration-subselection-region-hover, rgba(var(--ui-1), 0.3));
}
:host([image=adult_multi-level]) svg #subselection .region[active],
:host([image=child_multi-level]) svg #subselection .region[active],
:host([image=adult_multi-level_adipose]) svg #subselection .region[active] {
  fill: var(--patient-illustration-subselection-region-active, rgba(var(--ui-0), 0.6));
}
:host([image=adult_multi-level]) svg #subselection .bone #xp_contrast_seperator,
:host([image=child_multi-level]) svg #subselection .bone #xp_contrast_seperator,
:host([image=adult_multi-level_adipose]) svg #subselection .bone #xp_contrast_seperator {
  fill: var(--contrast-seperator);
}`;