// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tooltip.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tooltip-max-content-width=25rem] - maximum width of the tooltip
   */
  --sh-tooltip-max-content-width: 25rem;
  /* 
   * @cssprop [--sh-tooltip-content-padding=0.25rem 0.5rem] - padding for the tooltip
   */
  --sh-tooltip-content-padding: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-label-line-height=1rem] - line height of the tooltip label
   */
  --sh-tooltip-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tooltip-padding-title=0.5rem] - padding for the tooltip with title
   */
  --sh-tooltip-padding-title: 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-title-line-height=1rem] - line height of the tooltip title
   */
  --sh-tooltip-title-line-height: 1rem;
  /* 
   * @cssprop [--sh-tooltip-margin-value=0.5rem] - margin value of the tooltip title and error icon
   */
  --sh-tooltip-margin-value: 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-content-border-radius=0.125rem] - border-radius of the tooltip
   */
  --sh-tooltip-content-border-radius: 0.125rem;
  /* 
   * @cssprop [--sh-tooltip-margin-top-value=0rem] - margin top for the tooltip
   */
  --sh-tooltip-margin-top-value: 0rem;
}

:host {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  color: var(--text-primary);
  transition-property: opacity, visibility, z-index;
  transition-timing-function: ease-in-out;
  transition-duration: var(--transition-time);
  transition-delay: 0s;
  width: max-content;
  height: auto;
  margin: auto;
  margin-top: var(--sh-tooltip-margin-top-value) !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  display: block;
  background: var(--base-4);
  box-shadow: var(--shadow-tooltip);
  border-radius: var(--sh-tooltip-content-border-radius);
  padding: var(--sh-tooltip-content-padding);
  z-index: 999;
  max-width: var(--sh-tooltip-max-content-width);
  box-sizing: border-box;
  word-break: break-word;
}

:host([title]) {
  padding: var(--sh-tooltip-padding-title);
}

:host .tooltip-label {
  font: var(--body-2);
  color: var(--text-primary);
  line-height: var(--sh-tooltip-label-line-height);
}

:host .tooltip-title {
  font: var(--title-2);
  color: var(--text-primary);
  display: block;
  line-height: var(--sh-tooltip-title-line-height);
  margin-bottom: var(--sh-tooltip-margin-value);
  word-break: break-all;
}

.label-wrapper {
  display: flex;
  flex-direction: row;
}

#tooltipIcon {
  margin-right: var(--sh-tooltip-margin-value);
}

:host([visible]) {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

:host([visible][delay]) {
  transition-delay: 0.4s;
}`;