// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-workline-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-workline-item-svg-height-width=3.5rem] - height of the progress ring svg
   */
  --sh-workline-item-svg-height-width: 3.5rem;
  /* 
   * @cssprop [--sh-workline-item-type-info-height-width=3rem] - height of the workline item wrapper when it is of information type
   */
  --sh-workline-item-type-info-height-width: 3rem;
  /* 
   * @cssprop [--sh-workline-item-progress-circle=3.25rem] - height or width of the progress circle
   */
  --sh-workline-item-progress-circle: 3.25rem;
  /* 
   * @cssprop [--sh-workline-item-circle-stroke-width=0.1875rem] - stroke width of the circle
   */
  --sh-workline-item-circle-stroke-width: 0.1875rem;
  /* 
   * @cssprop [--sh-workline-item-circle-stroke-dasharray=0,5.83] - stroke width of the circle
   */
  --sh-workline-item-circle-stroke-dasharray: 0,5.83;
  /* 
   * @cssprop [--sh-workline-item-label-height=2rem] - height of the workline item label
   */
  --sh-workline-item-label-height: 2rem;
  /* 
   * @cssprop [--sh-workline-item-wrapper-margin=0.75rem] - margin of the item wrapper
   */
  --sh-workline-item-wrapper-margin: 0.75rem;
  /* 
   * @cssprop [--sh-workline-item-badge-wrapper-position=0.5rem] - badge wrapper position top and right 
   */
  --sh-workline-item-badge-wrapper-position: 0.5rem;
  /* 
   * @cssprop [--sh-workline-item-badge-wrapper-current-position=-0.25rem] - badge wrapper position top and right for current item 
   */
  --sh-workline-item-badge-wrapper-current-position: -0.25rem;
  /* 
   * @cssprop [--sh-workline-item-icon-size-m=0.5rem] - size of the icon badge
   */
  --sh-workline-item-icon-size-m: 0.5rem;
  /* 
   * @cssprop [--sh-workline-item-triangle-height=4rem] - height of the two triangles for current item
   */
  --sh-workline-item-triangle-height: 4rem;
  /* 
   * @cssprop [--sh-workline-item-triangle-border-left-right=0.375rem] - border left right of the two triangles in the current item
   */
  --sh-workline-item-triangle-border-left-right: 0.375rem;
  /* 
   * @cssprop [--sh-workline-item-triangle-border-top-bottom=0.5rem] - border top bottom of the two triangles in the current item
   */
  --sh-workline-item-triangle-border-top-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-workline-item-badge-slotted-position-top=-0.3rem] - position top value for the badge when info slots have been used
   */
  --sh-workline-item-badge-slotted-position-top: -0.3rem;
  /* 
   * @cssprop [--sh-workline-item-badge-slotted-position-right=-0.5rem] - position right value for the badge when info slots have been used
   */
  --sh-workline-item-badge-slotted-position-right: -0.5rem;
  /* 
   * @cssprop [--sh-workline-item-thumbnail-width-height=3rem] - height and width of the thumbnail wrapper
   */
  --sh-workline-item-thumbnail-width-height: 3rem;
  /* 
   * @cssprop [--sh-workline-item-active-icon-size-m=2rem] - size of the badge icon when active
   */
  --sh-workline-item-active-icon-size-m: 2rem;
  /* 
   * @cssprop [--sh-workline-item-info-line-height=1.5rem] - line height of the workline item info
   */
  --sh-workline-item-info-line-height: 1.5rem;
}

:host {
  z-index: 0;
  position: relative;
  position: -webkit-sticky;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  background: rgba(var(--ui-1), var(--opacity-5));
  border-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  text-align: center;
  box-sizing: border-box;
  transition: var(--transition-time) all ease-in-out, left 0s linear, right 0s linear, top 0s linear, "z-index" 0s linear, "margin" 0s linear, "background-image" 0s linear, "box-shadow" 0s linear;
  cursor: pointer;
}

:host([type=information]) {
  background: transparent;
}
:host([type=information]) > .item-wrapper {
  margin: 0rem;
  background: transparent;
  height: var(--sh-workline-item-type-info-height-width);
  width: var(--sh-workline-item-type-info-height-width);
}
:host([type=information]) > .item-wrapper > #itemIcon {
  color: rgba(var(--ui-1), var(--opacity-3));
}

/* item wrapper */
.item-wrapper {
  margin: var(--sh-workline-item-wrapper-margin);
  position: relative;
  background: rgba(var(--ui-6), var(--opacity-1));
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--sh-workline-item-svg-height-width);
  width: var(--sh-workline-item-svg-height-width);
  box-sizing: border-box;
  border-radius: 50%;
  transition: var(--transition-time) all ease-in-out;
}

/* thumbnail */
.thumbnail-wrapper {
  position: absolute;
  height: var(--sh-workline-item-thumbnail-width-height);
  width: var(--sh-workline-item-thumbnail-width-height);
  overflow: hidden;
  border-radius: 50%;
  display: block;
}

#itemThumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain;
  opacity: var(--opacity-3);
  top: 50%;
  left: 50%;
  image-rendering: -webkit-optimize-contrast;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

:host([active]) #itemThumbnail {
  opacity: var(--opacity-1);
}

/* label, info */
.item-label {
  position: absolute;
  bottom: calc(-1 * (var(--sh-workline-item-label-height) + 0.5rem));
  height: var(--sh-workline-item-label-height);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  left: 0rem;
}

.item-label,
.item-info {
  color: var(--text-secondary);
}

:host([active]) .item-label,
:host([active]) .item-info {
  color: var(--text-primary);
}

.item-info,
#itemIcon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* triangles */
.triangles {
  width: 0;
  height: var(--sh-workline-item-triangle-height);
  position: absolute;
  border-left: var(--sh-workline-item-triangle-border-left-right) solid transparent;
  border-right: var(--sh-workline-item-triangle-border-left-right) solid transparent;
  border-bottom: var(--sh-workline-item-triangle-border-top-bottom) solid rgba(var(--ui-1), var(--opacity-1));
  border-top: var(--sh-workline-item-triangle-border-top-bottom) solid rgba(var(--ui-1), var(--opacity-1));
}

:host([progress="100"][new]) #progress-ring {
  stroke: rgba(var(--ui-0), var(--opacity-1));
}

/* progress ring */
.progress-wrapper {
  height: var(--sh-workline-item-svg-height-width);
  width: var(--sh-workline-item-svg-height-width);
  position: absolute;
  transition: var(--transition-time) all ease-in-out;
  opacity: var(--opacity-3);
}

svg {
  stroke-width: calc(var(--sh-workline-item-circle-stroke-width) + 0.0625rem);
  fill: transparent;
  cx: 50%;
  cy: 50%;
  transition: var(--transition-time) all ease-in-out;
  transform: rotate(-90deg);
}

:host([progress="0"]) svg,
:host([progress="100"]) svg {
  stroke-width: var(--sh-workline-item-circle-stroke-width);
  r: 1.53125rem;
}

:host(:not([indeterminate])) .progress-wrapper > svg:nth-child(2) {
  animation: none;
}

/* badges */
:host(:not([empty-info])[active]) .badge-wrapper,
:host(:not([empty-info-right])[active]) .badge-wrapper {
  top: var(--sh-workline-item-badge-slotted-position-top);
  right: var(--sh-workline-item-badge-slotted-position-right);
}

:host(:not([empty-info-right])[current]) .info-right-wrapper,
:host(:not([empty-info-right])[active]) .info-right-wrapper {
  margin-right: 0.5rem;
}

.badge-wrapper-current {
  display: flex;
  position: absolute;
  right: var(--sh-workline-item-badge-wrapper-current-position);
  top: var(--sh-workline-item-badge-wrapper-current-position);
  transition: 0.2s all ease-in-out;
}

.badge-wrapper {
  display: flex;
  position: absolute;
  right: var(--sh-workline-item-badge-wrapper-position);
  top: var(--sh-workline-item-badge-wrapper-position);
  transition: var(--transition-time) all ease-in-out;
}

.badge-wrapper-current > ::slotted(sh-icon),
.badge-wrapper > ::slotted(sh-icon) {
  transition: 0s all ease-in-out;
  --sh-icon-size-m: var(--sh-workline-item-icon-size-m);
  --sh-icon-size-s: var(--sh-workline-item-icon-size-m);
  --sh-icon-font-size-m: var(--sh-workline-item-icon-size-m);
  --sh-icon-font-size-s: var(--sh-workline-item-icon-size-m);
}

:host(:not([empty-info-right])[active]) .badge-wrapper > ::slotted(sh-icon),
:host(:not([empty-info])[active]) .badge-wrapper > ::slotted(sh-icon) {
  line-height: var(--sh-workline-item-active-icon-size-m);
  font-size: var(--sh-workline-item-active-icon-size-m);
  max-height: var(--sh-workline-item-active-icon-size-m);
  min-height: var(--sh-workline-item-active-icon-size-m);
  min-width: var(--sh-workline-item-active-icon-size-m);
}

/* info slot */
.info-right-wrapper,
.info-wrapper {
  opacity: 0;
  width: auto;
  display: flex;
  max-width: 0rem;
  overflow: hidden;
  align-items: center;
  transition: var(--transition-time) max-width ease-in-out;
}

:host([active]) .info-right-wrapper,
:host([current]) .info-right-wrapper,
:host([active]) .info-wrapper,
:host([current]) .info-wrapper {
  opacity: 1;
  max-width: 12.5rem;
}

.info-wrapper > ::slotted(*:first-child) {
  margin-left: 1rem;
}

.info-wrapper > ::slotted(*:not(:first-child)) {
  margin-left: 0.5rem;
}

.info-right-wrapper > ::slotted(*) {
  margin-right: 0.5rem;
}

/* active */
:host([active]) {
  background: rgba(var(--ui-1), var(--opacity-5));
  box-shadow: inset 0rem 0rem 0rem 0.0625rem rgba(var(--ui-1), var(--opacity-1));
}

:host([active][type=information]) {
  background: transparent;
  box-shadow: none;
}

:host([active]) .item-wrapper {
  background: rgba(var(--ui-7), var(--opacity-1));
}

:host([type=information][active]) .item-wrapper {
  background: transparent;
}

:host([active]) .progress-wrapper {
  opacity: var(--opacity-1);
}

/* readonly */
:host([disabled]),
:host([readonly]) {
  pointer-events: none;
  cursor: default;
}

/* disabled */
:host([disabled]) .item-label,
:host([disabled]) .item-info {
  color: var(--text-disabled);
}

:host([disabled]) .progress-wrapper {
  opacity: var(--opacity-5);
}

:host([disabled]) {
  opacity: var(--opacity-4);
}

/* indeterminate */
:host([indeterminate]) .progress-wrapper > svg:nth-child(2) {
  animation: indeterminate 1.5s linear infinite;
}

@keyframes indeterminate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* message */
sh-popover {
  width: fit-content;
  width: -moz-fit-content;
  height: auto;
  cursor: default;
  text-align: left;
  max-width: 75rem;
  max-height: 50rem;
  transition: 0s transform linear;
}

:host(.no-transitions),
:host(.no-transitions) *,
:host(.no-transitions) * > ::slotted(*) {
  transition: all 0s linear;
}

:host(:not([disabled]):not([readonly]).repeat-animate) {
  animation-name: repeat-animate;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0, 0.5, 1, 0.5);
}

:host(:not([disabled]):not([readonly]).repeat-animate) .item-label {
  animation-name: fade-in;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

:host(:not([disabled]):not([readonly]).repeat-animate) .item-wrapper {
  animation-name: shrink-expand-shrink;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes shrink-expand-shrink {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes repeat-animate {
  0% {
    transform: translate(-2rem, 0.9375rem);
    height: calc(0.7 * var(--sh-workline-item-item-wrapper-height));
    width: calc(0.7 * var(--sh-workline-item-item-wrapper-height));
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 50%;
  }
  50% {
    transform: translate(0rem, -0.5rem);
    height: calc(1.2 * var(--sh-workline-item-item-wrapper-height));
    width: calc(1.2 * var(--sh-workline-item-item-wrapper-height));
    margin-left: 0.5rem;
    margin-right: -0.5rem;
    border-radius: 50%;
  }
  100% {
    transform: translate(0rem, 0rem);
    height: calc(var(--sh-workline-item-item-wrapper-height));
    width: calc(var(--sh-workline-item-item-wrapper-height));
    border-radius: 50%;
  }
}
.progress-ring-svg {
  transform: rotate(-90deg);
  height: var(--sh-workline-item-svg-height-width);
  width: var(--sh-workline-item-svg-height-width);
  position: absolute;
  left: 0;
  top: 0;
}

:host([type=information]) sh-popover[position=top] {
  transform: translateY(-1rem);
}

:host(.leftfix[float]) {
  position: absolute;
  z-index: 3;
  margin: 0;
  background-color: rgba(var(--ui-5), var(--opacity-1));
}

:host(.rightfix[float]) {
  position: absolute;
  z-index: 3;
  margin: 0;
  background-color: rgba(var(--ui-5), var(--opacity-1));
}

.item-info {
  line-height: var(--sh-workline-item-info-line-height);
}

.circle-ring {
  cx: calc(var(--sh-workline-item-svg-height-width) / 2);
  cy: calc(var(--sh-workline-item-svg-height-width) / 2);
  r: calc(var(--sh-workline-item-progress-circle) / 2);
}

#dash-ring {
  stroke-width: var(--sh-workline-item-circle-stroke-width);
}`;