// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
export const baseFontSize = 16;
export const calculatedBaseFontSize = getCalculatedBaseFontSize();
function getCalculatedBaseFontSize() {
  const calculatedBaseFontSize = parseFloat(
    window.getComputedStyle(document.querySelector('html'), null).getPropertyValue('font-size')
  );
  return calculatedBaseFontSize;
}
