// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { ShuiEnvironment } from './environment.js';
import './modernizr.js';

/*
hovermq property of Modernizr is true when hover media query is present in target device and
false when target is touch mobile device.

deviceIdentifier.isTouchDevice() can be used to add CSS classes to components and apply styles
based on target device.

If there is no enforcement of touch styles from the environment,
we will get the information from the browser.
*/

export const deviceIdentifier = {
  isTouch: false,
  isTouchDevice: function isTouchDevice() {
    if (ShuiEnvironment.isTouchDevice !== null) {
      this.isTouch = ShuiEnvironment.isTouchDevice;
      return this.isTouch;
    }
    if (!Modernizr.hovermq) {
      this.isTouch = true;
    } else {
      this.isTouch = false;
    }
    return this.isTouch;
  },
};
