// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-input-number.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-input-number-status-text-padding=0.25rem 0.5rem 0 0.5rem] - padding of status text (warning, hint, ..)
   */
  --sh-input-number-status-text-padding: 0.25rem 0.5rem 0 0.5rem;
  /* 
   * @cssprop [--sh-input-number-left-icon-padding=0 0 0 0.25rem] - padding of left icon
   */
  --sh-input-number-left-icon-padding: 0 0 0 0.25rem;
  /* 
   * @cssprop [--sh-input-number-right-icon-padding=0] - padding of right icon
   */
  --sh-input-number-right-icon-padding: 0;
  /* 
   * @cssprop [--sh-input-number-icon-size=1.9375rem] - size of the icon left and right
   */
  --sh-input-number-icon-size: 1.9375rem;
  /* 
   * @cssprop [--sh-input-number-input-right-padding=0.25rem] - right padding of the input
   */
  --sh-input-number-input-right-padding: 0.25rem;
}

:host {
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  position: relative;
  display: inline-block;
  flex-direction: column;
  --sh-input-text-icon-wrapper-right-padding: var(--sh-input-number-input-right-padding);
}
:host sh-icon {
  margin-top: auto;
  margin-bottom: auto;
}
:host sh-icon[icon=left-s],
:host sh-icon[icon=minus-light] {
  margin: var(--sh-input-number-left-icon-padding);
}
:host sh-icon[icon=right-s],
:host sh-icon[icon=plus-light] {
  margin: var(--sh-input-number-right-icon-padding);
}
:host sh-text {
  width: 100%;
}

:host([condensed]) sh-icon[icon=left-s],
:host([condensed]) sh-icon[icon=right-s],
:host([condensed]) sh-icon[icon=minus-light],
:host([condensed]) sh-icon[icon=plus-light] {
  --sh-icon-size-m: var(--sh-input-number-icon-size);
}

/* Stepper Styles */
:host([disabled]) .icon-container > * {
  pointer-events: none;
}

/* helper text */
.helper-text {
  padding: var(--sh-input-number-status-text-padding);
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
}

:host([disabled]) .helper-text {
  color: var(--text-disabled);
}`;