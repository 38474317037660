// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-stepper.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-stepper-circle-dimension=2rem] - stepper circle width
   */
  --sh-stepper-circle-dimension: 2rem;
  /* 
   * @cssprop [--sh-stepper-circle-line-height=2rem] - stepper circle line height
   */
  --sh-stepper-circle-line-height: 2rem;
  /* 
   * @cssprop [--sh-stepper-margin-bottom=0.5rem] - stepper circle height
   */
  --sh-stepper-margin-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-stepper-border-width=0.125rem] - circle border width
   */
  --sh-stepper-border-width: 0.125rem;
  /* 
   * @cssprop [--sh-stepper-line-top=0.9375rem] - stepper line top
   */
  --sh-stepper-line-top: 0.9375rem;
  /* 
   * @cssprop [--sh-stepper-label-padding=0 0.5rem] - label text padding
   */
  --sh-stepper-label-padding: 0 0.5rem;
  /* 
   * @cssprop [--sh-stepper-label-line-height=1.25rem] - label line height
   */
  --sh-stepper-label-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-stepper-info-line-height=1rem] - info line height
   */
  --sh-stepper-info-line-height: 1rem;
  /* 
   * @cssprop [--sh-stepper-info-margin-top=0.125rem] - info margin top
   */
  --sh-stepper-info-margin-top: 0.125rem;
  /* 
   * @cssprop [--sh-stepper-badge-top=-0.3125rem] - badge top
   */
  --sh-stepper-badge-top: -0.3125rem;
  /* 
   * @cssprop [--sh-stepper-badge-right=-0.4375rem] - badge top
   */
  --sh-stepper-badge-right: -0.4375rem;
  /* 
   * @cssprop [--sh-stepper-condensed-circle-dimension=0.875rem] - condensed circle width
   */
  --sh-stepper-condensed-circle-dimension: 0.875rem;
  /* 
   * @cssprop [--sh-stepper-condensed-border-width=0.125rem] - condensed border width
   */
  --sh-stepper-condensed-border-width: 0.125rem;
  /* 
   * @cssprop [--sh-stepper-condensed-badge-top=-0.1875rem] - condensed badge top
   */
  --sh-stepper-condensed-badge-top: -0.1875rem;
  /* 
   * @cssprop [--sh-stepper-condensed-badge-right=-0.1875rem] - condensed badge top
   */
  --sh-stepper-condensed-badge-right: -0.1875rem;
  /* 
   * @cssprop [--sh-stepper-line-border-width=0.125rem] - line border width
   */
  --sh-stepper-line-border-width: 0.125rem;
  /* 
   * @cssprop [--sh-stepper-line-width=1.5rem] - line  width
   */
  --sh-stepper-line-width: 1.5rem;
  /* 
   * @cssprop [--sh-stepper-condensed-line-top=0.4375rem] - condensed stepper line top
   */
  --sh-stepper-condensed-line-top: 0.4375rem;
  /* 
   * @cssprop [--sh-stepper-condensed-line-width=0.625rem] - line border width
   */
  --sh-stepper-condensed-line-width: 0.625rem;
  /* 
   * @cssprop [--sh-stepper-icon-size=1rem] - Size of the  icon
   */
  --sh-stepper-icon-size: 1rem;
  /* 
   * @cssprop [--sh-stepper-font-size=1rem] - font Size of the icon
   */
  --sh-stepper-font-size: 1rem;
  /* 
   * @cssprop [--sh-stepper-vertical-stepper-wrapper-min-height=3.875rem] - min height of the vertical stepper wrapper
   */
  --sh-stepper-vertical-stepper-wrapper-min-height: 3.875rem;
  /* 
   * @cssprop [--sh-stepper-condensed-vertical-stepper-wrapper-min-height=1.625rem] - min height of the condensed vertical stepper wrapper
   */
  --sh-stepper-condensed-vertical-stepper-wrapper-min-height: 1.625rem;
  /* 
   * @cssprop [--sh-stepper-label-info-padding=0.75rem 0rem] - padding of the label-info
   */
  --sh-stepper-label-info-padding: 0.75rem 0rem;
  /* 
   * @cssprop [--sh-stepper-condensed-label-info-padding=0.5rem 0rem] - padding of the condensed label-info
   */
  --sh-stepper-condensed-label-info-padding: 0.5rem 0rem;
  /* 
   * @cssprop [--sh-stepper-label-info-margin-left=0.25rem] - margin left of the label-info
   */
  --sh-stepper-label-info-margin-left: 0.25rem;
  /* 
   * @cssprop [--sh-stepper-condensed-label-info-margin-left=0rem] - margin left of the condensed label-info
   */
  --sh-stepper-condensed-label-info-margin-left: 0rem;
  /* 
   * @cssprop [--sh-stepper-vertical-stepper-circle-margin=0.5rem 0rem] - margin of the circle in vertical stepper
   */
  --sh-stepper-vertical-stepper-circle-margin: 0.5rem 0rem;
  /* 
   * @cssprop [--sh-stepper-vertical-line-left=0.9375rem] - vertical stepper line left
   */
  --sh-stepper-vertical-line-left: 0.9375rem;
  /* 
   * @cssprop [--sh-stepper-vertical-condensed-circle-margin=0.125rem 0rem] - vertical condensed circle margin
   */
  --sh-stepper-vertical-condensed-circle-margin: 0.125rem 0rem;
  /* 
   * @cssprop [--sh-stepper-vertical-condensed-line-left=0.375rem] - left line of vertical condensed stepper
   */
  --sh-stepper-vertical-condensed-line-left: 0.375rem;
  /* 
   * @cssprop [--sh-stepper-vertical-line-height=1.5rem] - vertical line border height
   */
  --sh-stepper-vertical-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-stepper-vertical-condensed-line-height=0.5625rem] - vertical condensed line border height
   */
  --sh-stepper-vertical-condensed-line-height: 0.5625rem;
}

:host {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  outline: 0;
}

:host([vertical]) {
  align-items: flex-start;
}

.vertical-stepper-wrapper {
  display: flex;
  box-sizing: border-box;
  min-height: var(--sh-stepper-vertical-stepper-wrapper-min-height);
  align-items: center;
  width: 100%;
}

:host([condensed]) .vertical-stepper-wrapper {
  min-height: var(--sh-stepper-condensed-vertical-stepper-wrapper-min-height);
}

sh-text {
  padding: var(--sh-stepper-label-padding);
  box-sizing: border-box;
  color: var(--text-secondary);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:host(:not([vertical])) sh-text {
  text-align: center;
}

.label-info {
  padding: var(--sh-stepper-label-info-padding);
  margin-left: var(--sh-stepper-label-info-margin-left);
}

:host([condensed]) .label-info {
  padding: var(--sh-stepper-condensed-label-info-padding);
  margin-left: var(--sh-stepper-condensed-label-info-margin-left);
}

:host(:not([condensed][vertical])) .info {
  margin-top: var(--sh-stepper-info-margin-top);
}

.info {
  line-height: var(--sh-stepper-info-line-height);
}

.circle {
  height: var(--sh-stepper-circle-dimension);
  width: var(--sh-stepper-circle-dimension);
  margin-bottom: var(--sh-stepper-margin-bottom);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  font: var(--header-2);
  color: var(--text-secondary);
  line-height: var(--sh-stepper-circle-line-height);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
  background: rgba(var(--ui-1), var(--opacity-6));
  border: var(--sh-stepper-border-width) solid transparent;
  position: relative;
  box-sizing: border-box;
}

:host([vertical]) .circle {
  flex-shrink: 0;
  margin: var(--sh-stepper-vertical-stepper-circle-margin);
}

:host(:hover) .circle,
:host(.hover) .circle,
:host(:hover) .label {
  color: var(--text-primary);
}

:host(:hover) .info {
  color: var(--text-secondary);
}

.line-left,
.line-right {
  position: absolute;
  top: var(--sh-stepper-line-top);
  width: calc(50% - var(--sh-stepper-line-width));
  border-bottom: var(--sh-stepper-line-border-width) solid rgba(var(--ui-1), var(--opacity-6));
}

.line-top,
.line-bottom {
  position: absolute;
  left: var(--sh-stepper-vertical-line-left);
  height: calc(50% - var(--sh-stepper-vertical-line-height));
  border-left: var(--sh-stepper-line-border-width) solid rgba(var(--ui-1), var(--opacity-6));
}

.line-left {
  left: 0rem;
}

.line-right {
  right: 0rem;
}

.line-top {
  top: 0rem;
}

.line-bottom {
  bottom: 0rem;
}

:host(:first-of-type) .line-left,
:host(:last-of-type) .line-right,
:host(:first-of-type) .line-top,
:host(:last-of-type) .line-bottom {
  display: none;
}

:host([active]) .circle {
  color: rgba(var(--ui-7), var(--opacity-1));
  background: rgba(var(--ui-1), var(--opacity-3));
}

:host([color=primary][active]) .circle {
  color: var(--text-white);
  background: rgba(var(--ui-3), var(--opacity-1));
}

sh-text[size=title-1] {
  line-height: var(--sh-stepper-label-line-height);
  transition: var(--hover-transition-time) color;
}

:host([active]) sh-text[size=title-1] {
  color: var(--text-primary);
}

:host([half]) .circle {
  background: linear-gradient(90deg, rgba(var(--ui-1), var(--opacity-5)) 50%, transparent 50%);
  background-size: calc(100% + 0.25rem);
  background-position: -0.125rem;
  border-color: var(--text-disabled);
}

:host([disabled]) {
  cursor: default;
  pointer-events: none;
}

:host([disabled]) .circle {
  color: var(--text-disabled);
  background: transparent;
  border-color: rgba(var(--ui-1), var(--opacity-6));
}

:host([disabled]) sh-text {
  color: var(--text-disabled);
}

.stepper-type {
  --sh-icon-size-xs: var(--sh-stepper-icon-size);
  --sh-icon-font-size-xs: var(--sh-stepper-font-size);
}

:host(:not([condensed])) .stepper-type {
  position: absolute;
  top: var(--sh-stepper-badge-top);
  right: var(--sh-stepper-badge-right);
}

:host([condensed]) .circle {
  border-width: var(--sh-stepper-condensed-border-width);
  color: transparent;
  height: var(--sh-stepper-condensed-circle-dimension);
  width: var(--sh-stepper-condensed-circle-dimension);
  margin: 0.0625rem 0.125rem;
}

:host([condensed][vertical]) .circle {
  margin: var(--sh-stepper-vertical-condensed-circle-margin);
}

:host([condensed][active]:not([disabled]):not([type])) .circle {
  background: rgba(var(--ui-1), var(--opacity-3));
}

:host([condensed]:not([disabled]):not([type])) .circle {
  background: rgba(var(--ui-1), var(--opacity-5));
}

:host([color=primary][condensed][active]:not([disabled]):not([type])) .circle {
  background: rgba(var(--ui-3), var(--opacity-1));
}

:host([condensed]) .line-left,
:host([condensed]) .line-right {
  top: var(--sh-stepper-condensed-line-top);
  width: calc(50% - var(--sh-stepper-condensed-line-width));
  border-bottom: var(--sh-stepper-condensed-border-width) solid rgba(var(--ui-1), var(--opacity-6));
}

:host([condensed][vertical]) .line-top,
:host([condensed][vertical]) .line-bottom {
  left: var(--sh-stepper-vertical-condensed-line-left);
  height: calc(50% - var(--sh-stepper-vertical-condensed-line-height));
  border-left: var(--sh-stepper-line-border-width) solid rgba(var(--ui-1), var(--opacity-6));
}

:host #stepperCircle.item-focused {
  outline: var(--focus-outline);
  outline-offset: -0.0625rem;
}

:host([type][condensed]) .circle {
  background: transparent;
  border-color: transparent;
}`;