// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-list-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-list-item-padding=0.25rem 1rem] - padding of the list item
   */
  --sh-list-item-padding: 0.25rem 1rem;
  /* 
   * @cssprop [--sh-list-item-min-height=2rem] - min height of the list item
   */
  --sh-list-item-min-height: 2rem;
  /* 
   * @cssprop [--sh-list-item-icon-margin=0.5rem] - margin of the icon and the arrow icon
   */
  --sh-list-item-icon-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-line-height=1.25rem] - line height of the label
   */
  --sh-list-item-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-list-item-info-line-height=1rem] - line height of the info
   */
  --sh-list-item-info-line-height: 1rem;
  /* 
   * @cssprop [--sh-list-item-text-padding=0.25rem 0] - top and bottom padding of the text content
   */
  --sh-list-item-text-padding: 0.25rem 0;
  /* 
   * @cssprop [--sh-list-item-arrow-right-margin=0.5rem] - margin of the arrow icon
   */
  --sh-list-item-arrow-right-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-text-wrapper-left-margin=0.5rem] - left margin of the text wrapper
   */
  --sh-list-item-text-wrapper-left-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-right-slotted-margin=0.5rem] - right margin of the text wrapper
   */
  --sh-list-item-right-slotted-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-select-margin-right=0.5rem] - margin right of the checkbox/radio button
   */
  --sh-list-item-select-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-icon-size-s=2rem] - size of the icon
   */
  --sh-list-item-icon-size-s: 2rem;
  /* 
   * @cssprop [--sh-list-item-bottom-first-margin=0.5rem] - first child margin in bottom slot
   */
  --sh-list-item-bottom-first-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-bottom-slot-margin=0.5rem] - bottom slotted child margins
   */
  --sh-list-item-bottom-slot-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-content-line-height=1.25rem] - line height for the direct content added
   */
  --sh-list-item-content-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-list-item-checkbox-right=0rem] - checkbox right margin
   */
  --sh-list-item-checkbox-right: 0rem;
  /* 
   * @cssprop [--sh-list-item-select-wrapper-size=2rem] - select wrapper height and width
   */
  --sh-list-item-select-wrapper-size: 2rem;
  /* 
   * @cssprop [--sh-list-item-info-margin=0.125rem 0] - top and bottom margin of the info
   */
  --sh-list-item-info-margin: 0.125rem 0;
  /* 
   * @cssprop [--sh-list-item-left-slotted-margin=0.25rem] - right margin of the left slotted items
   */
  --sh-list-item-left-slotted-margin: 0.25rem;
  /* 
   * @cssprop [--sh-list-item-right-slotted-first-margin=0.5rem] - left margin of the first right slotted item
   */
  --sh-list-item-right-slotted-first-margin: 0.5rem;
  /* 
   * @cssprop [--sh-list-item-divider-inset-margin=1rem] - divider inset margin to the left or right
   */
  --sh-list-item-divider-inset-margin: 1rem;
}

:host {
  position: relative;
  --sh-checkbox-margin-right: var(--sh-list-item-checkbox-right);
  color: var(--text-primary);
  display: block;
  height: fit-content;
}

:host(:not([disabled])[clickable]),
:host(:not([disabled])[toggle]),
:host(:not([disabled])[selectable]),
:host(:not([disabled])[href]) {
  cursor: pointer;
}

.outer-wrapper {
  min-height: var(--sh-list-item-min-height);
  font: var(--body-1);
  line-height: var(--sh-list-item-content-line-height);
  height: fit-content;
  padding: var(--sh-list-item-padding);
  display: flex;
  align-items: center;
  gap: var(--sh-list-item-text-wrapper-left-margin);
}

:host(:hover:not([disabled])) .outer-wrapper {
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host([active]:not([disabled])) .outer-wrapper {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([disabled])) .outer-wrapper:active {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host([active]:not([selectable]):not([toggle]):not([href]):not([clickable])) .outer-wrapper,
:host(:hover:not([selectable]):not([toggle]):not([href]):not([clickable])) .outer-wrapper {
  background: transparent;
}

:host slot[name=left]::slotted(*) {
  margin-right: var(--sh-list-item-left-slotted-margin);
}

:host slot[name=right]::slotted(*:not(:first-child)) {
  margin-left: var(--sh-list-item-right-slotted-margin);
}

:host slot[name=right]::slotted(*:first-child) {
  margin-left: var(--sh-list-item-right-slotted-first-margin);
}

:host slot[name=bottom]::slotted(*:last-child) {
  margin-bottom: var(--sh-list-item-bottom-slot-margin);
}

:host slot[name=bottom]::slotted(*:not(:last-child)) {
  margin-bottom: var(--sh-list-item-bottom-first-margin);
}

.label {
  line-height: var(--sh-list-item-line-height);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.info {
  line-height: var(--sh-list-item-info-line-height);
  margin: var(--sh-list-item-info-margin);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

:host(:not([label]):not([info])) .slot-body {
  display: flex;
  align-items: center;
  height: 100%;
}

.icon {
  margin-right: var(--sh-list-item-icon-margin);
  min-height: var(--sh-list-item-icon-size-s);
  min-width: var(--sh-list-item-icon-size-s);
}

.text-wrapper {
  flex: 1;
  padding: var(--sh-list-item-text-padding);
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.right-slot-wrapper {
  display: flex;
  padding: 0.25rem 0;
}

.right {
  display: flex;
}

.arrow {
  margin-left: var(--sh-list-item-icon-margin);
  margin-right: calc(-1 * var(--sh-list-item-arrow-right-margin));
}

.content-wrapper {
  flex: 1;
}

.select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sh-list-item-select-wrapper-size);
  height: var(--sh-list-item-select-wrapper-size);
}

:host([vertical-align=top]) .select-wrapper,
:host([vertical-align=top]) .arrow {
  align-self: flex-start;
}

:host([vertical-align=top]) .content-wrapper {
  margin-top: 0rem;
}

:host([divider=full-width]) .divider-wrapper {
  left: 0rem;
}

:host([divider=inset]) .divider-wrapper {
  left: var(--sh-list-item-divider-inset-margin);
  right: var(--sh-list-item-divider-inset-margin);
}

.select {
  width: fit-content;
}

.divider-wrapper {
  bottom: 0;
  left: var(--sh-list-item-divider-inset-margin);
  position: absolute;
  right: 0;
  border-bottom: 1px solid rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([disabled])[active]) .divider-wrapper,
:host(:not([disabled]):hover) .divider-wrapper {
  right: 0;
  left: 0;
}

:host(:not([disabled]):hover) .divider-wrapper {
  border-color: rgba(var(--ui-9), var(--opacity-1));
}

:host(:not([disabled])[active]) .divider-wrapper {
  border-color: rgba(var(--divider), var(--opacity-1));
}

:host(:not([selectable]):not([toggle]):not([href]):not([clickable]):hover) .divider-wrapper {
  border-color: rgba(var(--ui-1), var(--opacity-6));
}

:host(:not([selectable]):not([toggle]):not([href]):not([clickable])[divider=inset]:hover) .divider-wrapper {
  right: var(--sh-list-item-divider-inset-margin);
  left: var(--sh-list-item-divider-inset-margin);
}

:host(:not([selectable]):not([toggle]):not([href]):not([clickable])[divider=inset-left]:hover) .divider-wrapper {
  left: var(--sh-list-item-divider-inset-margin);
}

:host(:not([selectable]):not([toggle]):not([href]):not([clickable])[divider=full-width]:hover) .divider-wrapper {
  right: 0;
  left: 0;
}

:host(:not([selectable]):not([toggle]):not([href]):not([clickable])[active])::before,
:host(:not([selectable]):not([toggle]):not([href]):not([clickable])[active]):hover::before,
:host(:not([selectable]):not([toggle]):not([href]):not([clickable]):hover)::before {
  background-color: transparent;
}

:host(:not([disabled]):not([divider=none])[active])::before,
:host(:not([disabled]):not([divider=none])[active]):hover::before,
:host(:not([disabled]):not([divider=none]):hover)::before {
  content: "";
  position: absolute;
  top: -0.0625rem;
  left: 0;
  width: 100%;
  height: 0.0625rem;
}

:host(:not([disabled]):not([divider=none]):hover)::before {
  background-color: rgba(var(--ui-9), var(--opacity-1));
}

:host(:not([disabled]):not([divider=none])[active])::before,
:host(:not([disabled]):not([divider=none])[active]):hover::before {
  background-color: rgba(var(--divider), var(--opacity-1));
}

.draggable {
  margin-left: var(--sh-list-item-text-wrapper-left-margin);
}

:host([disabled]) {
  pointer-events: none;
}
:host([disabled]) .outer-wrapper {
  opacity: 0.2;
}

:host(:not([disabled]):not([divider=none]):active:hover)::before {
  background-color: rgba(var(--divider), var(--opacity-1));
}

:host(:not([disabled]):active:hover) .divider-wrapper {
  border-color: rgba(var(--divider, var(--opacity-1)));
}`;