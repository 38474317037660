// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from './attribute-changed-event-dispatcher.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T;

export const CheckboxToggleMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class CheckboxToggleMixinElement extends superClass {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;

    /**Default false. Defines if a Toggle is selected or not. Changes when clicked.*/
    @property({ type: Boolean, reflect: true })
    public active: boolean | undefined;

    /** Default false. If true, the active state will be gray instead of orange. */
    @property({ type: Boolean, reflect: true })
    public neutral: boolean | undefined;

    override updated(changedProperties: Map<string, unknown>): void {
      super.updated(changedProperties);
      const listOfProperties = ['active', 'neutral'];
      dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    }
    override firstUpdated() {
      this.addEventListener('click', () => this.handleClick());
      this.addEventListener('focus', () => this.handleFocus());
      this.addEventListener('blur', () => this.handleBlur());
    }
    protected handleClick() {
      this.active = !this.active;
    }
  }
  return CheckboxToggleMixinElement as unknown as Constructor<CheckboxToggleMixinInterface> & T;
};

/**
 * due to typescript limitations we must explicitly define a clas
 * with the externally used api here and cast the return value of our
 * mixin to this (intermediate unknown cast is so we don't need to have to list irrelevant fields)
 * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
 */
export declare class CheckboxToggleMixinInterface {
  public active: boolean | undefined;
  protected handleClick: () => void;
}
