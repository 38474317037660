// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { baseFontSize } from '../../utils/base-font-size.js';
import paneStyles from './sh-pane.lit.scss.js';
/**
 * @slot unnamed - slot where the main content is rendered. Takes any component as child.
 * @slot header - Takes children such as Tabs.
 * @slot functions - Takes Icons as children.
 * @slot footer - Takes Buttons as children.
 * @slot condensed - Slot where the condensed content goes. Takes Icons and Tools as children.
 */
let SHPane = class SHPane extends LitElement {
  constructor() {
    super(...arguments);
    /** Default `expanded`. Defines the size of the pane. Possible values are `expanded`, `collapsed`, `condensed`, `resizable` or a fixed value (in XXpx, XXrem, XXvw and XX% - percentile values will be converted into XXvw). It is updated upon clicking on the arrow in case any behavior is set. If set to `resizable` the width can be resized in the horizontal direction with a minimum value (352px).
     * @type {expanded|collapsed|condensed|resizable}
     */
    this.size = 'expanded';
    /** Default center. Defines the position of the arrow in case `behavior` is set. Accepted values are `top`, `center` and `bottom`.
     * @type {top|center|bottom}
     */
    this.arrowPosition = 'center';
    /** Defines the maximum width upto which pane can be resized. It is applicable only when `resizable` attribute is set. */
    this.maxResizeWidth = `75%`;
    this.emptyFooter = true;
    this.emptyHeader = true;
    this.isResize = false;
  }
  static get styles() {
    return [sharedStyles, paneStyles];
  }
  render() {
    return html`
      <div
        class="pane-wrapper"
        ?empty-footer="${this.emptyFooter}"
        ?empty-header="${this.emptyHeader}"
        ?is-Resize="${this.isResize}"
        style="${styleMap(this.getWidth() || {})}"
      >
        <div class="header-wrapper transition">
          <slot
            name="header"
            id="header"
            @slotchange="${(e) => this.updateHeaderSlotFlag(e.target)}"
          ></slot>
        </div>
        ${!this.label || !this.emptyHeader
          ? ''
          : html` <div class="label-wrapper transition">
              <sh-text size="super-header" class="pane-label">${this.label}</sh-text>
              <slot name="functions" id="functions"></slot>
            </div>`}

        <div class="body-wrapper transition">
          <slot id="body"></slot>
        </div>
        <div class="footer-wrapper transition">
          <slot
            name="footer"
            id="footer"
            @slotchange="${(e) => this.updateFooterSlotFlag(e.target)}"
          ></slot>
        </div>
        ${this.behavior === 'condense' || this.size === 'condensed'
          ? html` <div class="condensed-wrapper">
              <slot name="condensed" id="condensed"></slot>
            </div>`
          : ''}
        ${this.behavior
          ? html` <div class="arrow-wrapper" @click="${() => this.handleExpand()}">
              <sh-icon icon="arrow-right-s" button="" class="arrow"></sh-icon>
            </div>`
          : ''}
        <div class="resize-handle"></div>
      </div>
    `;
  }
  firstUpdated() {
    var _a;
    /** @ignore */
    const resizeHandle =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.resize-handle');
    resizeHandle === null || resizeHandle === void 0
      ? void 0
      : resizeHandle.addEventListener('mousedown', () => {
          //removing previously added events to body
          this.removeEventListenersFromDocument();
          // adding new events to body
          if (this.size === 'resizable' || this.resizable) {
            this.addEventListenersToDocument();
          }
        });
  }
  connectedCallback() {
    super.connectedCallback();
    /** @ignore */
    this.documentMouseDownListener = this.documentMouseDownActions.bind(this);
    /** @ignore */
    this.documentMouseUpListener = this.documentMouseUpActions.bind(this);
    /** @ignore */
    this.documentMouseMoveListener = this.documentMouseMoveActions.bind(this);
    /** @ignore */
    this.documentMouseOverListener = this.documentMouseOverActions.bind(this);
    /** @ignore */
    this.documentDragStartListener = this.documentDragStartActions.bind(this);
    /** @ignore */
    this.body = document.body;
    /** @ignore */
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.hundredPercentWidth = entry.contentRect.width;
        this.handleMaxResizeChange();
      }
    });
    this.resizeObserver.observe(this.body);
  }
  disconnectedCallback() {
    var _a;
    this.removeEventListenersFromDocument();
    (_a = this.resizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
    super.disconnectedCallback();
  }
  updated(changedProperties) {
    const listOfProperties = ['label', 'size', 'arrowPosition', 'behavior'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    if (changedProperties.has('size')) {
      this.updateSize();
      // if there is a change in size property while it has resizable property,
      // then that new size becomes the resized width
      const isNumberSize =
        /[0-9]*\.?[0-9]+(px|rem|vw)/.test(this.size) || /[0-9]*\.?[0-9]+(%)/.test(this.size);
      if (this.resizable && isNumberSize && this.resizedWidth) {
        this.resizedWidth = this.fixSize;
      }
    }
    if (changedProperties.has('maxResizeWidth')) {
      this.handleMaxResizeChange();
    }
  }
  getWidth() {
    if (this.size === 'resizable' || this.resizable) {
      const isNumberSize =
        /[0-9]*\.?[0-9]+(px|rem|vw)/.test(this.size) || /[0-9]*\.?[0-9]+(%)/.test(this.size);
      // if the pane is resized, then this condition follows
      if (this.resizedWidth) {
        return { '--sh-pane-wrapper-width': this.resizedWidth };
      }
      // if it is resizable and has number size without being resized- the initial case for size=300px and resizable
      else if (this.resizable && isNumberSize) {
        return { '--sh-pane-wrapper-width': this.fixSize };
      } else {
        return;
      }
    } else if (
      /[0-9]*\.?[0-9]+(px|rem|vw)/.test(this.size) ||
      /[0-9]*\.?[0-9]+(%)/.test(this.size)
    ) {
      return { '--sh-pane-wrapper-width': this.fixSize };
    } else {
      return;
    }
  }
  updateSize() {
    //test for valid set sizes (px, rem, %)
    if (/[0-9]*\.?[0-9]+(px|rem|vw)/.test(this.size)) {
      this.fixSize = this.size;
    } else if (/[0-9]*\.?[0-9]+(%)/.test(this.size)) {
      this.fixSize = `${parseInt(this.size, 10)}vw`;
    }
  }
  updateHeaderSlotFlag(slot) {
    const headerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyHeader = headerNodes === 0;
  }
  updateFooterSlotFlag(slot) {
    const headerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = headerNodes === 0;
  }
  documentMouseDownActions(e) {
    var _a;
    if (
      e.composedPath()[0] ===
      ((_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.resize-handle'))
    ) {
      this.isResize = true;
    }
  }
  documentMouseMoveActions(e) {
    this.setPaneWidth(e);
  }
  documentMouseOverActions(e) {
    this.setPaneWidth(e);
  }
  documentMouseUpActions() {
    this.removeEventListenersFromDocument();
    this.isResize = false;
  }
  documentDragStartActions(e) {
    if (this.isResize) {
      e.preventDefault();
    }
  }
  setPaneWidth(e) {
    const isLeftPane = this.getAttribute('slot') === 'left' ? true : false;
    const defaultPane = 22;
    if (this.isResize) {
      let resizedWidth;
      if (isLeftPane) {
        resizedWidth = e.clientX;
      } else {
        resizedWidth = this.hundredPercentWidth - e.clientX;
      }
      if (resizedWidth >= this.maxResizeLimit) {
        resizedWidth = this.maxResizeLimit;
      }
      // converting 352px to its equal value in rem (22rem) and comparing if the rem value of the
      // current value is below 22
      else if (this.size === 'resizable' && resizedWidth / baseFontSize <= defaultPane) {
        // converting the rem value to px here so that it can be re-converted to rem below
        resizedWidth = defaultPane * baseFontSize;
      }
      this.resizedWidth = `${resizedWidth}px`;
    }
  }
  addEventListenersToDocument() {
    document.body.addEventListener('mousedown', this.documentMouseDownListener);
    document.body.addEventListener('mouseup', this.documentMouseUpListener);
    document.body.addEventListener('mousemove', this.documentMouseMoveListener);
    document.body.addEventListener('mouseover', this.documentMouseOverListener);
    document.body.addEventListener('dragstart', this.documentDragStartListener);
  }
  removeEventListenersFromDocument() {
    document.body.removeEventListener('mouseup', this.documentMouseUpListener);
    document.body.removeEventListener('mousedown', this.documentMouseDownListener);
    document.body.removeEventListener('mousemove', this.documentMouseMoveListener);
    document.body.removeEventListener('mouseover', this.documentMouseOverListener);
    document.body.removeEventListener('dragstart', this.documentDragStartListener);
  }
  handleMaxResizeChange() {
    /** @ignore */
    this.maxLimitChecker = document.createElement('div');
    document.body.appendChild(this.maxLimitChecker);
    this.maxLimitChecker.classList.add('max-resize-checker');
    this.maxLimitChecker.style.width = `${this.maxResizeWidth}`;
    /** @ignore */
    this.maxResizeLimit = this.maxLimitChecker.getBoundingClientRect().width;
    document.body.removeChild(this.maxLimitChecker);
    this.maxLimitChecker = null;
  }
  // switches expanded prop on button click
  handleExpand() {
    if (this.size === 'expanded' || /[0-9]*\.?[0-9]+(px|%|rem|vw)/.test(this.size)) {
      //close pane
      if (this.behavior === 'condense') {
        this.size = 'condensed';
      } else if (this.behavior === 'collapse') {
        this.size = 'collapsed';
      }
    } else if (this.size === 'collapsed' && this.behavior === 'condense') {
      this.size = 'condensed';
      this.behavior = 'collapse';
    } else if (this.size === 'condensed' && this.behavior === 'collapse') {
      this.size = 'collapsed';
      this.behavior = 'condense';
    } else if (this.fixSize || this.resizedWidth) {
      if (this.resizedWidth) {
        this.size = this.resizedWidth;
      } else {
        this.size = this.fixSize;
      }
    } else {
      //open
      this.size = 'expanded';
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHPane.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHPane.prototype, 'behavior', void 0);
__decorate([property({ type: String, reflect: true })], SHPane.prototype, 'size', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'arrow-position' })],
  SHPane.prototype,
  'arrowPosition',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'max-resize-width' })],
  SHPane.prototype,
  'maxResizeWidth',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHPane.prototype, 'resizable', void 0);
__decorate([property({ type: Boolean })], SHPane.prototype, 'emptyFooter', void 0);
__decorate([property({ type: Boolean })], SHPane.prototype, 'emptyHeader', void 0);
__decorate([property({ type: Boolean })], SHPane.prototype, 'isResize', void 0);
__decorate([property({ type: String })], SHPane.prototype, 'resizedWidth', void 0);
__decorate([property({ type: String })], SHPane.prototype, 'fixSize', void 0);
SHPane = __decorate([customElement('sh-pane')], SHPane);
export { SHPane };
