// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-toolbar.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  display: flex;
  background: rgba(var(--ui-6), var(--opacity-2));
  border-radius: 0.125rem;
}

:host([orientation=horizontal]),
:host([slot=top]),
:host([slot=bottom]) {
  flex-direction: row;
}

:host([orientation=vertical]),
:host([slot=left]),
:host([slot=right]) {
  flex-direction: column;
}`;