// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
export class DateFormatHelper {
  /**
   * Formats the raw Date to the specified format,separator and locale.
   * All format parts except MMM are formatted using english locale.
   * @param {Date} rawDate
   * @param {String} format
   * @param {String} separator
   * @param {String} locale
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  static getFormattedDate(rawDate, format, separator, locale) {
    let actualFormat = this.insertSeperator(separator, format);
    if (format.includes('MMM')) {
      actualFormat = actualFormat.replace('MMM', this.getFormattedPart(rawDate, 'MMM', locale));
    } else if (format.includes('MM')) {
      actualFormat = actualFormat.replace('MM', this.getFormattedPart(rawDate, 'MM', 'en'));
    } else if (format.includes('M')) {
      actualFormat = actualFormat.replace('M', this.getFormattedPart(rawDate, 'M', 'en'));
    }
    if (format.includes('DD')) {
      actualFormat = actualFormat.replace('DD', this.getFormattedPart(rawDate, 'DD', 'en'));
    } else if (format.includes('D')) {
      actualFormat = actualFormat.replace('D', this.getFormattedPart(rawDate, 'D', 'en'));
    }
    if (format.includes('YYYY')) {
      actualFormat = actualFormat.replace('YYYY', this.getFormattedPart(rawDate, 'YYYY', 'en'));
    } else if (format.includes('YY')) {
      actualFormat = actualFormat.replace('YY', this.getFormattedPart(rawDate, 'YY', 'en'));
    }
    return actualFormat;
  }
  /**
   * Checks if the datestring is a valid date or not for the given format,separator,locale and todaysDate.
   * @param {String} dateString
   * @param {String} format
   * @param {String} separator
   * @param {String} locale
   * @param {Date} todaysDate
   * @returns
   */
  static isValidDate(dateString, format, separator, locale, todaysDate) {
    let isValid = false;
    const actualFormat = this.insertSeperator(separator, format);
    const sameSeperatorCount = this.hastheSameSeperatorCount(dateString, actualFormat, separator);
    if (sameSeperatorCount) {
      const sameNoOfParts = this.hasSameNoOfParts(dateString, actualFormat, separator);
      if (sameNoOfParts) {
        const partsInfo = this.validateAndGetParts(dateString, actualFormat, separator, locale);
        const hasInvalidParts = partsInfo.find((el) => !el.isValid);
        if (!hasInvalidParts) {
          const extractedDate = this.extractDateFromParts(partsInfo, todaysDate);
          const { year, month } = this.extractActualParts(partsInfo, todaysDate);
          isValid = extractedDate.getMonth() === month && extractedDate.getFullYear() === year;
        }
      }
    }
    return isValid;
  }
  /**
   * Inserts separator between the parts of the format and returns the
   * actual format
   * @param {String} separator
   * @param {String} format
   * @returns
   */
  static insertSeperator(separator, format) {
    let uniqueCharacter = format[0];
    for (let i = 0; i < format.length; ) {
      if (uniqueCharacter !== format[i] && i !== format.length) {
        let arrStr = format.split('');
        arrStr = [...arrStr.slice(0, i), separator, ...arrStr.slice(i)];
        format = arrStr.join('');
        i += separator.length;
        uniqueCharacter = format[i];
      } else {
        ++i;
      }
    }
    return format;
  }
  /**
   * Checks if the value of the datepicker is a valid value or not.
   * @param {String} value
   * @param {Boolean} range
   * @param {String} separator
   * @param {String} format
   * @param {Boolean} futureDateDisable
   * @param {Boolean} pastDateDisable
   * @param {Date} todaysDate
   * @param {String} min
   * @param {String} max
   * @param {String} locale
   * @returns
   */
  static isValueValid(
    value,
    range,
    separator,
    format,
    futureDateDisable,
    pastDateDisable,
    todaysDate,
    min,
    max,
    locale
  ) {
    let isValid = false;
    const actualFormat = this.insertSeperator(separator, format);
    let actualFromDate, actualToDate, actualSingleDate;
    // basic check to see if the value entered is of correct format
    if (range) {
      const [fromPart, toPart] = this.extractFromToParts(value, separator, actualFormat);
      const fromPartIsValid =
        Boolean(fromPart) && this.isValidDate(fromPart, format, separator, locale, todaysDate);
      const toPartIsValid =
        Boolean(toPart) && this.isValidDate(toPart, format, separator, locale, todaysDate);
      if (fromPartIsValid && toPartIsValid) {
        isValid = true;
        actualFromDate = this.parseDate(fromPart, actualFormat, separator, locale, todaysDate);
        actualToDate = this.parseDate(toPart, actualFormat, separator, locale, todaysDate);
        if (actualFromDate.getTime() > actualToDate.getTime()) {
          isValid = false;
        }
      }
    } else {
      const valueIsValid = this.isValidDate(value, format, separator, locale, todaysDate);
      if (valueIsValid) {
        actualSingleDate = this.parseDate(value, actualFormat, separator, locale, todaysDate);
        isValid = true;
      }
    }
    // actual check to see if the correctly formatted value/values are actually
    // valid values based on the other parameters.
    // if it has range and is valid by the previous check, then we have already
    // stored the actualFromDate and actualToDate. So reusing them here.
    if (range && isValid) {
      const fromDateIsDisabled = this.isDisabledDate(
        actualFromDate,
        todaysDate,
        futureDateDisable,
        pastDateDisable,
        min,
        max
      );
      const toDateIsDisabled = this.isDisabledDate(
        actualToDate,
        todaysDate,
        futureDateDisable,
        pastDateDisable,
        min,
        max
      );
      if (fromDateIsDisabled || toDateIsDisabled) {
        isValid = false;
      }
    } else if (!range && isValid) {
      const validDateIsDisabled = this.isDisabledDate(
        actualSingleDate,
        todaysDate,
        futureDateDisable,
        pastDateDisable,
        min,
        max
      );
      if (validDateIsDisabled) {
        isValid = false;
      }
    }
    return isValid;
  }
  /**
   * Parses the date from the given datestring from the
   * given parameters.
   * @param {String} dateString
   * @param {String} actualFormat
   * @param {String} separator
   * @param {String} locale
   * @param {Date} todaysDate
   * @returns
   */
  static parseDate(dateString, actualFormat, separator, locale, todaysDate) {
    const partsInfo = this.validateAndGetParts(dateString, actualFormat, separator, locale);
    return this.extractDateFromParts(partsInfo, todaysDate);
  }
  /**
   * Analyses the value and extracts the from and
   * to dates and returns them in the raw date form.
   * @param {String} dateString - the value to extract the from and to date
   * @param {String} actualFormat - the actual format. eg: YYYY-MM-DD
   * @param {String} separator - the separator used
   * @param {String} locale - the specified locale
   * @param {Date} todaysDate
   * @returns {Date} the raw Date
   */
  static parseRangeDates(dateString, actualFormat, separator, locale, todaysDate) {
    const [fromPart, toPart] = this.extractFromToParts(dateString, separator, actualFormat);
    const fromDate = this.parseDate(fromPart, actualFormat, separator, locale, todaysDate);
    const toDate = this.parseDate(toPart, actualFormat, separator, locale, todaysDate);
    return [fromDate, toDate];
  }
  /**
   * Extracts the from and to parts of a range value
   * @param {String} value - the value to extract the from and to parts
   * @param {String} separator - the separator used
   * @returns {[string,string]} - the from and to parts
   */
  static extractFromToParts(value, separator, actualFormat) {
    let fromPart = '',
      toPart = '';
    if (separator === '-') {
      let separatorIndex = -1;
      let occurenceCounter = 0;
      const numOfDashesInFormat = this.getOccurenceCount(actualFormat, '-');
      const separatingDashOccurencePosition = numOfDashesInFormat + 1;
      for (let i = 0; i < value.length; i++) {
        if (value[i] === '-') {
          ++occurenceCounter;
        }
        if (occurenceCounter === separatingDashOccurencePosition) {
          separatorIndex = i;
          break;
        }
      }
      if (separatorIndex !== -1) {
        fromPart = value.substring(0, separatorIndex);
        toPart = value.substring(separatorIndex + 1);
      }
    } else {
      const splitDateInput = value.split('-');
      fromPart = splitDateInput[0];
      toPart = splitDateInput[1];
    }
    return [fromPart, toPart];
  }
  /**
   * Returns the index of the given short month name.
   *
   * @param {String} monthName - the name of the short form of the month
   * @param {String} locale - the locale used. Default value is 'en'
   * @returns index of the given month name
   */
  static getMonthIndex(monthName, locale) {
    const monthNameDict = new Array(12)
      .fill('_')
      .map((_, _index) => this.getShortMonthName(_index, locale));
    return monthNameDict.indexOf(monthName);
  }
  /**
   * Gives the short name of the month corresponding
   * to the given month-index
   *
   * @param {Number} monthNum - the index of the month
   * @param {String} locale - the locale used. Default value is 'en'
   * @returns {String} `monthName` - name of the month
   */
  static getShortMonthName(monthNum, locale = 'en') {
    // adding the below check in case the locale attribute is removed
    // from the datepicker to fallback to 'en' locale
    locale = locale ? locale : 'en';
    let monthName = this.getMonthDateDict()[monthNum].toLocaleString(locale, { month: 'short' });
    monthName = monthName[0].toLocaleUpperCase(locale) + monthName.slice(1);
    return monthName;
  }
  /**
   * returns the name of the weekday for the
   * given index in the specified locale
   * Uses the May month of 2022 as reference
   * since that month starts from Sunday(has it's 1st starting from Sunday)
   * @param {Number} index -  the index of the weekday
   * @param {String} locale - the specified locale. Default is 'en'
   * @returns {String} the name of the weekday
   */
  static getWeekDays(index, locale = 'en') {
    locale = locale ? locale : 'en';
    const variation =
      locale === 'en' ||
      locale === 'de' ||
      locale === 'es' ||
      locale === 'fr' ||
      locale === 'ru-ru' ||
      locale === 'it-it' ||
      locale === 'pt' ||
      locale === 'pt-br'
        ? 'short'
        : 'narrow';
    let weekDayDates = new Array(7).fill('_');
    weekDayDates = weekDayDates.map((_, _index) => new Date(2022, 4, _index + 1, 0, 0, 0, 0));
    let weekDay = weekDayDates[index].toLocaleString(locale, { weekday: variation });
    weekDay = weekDay[0].toLocaleUpperCase(locale) + weekDay.slice(1);
    return weekDay;
  }
  /**
   * Formats the given date to the
   * given format part and returns
   * the formatted part of the date.
   *
   * @param {Date} date
   * @param {String} formatPart
   */
  static getFormattedPart(date, formatPart, locale) {
    let options;
    switch (formatPart) {
      case 'D':
        options = { day: 'numeric' };
        break;
      case 'DD':
        options = { day: '2-digit' };
        break;
      case 'M':
        options = { month: 'numeric' };
        break;
      case 'MM':
        options = { month: '2-digit' };
        break;
      case 'MMM':
        options = { month: 'short' };
        break;
      case 'YYYY':
        options = { year: 'numeric' };
        break;
      case 'YY':
        options = { year: '2-digit' };
        break;
    }
    let localeFormattedString = date.toLocaleString(locale, options);
    if (formatPart === 'MMM') {
      localeFormattedString =
        localeFormattedString[0].toLocaleUpperCase(locale) + localeFormattedString.slice(1);
    }
    return localeFormattedString;
  }
  /**
   * Finds the number of occurences of the given separator
   * in the given teststring
   *
   * @param {String} testString
   * @param {String} separator
   * @returns
   */
  static getOccurenceCount(testString, separator) {
    let count = 0;
    let replaceCharacter = '*'; // this is to support * or any combination of * with other characters as separator.
    if (separator.includes('*')) {
      replaceCharacter = '$';
    }
    const str = testString.replaceAll(separator, replaceCharacter);
    for (let i = 0; i < str.length; i++) {
      if (str[i] === replaceCharacter) {
        ++count;
      }
    }
    return count;
  }
  /**
   * Checks if the given datestring and actualformat have the
   * same number of separators or not.
   * @param {String} dateString
   * @param {String} correctFormat
   * @param {String} separator
   * @returns
   */
  static hastheSameSeperatorCount(dateString, correctFormat, separator) {
    const formatCount = this.getOccurenceCount(correctFormat, separator);
    const dateStringCount = this.getOccurenceCount(dateString, separator);
    return formatCount === dateStringCount;
  }
  /**
   * Checks if the given datestring and actualformat have the
   * same number of parts or not.
   * @param {String} dateString
   * @param {String} correctFormat
   * @param {String} separator
   * @returns
   */
  static hasSameNoOfParts(dateString, actualFormat, separator) {
    const dateStrArr = dateString.split(separator);
    const actualFormatArr = actualFormat.split(separator);
    return dateStrArr.length === actualFormatArr.length;
  }
  /**
   * Extracts various parts of a datestring and creates an object
   * that maps the parts of the actual-format with the parts of datestring.
   *
   * eg:
   * a dateString of `1990-14-12` ,an actual-format of `YYYY-DD-MM` and a
   * separator of `-` given to this function must return an object as:
   * ```js
   * {
   *  YYYY : 1990,
   *  DD : 14,
   *  MM : 12
   * }
   * ```
   *
   * @param {String} dateString
   * @param {String} actualFormat - the actual format with separators included
   * @param {String} separator
   * @returns
   */
  static extractVariousData(dateString, actualFormat, separator) {
    const dateStrArr = dateString.split(separator);
    const actualFormatArr = actualFormat.split(separator);
    const extractedData = {};
    actualFormatArr.forEach((el, ind) => {
      extractedData[el] = dateStrArr[ind];
    });
    return extractedData;
  }
  /**
   * Checks if the given string representation of the number actually
   * represents an integer or not and whether it has the same length
   * as the given length
   *
   * @param {String} numberStr - the supposed string representation of the number
   * @param {Number} length - the length which the numberStr must satisfy
   * @returns {Boolean} - whether it represents an integer and satifies the length
   * constraint or not.
   */
  static isOfCorrectLengthAndNumber(numberStr, length) {
    return numberStr.length === length && Number.isInteger(Number(numberStr));
  }
  /**
   * Validates whether the value corresponding to a given formatPart is
   * valid or not and also returns which part it is and what the value
   * is numerically.
   *
   * For example:
   *
   * if the formatPart is `MMM` and the actualValue is `Mar`, then it will
   * return an object like :
   * ```js
   * {isValid: true, value: 2, part: 'month'}
   * ```
   *
   * @param {String} formatPart - the part of the format to validate
   * @param {String} actualValue - the value of the part of the format
   * @param {String} locale - the locale
   * @returns {{isValid:Boolean,value:number,part:string}}
   */
  static validateAndGetPart(formatPart, actualValue, locale) {
    var _a, _b;
    let isValid = false;
    let value;
    let part;
    switch (formatPart) {
      case 'M':
      case 'D':
      case 'YY':
      case 'YYYY':
      case 'MM':
      case 'DD': {
        const isCorrectFormat =
          formatPart === 'M' || formatPart === 'D'
            ? Number.isInteger(Number(actualValue))
            : this.isOfCorrectLengthAndNumber(actualValue, formatPart.length);
        if (isCorrectFormat) {
          value = Number(actualValue);
          switch (formatPart[0]) {
            case 'D': {
              isValid = value >= 1 && value <= 31;
              part = 'date';
              break;
            }
            case 'M': {
              isValid = value >= 1 && value <= 12;
              value -= 1;
              part = 'month';
              break;
            }
            case 'Y': {
              if (formatPart === 'YYYY') {
                isValid = value >= 1000 && value <= 9999;
              } else if (formatPart === 'YY') {
                isValid = true;
                const todaysYear = new Date().getFullYear();
                const currentCenturyPrefix = `20`;
                const previousCenturyPrefix = `19`;
                const previousCenturyYear = Number(`${previousCenturyPrefix}${actualValue}`);
                const currentCenturyYear = Number(`${currentCenturyPrefix}${actualValue}`);
                value =
                  Math.min(
                    Math.abs(currentCenturyYear - todaysYear),
                    Math.abs(previousCenturyYear - todaysYear)
                  ) === Math.abs(currentCenturyYear - todaysYear)
                    ? currentCenturyYear
                    : previousCenturyYear;
              }
              part = 'year';
              break;
            }
          }
        }
        break;
      }
      case 'MMM': {
        const longMonthNames = this.getMonthDateDict().map((date) =>
          date.toLocaleString(locale, { month: 'long' }).toLocaleLowerCase(locale)
        );
        const shortMonthNames = this.getMonthDateDict().map((date) =>
          date.toLocaleString(locale, { month: 'short' }).toLocaleLowerCase(locale)
        );
        const filteredLongMonths = longMonthNames
          .map((el, index) => {
            if (Boolean(actualValue) && el.startsWith(actualValue.toLocaleLowerCase(locale))) {
              return { monthName: el, monthIndex: index };
            } else {
              return undefined;
            }
          })
          .filter((el) => el !== undefined);
        const filteredShortMonths = shortMonthNames
          .map((el, index) => {
            if (Boolean(actualValue) && el.startsWith(actualValue.toLocaleLowerCase(locale))) {
              return { monthName: el, monthIndex: index };
            } else {
              return undefined;
            }
          })
          .filter((el) => el !== undefined);
        isValid = filteredLongMonths.length > 0 || filteredShortMonths.length > 0;
        if (isValid) {
          value =
            filteredLongMonths.length > 0
              ? (_a = filteredLongMonths[0]) === null || _a === void 0
                ? void 0
                : _a.monthIndex
              : (_b = filteredShortMonths[0]) === null || _b === void 0
                ? void 0
                : _b.monthIndex;
          part = 'month';
        }
      }
    }
    return { isValid, value, part };
  }
  /**
   * Validates the given dateString
   *
   * and gets an array of objects of the form `{isValid:boolean, value:number|undefined, part:number|undefined}`.
   *
   * Please refer the documentation of `validateAndGetPart` for what this object is.
   *
   * @param {String} dateString - the date string to be validated and parts information extracted
   * @param {String} actualFormat - the format with separators included
   * @param {String} separator - the separator
   * @param {String} locale - the locale
   * @returns
   */
  static validateAndGetParts(dateString, actualFormat, separator, locale) {
    const extractedParts = Object.entries(
      this.extractVariousData(dateString, actualFormat, separator)
    );
    const validationDataInfo = extractedParts.map((part) => {
      const key = part[0];
      const value = part[1];
      return this.validateAndGetPart(key, value, locale);
    });
    return validationDataInfo;
  }
  /**
   * Checks if the provided `currentDate` is disabled or not based on
   * the values of `todaysDate`, `futureDateDisable`, `pastDateDisable`, `min` and `max`
   * @param {Date} currentDate - the current-date
   * @param {Date} todaysDate - today's date
   * @param {Boolean} futureDateDisable - whether the dates after today's date are disabled or not.
   * @param {Boolean} pastDateDisable - whether the dates before today's date are disabled or not.
   * @param {String} min - the min-date - expected to be in the format 'YYYY-MM-DD'
   * @param {String} max - the max-date - expected to be in the format 'YYYY-MM-DD'
   * @returns {Boolean} `isDisabled` - whether the date is disabled or not
   */
  static isDisabledDate(currentDate, todaysDate, futureDateDisable, pastDateDisable, min, max) {
    const currentDateTime = currentDate.getTime();
    const todaysDateTime = todaysDate.getTime();
    let isDisabled =
      (Boolean(futureDateDisable) && currentDateTime > todaysDateTime) ||
      (Boolean(pastDateDisable) && currentDateTime < todaysDateTime);
    if (!isDisabled) {
      if (this.isValidDate(min, 'YYYYMMDD', '-', 'en', todaysDate)) {
        const minYear = Number(min.split('-')[0]);
        const minMonthIndex = Number(Number(min.split('-')[1]) - 1);
        const minDayOfMonth = Number(min.split('-')[2]);
        const minDate = new Date(minYear, minMonthIndex, minDayOfMonth, 0, 0, 0, 0);
        if (currentDate.getTime() < minDate.getTime()) {
          isDisabled = true;
        }
      }
      if (this.isValidDate(max, 'YYYYMMDD', '-', 'en', todaysDate)) {
        const maxYear = Number(max.split('-')[0]);
        const maxMonthIndex = Number(Number(max.split('-')[1]) - 1);
        const maxDayOfMonth = Number(max.split('-')[2]);
        const maxDate = new Date(maxYear, maxMonthIndex, maxDayOfMonth, 0, 0, 0, 0);
        if (currentDate.getTime() > maxDate.getTime()) {
          isDisabled = true;
        }
      }
    }
    return isDisabled;
  }
  /**
   * Extracts the date from the date from partsInfo
   * Uses extractDateFromParts internally to set the
   * year, month and dates.
   *
   *@param {{isValid: boolean; value: number; part: string;}[]} partsInfo
   * @param {Date} todaysDate
   * @returns
   */
  static extractDateFromParts(partsInfo, todaysDate) {
    const { year, month, date } = this.extractActualParts(partsInfo, todaysDate);
    return new Date(year, month, date, 0, 0, 0, 0);
  }
  /**
   * Extracts the year, month and date from the partsInfo Array.
   *
   * If any value is missing in the format eg if the format is given as
   * YYYY, (M and D are missing), then todays date's values will be substituted
   * for them.
   *
   * Since this function is called only after validating that the
   * value is correct, the missing entry will only be present due to missing
   * entry in the format.
   *
   * @param {{isValid: boolean; value: number; part: string;}[]} partsInfo
   * @param {Date} todaysDate
   * @returns {{year:number,month:number,date:number}}
   */
  static extractActualParts(partsInfo, todaysDate) {
    const dateInfo = partsInfo.find((el) => el.part === 'date');
    const monthInfo = partsInfo.find((el) => el.part === 'month');
    const yearInfo = partsInfo.find((el) => el.part === 'year');
    const date = dateInfo ? dateInfo.value : todaysDate.getDate();
    const month = monthInfo ? monthInfo.value : todaysDate.getMonth();
    const year = yearInfo ? yearInfo.value : todaysDate.getFullYear();
    return { year, month, date };
  }
  /**
   * Gives an array of dates corresponding to the
   * 1st day of each month of the year 2022
   * @returns {Date[]}
   */
  static getMonthDateDict() {
    let monthDateDict = new Array(12).fill('_');
    monthDateDict = monthDateDict.map((_, _index) => new Date(2022, _index, 1, 0, 0, 0, 0));
    return monthDateDict;
  }
}
