// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-accordion.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-accordion-header-wrapper-min-height=2.5rem] - minimum height of header wrapper
   */
  --sh-accordion-header-wrapper-min-height: 2.5rem;
  /* 
   * @cssprop [--sh-accordion-container-padding-left-right=0 1rem] - Left and right side padding of container
   */
  --sh-accordion-container-padding-left-right: 0 1rem;
  /* 
   * @cssprop [--sh-accordion-header-label-info-padding=0.5rem 0.5rem 0.5rem 0] - padding of accordion label and info
   */
  --sh-accordion-header-label-info-padding: 0.5rem 0.5rem 0.5rem 0;
  /* 
   * @cssprop [--sh-accordion-content-wrapper-width=1rem] - width of content wrapper
   */
  --sh-accordion-content-wrapper-width: 1rem;
  /* 
   * @cssprop [--sh-accordion-content-wrapper-line-height=1.25rem] - line-height of content wrapper
   */
  --sh-accordion-content-wrapper-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-accordion-wrapper-padding-bottom=1rem] - bottom padding of accordion wrapper
   */
  --sh-accordion-wrapper-padding-bottom: 1rem;
  /* 
   * @cssprop [--sh-accordion-label-line-height=1.5rem] - line height of accordion label
   */
  --sh-accordion-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-accordion-expand-icon-margin=0.25rem 0 0.25rem 0] - margin of expand icon
   */
  --sh-accordion-expand-icon-margin: 0.25rem 0 0.25rem 0;
  /* 
   * @cssprop [--sh-accordion-arrow-invert-margin=0.25rem 0.5rem 0.25rem 0] - margin of expand icon
   */
  --sh-accordion-arrow-invert-margin: 0.25rem 0.5rem 0.25rem 0;
  /* 
   * @cssprop [--sh-accordion-icon-padding=0.5rem 0.5rem 0.5rem 0] - padding of header icon
   */
  --sh-accordion-icon-padding: 0.5rem 0.5rem 0.5rem 0;
  /* 
   * @cssprop [--sh-accordion-content-items-margin-top=0.5rem] - vertical gap between items in content
   */
  --sh-accordion-content-items-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-accordion-content-items-margin-top-touch=1rem] - vertical gap between items in content for touch device
   */
  --sh-accordion-content-items-margin-top-touch: 1rem;
  /* 
   * @cssprop [--sh-accordion-content-first-item-margin-top=0rem] - top margin of first item in the content
   */
  --sh-accordion-content-first-item-margin-top: 0rem;
  /* 
   * @cssprop [--sh-accordion-footer-wrapper-padding-top=1rem] - top padding of footer wrapper
   */
  --sh-accordion-footer-wrapper-padding-top: 1rem;
  /* 
   * @cssprop [--sh-accordion-footer-wrapper-margin-left=0.5rem] - left margin of footer wrapper
   */
  --sh-accordion-footer-wrapper-margin-left: 0.5rem;
}

:host {
  width: 100%;
  transition: var(--transition-time) max-height ease-in-out, var(--transition-time) background-color ease-in-out;
  box-shadow: var(--shadow-raised);
  box-sizing: border-box;
  background: var(--base-3);
  border-radius: 0.125rem;
  display: block;
}

:host([flat]) {
  border-radius: 0rem;
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.accordion-wrapper {
  width: 100%;
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  display: flex;
  flex-direction: column;
  transition: var(--transition-time) all ease-in-out;
  box-sizing: border-box;
  border-radius: 0.125rem;
  word-break: break-word;
}

.header-wrapper {
  min-height: var(--sh-accordion-header-wrapper-min-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--sh-accordion-container-padding-left-right);
  cursor: pointer;
}

:host([flat]) .header-wrapper {
  padding: 0rem;
}

.header-wrapper:focus-visible {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}

.accordion-label {
  color: var(--text-primary);
  font: var(--header-2);
  line-height: var(--sh-accordion-label-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: var(--sh-accordion-header-label-info-padding);
  align-self: center;
}

:host([label-rows=auto]) .accordion-label {
  white-space: unset;
}

.label {
  display: contents;
}

.accordion-info {
  min-width: 2ch;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  flex: auto;
  line-height: var(--sh-accordion-label-line-height);
  padding: var(--sh-accordion-header-label-info-padding);
}

:host([info]) .accordion-label {
  width: auto;
  flex: auto;
}

:host(:not([info])) .accordion-info {
  display: none;
}

.accordion-wrapper sh-icon.expand {
  color: rgba(var(--ui-1), var(--opacity-3));
  transition: var(--transition-time) color ease-in-out;
  margin: 0.25rem 0;
}

:host(:not([flat]):not([arrow-invert])) > .accordion-wrapper sh-icon.expand {
  margin: var(--sh-accordion-expand-icon-margin);
}

.content-wrapper {
  display: flex;
  width: calc(100% - var(--sh-accordion-content-wrapper-width) * 2);
  flex-direction: column;
  padding: var(--sh-accordion-container-padding-left-right);
  font: var(--body-1);
  line-height: var(--sh-accordion-content-wrapper-line-height);
  color: var(--text-primary);
}

/* expanded */
:host(:not([expanded])) > .accordion-wrapper > .content-wrapper {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: var(--transition-time) all ease-in-out, var(--transition-time) max-height ease-in-out, var(--transition-time) padding ease-in-out;
  max-height: 0rem;
}

:host([expanded]) > .accordion-wrapper {
  padding-bottom: var(--sh-accordion-wrapper-padding-bottom);
}

:host([expanded]) > .accordion-wrapper > .header-wrapper > sh-icon.expand {
  transform: rotate(-180deg);
  transform-style: preserve-3d;
}

:host([expanded]) > .accordion-wrapper > .content-wrapper {
  visibility: visible;
  opacity: 1;
  max-height: 9999rem;
  overflow: visible;
}

.accordion-icon {
  padding: var(--sh-accordion-icon-padding);
}

:host([arrow-invert]) .expand {
  margin: var(--sh-accordion-arrow-invert-margin);
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: var(--sh-accordion-footer-wrapper-padding-top);
  margin-top: 0rem;
}

.footer-wrapper[empty-footer] {
  display: none;
}

.footer-wrapper > ::slotted(*) {
  margin-left: var(--sh-accordion-footer-wrapper-margin-left);
}

.content-wrapper > ::slotted(*) {
  margin-top: var(--sh-accordion-content-items-margin-top);
}

.content-wrapper > ::slotted(*:first-child) {
  margin-top: var(--sh-accordion-content-first-item-margin-top);
}

.content-wrapper > ::slotted(sh-menu-item) {
  margin-top: 0rem;
}

.header-slot-wrapper {
  flex: 1;
  max-width: calc(100% - 2.5rem);
  margin-right: 0.5rem;
}

.header-slot-wrapper > ::slotted(*) {
  padding: var(--sh-accordion-header-label-info-padding);
  line-height: var(--sh-accordion-label-line-height);
  font-weight: bold;
  font-size: var(--font-size-header-2);
}

.header-slot-wrapper[empty-header] {
  display: none;
}

/* disabled */
:host([disabled]) {
  pointer-events: none;
}

:host([disabled]) .header-slot-wrapper > ::slotted(*),
:host([disabled]) .accordion-label,
:host([disabled]) .accordion-info {
  color: var(--text-disabled);
}

/* touch */
@media (pointer: coarse) {
  .content-wrapper > ::slotted(*) {
    margin-top: var(--sh-accordion-content-items-margin-top-touch);
  }
}
/* hover */
:host(:not(.touch-device)) .header-wrapper:hover sh-icon.expand {
  color: rgba(var(--ui-1), var(--opacity-2));
}`;