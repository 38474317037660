// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-spinner.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-spinner-width-s=2rem] - spinner width of s size
   */
  --sh-spinner-width-s: 2rem;
  /* 
   * @cssprop [--sh-spinner-height-s=2rem] - spinner height of s size
   */
  --sh-spinner-height-s: 2rem;
  /* 
   * @cssprop [--sh-spinner-width-m=3rem] - spinner width of m size
   */
  --sh-spinner-width-m: 3rem;
  /* 
   * @cssprop [--sh-spinner-height-m=3rem] - spinner height of m size
   */
  --sh-spinner-height-m: 3rem;
  /* 
   * @cssprop [--sh-spinner-border-width=0.25rem] - border width of s size
   */
  --sh-spinner-border-width: 0.25rem;
  /* 
   * @cssprop [--sh-spinner-label-height=1rem] - label height
   */
  --sh-spinner-label-height: 1rem;
  /* 
   * @cssprop [--sh-spinner-label-line-height=1.125rem] - label line height
   */
  --sh-spinner-label-line-height: 1.125rem;
  /* 
   * @cssprop [--sh-spinner-margin-top=0.5rem] - label margin top
   */
  --sh-spinner-margin-top: 0.5rem;
}

:host {
  transition: var(--transition-time) all ease-in-out;
  width: 100% !important;
  display: block;
  overflow: hidden;
}

:host([size=s]) .loader {
  width: var(--sh-spinner-width-s);
  height: var(--sh-spinner-height-s);
}

:host([size=m]) .loader {
  height: var(--sh-spinner-width-m);
  width: var(--sh-spinner-width-m);
}

:host([overlay]) {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--ui-7), var(--opacity-4));
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:host([overlay]) .loader {
  margin: 0;
}

.loader {
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  border-width: var(--sh-spinner-border-width);
  border-style: solid;
  border-left-color: rgba(var(--ui-1), var(--opacity-6));
  border-right-color: rgba(var(--ui-1), var(--opacity-6));
  border-bottom-color: rgba(var(--ui-1), var(--opacity-6));
  border-top-color: rgba(var(--ui-2), var(--opacity-1));
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-label {
  font: var(--body-1);
  color: var(--text-secondary);
  text-align: center;
  margin-top: var(--sh-spinner-margin-top);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: var(--sh-spinner-label-height);
  height: var(--sh-spinner-label-line-height);
}`;