// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------

import { css, html, LitElement } from 'lit';
import { sharedStyles } from '../../styles';
/**
 * @prop {String} icon - Defines the main icon of the home card. This is a mandatory property.
 * @prop {String} label - Defines the label shown below the home card's icon. This is a mandatory property.
 * @prop {Number} notifications - Defines how many notifications exist for a card. If left undefined no notifications will be displayed.
 * @prop {Number} errors - Defines how many errors exist for a card. If left undefined no errors will be displayed.
 * @slot - Holds additional information shown below the card's icon and label. If no children exist, it will not be displayed.
 */
export class SHHomeCard extends LitElement {
  static get properties() {
    return {
      icon: { type: String, reflect: true },
      label: { type: String, reflect: true },
      notifications: { type: Number, reflect: true },
      errors: { type: Number, reflect: true },
    };
  }
  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          cursor: pointer;
          transition: var(--transition-time) all ease-in-out;
          display: flex;
        }

        :host(:hover) {
          transform: translateY(-0.25rem);
        }

        sh-card {
          height: 100%;
        }

        sh-card {
          height: 16.5rem;
          width: 19rem;
          margin: 0.5rem;
        }

        .card-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .content-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        #card-icon {
          color: var(--text-secondary);
          --sh-icon-size-m: 4rem;
          --sh-icon-font-size-m: 4rem;
          position: relative;
        }
        .card-title {
          margin-top: 0.5rem;
          text-align: center;
        }

        #footer-wrapper {
          border-top: 0.0625rem solid rgba(var(--ui-1), var(--opacity-6));
          padding-top: 1rem;
          height: 4.5rem;
          overflow: auto;
        }
        :host([notifications]) .notification-dot {
          background: var(--support-5);
          height: 1rem;
          width: 1rem;
          font: var(--body-2);
          text-align: center;
          font-weight: bold;
          line-height: 1rem;
          color: var(--base-3);
          border: 0.125rem solid var(--base-3);
          position: absolute;
          right: 0rem;
          bottom: 0rem;
          border-radius: 50%;
        }

        :host([errors]) .error-dot {
          background: rgb(var(--functional-red));
          height: 1rem;
          width: 1rem;
          font: var(--body-2);
          text-align: center;
          font-weight: bold;
          line-height: 1rem;
          color: var(--base-3);
          border: 0.125rem solid var(--base-3);
          position: absolute;
          right: 0rem;
          bottom: 0rem;
          border-radius: 50%;
        }

        /* small screens */
        @media only screen and (max-width: 47.9375rem) {
          :host(:hover) {
            transform: none;
          }
        }
      `,
    ];
  }
  render() {
    return html`
      <sh-card>
        <div class="card-wrapper">
          <div class="content-wrapper">
            <div style="position: relative">
              ${this.icon ? html` <sh-icon id="card-icon" icon="${this.icon}"></sh-icon>` : ''}
              ${this.notifications
                ? html` <div class="notification-dot">${this.notifications}</div>`
                : ''}
              ${this.errors ? html` <div class="error-dot">${this.errors}</div> ` : ''}
            </div>
            ${this.label ? html` <sh-text class="card-title" title-1>${this.label}</sh-text> ` : ''}
          </div>
          <div id="footer-wrapper">
            <slot @slotchange="${this._handleSlotChange.bind(this)}"></slot>
          </div>
        </div>
      </sh-card>
    `;
  }
  constructor() {
    super();
    this.icon = 'patient';
    this.label = 'Card Label';
  }
  _handleSlotChange() {
    if (this.children.length === 0) {
      this.shadowRoot.querySelector('#footer-wrapper').setAttribute('style', 'display: none');
    } else {
      this.shadowRoot.querySelector('#footer-wrapper').setAttribute('style', 'display: block');
    }
  }
}
customElements.define('sh-home-card', SHHomeCard);
