// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-nav-bar.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-nav-bar-nav-bar-wrapper-height=3.5rem] - height of nav bar wrapper
   */
  --sh-nav-bar-nav-bar-wrapper-height: 3.5rem;
  /* 
   * @cssprop [--sh-nav-bar-condensed-nav-bar-wrapper-height=2.5rem] - height of condensed nav bar wrapper height
   */
  --sh-nav-bar-condensed-nav-bar-wrapper-height: 2.5rem;
  /* 
   * @cssprop [--sh-nav-bar-functions-wrapper-gap=0.5rem] - gap of functions wrapper
   */
  --sh-nav-bar-functions-wrapper-gap: 0.5rem;
  /* 
   * @cssprop [--sh-nav-bar-functions-wrapper-padding=0 0 0 0.5rem] - padding of functions wrapper
   */
  --sh-nav-bar-functions-wrapper-padding: 0 0 0 0.5rem;
  /* 
   * @cssprop [--sh-nav-bar-nav-bar-wrapper-padding=0 1rem] - padding of nav-bar-wrapper
   */
  --sh-nav-bar-nav-bar-wrapper-padding: 0 1rem;
}

:host {
  background-color: var(--base-2);
  transition: var(--transition-time) all ease-in-out;
  box-shadow: var(--shadow-navigation);
  z-index: 3;
  width: 100%;
  overflow: auto;
  display: block;
  flex-shrink: 0;
}

.nav-bar-wrapper {
  height: var(--sh-nav-bar-nav-bar-wrapper-height);
  width: 100%;
  padding: var(--sh-nav-bar-nav-bar-wrapper-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

:host([slot=footer]) .tabs-wrapper {
  flex: none;
  margin: auto;
}

.tabs-wrapper {
  flex: 1;
  display: flex;
  overflow: hidden;
  height: 100%;
  align-items: center;
}

.tabs-wrapper > ::slotted(sh-tabs) {
  margin: -1rem 0;
}

.functions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--sh-nav-bar-functions-wrapper-padding);
  gap: var(--sh-nav-bar-functions-wrapper-gap);
}

.functions-wrapper[empty-function] {
  display: none;
}

:host([condensed]) .nav-bar-wrapper {
  height: var(--sh-nav-bar-condensed-nav-bar-wrapper-height);
}`;