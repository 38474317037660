// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import '../../styles';
import { ThrottlingMixin } from '../../utils/throttling-mixin.js';
import carouselStyles from './sh-carousel.lit.scss.js';
/**
 * @slot - The slot where the main content is rendered. Takes card, thumbnail and images as child.
 * @fires  left-arrow-click - Dispatched when the left arrow is clicked.
 * @fires  right-arrow-click - Dispatched when the right arrow is clicked.
 */
let SHCarousel = class SHCarousel extends ThrottlingMixin(LitElement) {
  constructor() {
    super(...arguments);
    /** Defines the item currently in focus. */
    this._currentItem = 0;
    /** Defines the total number of elements the carousel component has. */
    this._totalSlotElements = 0;
    /** Defines the width of the slotted components in "rem", "%", "px", etc. Please note for images, a change in width will also result a change in height if not specified. */
    this.elementWidth = '30%';
    /** Defines the space between elements inside the carousel in "rem", "px", etc. */
    this.gap = '0.5rem';
    /** Defines how elements inside the carousel snap to the container when scrolled. Possible values are `start`, `center`, `end`, `none`.
     * @type {"start"|"center"|"end"|"none"}
     */
    this.snap = 'start';
    this.resizeObserver = new ResizeObserver(this.slotResizeObserverCallback.bind(this));
    this.currentScrollPosition = 0; // Track the intended scroll position
  }
  static get styles() {
    return [carouselStyles];
  }
  render() {
    return html`
      <slot
        id="slot"
        style="${styleMap(this.getDimensionStyles())}"
        @scroll="${this.throttleScroll}"
        @slotchange="${(e) => this.contentSlotChange(e)}"
      ></slot>
      ${this.counter
        ? html` <div class="counter">${this._currentItem} / ${this._totalSlotElements}</div> `
        : ''}
      ${this.arrows
        ? html`
            ${this._currentItem > 1
              ? html` <sh-icon
                  button
                  icon="arrow-left-s"
                  size="m"
                  class="arrow left"
                  @click="${() => this.scrollToItem(this._currentItem - 1, -1)}"
                ></sh-icon>`
              : ''}
            ${!this.reachedEnd && typeof this.reachedEnd === 'boolean'
              ? html` <sh-icon
                  icon="arrow-right-s"
                  button
                  @click="${() => this.scrollToItem(this._currentItem - 1, 1)}"
                  class="arrow right"
                ></sh-icon>`
              : ''}
          `
        : ''}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    this.updateComplete.then(() => {
      var _a;
      const slot =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
      this.resizeObserver.observe(slot);
    });
  }
  disconnectedCallback() {
    var _a;
    const slot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
    this.resizeObserver.unobserve(slot);
    this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('snap') && this.arrows) {
      this.snap = 'start';
    }
    if (changedProperties.has('arrows') && this.arrows && !(this.snap === 'start')) {
      this.snap = 'start';
    }
  }
  contentSlotChange(e) {
    const slot = e.target;
    const slotElements = slot.assignedElements({
      flatten: true,
    }).length;
    if (slotElements) {
      this._totalSlotElements = this.children.length;
      this.handleScroll();
    }
  }
  getDimensionStyles() {
    return {
      '--slot-component-width': this.elementWidth,
      gap: this.gap,
    };
  }
  handleScroll() {
    // This function updates the _currentItem when scrolled to the one which is focused.
    const slot = this.renderRoot.querySelector('slot');
    const slotElementsPresent = slot.assignedElements({
      flatten: true,
    }).length;
    if (slotElementsPresent) {
      this._currentItem = Math.floor(
        slot.scrollLeft / (this.children[0].clientWidth + parseInt(this.gap, 10)) + 1
      );
      this.reachedEnd = slot.scrollLeft + slot.clientWidth >= slot.scrollWidth;
    }
  }
  throttleScroll() {
    // Throttle mixin
    this.throttle(this.handleScroll.bind(this), 50);
  }
  scrollToItem(itemIndex, addOrSubtract) {
    var _a;
    // scroll to a given item (start from 1) i.e given itemIndex becomes focusable.
    if (itemIndex < 1) {
      itemIndex = 1;
    }
    if (itemIndex > this.children.length) {
      itemIndex = this.children.length;
    }
    const slot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
    if (slot) {
      // Calculate the amount to scroll based on the width of the visible area (clientWidth)
      // and the direction of the scroll (addOrSubtract determines left or right scroll).
      const scrollAmount = slot.clientWidth * addOrSubtract;
      // Update the intended scroll position by adding the calculated scroll amount.
      // This ensures that multiple rapid clicks are cumulatively accounted for,
      // moving the slider further each time.
      this.currentScrollPosition += scrollAmount;
      // Ensure the updated scroll position stays within the valid boundaries of the scrollable area.
      // It prevents scrolling beyond the first slide (0) or past the last slide
      // (slot.scrollWidth - slot.clientWidth).
      this.currentScrollPosition = Math.max(
        0,
        Math.min(this.currentScrollPosition, slot.scrollWidth - slot.clientWidth)
      );
      // Perform the scroll to the calculated position with a smooth transition effect.
      // This moves the slider to the intended position based on user interactions.
      slot.scroll({
        left: this.currentScrollPosition,
        behavior: 'smooth',
      });
      // Dispatch custom events based on the direction of the scroll
      const customEventName = addOrSubtract === -1 ? 'left-arrow-click' : 'right-arrow-click';
      const customEvent = new CustomEvent(customEventName, {
        detail: { currentItem: itemIndex },
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(customEvent);
    }
  }
  slotResizeObserverCallback(e) {
    const slot = e[0].target;
    const slotElementsPresent = slot.assignedElements({
      flatten: true,
    }).length;
    if (slotElementsPresent) {
      this._currentItem = Math.floor(
        slot.scrollLeft / (this.children[0].clientWidth + parseInt(this.gap, 10)) + 1
      );
      this.reachedEnd = slot.scrollLeft + slot.clientWidth >= slot.scrollWidth;
    }
  }
};
__decorate([state()], SHCarousel.prototype, '_currentItem', void 0);
__decorate([state()], SHCarousel.prototype, '_totalSlotElements', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'element-width' })],
  SHCarousel.prototype,
  'elementWidth',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHCarousel.prototype, 'gap', void 0);
__decorate([property({ type: String, reflect: true })], SHCarousel.prototype, 'snap', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCarousel.prototype, 'counter', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCarousel.prototype, 'arrows', void 0);
__decorate([state()], SHCarousel.prototype, 'reachedEnd', void 0);
SHCarousel = __decorate([customElement('sh-carousel')], SHCarousel);
export { SHCarousel };
