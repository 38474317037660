// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher';
import { deviceIdentifier } from '../../utils/device-identifier';
import { KEYCODE } from '../../utils/keycode';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
import thumbnailStyles from './sh-thumbnail.lit.scss.js';
/**
 * @slot icon - Slot for displaying additional icons, displayed as row below the image. Takes icons as children.
 * @slot info - Slot for displaying metadata associated with the dataset. Takes children such as text.
 * @slot data-type - Slot for displaying icons for the type-of or origin of the dataset. Displayed on the bottom-right of the thumbnail. Using this slot will override the icon displayed using the icon property. Takes icons as children.
 * @slot indications - Slot for displaying icons related to the status of a thumbnail, or attributes of the thumbnail relevant to a user. Displayed on the top-right of the thumbnail. Takes icons as children.
 * @attr {String} aspect-ratio - Default 1/1. Makes the thumbnail support different image aspect ratios, for e.g. 4/3. (Optional)
 * @fires menu-clicked - Dispatched when the menu(options) icon is clicked.
 */
let SHThumbnail = class SHThumbnail extends TooltipEllipsisCheckerMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**  Necessary field, defines the source location of the image data. */
    this.src = '';
    /**  Default 1/1. Makes the thumbnail support different image aspect ratios, for e.g. 4/3. (Optional) */
    this.aspectRatio = '1/1';
    /**  *hide boolean check for empty info. */
    this.emptyInfo = true;
    /**  *hide boolean check for empty info. */
    this.emptyDataTypeIcons = true;
    /**  *hide boolean check for empty info. */
    this.emptyIndications = true;
  }
  static get styles() {
    return [thumbnailStyles];
  }
  render() {
    return html`
      ${!this.loading
        ? html`
            <div
              class="thumbnail-wrapper"
              id="thumbnailWrapper"
              ?empty-info="${this.emptyInfo}"
              ?empty-data-type="${this.emptyDataTypeIcons}"
              ?empty-indications="${this.emptyIndications}"
            >
              <div class="image-wrapper">
                <div
                  class="image"
                  style='background-image:url("${this.src
                    ? this.src
                    : ''}"); padding-top: calc(100% / (${this.aspectRatio}));
                    --outline-color:${this.outlineColor}'
                ></div>
                ${this.showDiscardWrapper()}
                ${this.icon
                  ? html`<sh-icon class="thumbnail-icon" icon="${this.icon}"></sh-icon>`
                  : ''}
                <div class="data-type-wrapper">
                  <slot
                    name="data-type"
                    class="data-type"
                    @slotchange="${(e) => this.slotObserver(e, 'dataType')}"
                  ></slot>
                </div>
                ${this.selectable
                  ? html` <sh-checkbox
                      label=""
                      class="thumbnail-checkbox"
                      ?active="${this.checked}"
                      @active-changed="${(e) => {
                        this.checked = e.target.active;
                      }}"
                      ?indeterminate="${this.indeterminate}"
                    ></sh-checkbox>`
                  : ''}
                ${this.new ? html`<div class="new-icon"></div>` : ''}
                <div class="indications-wrapper">
                  ${this.badge
                    ? html`<sh-badge class="thumbnail-badge" type="${this.badge}"></sh-badge>`
                    : ''}
                  <slot
                    name="indications"
                    class="indications"
                    @slotchange="${(e) => this.slotObserver(e, 'indications')}"
                  ></slot>
                </div>
                <div class="info-slot-wrapper">
                  <slot
                    name="info"
                    class="info"
                    @slotchange="${(e) => this.slotObserver(e, 'info')}"
                  ></slot>
                </div>
              </div>
            </div>
            <div class="label-wrapper">
              ${this.isLabelOrNumber() ? this.getLabelOrNumber() : ''}
              ${this.options
                ? this.showIconsAndPopover()
                : html`<slot id="mainSlot" @slotchange="${this.mainSlotObserver}"></slot>`}
            </div>
            <div class="icons-wrapper">
              <slot name="icons" id="icons"></slot>
            </div>
          `
        : html`
            <div class="loading-wrapper">
              <div
                class="loading-image loading"
                style="padding-top: calc(100% / (${this.aspectRatio}));"
              ></div>
              ${this.isLabelOrNumber()
                ? this.getLabelOrNumber()
                : html` <div class="loading-text loading"></div>`}
            </div>
          `}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this.documentClickListener = this.documentClickActions.bind(this);
  }
  updated(changedProperties) {
    const listOfProperties = [
      'label',
      'src',
      'number',
      'active',
      'visualFocus',
      'selectable',
      'new',
      'badge',
      'checked',
      'indeterminate',
      'options',
      'condensed',
      'icon',
      'aspectRatio',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  getLabelOrNumber() {
    var _a, _b;
    return html`
      <sh-text
        size="body-2"
        color="secondary"
        class="label"
        id="sh-thumbnail-text"
        @mouseenter="${(e) => this.showTooltip(e, this.label)}"
      >
        ${((_a = this.number) === null || _a === void 0 ? void 0 : _a.toString()) &&
        !isNaN(this.number)
          ? html`<b class="id-number"
              >${this.number}
              ${this.label &&
              ((_b = this.number) === null || _b === void 0 ? void 0 : _b.toString())
                ? html`•`
                : ''}
            </b>`
          : ''}
        ${this.label ? this.label : ''}
      </sh-text>
      <sh-tooltip
        .label="${this.label}"
        delay
        placement="bottom-left"
        target="sh-thumbnail-text"
        position-fixed-adjustment
      ></sh-tooltip>
    `;
  }
  documentClickActions(e) {
    // if either the thumbnail itself is not clicked,
    // or if the popover is showing mobile view
    // and if the options icon is not clicked,
    // then hide the popover.
    var _a, _b, _c, _d;
    const optionsIcon =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.options-icon');
    const notClickedHost = e.target !== this;
    const popoverIsMobile =
      ((_b = this.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('.options-popover')) &&
      ((_d =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.options-popover')) === null || _d === void 0
        ? void 0
        : _d.mobile);
    const notClickedOptionsIcon = e.composedPath().indexOf(optionsIcon) === -1;
    if ((notClickedHost || popoverIsMobile) && notClickedOptionsIcon) {
      this.hasPopover = false;
    }
  }
  showIconsAndPopover() {
    return html` <sh-icon
        button
        icon="more"
        class="options-icon"
        id="optionsIcon"
        @click="${() => this.showOptions()}"
      ></sh-icon>
      ${this.hasPopover
        ? html` <sh-popover
            id="popover"
            class="options-popover"
            track-target-on="scrolling"
            scroll-action="refit"
            target="optionsIcon"
            position="bottom-left"
            @click="${this.handleMenuItemClick}"
            @keyup="${this.handleMenuItemKeyup}"
          >
            <slot id="mainSlot" @slotchange="${this.mainSlotObserver}"></slot>
          </sh-popover>`
        : ''}`;
  }
  showDiscardWrapper() {
    return this.discard === '' || this.discard
      ? html` <div class="discard-wrapper">
          ${this.discard.length === 0
            ? ''
            : html` <sh-icon class="discard-icon" icon=${this.discard}></sh-icon>`}
        </div>`
      : '';
  }
  isLabelOrNumber() {
    var _a;
    return this.label || ((_a = this.number) === null || _a === void 0 ? void 0 : _a.toString());
  }
  handleMenuItemClick(e) {
    let clickedElement;
    if (e.target) {
      clickedElement = e.target;
    }
    const hasClickedOnMenuItem =
      (clickedElement === null || clickedElement === void 0
        ? void 0
        : clickedElement.tagName.toString().toLowerCase()) === 'sh-menu-item';
    if (hasClickedOnMenuItem) {
      clickedElement === null || clickedElement === void 0
        ? void 0
        : clickedElement.removeAttribute('active');
      this.hasPopover = false;
    }
  }
  handleMenuItemKeyup(e) {
    let pressedElement;
    if (e.target) {
      pressedElement = e.target;
    }
    const pressedEnter = e.keyCode === KEYCODE.ENTER.keycode;
    const pressedSpace = e.keyCode === KEYCODE.SPACE.keycode;
    const hasPressedOnMenuItem =
      (pressedElement === null || pressedElement === void 0
        ? void 0
        : pressedElement.tagName.toString().toLowerCase()) === 'sh-menu-item';
    if (hasPressedOnMenuItem && (pressedEnter || pressedSpace)) {
      this.hasPopover = false;
    }
  }
  slotObserver(e, type) {
    const slot = e.target;
    const numberOfAssignedNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    if (type === 'indications') {
      this.emptyIndications = numberOfAssignedNodes === 0;
    } else if (type === 'dataType') {
      this.emptyDataTypeIcons = numberOfAssignedNodes === 0;
    } else if (type === 'info') {
      this.emptyInfo = numberOfAssignedNodes === 0;
    }
  }
  mainSlotObserver() {
    const numberOfMenuItems = this.querySelectorAll('sh-menu-item').length;
    this.options = numberOfMenuItems > 0;
  }
  showOptions() {
    this.hasPopover = !this.hasPopover;
    if (this.hasPopover) {
      document.body.addEventListener('click', this.documentClickListener);
    } else {
      document.body.removeEventListener('click', this.documentClickListener);
    }
    dispatchCustomEvent(this, 'menu-clicked', this);
  }
  showTooltip(e, content) {
    if (!deviceIdentifier.isTouchDevice()) {
      const tooltip = this.renderRoot.querySelector('sh-tooltip');
      if (content && tooltip) {
        const target = e.target;
        const isEllipsis = this.ellipsisChecker(
          target.scrollWidth,
          target.offsetWidth,
          target.scrollHeight,
          target.offsetHeight,
          'both'
        );
        if (isEllipsis) {
          tooltip.target = target;
          tooltip.visible = true;
          tooltip.label = content;
        } else {
          tooltip.target = '';
          tooltip.visible = false;
          tooltip.label = '';
        }
      }
    }
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.documentClickListener);
    super.disconnectedCallback();
  }
};
__decorate([property({ type: String, reflect: true })], SHThumbnail.prototype, 'src', void 0);
__decorate([property({ type: String, reflect: true })], SHThumbnail.prototype, 'label', void 0);
__decorate([property({ type: Number, reflect: true })], SHThumbnail.prototype, 'number', void 0);
__decorate([property({ type: String, reflect: true })], SHThumbnail.prototype, 'icon', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHThumbnail.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHThumbnail.prototype, 'new', void 0);
__decorate([property({ type: String, reflect: true })], SHThumbnail.prototype, 'badge', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'visual-focus' })],
  SHThumbnail.prototype,
  'visualFocus',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'selectable',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'aspect-ratio' })],
  SHThumbnail.prototype,
  'aspectRatio',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHThumbnail.prototype, 'options', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'indeterminate',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHThumbnail.prototype, 'checked', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'condensed',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHThumbnail.prototype, 'discard', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHThumbnail.prototype, 'loading', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'hasPopover',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'outline-color' })],
  SHThumbnail.prototype,
  'outlineColor',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'emptyInfo',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'emptyDataTypeIcons',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHThumbnail.prototype,
  'emptyIndications',
  void 0
);
SHThumbnail = __decorate([customElement('sh-thumbnail')], SHThumbnail);
export { SHThumbnail };
