// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import menuItemStyles from '../../sh-menu-item/src/sh-menu-item.lit.scss.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin.js';
/**
 * @prop {String} label - Defines the label of the Menu Item.
 * @prop {String} value - Defines the value of the Menu Item.
 * @prop {String} icon - Defines the icon shown before the label. For available icon list, refer Icon page.
 * @prop {String} href - Defines the destination link to be be navigated to when the item is clicked.
 * @prop {Boolean} disabled - If set to `true`, the item will be shown as disabled and won't allow clicks.
 * @prop {Boolean} active - If set to `true`, the style will adapt to represent that the item is currently active.
 * @prop {Boolean} expanded - If chapter is set to true, this will determine whether the chapter is expanded or not. This state switches upon clicking on the arrow.
 * @prop {Boolean} checkbox - If set to `true`, checkbox will be added to menu item.
 * @prop {Boolean} toggle - Default true. Toggles the active property on click event.
 * @prop {String} iconColor - Sets the color of icon added by setting icon property.
 * @attr {String} icon-color - Sets the color of icon added by setting icon property.
 * @prop {Boolean} verticalAlignment - When vertical-alignment property is set, all items inside the menu item are vertical aligned.
 * @attr {String} vertical-alignment -  When vertical-alignment property is set, all items inside the menu item are vertical aligned.
 * @prop {String} iconSize -  Sets the icon size of icon component added through `icon` property. Possible values are `xs, s, m, l`.
 * @attr {String} icon-size -  Sets the icon size of icon component added through `icon` property. Possible values are `xs, s, m, l`..
 * @prop {String} labelRows -  Label can have multiple lines when set. Possible values are `1,2 and auto`.
 * @attr {String} label-rows -  Label can have multiple lines when set. Possible values are `1,2 and auto`.
 * @fires expand - Fired when clicking on the arrow icon (if expanded is false).
 * @fires collapse - Fired when clicking on the arrow icon (if expanded is true).
 */
let SHMenuItem = class SHMenuItem extends ControlStateMixin(
  FocusBlurMixin(RefrainSpaceScrollMixin(TooltipEllipsisCheckerMixin(LitElement)))
) {
  constructor() {
    super(...arguments);
    /**Defines the label of the Menu Item. */
    this.label = 'label';
    /**Defines the value of the Menu Item. */
    this.value = '';
    /** Default true. Toggles the active property on click event. */
    this.toggle = true;
    /**Sets the icon size of icon component added through `icon` property. Possible values are `xs, s, m, l`.*/
    this.iconSize = 'm';
    /**When vertical-alignment property is set, all items inside the menu item are vertical aligned.*/
    this.verticalAlignment = false;
    /**Label can have multiple lines when set. Possible values are `1,2 and auto`.*/
    this.labelRows = '1';
  }
  static get styles() {
    return [sharedStyles, menuItemStyles];
  }
  render() {
    this.checkbox ? (this.href = undefined) : nothing;
    return html`
      <div class="item-wrapper">
        ${this.chapter
          ? html` <sh-icon
              id="menuExpandIcon"
              .disabled="${this.disabled}"
              button
              icon="arrow-right-s"
              size="s"
              class="arrow-icon"
              @click="${this.handleExpandIconClick}"
              tabindex="-1"
            >
            </sh-icon>`
          : ''}
        ${this.href !== undefined
          ? html`<a
              href="${this.href}"
              id="menuExpandWrapper"
              class="menu-item-wrapper${!this.chapter ? ' focus-item' : ''}"
              role="button"
              ?active="${this.active}"
              ?disabled="${this.disabled}"
            >
              ${this.menuItemContent()}
            </a>`
          : html`<div
              id="menuExpandWrapper"
              class="menu-item-wrapper${!this.chapter ? ' focus-item' : ''}"
              role="button"
              ?active="${this.active}"
              ?disabled="${this.disabled}"
              @click="${this.handleClicked}"
            >
              ${this.menuItemContent()}
            </div>`}
        ${this.verticalAlignment ? html` <slot name="functions" id="functions"></slot>` : ''}
      </div>
      <div class="chapter-wrapper">
        <slot id="chapterSlot" @slotchange="${this.chapterSlotObserver}"></slot>
      </div>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'listitem');
    }
    this.handleItemFocus = this.handleItemFocus.bind(this);
    this.addEventListener('focus', this.handleItemFocus);
    this.addOrRemoveClass();
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.addEventListener('keyup', (e) => this.handleOnKeyup(e));
    this.addEventListener('keydown', (e) => this.handleKeydown(e));
  }
  update(changedProperties) {
    if (changedProperties.has('disabled')) {
      this.handleDisabledChanged();
    }
    if (changedProperties.has('active')) {
      this.updateComplete.then(() => {
        this.handleActiveChange();
      });
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    //active not added to list since it is dispatched in handleActiveChange method
    const listOfProperties = ['icon', 'href', 'expanded', 'value'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  menuItemContent() {
    return html`<div class="vertical-item-wrapper">
        ${this.icon && !this.checkbox
          ? html` <sh-icon
              .icon="${this.icon}"
              size="${this.iconSize ? this.iconSize : 'm'}"
              id="menu-item-icon"
              .color="${this.iconColor}"
            >
            </sh-icon>`
          : ''}
        ${this.checkbox
          ? html` <sh-checkbox
              neutral
              tabindex="-1"
              label=""
              ?active="${this.active}"
              .disabled="${this.disabled}"
              .indeterminate="${this.indeterminate}"
              id="checkbox"
            ></sh-checkbox>`
          : ''}
        <!--Do not change the class name "menu-item-label" as it could be used at application end to expand/collapse tree structure view on click of menu item label or arrow-->
        ${this.displaymenuItemLabel()}
        <sh-tooltip placement="bottom-left" position-fixed-adjustment></sh-tooltip>
      </div>
      ${!this.verticalAlignment
        ? html`<div class="functions-wrapper">
            <slot name="functions" id="functions"></slot>
          </div> `
        : ''}`;
  }
  handleItemFocus() {
    this.onkeyup = (e) => {
      if (
        ((keyboardInteraction(e, KEYCODE.ENTER) && !this.checkbox) ||
          keyboardInteraction(e, KEYCODE.SPACE)) &&
        e.composedPath()[0] === this
      ) {
        this.handleClicked();
        if (this.href !== undefined) {
          window.open(this.href);
        }
      }
    };
  }
  showTooltip(e, content) {
    if (!deviceIdentifier.isTouchDevice()) {
      const tooltip = this.renderRoot.querySelector('sh-tooltip');
      if (content && tooltip) {
        const target = e.target;
        const isEllipsis = this.ellipsisChecker(
          target.scrollWidth,
          target.offsetWidth,
          target.scrollHeight,
          target.offsetHeight,
          'both'
        );
        if (isEllipsis) {
          tooltip.target = target;
          tooltip.visible = true;
          tooltip.label = content;
        } else {
          tooltip.target = '';
          tooltip.visible = false;
          tooltip.label = '';
        }
      }
    }
  }
  chapterSlotObserver() {
    var _a;
    const functionsSlot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#functions');
    const hasChildMenuItems =
      this.childElementCount >
      (functionsSlot === null || functionsSlot === void 0
        ? void 0
        : functionsSlot.assignedNodes({ flatten: true }).length);
    this.chapter = hasChildMenuItems;
    this.addOrRemoveClass();
  }
  addOrRemoveClass() {
    var _a;
    if (this.parentNode.tagName === 'SH-MENU-ITEM') {
      return;
    }
    const siblingItems = (_a = this.parentNode) === null || _a === void 0 ? void 0 : _a.children;
    if (siblingItems) {
      if (this.chapter) {
        for (const sibling of siblingItems) {
          sibling.classList.add('chapter-sibling');
        }
      } else if (!this.classList.contains('chapter-sibling')) {
        for (const sibling of siblingItems) {
          if (sibling.chapter) {
            this.classList.add('chapter-sibling');
            break;
          }
        }
      }
    }
  }
  handleDisabledChanged() {
    for (const child of this.children) {
      child.disabled = this.disabled;
    }
  }
  //Bubble up the "label" property on the click event to parent element
  handleClicked() {
    dispatchCustomEvent(this, 'clicked');
    this.clicked = true;
    this.toggleActive();
  }
  toggleActive() {
    if (this.toggle) {
      this.active = !this.active;
    }
  }
  //Event to dynamically set value of sh-tool component
  handleActiveChange() {
    //This event is used in dropdown
    dispatchCustomEvent(this, 'active-changed');
    if (this.active && !this.clicked) {
      dispatchCustomEvent(this, 'menu-item-active');
    }
    this.clicked = false;
  }
  handleExpandIconClick() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }
  handleKeydown(e) {
    const target = e.target;
    if (
      keyboardInteraction(e, KEYCODE.TAB) &&
      (target === null || target === void 0 ? void 0 : target.tagName) === 'SH-MENU-ITEM'
    ) {
      this.setTabIndex(target.children);
      if (!e.shiftKey) {
        if (
          target.nextElementSibling &&
          !target.nextElementSibling.disabled &&
          target.nextElementSibling.tagName === 'SH-MENU-ITEM'
        ) {
          this.addItemFocusedClass(target.nextElementSibling);
        } else if (
          target.parentElement &&
          target.parentElement.tagName === 'SH-MENU-ITEM' &&
          target.parentElement.nextElementSibling &&
          target.parentElement.nextElementSibling.tagName === 'SH-MENU-ITEM'
        ) {
          this.addItemFocusedClass(target.parentElement.nextElementSibling);
        }
      } else {
        if (target.previousElementSibling && !target.previousElementSibling.disabled) {
          this.addItemFocusedClass(target.previousElementSibling);
        }
      }
    }
    if (
      keyboardInteraction(e, KEYCODE.ARROW_DOWN) ||
      keyboardInteraction(e, KEYCODE.ARROW_UP) ||
      e.code === 'Home' ||
      e.code === 'End'
    ) {
      e.preventDefault();
    }
  }
  addItemFocusedClass(item) {
    if (!item.disabled) {
      item.tabIndex = 0;
      item.classList.add('item-focused');
    }
  }
  handleOnKeyup(e) {
    var _a, _b, _c, _d;
    let target = e.target;
    if (
      (target === null || target === void 0 ? void 0 : target.chapter) &&
      keyboardInteraction(e, KEYCODE.ARROW_RIGHT)
    ) {
      if (!target.expanded) {
        this.expand();
        e.stopPropagation();
      } else {
        target.children[0].focus();
      }
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_DOWN) || e.code === 'Home') {
      e.preventDefault();
      const focusNextItem = (element) => {
        let nextElement = element.nextElementSibling;
        while (nextElement) {
          if (nextElement.tagName === 'SH-MENU-ITEM' && !nextElement.disabled) {
            nextElement.focus();
            return true;
          }
          nextElement = nextElement.nextElementSibling;
        }
        return false;
      };
      const focusNextParentSibling = (element) => {
        let parent = element.parentElement;
        while (parent) {
          if (focusNextItem(parent)) {
            return true;
          }
          parent = parent.parentElement;
        }
        return false;
      };
      if (target.expanded && target.chapter && target.children.length > 0) {
        for (let i = 0; i < target.children.length; i++) {
          const child = target.children[i];
          if (child.tagName === 'SH-MENU-ITEM') {
            child.focus();
            return;
          }
        }
      } else if (target.tagName === 'SH-MENU-ITEM' && focusNextItem(target)) {
        return;
      } else if (target.parentElement) {
        if (focusNextItem(target.parentElement)) {
          return;
        } else if (focusNextParentSibling(target.parentElement)) {
          return;
        }
      }
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_UP) || e.code === 'End') {
      e.preventDefault();
      if (target.previousElementSibling !== null && target.tagName === 'SH-MENU-ITEM') {
        while (
          (target === null || target === void 0 ? void 0 : target.previousElementSibling) &&
          (((_a = target === null || target === void 0 ? void 0 : target.previousElementSibling) ===
            null || _a === void 0
            ? void 0
            : _a.disabled) ||
            ((_b =
              target === null || target === void 0 ? void 0 : target.previousElementSibling) ===
              null || _b === void 0
              ? void 0
              : _b.tagName) !== 'SH-MENU-ITEM')
        ) {
          target = target === null || target === void 0 ? void 0 : target.previousElementSibling;
        }
        (_c = target.previousElementSibling) === null || _c === void 0 ? void 0 : _c.focus();
      } else {
        (_d = target.parentElement) === null || _d === void 0 ? void 0 : _d.focus();
      }
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_LEFT)) {
      if (target.chapter && target.expanded) {
        this.collapse();
        this.setTabIndex(target.children);
        e.stopPropagation();
      } else {
        target.parentElement.focus();
      }
    }
  }
  setTabIndex(menuChildren) {
    Array.from(menuChildren).forEach((element) => {
      if (element.tagName === 'SH-MENU-ITEM') {
        element.tabIndex = -1;
      }
    });
  }
  collapse() {
    this.expanded = false;
    dispatchCustomEvent(this, 'collapse', null, false);
  }
  expand() {
    this.expanded = true;
    dispatchCustomEvent(this, 'expand', null, false);
  }
  displaymenuItemLabel() {
    return html` <div
      class="menu-item-label"
      @mouseover="${(e) => this.showTooltip(e, this.label)}"
      style="-webkit-line-clamp: ${this.labelRows !== '1' &&
      this.labelRows !== '2' &&
      this.labelRows !== 'auto'
        ? '2'
        : this.labelRows}"
    >
      ${this.label}
    </div>`;
  }
  disconnectedCallback() {
    this.removeEventListener('focus', this.handleItemFocus);
    super.disconnectedCallback();
  }
};
__decorate([property({ type: String, reflect: true })], SHMenuItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHMenuItem.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHMenuItem.prototype, 'href', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHMenuItem.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHMenuItem.prototype, 'chapter', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHMenuItem.prototype, 'expanded', void 0);
__decorate([property({ type: String, reflect: true })], SHMenuItem.prototype, 'value', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHMenuItem.prototype, 'checkbox', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHMenuItem.prototype, 'toggle', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHMenuItem.prototype,
  'iconColor',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-size' })],
  SHMenuItem.prototype,
  'iconSize',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'vertical-alignment' })],
  SHMenuItem.prototype,
  'verticalAlignment',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHMenuItem.prototype,
  'labelRows',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHMenuItem.prototype,
  'indeterminate',
  void 0
);
SHMenuItem = __decorate([customElement('sh-menu-item')], SHMenuItem);
export { SHMenuItem };
