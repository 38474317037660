// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import notificationItemStyles from './sh-notification-item.lit.scss.js';
/**
 * @attr {String} icon-color - Sets the color of the icon added through `icon` property.
 * @slot - Takes plain text or other components as children.
 * @slot footer - Takes button as children.
 * @fires close - Dispatched when clicking on the close icon and also on autoclose
 */
let SHNotificationItem = class SHNotificationItem extends LitElement {
  constructor() {
    super(...arguments);
    this.emptyFooter = true;
  }
  static get styles() {
    return [notificationItemStyles, sharedStyles];
  }
  render() {
    return html`
      ${this.label ? this.createHeaderWrapper() : ''} ${this.getDescription()}
      <div class="footer-wrapper" ?empty-footer=${this.emptyFooter}>
        <slot
          id="footer"
          name="footer"
          @slotchange="${(e) => {
            return this.updateFooterSlot(e.target);
          }}"
        ></slot>
      </div>
    `;
  }
  createHeaderWrapper() {
    if (this.visible === true && (this.type === 'confirmation' || this.type === 'alert')) {
      console.warn(
        "Notification type 'confirmation' and 'alert' are deprecated and will be removed in a future major release. Please transition to using 'success' and 'warning' as soon as possible to ensure compatibility with future updates."
      );
    }
    return html`
      <div class="header-wrapper">
        ${this.icon &&
        this.type !== 'alert' &&
        this.type !== 'confirmation' &&
        this.type !== 'error' &&
        this.type !== 'success' &&
        this.type !== 'warning'
          ? html` <sh-icon
              icon="${this.icon}"
              .color="${this.iconColor}"
              id="notification-icon"
              size="s"
            ></sh-icon>`
          : ''}
        ${this.type === 'error'
          ? html`<sh-icon icon="error" class="type-icon-error" size="s"></sh-icon>`
          : ''}
        ${this.type === 'alert' || this.type === 'warning'
          ? html`<sh-icon icon="warning" class="type-icon-warning" size="s"></sh-icon>`
          : ''}
        ${this.type === 'confirmation' || this.type === 'success'
          ? html`<sh-icon icon="success" class="type-icon-success" size="s"></sh-icon>`
          : ''}
        ${this.label ? html`<sh-text size="header-2" id="label">${this.label}</sh-text>` : ''}
        ${this.new ? html`<sh-badge class="badge"></sh-badge>` : ''}
        ${!this.sticky && this.label
          ? html`<sh-icon
              button
              icon="cancel"
              size="s"
              tabindex="0"
              @click="${() => this.fireClosevent()}"
              id="close-button"
            ></sh-icon>`
          : ''}
      </div>
    `;
  }
  getDescription() {
    return html` <div class="default">
      ${!this.sticky && !this.label
        ? html`
            <sh-icon
              button
              icon="cancel"
              size="s"
              tabindex="0"
              @click="${() => this.fireClosevent()}"
              id="close-button-2"
            ></sh-icon>
            ${this.new ? html`<sh-badge class="badge"></sh-badge>` : ''}
          `
        : ''}
      <sh-text size="body-1"> ${this.message ? this.message : html` <slot></slot> `} </sh-text>
      ${!this.label ? this.getBodyIcon() : ''}
    </div>`;
  }
  getBodyIcon() {
    return html`
      ${this.icon &&
      this.type !== 'alert' &&
      this.type !== 'confirmation' &&
      this.type !== 'error' &&
      this.type !== 'success' &&
      this.type !== 'warning'
        ? html` <sh-icon icon="${this.icon}" id="notification-icon" size="s"></sh-icon>`
        : ''}
      ${this.type === 'error'
        ? html`<sh-icon icon="error" class="type-icon-error" size="s"></sh-icon>`
        : ''}
      ${this.type === 'alert' || this.type === 'warning'
        ? html`<sh-icon icon="warning" class="type-icon-warning" size="s"></sh-icon>`
        : ''}
      ${this.type === 'confirmation' || this.type === 'success'
        ? html`<sh-icon icon="success" class="type-icon-success" size="s"></sh-icon>`
        : ''}
    `;
  }
  updateFooterSlot(slot) {
    const numberOfNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = numberOfNodes === 0;
  }
  fireClosevent() {
    this.visible = false;
    // fire event on closing
    dispatchCustomEvent(this, 'close');
  }
  updated(changedProperties) {
    if (changedProperties.has('visible')) {
      this.autoClose();
    }
    const listOfProperties = [
      'label',
      'icon',
      'type',
      'message',
      'visible',
      'sticky',
      'flat',
      'new',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  autoClose() {
    const shortDuration = 2000;
    const longDuration = 5000;
    if (this.duration === 'short' && this.visible) {
      this.addsDelay(shortDuration);
    } else if (this.duration === 'long' && this.visible) {
      this.addsDelay(longDuration);
    } else if (this.duration && this.visible) {
      const customDuration = parseFloat(this.duration);
      this.addsDelay(customDuration);
    }
  }
  addsDelay(duration) {
    this.delay = setTimeout(() => {
      this.fireClosevent();
      clearTimeout(this.delay);
    }, duration);
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHNotificationItem.prototype,
  'label',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHNotificationItem.prototype,
  'icon',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHNotificationItem.prototype,
  'type',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHNotificationItem.prototype,
  'message',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHNotificationItem.prototype,
  'duration',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotificationItem.prototype,
  'visible',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotificationItem.prototype,
  'sticky',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotificationItem.prototype,
  'flat',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotificationItem.prototype,
  'new',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHNotificationItem.prototype,
  'inline',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHNotificationItem.prototype,
  'iconColor',
  void 0
);
__decorate([property({ type: Boolean })], SHNotificationItem.prototype, 'emptyFooter', void 0);
SHNotificationItem = __decorate([customElement('sh-notification-item')], SHNotificationItem);
export { SHNotificationItem };
