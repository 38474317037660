// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import ImageSegmentStyles from './sh-image-segment.lit.scss.js';
/**
 * @attr {Boolean} visual-focus - If set to `true`, the segment will have a solid outline with 2px thickness.
 * @attr {Boolean} hide-overlay - If set to `true`, the content inside the side and corner slots will be hidden.
 * @slot - The default slot which holds a functional viewing segment such as an img or a canvas.
 * @slot right - Take sh-toolbar as a child, which hosts elements such as tools or slider.
 * @slot left - Take sh-toolbar as a child, which hosts elements such as tools or slider.
 * @slot top - Take sh-toolbar as a child, which hosts elements such as tools or slider.
 * @slot bottom - Take sh-toolbar as a child, which hosts elements such as tools or slider.
 * @slot top-right - Take sh-image-text as children elements.
 * @slot top-left - Take sh-image-text as children elements.
 * @slot bottom-right - Take sh-image-text as children elements.
 * @slot bottom-left - Take sh-image-text as children elements.
 */
let SHImageSegment = class SHImageSegment extends LitElement {
  /** @ignore */
  static get styles() {
    return [ImageSegmentStyles];
  }
  render() {
    return html`
      <div class="segment-outline" style="border-color: ${this.color}"></div>
      <!-- corner slots -->
      <div class="corner top left">
        <slot name="top-left"></slot>
      </div>
      <div class="corner top right">
        <slot name="top-right"></slot>
      </div>
      <div class="corner bottom left">
        <slot name="bottom-left"></slot>
      </div>
      <div class="corner bottom right">
        <slot name="bottom-right"></slot>
      </div>
      <!-- side slots -->
      <div class="side left">
        <slot name="left"></slot>
      </div>
      <div class="side right">
        <slot name="right"></slot>
      </div>
      <div class="side top">
        <slot name="top"></slot>
      </div>
      <div class="side bottom">
        <slot name="bottom"></slot>
      </div>
      <!-- image slot -->
      <slot></slot>
    `;
  }
};
__decorate([property({ type: String, reflect: true })], SHImageSegment.prototype, 'color', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'visual-focus' })],
  SHImageSegment.prototype,
  'visualFocus',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'hide-overlay' })],
  SHImageSegment.prototype,
  'hideOverlay',
  void 0
);
SHImageSegment = __decorate([customElement('sh-image-segment')], SHImageSegment);
export { SHImageSegment };
