// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement, svg } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { convertToPixel } from '../../utils/rem-to-pixel-convertor.js';
import riskScoreStyles from './sh-risk-score.lit.scss.js';
/**
 * @slot footer - Takes icons, text or links as children.
 */
let SHRiskscore = class SHRiskscore extends LitElement {
  static get styles() {
    return [sharedStyles, riskScoreStyles];
  }
  render() {
    return html`
      <div class="risk-wrapper" ?empty-footer=${this.emptyFooter}>
        <div class="header-wrapper">
          <sh-text size="title-1" class="label">${this.label}</sh-text>
          ${this.value && !this.radial
            ? html` <sh-text size="title-1" class="value">${this.value}</sh-text> `
            : ''}
        </div>
        ${!this.radial
          ? html` <div class="linear-wrapper">${this.createLinearSegments()}</div> `
          : html`
              <div class="radial-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="radial"
                  viewBox="${this.size === 's' ? 0 : -2} 
                  ${this.size === 's' ? 0 : -2} 
                  ${this.convertRadialSize() + (this.size === 's' ? 0 : 4)} 
                  ${this.convertRadialSize() + (this.size === 's' ? 0 : 4)}"
                  preserveAspectRatio="xMidYMid meet"
                >
                  ${this.createRadialSegments()}
                </svg>
                ${this.value
                  ? html` <sh-text size="title-1" class="radial-label">${this.value}</sh-text> `
                  : ''}
              </div>
            `}
        <div class="footer-wrapper">
          <slot
            class="footer"
            id="footer"
            name="footer"
            @slotchange="${() => this.footerSlotObserver()}"
          ></slot>
        </div>
      </div>
    `;
  }
  constructor() {
    super();
    /** Defines the value of each segment in the Riskscore. */
    this.segmentValue = [];
    /** Defines the color for each segments in the Riskscore. */
    this.segmentColor = ['default'];
    this.size = 's';
    this.segments = 1;
    this.label = 'Label';
    this.segmentColor = !this.segmentColor ? ['default'] : this.segmentColor;
    this.segmentValue = !this.segmentValue ? [] : this.segmentValue;
    this.currentAngle = 90;
  }
  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => {
      this.requestUpdate();
    });
    this.resizeObserver.observe(this);
  }
  disconnectedCallback() {
    var _a;
    (_a = this.resizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
    super.disconnectedCallback();
  }
  update(changedProperties) {
    super.update(changedProperties);
    const listOfProperties = ['value'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
    this.footerSlotObserver();
  }
  createLinearSegments() {
    const linearSegmentsArray = [];
    for (let segmentNo = 0; segmentNo < Math.round(this.segments); segmentNo++) {
      linearSegmentsArray.push(html`
        <div class="riskbar" style="width: 100%">
          <div
            class="bar-value"
            style="width: ${this.getSegmentValue(segmentNo)}% ;
        background-color: ${this.getSegmentColor(segmentNo)}"
          ></div>
        </div>
      `);
    }
    return linearSegmentsArray;
  }
  createRadialSegments() {
    const radialSegmentsArray = [];
    for (let segmentNo = 0; segmentNo < Math.round(this.segments); segmentNo++) {
      radialSegmentsArray.push(svg`
                    <path class="radial-background" 
                      fill=none
                      stroke= 'rgba(var(--ui-1), var(--opacity-5))'
                      d= "${
                        this.calculateRadialSegmentPath(segmentNo, this.convertRadialSize(), true) +
                        ' ' +
                        (this.segments === 1 ? 'Z' : '')
                      }"/>
                    
                    <path
                      class="radial-segment" 
                      fill=none 
                      stroke="${this.getSegmentColor(segmentNo)}"
                      d = "${
                        this.calculateRadialSegmentPath(
                          segmentNo,
                          this.convertRadialSize(),
                          false
                        ) +
                        ' ' +
                        (this.segments === 1 && this.getSegmentValue(0) === 100 ? 'Z' : '')
                      }"
                     />
                  `);
    }
    return radialSegmentsArray;
  }
  getSegmentColor(segNo) {
    const disabledColor = 'rgba(var(--ui-1), var(--opacity-6))';
    this.calculateSegmentContent(segNo);
    const segmentColor = this.disabled ? disabledColor : this.segmentColor[segNo];
    return segmentColor;
  }
  getSegmentValue(segNo) {
    this.calculateSegmentContent(segNo);
    const segmentValue = this.disabled ? 0 : this.segmentValue[segNo];
    return segmentValue;
  }
  calculateSegmentContent(segNo) {
    const defaultColor = 'rgba(var(--ui-1), var(--opacity-5))';
    const activeColor = 'rgba(var(--ui-1), var(--opacity-3))';
    const segColor = this.segmentColor[segNo];
    const segValue = this.segmentValue[segNo];
    //default color or undefined will have value 0 and default color
    if ((!segColor || segColor === 'default' || segColor === defaultColor) && !segValue) {
      this.segmentColor[segNo] = defaultColor;
      this.segmentValue[segNo] = 0;
    }
    //if value is greater than 0 & color is not defined  => segment takes active color
    else if (
      (!segColor || segColor === 'default' || segColor === 'active' || segColor === defaultColor) &&
      segValue > 0
    ) {
      this.segmentColor[segNo] = activeColor;
      if (!segValue) {
        this.segmentValue[segNo] = 100;
      }
    }
    //segment takes the user defiend color and value
    else {
      if (!segValue) {
        this.segmentValue[segNo] = 100;
      }
    }
  }
  calculateRadialSegmentPath(segNo, radialsize, isOuter) {
    // let cx, cy;
    // cx = cy = radialsize / 2;
    const cx = radialsize / 2;
    const cy = radialsize / 2;
    const radius = radialsize / 2 - 4;
    const segmentGap = 8;
    const startAngle = this.currentAngle + 4;
    const segmentValue = this.getSegmentValue(segNo);
    const arcAngle = 360 / Math.round(this.segments) - segmentGap;
    let endAngle;
    if (isOuter) {
      endAngle = startAngle + arcAngle;
    } else {
      endAngle = segmentValue === 0 ? startAngle : startAngle + arcAngle / (100 / segmentValue);
      this.currentAngle += 360 / Math.round(this.segments);
    }
    return this.getArcPath(cx, cy, radius, startAngle, endAngle);
  }
  polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }
  getArcPath(x, y, radius, startAngle, endAngle) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
      ' '
    );
    return d;
  }
  footerSlotObserver() {
    var _a;
    const footerSlot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#footer');
    const footerNodes = footerSlot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerNodes === 0;
  }
  convertRadialSize() {
    let size;
    switch (this.size) {
      case 's':
        size = parseFloat(getComputedStyle(this).getPropertyValue('--sh-risk-score-sizeS'));
        break;
      case 'l':
        size = parseFloat(getComputedStyle(this).getPropertyValue('--sh-risk-score-sizeL'));
        break;
    }
    return convertToPixel(size);
  }
};
__decorate([property({ type: String, reflect: true })], SHRiskscore.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHRiskscore.prototype, 'value', void 0);
__decorate([property({ type: Number, reflect: true })], SHRiskscore.prototype, 'segments', void 0);
__decorate([property({ type: String, reflect: true })], SHRiskscore.prototype, 'size', void 0);
__decorate(
  [property({ type: Array, reflect: true, attribute: 'segment-value' })],
  SHRiskscore.prototype,
  'segmentValue',
  void 0
);
__decorate(
  [
    property({ type: Array, reflect: true, attribute: 'segment-color' }),
    // public segmentColor: string[] = ['default'];
  ],
  SHRiskscore.prototype,
  'segmentColor',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHRiskscore.prototype, 'radial', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHRiskscore.prototype, 'disabled', void 0);
__decorate(
  [property({ type: Number, reflect: true })],
  SHRiskscore.prototype,
  'currentAngle',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'empty-footer' })],
  SHRiskscore.prototype,
  'emptyFooter',
  void 0
);
SHRiskscore = __decorate([customElement('sh-risk-score')], SHRiskscore);
export { SHRiskscore };
