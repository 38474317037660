// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-image-text.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-image-segment-slot-left=0.75rem] - slot left
   */
  --sh-image-segment-slot-left: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-right=0.75rem] - slot right
   */
  --sh-image-segment-slot-right: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-top=0.75rem] - slot top
   */
  --sh-image-segment-slot-top: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-bottom=0.75rem] - slot bottom
   */
  --sh-image-segment-slot-bottom: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-left-right-margin=0.25rem 0] - margin for left and right side partions of slot
   */
  --sh-image-segment-left-right-margin: 0.25rem 0;
  /* 
   * @cssprop [--sh-image-segment-top-bottom-margin=0 0.25rem] - margin for top and bottom side partions of slot
   */
  --sh-image-segment-top-bottom-margin: 0 0.25rem;
  /* 
   * @cssprop [--sh-image-segment-text-line-height=1.25rem] - line-height of text
   */
  --sh-image-segment-text-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-image-segment-corner-right-ml=0.5rem] - margin-left of corner-right
   */
  --sh-image-segment-corner-right-ml: 0.5rem;
}

:host {
  transition: var(--transition-time) all ease-in-out;
  width: 100%;
  font: var(--body-1);
  line-height: var(--sh-image-segment-text-line-height);
  color: var(--text-white);
  text-shadow: 0.0625rem 0.0625rem 0.25rem black, -0.0625rem -0.0625rem 0.25rem black, 0rem 0rem 0.25rem black, 0rem 0rem 0.125rem black;
  word-wrap: break-word;
}

:host([alignment=left]) {
  text-align: left;
}

:host([alignment=right]) {
  text-align: right;
}`;