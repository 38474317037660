import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { ControlStateMixin } from '../../utils/control-state-mixin';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { windowLocation } from '../../utils/window-location.js';
import iconFont from './sh-icon.font.lit.scss.js';
import iconStyles from './sh-icon.lit.scss.js';
let SHIcon = class SHIcon extends ControlStateMixin(FocusBlurMixin(LitElement)) {
  constructor() {
    super(...arguments);
    /** Defines the size of the icon. Accepted values are xs - 16px, s - 24px, m - 32px, l - 48px.
     * @type {"xs"|"s"|"m"|"l"}
     */
    this.size = 'm';
    /** If set to `true`, adds button behavior and styles to icon. */
    this.button = false;
    /**If the icon is of a toggle and button type, defines if it is active(true) or not (false). */
    this.active = false;
    /**If set to true, the icon button will have a toggle behavior, turning on and off upon click on the icon.*/
    this.toggle = false;
    /**If set to true it adds a background to toggleable icon buttons during hover and pressed states */
    this.ixBackground = false;
  }
  static get styles() {
    return [iconFont, iconStyles, extendInteractiveArea(':host([button])')];
  }
  render() {
    return html`<div class="first"></div>
      <div class="second"></div>
      <div class="icon">
        ${this.badge !== undefined && this.badge !== null
          ? html` <sh-badge class="badge" label="${this.badge}"></sh-badge>`
          : nothing}
      </div>
      ${this.toggle || this.active || (this.button && this.ixBackground)
        ? html` <div class="interactive-background"></div>`
        : nothing} `;
  }
  connectedCallback() {
    /** sh-icon handles the default tabindex value by itself */
    this.defaultTabIndex = '';
    if (!this.button && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '-1');
    }
    if (this.button && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('icon')) {
      this.updateIcon();
    }
    if (changedProperties.has('color')) {
      this.updateColor();
    }
    const listOfProperties = ['icon', 'button', 'color', 'size', 'href', 'badge', 'toggle'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.addEventListener('click', this.clickEventListener);
    this.addEventListener('mousedown', function () {
      this.classList.add('active');
    });
    this.addEventListener('mouseup', function () {
      this.classList.remove('active');
    });
  }
  handleKeyup(e) {
    super.handleKeyup(e);
    if (keyboardInteraction(e, KEYCODE.ENTER)) {
      this.click();
    }
  }
  _spaceKeyAction() {
    this.click();
  }
  clickEventListener(e) {
    if (this.href !== undefined) {
      windowLocation.setWindowLocationHRef(this.href);
    }
    if (this.disabled) {
      e.stopImmediatePropagation();
    }
    if (e.type == 'click' && this.button) {
      this._toggleActive();
    }
  }
  updateIcon() {
    var _a;
    const firstDiv =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.first');
    if (firstDiv == null) return;
    if (this.icon && this.icon.indexOf('url') >= 0) {
      firstDiv.style.backgroundImage = this.icon;
    } else {
      firstDiv.style.backgroundImage = '';
    }
    switch (this.icon) {
      case 'patient-position-prone':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use 'position-supine' instead.`
        );
        break;
      case 'patient-position-supine':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-prone" instead.`
        );
        break;
      case 'patient-position-lateral-right':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-lateral-left" instead.`
        );
        break;
      case 'patient-position-lateral-left':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-lateral-right" instead.`
        );
        break;
      default:
        return;
    }
  }
  updateColor() {
    if (this.color !== undefined) {
      this.style.color = this.color;
    }
  }
  _toggleActive() {
    if (this.toggle) {
      this.active = !this.active;
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'badge', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'href', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'button', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'toggle', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'ix-background' })],
  SHIcon.prototype,
  'ixBackground',
  void 0
);
SHIcon = __decorate([customElement('sh-icon')], SHIcon);
export { SHIcon };
