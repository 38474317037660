// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-radio-group.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-radio-group-margin=0.5rem] - margin for the radio-button
   */
  --sh-radio-group-margin: 0.5rem;
  /* 
   * @cssprop [--sh-radio-group-horizontal-gap=0.5rem 1.5rem] - horizontal gap in-between radio-buttons
   */
  --sh-radio-group-horizontal-gap: 0.5rem 1.5rem;
}

:host {
  width: 100%;
  outline: 0;
  display: block;
}

.radio-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#grouped {
  flex: 1;
  box-sizing: border-box;
  display: flex;
}

:host([horizontal]) #grouped {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--sh-radio-group-horizontal-gap);
}

:host(:not([horizontal])) #grouped {
  flex-direction: column;
}

:host([horizontal]) .radio-wrapper > ::slotted(sh-radio-button) {
  width: unset;
}

:host(:not([horizontal])) .radio-wrapper > ::slotted(*) {
  margin-top: var(--sh-radio-group-margin);
}

:host(:not([horizontal])) .radio-wrapper > ::slotted(:first-child) {
  margin-top: 0;
}`;