// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2022. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { property } from 'lit/decorators.js';
/** ### ThrottlingMixin
 * ___
 * A class aimed to add the throttling technique.
 * ___
 * #### How to use this ?
 *
 * Import like this in the component:
 * ```js
 * import { ThrottlingMixin } from '../../utils/throttling-mixin';
 * ```
 * ___
 *
 */
export const ThrottlingMixin = (superClass) => {
  class Throttle extends superClass {
    constructor() {
      super(...arguments);
      this.delay = 0;
    }
    throttle(throttlingFunction, delay) {
      /* Everytime any event happens like scroll, resize or a mouseover, a function is called to perform some task.
       * Throttling technique reduces the number of calls to the function depending upon how the user interacts with the application.
       * A timeout is defined which calls the function after a certain delay.
       */
      if (this.timerSet) return;
      this.throttleCallback = throttlingFunction;
      this.delay = delay;
      this.clock = window.requestAnimationFrame(this.clockControl.bind(this));
    }
    clockControl() {
      this.timerSet = true;
      this.timer = setTimeout(() => {
        this.timerSet = undefined;
        this.throttleCallback();
      }, this.delay);
    }
    disconnectedCallback() {
      window.cancelAnimationFrame(this.clock);
      clearTimeout(this.timer);
      super.disconnectedCallback();
    }
  }
  __decorate([property({ type: Number, reflect: true })], Throttle.prototype, 'delay', void 0);
  __decorate([property({ type: Boolean, reflect: true })], Throttle.prototype, 'timerSet', void 0);
  return Throttle;
};
