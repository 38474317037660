// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-splash-screen.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-splash-screen-logo-height=2.5rem] - height of the logo
   */
  --sh-splash-screen-logo-height: 2.5rem;
  /* 
   * @cssprop [--sh-splash-screen-logo-padding=0.25rem 0rem 0rem 0.25rem] - padding of the logo
   */
  --sh-splash-screen-logo-padding: 0.25rem 0rem 0rem 0.25rem;
  /* 
   * @cssprop [--sh-splash-screen-data-wrapper-width=30rem] - width of the data wrapper
   */
  --sh-splash-screen-data-wrapper-width: 30rem;
  /* 
   * @cssprop [--sh-splash-screen-data-wrapper-min-height=26.25rem] - min height of the data wrapper
   */
  --sh-splash-screen-data-wrapper-min-height: 26.25rem;
  /* 
   * @cssprop [--sh-splash-screen-data-wrapper-margin=1rem auto 0rem] - margin of the data wrapper
   */
  --sh-splash-screen-data-wrapper-margin: 1rem auto 0rem;
  /* 
   * @cssprop [--sh-splash-screen-label-font-size=1.875rem] - font size of the label
   */
  --sh-splash-screen-label-font-size: 1.875rem;
  /* 
   * @cssprop [--sh-splash-screen-label-line-height=2rem] - line height of the label
   */
  --sh-splash-screen-label-line-height: 2rem;
  /* 
   * @cssprop [--sh-splash-screen-label-margin-top=1rem] - margin top of the label
   */
  --sh-splash-screen-label-margin-top: 1rem;
  /* 
   * @cssprop [--sh-splash-screen-label-margin-bottom=2rem] - margin bottom of the label when version is not defined
   */
  --sh-splash-screen-label-margin-bottom: 2rem;
  /* 
   * @cssprop [--sh-splash-screen-header-2-margin-top=0.5rem] - margin top of the header 2
   */
  --sh-splash-screen-header-2-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-splash-screen-header-2-margin-bottom=2rem] - margin top of the header 2
   */
  --sh-splash-screen-header-2-margin-bottom: 2rem;
  /* 
   * @cssprop [--sh-splash-screen-header-2-line-height=1.5rem] - line height of the header 2
   */
  --sh-splash-screen-header-2-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-splash-screen-data-wrapper-margin-bottom-slotted=0.5rem] - margin bottom of data wrapper
   */
  --sh-splash-screen-data-wrapper-margin-bottom-slotted: 0.5rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-wrapper-margin-top=1.5rem] - margin top of footer wrapper
   */
  --sh-splash-screen-footer-wrapper-margin-top: 1.5rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-wrapper-margin-bottom=1rem] - margin bottom of footer wrapper
   */
  --sh-splash-screen-footer-wrapper-margin-bottom: 1rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-wrapper-margin-left-slotted-items=0.5rem] - margin left of footer wrapper slotted items
   */
  --sh-splash-screen-footer-wrapper-margin-left-slotted-items: 0.5rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-text-margin-top=1rem] - margin top of footer text
   */
  --sh-splash-screen-footer-text-margin-top: 1rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-text-min-height=2rem] - min height of footer text
   */
  --sh-splash-screen-footer-text-min-height: 2rem;
  /* 
   * @cssprop [--sh-splash-screen-footer-text-slotted-margin-bottom=0.25rem] - margin bottom of slotted item in footer text
   */
  --sh-splash-screen-footer-text-slotted-margin-bottom: 0.25rem;
  /* 
   * @cssprop [--sh-splash-screen-modal-min-height=35rem] - min height of the modal
   */
  --sh-splash-screen-modal-min-height: 35rem;
  /* 
   * @cssprop [--sh-splash-screen-modal-width=50rem] - width of the modal
   */
  --sh-splash-screen-modal-width: 50rem;
  /* 
   * @cssprop [--sh-splash-screen-image-margin=-1rem -1rem -1rem 1rem] - margin of the image
   */
  --sh-splash-screen-image-margin: -1rem -1rem -1rem 1rem;
  /* 
   * @cssprop [--sh-splash-screen-image-border-radius=0rem 0.125rem 0.125rem 0rem] - border radius of the image
   */
  --sh-splash-screen-image-border-radius: 0rem 0.125rem 0.125rem 0rem;
}

:host {
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  z-index: 5;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
}

sh-card {
  height: 100%;
  width: 100%;
}

:host([modal]) sh-card {
  height: fit-content;
  height: -moz-fit-content;
  height: -ms-fit-content;
  min-height: var(--sh-splash-screen-modal-min-height);
  width: var(--sh-splash-screen-modal-width);
  max-width: calc(100% - 1rem);
}

.logo {
  padding: var(--sh-splash-screen-logo-padding);
  height: var(--sh-splash-screen-logo-height);
  min-height: var(--sh-splash-screen-logo-height);
  width: fit-content;
  width: -moz-fit-content;
  width: -ms-fit-content;
}

.logo img {
  height: 100%;
}

.siemens {
  fill: var(--logo-2);
}

.healthineers {
  fill: var(--logo-1);
}

/* progress bar */
:host(:not([progress])) sh-progress,
:host([progress]) .data-wrapper ::slotted(*) {
  display: none;
}

.data-wrapper > ::slotted(*) {
  margin-bottom: var(--sh-splash-screen-data-wrapper-margin-bottom-slotted);
}

.data-wrapper {
  width: var(--sh-splash-screen-data-wrapper-width);
  max-width: 100%;
  min-height: var(--sh-splash-screen-data-wrapper-min-height);
  margin: var(--sh-splash-screen-data-wrapper-margin);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:host(:not([label])) .label {
  display: none;
}

.label {
  text-align: center;
  font-size: var(--sh-splash-screen-label-font-size);
  line-height: var(--sh-splash-screen-label-line-height);
  margin-top: var(--sh-splash-screen-label-margin-top);
  word-break: break-word;
}

:host([label-alignment=left]) .label,
:host([label-alignment=left]) .version {
  text-align: left;
}

:host(:not([version]):not([sub-headline])) .label {
  margin-bottom: var(--sh-splash-screen-label-margin-bottom);
}

sh-text[header-2] {
  margin-top: var(--sh-splash-screen-header-2-margin-top);
  margin-bottom: var(--sh-splash-screen-header-2-margin-bottom);
  overflow: hidden;
  text-align: center;
  font: var(--header-2);
  line-height: var(--sh-splash-screen-header-2-line-height);
}

.content-container {
  display: flex;
  height: 100%;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

:host([image]) .content-wrapper {
  width: calc(50% - 0.5rem);
}

/* footer */
.footer-wrapper {
  width: 100%;
  display: flex;
  margin-top: var(--sh-splash-screen-footer-wrapper-margin-top);
  margin-bottom: var(--sh-splash-screen-footer-wrapper-margin-bottom);
  justify-content: flex-end;
  align-items: center;
}

.footer-wrapper > ::slotted(sh-button) {
  margin-left: var(--sh-splash-screen-footer-wrapper-margin-left-slotted-items);
}

.info {
  display: none;
}

.footer-text,
:host([empty-info]) .info {
  display: inline-grid;
  align-items: self-end;
  margin-top: var(--sh-splash-screen-footer-text-margin-top);
  min-height: var(--sh-splash-screen-footer-text-min-height);
  overflow: hidden;
}

.footer-text > ::slotted(*:not(:last-child)) {
  margin-bottom: var(--sh-splash-screen-footer-text-slotted-margin-bottom);
}

:host(:not([info])) .info {
  display: none;
}

:host([info][empty-info]) .footer-text {
  display: none;
}

/* image */
:host([image]) .image {
  flex: 1;
  margin: var(--sh-splash-screen-image-margin);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: var(--sh-splash-screen-image-border-radius);
}

@media only screen and (max-width: 30rem) {
  .logo {
    margin: auto;
  }
}
@media only screen and (max-width: 48rem) {
  :host([sh-scale=s]) .card {
    height: 100% !important;
    width: 100% !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: fit-content;
  }
  :host([sh-scale=s]) .content-wrapper {
    width: 100% !important;
  }
  :host([sh-scale=s]) .info {
    text-align: center;
  }
  :host([sh-scale=s]) .image {
    display: none;
  }
}
@media only screen and (max-width: 62rem) {
  :host([sh-scale=m]) .card {
    height: 100% !important;
    width: 100% !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: fit-content;
  }
  :host([sh-scale=m]) .content-wrapper {
    width: 100% !important;
  }
  :host([sh-scale=m]) .info {
    text-align: center;
  }
  :host([sh-scale=m]) .image {
    display: none;
  }
}
@media only screen and (max-width: 72rem), (max-height: 51rem) {
  :host([sh-scale=l]) .card {
    height: 100% !important;
    width: 100% !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: fit-content;
  }
  :host([sh-scale=l]) .content-wrapper {
    width: 100% !important;
  }
  :host([sh-scale=l]) .info {
    text-align: center;
  }
  :host([sh-scale=l]) .image {
    display: none;
  }
}
@media only screen and (max-width: 84rem), (max-height: 60rem) {
  :host([sh-scale=xl]) .card {
    height: 100% !important;
    width: 100% !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: fit-content;
  }
  :host([sh-scale=xl]) .content-wrapper {
    width: 100% !important;
  }
  :host([sh-scale=xl]) .info {
    text-align: center;
  }
  :host([sh-scale=xl]) .image {
    display: none;
  }
}`;