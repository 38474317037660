// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-text.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-text-super-header-line-height=2rem] - line height of super header text
   */
  --sh-text-super-header-line-height: 2rem;
  /* 
   * @cssprop [--sh-text-header-line-height=1.5rem] - line height of header text
   */
  --sh-text-header-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-text-title1-line-height=1.25rem] - line height of title1 text
   */
  --sh-text-title1-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-text-title2-line-height=1rem] - line height of title2 text
   */
  --sh-text-title2-line-height: 1rem;
  /* 
   * @cssprop [--sh-text-body1-line-height=1.25rem] - line height of body1 text
   */
  --sh-text-body1-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-text-body2-line-height=1rem] - line height of body2 text
   */
  --sh-text-body2-line-height: 1rem;
}

:host {
  height: auto;
  height: -ms-fit-content;
  height: -moz-fit-content;
  width: 100%;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  transition: var(--transition-time) color ease-in-out;
  display: block;
}

:host ::slotted(a) {
  color: currentColor;
}

:host ::slotted(a:hover),
:host ::slotted(a.hover) {
  color: var(--text-highlight);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
}

:host([super-header]),
:host([size=super-header]) {
  font: var(--super-header);
  line-height: var(--sh-text-super-header-line-height);
}

:host([size=header-1]) {
  font: var(--header-1);
}

:host([header]),
:host([size=header-2]) {
  font: var(--header-2);
}

:host([title-1]),
:host([size=title-1]) {
  font: var(--title-1);
}

:host([title-2]),
:host([size=title-2]) {
  font: var(--title-2);
}

:host([body-1]),
:host([size=body-1]) {
  font: var(--body-1);
}

:host([body-2]),
:host([size=body-2]) {
  font: var(--body-2);
  line-height: var(--sh-text-body2-line-height);
}

/* color */
:host([color=primary]),
:host([color=primary][href]) a {
  color: var(--text-primary);
}

/* color */
:host([color=secondary]),
:host([color=secondary][href]) a {
  color: var(--text-secondary);
}

/* disabled */
:host([disabled]),
:host([disabled][href]) a {
  color: var(--text-disabled);
  pointer-events: none;
}

:host([href][color=primary]:not([disabled])) a {
  color: var(--text-primary);
}

:host([href][color=secondary]:not([disabled])) a {
  color: var(--text-secondary);
}

/* href */
:host([href]:hover:not([disabled])[size=body-2]:not(.touch-device)) a,
:host([href]:hover:not([disabled])[size=body-1]:not(.touch-device)) a {
  color: var(--text-highlight);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
}

:host h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-size: inherit;
  font-weight: unset;
}

/* line height */
:host([size=header-1]),
:host([size=header-2]) {
  line-height: var(--sh-text-header-line-height);
}

:host([size=body-1]) {
  line-height: var(--sh-text-body1-line-height);
}

:host([size=title-1]) {
  line-height: var(--sh-text-title1-line-height);
}

:host([size=title-2]) {
  line-height: var(--sh-text-title2-line-height);
}`;